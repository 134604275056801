export const GET_REFUNDS = "GET_REFUNDS";
export const GET_REFUNDS_START = "GET_REFUNDS_START";
export const GET_REFUNDS_FAILED = "GET_REFUNDS_FAILED";
export const GET_REFUNDS_RESET = "GET_REFUNDS_RESET";


export const CREATE_REFUND_DONE = "CREATE_REFUND_DONE";
export const CREATE_REFUND_START = "CREATE_REFUND_START";
export const CREATE_REFUND_FAILED = "CREATE_REFUND_FAILED";
export const CREATE_REFUND_RESET = "CREATE_REFUND_RESET";
