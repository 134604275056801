import { createStore, applyMiddleware, compose } from "redux";
import { devToolsEnhancer } from "@redux-devtools/extension"; // using chrome extension tool
import { persistStore } from "redux-persist";
import thunk from 'redux-thunk'
import reducer from "./reducer";
// import logger from "./middlewares/logger";
import api from "./middlewares/api";

// const composedEnhancer = compose(applyMiddleware(logger), applyMiddleware(api), devToolsEnhancer({trace: true}));
const composedEnhancer: any = compose(applyMiddleware(api), devToolsEnhancer({trace: true}));

export const store = createStore(reducer, composedEnhancer);
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;