import moment from "moment";

export const isDate = (data) => {
  // return (Object.prototype.toString.call(data) === '[object Date]')
  // return data.constructor.toString().indexOf("Date") > -1;
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  return pattern.test(data);
}


export const isArray = (data) => {
  return data.constructor.toString().indexOf("Array") > -1;
}


export const dateFormatSubmit = (date) => {
  if (date !== ""){
    return moment(date).toISOString();
  }
  else{
    return "";
  }
}


export const dateFormatRemoveISO = (date) => {
  if (date !== "" && date !== null && date !== undefined){
    return moment(date).format("YYYY-MM-DD");
  }
  else{
    return "";
  }
}

