import OneTimeColored from "assets/images/one-time-colored.png";
import OneTime from "assets/images/one-time.png";
import TuitionColored from "assets/images/tuition-colored.png";
import Tuition from "assets/images/tuition.png";
import { Badge } from "components/ui/badge";
import CREATE_PAYMENT_LINK_STEP from "constants/payments/create.payment.link.step";
import { NavLink } from "react-router-dom";
import { PAYMENT_PATHS } from "../paths";
import { usePaymentLinkStore } from "zustand-store/payments/links";

export const PaymentType = () => {
  const { setCompleted, selectedType, setSelectedType } = usePaymentLinkStore();
  return (
    <section className="mt-10 flex flex-col items-center gap-8 max-w-96 w-full mx-auto">
      <div className="page-title">Create a payment link for,</div>

      <section className="space-y-5">
        <NavLink
          className="w-full"
          to={`${PAYMENT_PATHS.CREATE_PAYMENT_LINK}?tab=${CREATE_PAYMENT_LINK_STEP[1].slug}&type=${selectedType}`}
        >
          <div
            className={`flex items-center cursor-pointer gap-5 w-full p-5 rounded-3xl border`}
            onClick={() => {
              setSelectedType("one-time");
              setCompleted("payment_type")
            }}
          >
            {selectedType === "one-time" ? (
              <img src={OneTimeColored} alt="one-time" />
            ) : (
              <img src={OneTime} alt="one-time" />
            )}
            <div className="space-y-1 ">
              <h4 className="font-semibold">One-time Payment</h4>
              <p className="text-sm font-light text-subtext-gray">
                Create and send a link to collect payment from an individual or a
                business
              </p>
            </div>
          </div>
        </NavLink>

        <div
          className={`flex pointer-events-none items-center gap-5 w-full p-5 rounded-3xl border ${selectedType === "tuition" && "border-black"
            } `}
        >
          {selectedType === "tuition" ? (
            <img src={TuitionColored} alt="tuition" />
          ) : (
            <img src={Tuition} alt="tuition" />
          )}
          <div className="space-y-1">
            <h4 className="font-semibold flex items-center gap-1.5">
              <span>Tuition Payment</span>
              <Badge variant="outline">Coming soon</Badge>
            </h4>
            <p className="text-sm font-light text-subtext-gray">
              Create and send a link to an individual looking to make payment
              for tuition
            </p>
          </div>
        </div>
      </section>
      {/* <NavLink
        className="w-full"
        to={`${PAYMENT_PATHS.CREATE_PAYMENT_LINK}?tab=${CREATE_PAYMENT_LINK_STEP[1].slug}&type=${selectedType}`}
      >
        <Button
          onClick={() => setCompleted("payment_type")}
          className="w-full"
          disabled={!selectedType}
        >
          Continue
        </Button>
      </NavLink> */}
    </section>
  );
};
