import { useCallback, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import Maybe from "components/common/Maybe";
import validate from "utils/validate";
import ChevronDownIcon from "assets/images/icons/ChevronDown";

export function SelectInput(props) {
  const {
    // key,
    value,
    label,
    required,
    tooltip,
    defaultValue,
    placeholder,
    readonly,
    disabled,
    onChange,
    containerClass,
    selectClass,
    validationName,
    checkFormValidation,
    // children,
    isSearchable,
    searchPlaceholder
  } = props;
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState({
    name: undefined,
    value: undefined,
  });
  const [allChildren, setAllChildren] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  const getName = (data) => {
    if (Array.isArray(data) === true) {
      return data?.join("");
    } else {
      return data;
    }
  };

  const selectFirstOption = useCallback(
    (e = allChildren) => {
      if (e !== undefined && e[0]) {
        const { value, children } = e[0]?.props;
        setSelected({ name: children, value });
      }
    },
    [allChildren]
  );

  const getAllChildren = useCallback(() => {
    const options = [];
    if (!props.children.map) return;
    props?.children?.map((e) => {
      if (Array.isArray(e)) {
        const b = e?.filter((f) => f !== undefined);
        options.push(...b);
      } else {
        if (e !== undefined) {
          options.push(e);
        }
      }
      return null;
    });
    setAllChildren(options);
    selectFirstOption(options);
  }, [props.children, selectFirstOption]);

  const doOnChange = (data) => {
    let name = getName(data?.children ?? "");
    const value = data?.value ?? "";
    const countryCode = data?.dataset?.countryCode ?? "";
    const e = {
      target: {
        value,
        countryCode,
      },
    };
    onChange(e);
    doValidation(e);
    setSelected({ name, value });
  };

  const doValidation = (e) => {
    if (validationName !== undefined) {
      const data = { [validationName]: e.target.value };
      const vErrors = validate(data);
      if (vErrors) {
        setErrors(vErrors);
      } else {
        setErrors({});
      }
      checkFormValidation(undefined, validationName, e.target.value);
    }
  };

  useEffect(() => {
    getAllChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children]);

  useEffect(() => {
    if (value !== "" && value !== null && value !== undefined) {
      const selectedChild = allChildren.filter(
        (option) => option?.props?.value === value
      );
      setSelected({
        name: getName(selectedChild[0]?.props?.children),
        value: selectedChild[0]?.props?.value,
      });
    } else if (placeholder) {
      setSelected({ name: placeholder, value: "" });
    }
    // eslint-disable-next-line
  }, [value, allChildren]);

  useEffect(() => {
    if (query?.length > 0) {
      // eslint-disable-next-line array-callback-return
      const matches = allChildren?.filter((item) => {

        if (query === '') {
          return true;
        } else {
          if (typeof item?.props?.children === 'object') {
            return item?.props?.children?.props?.children[1]?.toLowerCase().includes(query.toLowerCase())
          } else if (typeof item?.props?.children === 'string') {
            return item?.props?.children?.toLowerCase().includes(query.toLowerCase())
          }
        }
      })

      if (matches.length > 0) {
        setAllChildren(matches)
      } else {
        getAllChildren();
      }
    }

    // eslint-disable-next-line
  }, [query])

  // useEffect(() => {
  //     if (!value && placeholder) {
  //         setSelected({ name: placeholder, value: "" });
  //     }
  // }, [placeholder, value]);

  return (
    <div className={`form-group ${containerClass || ""}`}>
      <Maybe condition={tooltip !== undefined}>
        <Tooltip id={label || ""} className="tooptip-size">
          {tooltip}
        </Tooltip>
      </Maybe>

      <Maybe condition={label !== "" && label !== " "}>
        <label className=" text-shiga-gray-100 text-[14px]">
          {label}
          <Maybe condition={required === true}>
            <span className="form-input-required">*</span>
          </Maybe>
          <Maybe condition={tooltip !== undefined}>
            <FontAwesomeIcon
              data-tip
              data-for={label || ""}
              icon="info-circle"
              className="ml-2 tip-icon text-ep-primary"
            />
          </Maybe>
        </label>
      </Maybe>

      <Menu as="div">
        <div className="w-full relative group inline-block">
          <div className="relative">
            <Menu.Button
              as="div"
              onClick={() => setIsOpen((prev) => !prev)}
              className={`w-full form-input !pt-3 ellipsis cursor-pointer ${selectClass ?? ""
                } ${(readonly || disabled) === true && "disabled"} ${selected.value !== placeholder && selected.value
                  ? "text-shiga-gray-black"
                  : "text-shiga-gray-100"
                }`}
            >
              {selected.name ?? defaultValue}
              <ChevronDownIcon
                className={`absolute top-6 right-5 translate-y-[-50%] ${isOpen ? "rotate-180" : ""
                  }`}
              />
            </Menu.Button>
          </div>

          <Menu.Items
            as="div"
            className={`w-full dropdown max-h-40   ${isOpen ? "overflow-y-auto" : ""
              } custom-scrollbar`}
          >
            {placeholder && (
              <Menu.Item as="div" disabled>
                <div className="dropdown-item flex items-center justify-between text-shiga-gray-100">
                  {placeholder}
                </div>
              </Menu.Item>
            )}

            <Maybe condition={isSearchable === true}>
              <div className="w-full bg-black text-white sticky top-0 left-0">
                <input
                  type="text"
                  value={query}
                  placeholder={searchPlaceholder || 'Search'}
                  onChange={((e) => setQuery(e.target.value))}
                  className="ring-0 border-0 w-[98%] mx-auto bg-transparent"
                />
              </div>
            </Maybe>

            {allChildren?.map((option, index) => (
              <Menu.Item
                as="div"
                // data-country-code={option.props['data-country-code']}
                key={index}
                onClick={() => {
                  doOnChange(option?.props);
                  setIsOpen(false);
                }}
                className="dropdown-item flex items-center justify-between"
              >
                <div className="ellipsis">
                  {/* <img
                                        src={option.props.icon}
                                        alt="currency-icon"
                                        className="w-[14px] h-[14px] mr-2"
                                    /> */}

                  {getName(option?.props?.children ?? "")}
                </div>
                <div className="w-7 text-right flex-shrink-0">
                  <Maybe condition={selected.value === option?.props?.value}>
                    <FontAwesomeIcon icon="check-circle" />
                  </Maybe>
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </div>
      </Menu>

      <Maybe condition={errors[validationName] !== undefined}>
        <div className="form-input-message">{errors[validationName]}</div>
      </Maybe>
    </div>
  );
}

export default SelectInput;
