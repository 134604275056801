import CustomTableFilter from "components/common/CustomTableFilter";
import Pagination from "components/common/Pagination";
import SearchBox from "components/common/SearchBox";

export const PaymentLinkFilterHandler = ({
  paymentLinks,
}: {
  paymentLinks: any;
}) => {
  return (
    <div className="my-2 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-2/4">
        <CustomTableFilter
          dropdownClass=""
          filters={[
            {
              title: "Status",
              name: "status",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "Active",
                  payload: "ACTIVE",
                },
                {
                  display: "Inactive",
                  payload: "INACTIVE",
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full lg:w-2/4 flex !items-center justify-between pt-5 lg:pt-0 lg:space-x-5 lg:justify-end">
        <Pagination meta={paymentLinks?.meta} />

        <div className="lg:pb-7">
          <SearchBox
            name="search"
            extraUrlParams="page=1"
            placeholder="Search transactions"
          />
        </div>
      </div>
    </div>
  );
};
