import { Steps } from "types/payment/steps";
import user from "assets/images/icons/user_circle.png";
import dollar from "assets/images/icons/dollar-2.svg";
// import PaperPlane from "assets/images/icons/PaperPlane";
import paperPlane from "assets/images/icons/paper-plane.svg";
import { ReactComponent as Note } from "assets/images/icons/note.svg";

export const CREATE_INVOICE_STEPS: Steps[] = [
  {
    name: "Invoice Details",
    slug: "invoice_details",
    icon: <Note  className="w-6 h-6"/>,
  },
  {
    name: "Receipient",
    slug: "receipient",
    icon: <img src={user} alt="recipient" />,
  },
  {
    name: "Add Items",
    slug: "add_items",
    icon: <img src={dollar} alt="add items" />,
  },
  {
    name: "Review & Send",
    slug: "review_send",
    icon: <img src={paperPlane} alt="review and send" />,
  },
];
