import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUrlNavigation from "hooks/useUrlNavigation";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import useUrlQueryParams from "utils/useUrlQueryParams";


const SearchBox = (props) => {
  const { name, placeholder, extraUrlParams: urlParams, noTopMargin } = props;

  const { urlQuery } = useUrlNavigation();
  const { urlQueryParams } = useUrlQueryParams();
  const navigate = useNavigate();

  const extraUrlParams = urlParams ? "&" + urlParams : "";

  const searchQuery = urlQueryParams[name];
  const [search, setSearch] = useState(searchQuery || "");

  const submitForm = async (e) => {
    e.preventDefault();
    navigate(urlQuery(name + "=" + search + extraUrlParams));
  }

  return (
    <form onSubmit={submitForm}>

      <div className={`mb-0 ${!noTopMargin && 'form-group'}`}>

        <div className="relative">
          <input
            type="search"
            id="search"
            name={name}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder}
            className="min-w-[200px] form-input pl-12"
            style={{ paddingLeft: "0", border: "none", outline: "none" }}
          />

          <div className={`absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500`}>
            <IconSearch />
          </div>
        </div>

      </div>

    </form>
  );
}

export default SearchBox;
