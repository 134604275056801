import * as actions from "./actionTypes";
import * as config from "data/config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function resetRcnValidation() {
    return {
        type: actions.RESET_RCN_VALIDATION,
        payload: {},
    }
}

export function validateRcn(payload) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiValidateRcNumber,
            method: "post",
            data: payload,
            onStart: actions.VALIDATE_RCN_START,
            onSuccess: actions.VALIDATE_RCN_DONE,
            onError: actions.VALIDATE_RCN_FAILED,
        },
    }
}

export function completeKybSubmission(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompleteKybSubmission}${id}/verification-status`,
            method: "patch",
            data: {},
            onStart: actions.COMPLETE_KYB_START,
            onSuccess: actions.COMPLETE_KYB_DONE,
            onError: actions.COMPLETE_KYB_FAILED,
        },
    }
}

export function deleteBusinessDirector(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteDirector + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_DIRECTOR_START,
            onSuccess: actions.DELETE_DIRECTOR_DONE,
            onError: actions.DELETE_DIRECTOR_FAILED,
        },
    }
}

export function updateBusinessInformation(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateBusinessInformation,
            method: "post",
            data: params,
            onStart: actions.UPDATE_BUSINESS_VERIFICATION_START,
            onSuccess: actions.UPDATE_BUSINESS_VERIFICATION,
            onError: actions.UPDATE_BUSINESS_VERIFICATION_FAILED,
        },
    }
}

export function updateBusinessDocuments(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateBusinessDocuments,
            method: "post",
            data: params,
            onStart: actions.UPDATE_BUSINESS_VERIFICATION_START,
            onSuccess: actions.UPDATE_BUSINESS_VERIFICATION,
            onError: actions.UPDATE_BUSINESS_VERIFICATION_FAILED,
        },
    }
}

export function updateBusinessOwner(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateBusinessOwner,
            method: "post",
            data: params,
            onStart: actions.UPDATE_BUSINESS_VERIFICATION_START,
            onSuccess: actions.UPDATE_BUSINESS_VERIFICATION,
            onError: actions.UPDATE_BUSINESS_VERIFICATION_FAILED,
        },
    }
}

export function addDirectors(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAddDirectors,
            method: "post",
            data: params,
            onStart: actions.ADD_DIRECTOR_START,
            onSuccess: actions.ADD_DIRECTOR,
            onError: actions.ADD_DIRECTOR_FAILED,
        },
    }
}

export function resetAddDirectors() {
    return {
        type: actions.ADD_DIRECTOR_RESET,
        payload: {},
    }
}

export function resetUpdateBusinessVerification() {
    return {
        type: actions.UPDATE_BUSINESS_VERIFICATION_RESET,
        payload: {},
    }
}

export function getSampleDocuments(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiBusinessDownloadSampleFile + (ObjectToQueryString(params) ?? ""),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_SAMPLE_DOCUMENTS_START,
            onSuccess: actions.DOWNLOAD_SAMPLE_DOCUMENTS,
            onError: actions.DOWNLOAD_SAMPLE_DOCUMENTS_FAILED,
        },
    }
}
