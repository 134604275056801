import { Business } from "./business";
import { STATUS } from "./misc";
import { Transaction } from "./transaction";
import { Wallet } from "./wallet";

export interface BillPayment {
  id: string;
  businessId: string;
  walletId: string;
  providerTxnId: string;
  paymentType: "AIRTIME" | "INTERNET" | "CABLE_TV" | "ELECTRICITY";
  amount: number;
  providerLogo: string;
  phoneNumber: string;
  cardNumber: string;
  email?: string;
  meterNumber: string;
  bundleCode: string;
  token: string;
  status: STATUS;
  currency: string;
  createdAt: string;
  updatedAt: string;
  meta?: any;
  type: string;
  networkType: string;
  transaction?: Transaction;
  business?: Business;
  wallet?: Wallet;
}

export interface BaseBillPayment {
  provider: string;
  providerLogoUrl: string;
}

export interface Airtime extends BaseBillPayment {
  minAmount: number;
  maxAmount: number;
  serviceCategoryId: string;
  name: string;
  logoUrl: string;
}

export interface Internet extends BaseBillPayment {
  serviceCategoryId
: string;
  name: string;
  logoUrl: string;
}

export interface TVService extends BaseBillPayment {
  serviceCategoryId
: string;
  name: string;
  logoUrl: string;
  identifier: string;
}

export interface Electricity extends BaseBillPayment {
  minAmount: number;
  serviceCategoryId
: string;
  name: string;
  logoUrl: string;
}

export interface CablePackage {
  amount: number;
  id: number;
  code: string;
  name: string;
  bundleCode: string;
}
export interface DataPlan {
  id: number;
  name: string;
  amount: string;
  bundleCode: string;
  validity: string;
}

export interface ValidateBill {
  code: string;
  // data: {
    name: string;
    number: string;
    provider: string;
    type: string;
    Current_Bouquet: string;
    Current_Bouquet_Code: string;
    Customer_Number: string;
    Customer_Type: string;
    Due_Date: string;
    Renewal_Amount: number;
    Status: string;
  // };
}

export interface ValidateElectricity {
  Customer_Name: string;
  number: string;
  provider: string;
  type: string;
}

export const defaultEletricity: ValidateElectricity = {
  Customer_Name: "",
  number: "",
  provider: "",
  type: "",
};

export type MeterType = "PREPAID" | "POSTPAID";
