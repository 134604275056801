import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader } from "components/common/form";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import * as userActions from "store/auth/user/action";
import PinInput from "react-pin-input";


const LoginMFA = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loginLoading, loginResponse, loginForm } = useSelector((s: any) => s.auth.user);
    const pinLength = 6;

    let elementRef: any = useRef();
    const [pin, setPin] = useState("");
    const [form, setForm] = useState({
        email: "",
    })

    const clearInputPin = () => {
        if (elementRef.currentIndex !== undefined) {
            elementRef.clear();
            setPin("");
        }
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors: any = validate(form);
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            if (pin !== "" && pin.length === pinLength) {
                const params = {
                    email: form.email,
                    token: pin,
                }
                dispatch(userActions.loginWithMFA(params));
            }
            else {
                toast.error("Please enter your 6-digit verification code");
            }
        }
    }


    const handlePaste = (event: any) => {
        const pastedData = event.clipboardData.getData('text');

        if (pastedData.length === pinLength) {
            setPin(pastedData);
        }
    };


    useEffect(() => {
        if (Object.keys(loginForm).length === 0) {
            navigate("/login");
        }
        else {
            clearInputPin();
            setForm({ ...form, email: loginForm.email })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (loginResponse?.success === true && loginResponse?.data?.token !== undefined) {
            dispatch(userActions.saveLoginForm({}));
            navigate("/dashboard");
        }
        // eslint-disable-next-line
    }, [loginResponse])

    return (
        <>
            <DynamicHeadTag
                headerText="Login"
            />

            <AuthPageTitle
                title="Enter 2FA code"
                description="Enter the 6-digit verification code generated by your authenticator app"
            />

            <div>
                <form onPaste={handlePaste} onSubmit={submitForm}>
                    <div className="mt-10">

                        <PinInput
                            length={pinLength}
                            initialValue={pin}
                            onChange={(value) => setPin(value)}
                            type="numeric"
                            inputMode="number"
                            inputStyle={{ width: '48px', height: '48px', background: "#FAFAFB", border: "2px solid transparent", marginRight: "6px", fontSize: "32px", fontWeight: "bold", paddingBottom: "2px", borderRadius: "1rem" }}
                            inputFocusStyle={{ border: "2px solid #343433" }}
                            onComplete={(value, index) => { }}
                            autoSelect={true}
                            focus={true}
                            secret={false}
                            ref={(n) => (elementRef = n)}
                        />

                        <ButtonLoader type="submit" loading={loginLoading} disabled={pin?.length < pinLength} className="btn btn-lg btn-block btn-primary mt-8">
                            Log In
                        </ButtonLoader>

                    </div>
                </form>
            </div>
        </>
    )
}


export default LoginMFA;