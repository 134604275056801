import React, { useState, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TransferMethod from "components/transfer/TransferMethod";
import SendToBankIcon from "assets/images/icons/send_to_bank.svg";
import SendToBankIconEnable from "assets/images/icons/send_to_bank_enable.png";
import SendToBulkIcon from "assets/images/icons/bulk_disabled.png";
import SendToBulkIconEnabled from "assets/images/icons/send_bulk_enabled.png";
import { ButtonLoader } from "components/common/form";
import PATHS from "NavigationRoute";
import { getCurrencies } from "store/ui/currencies/action";
import { useDispatch } from "react-redux";
import { getWalletsAndBalance } from "store/ui/dashboard/action";


const Transfer: React.FC = () => {
    const [selectedMethod, setSelectedMethod] = useState<string>("bank");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleMethodClick = (index: string): void => {
        setSelectedMethod(index);
    };

    useEffect(() => {
        dispatch(getCurrencies())
        dispatch(getWalletsAndBalance())
        // eslint-disable-next-line
    }, [])

    const handleContinueClicked = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (selectedMethod === "bank") {
            navigate(PATHS.TRANSFER_BENEFICIARY);
        } else if (selectedMethod === "bulk") {
            navigate(PATHS.TRANSFER_BULK_PAYMENT)
            // Add code to handle bulk payment if necessary
        }
    };

    const backgroundColorClass = selectedMethod === "bank" || selectedMethod === "bulk" ? "bg-black" : "bg-shiga-gray";

    return (
        <div className="transfer-container">
            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                Make a Transfer
            </div>

            <div className="transfer-wrapper">
                <div className="w-full mb-8">
                    <p className="transferHeading mt-2 lg:text-lg">
                        How would you like to make your transfer?
                    </p>
                </div>

                <div className="space-y-8 mt-4">
                    <TransferMethod
                        image={selectedMethod === "bank" ? SendToBankIconEnable : SendToBankIcon}
                        title="Send to a Bank Account"
                        details="Send money from your Shiga account to any bank account instantly."
                        onClick={() => handleMethodClick("bank")}
                        active={selectedMethod === "bank"}
                        borderColor={'#625BF6'}
                    />

                    <TransferMethod
                        image={selectedMethod === "bulk" ? SendToBulkIconEnabled : SendToBulkIcon}
                        title="Make a Bulk Payment"
                        details="Send money from your Shiga account to multiple bank accounts at the same time."
                        onClick={() => handleMethodClick("bulk")}
                        active={selectedMethod === "bulk"}
                        borderColor={'#FF4E4E'}
                    />

                    <div className="w-full flex items-center justify-center">
                        <ButtonLoader
                            disabled={false}
                            type="submit"
                            onClick={handleContinueClicked}
                            className={`btn ${backgroundColorClass} text-white btn-lg btn-block mt-8`}>
                            Continue
                        </ButtonLoader>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Transfer;
