import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as IconContact } from "assets/images/icons/nav-contact.svg";
import useRoutes from "hooks/useRoutes";
import Maybe from "./Maybe";
import useToolkit from "hooks/useToolkit";

export const NavigationLinks = () => {
  const { dashboardRoutes } = useRoutes()

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="">
        {dashboardRoutes.slice(0, 9).map((route, idx) => (
          <SidebarRouteItem key={idx} route={route} />
        ))}
      </div>

      <div className="">
        {dashboardRoutes.slice(9).map((route, idx) => (
          <SidebarRouteItem key={idx} route={route} />
        ))}

        <NavLink to="mailto:support@payshiga.com" className="sidebar-links">
          <IconContact className="sidebar-links-icon" />
          <div>Contact us</div>
        </NavLink>
        <div className="h-4 hidden md:block"></div>
      </div>
    </div>
  );
};



const SidebarRouteItem = ({ route }) => {
  const { pathname } = useLocation();
  const { title, path, permission } = route;
  const isActive = path?.includes('settings') ?
    pathname?.includes('settings') :
    path?.includes('manage-bills') ?
      pathname?.includes('manage-bills') : pathname?.includes(path);
  const { kybVerified } = useToolkit();

  const isSettlements = title?.includes('Settlements')

  return (
    <div className="w-full pl-4 pr-3 space-y-4">

      <Maybe condition={!permission}>

        <div className={`h-11 flex items-center cursor-not-allowed text-shiga-gray-300 text-opacity-40 pl-3`}>

          <img
            alt={title}
            className="mr-2"
            style={{ opacity: (isActive && kybVerified) ? '100%' : '40%' }}
            src={`/sidebar-icons/${title.toLowerCase()}-${(isActive && kybVerified) ? 'active' : 'inactive'}.svg`}
          />
          <div>{title}</div>
        </div>

      </Maybe>

      <Maybe condition={permission}>

        <NavLink to={path}>
          <div className={`h-11 flex items-center hover:bg-[#f0f0f0] hover:rounded-lg ${isSettlements && 'pl-4'}
          ${isActive ? 'pl-3.5 text-shiga-purple-500 font-medium' : 'text-shiga-gray-300 pl-3'}`}>

            <img
              alt={title}
              className="mr-2"
              src={`/sidebar-icons/${title.toLowerCase()}-${isActive ? 'active' : 'inactive'}.svg`}
            />
            <div>{title}</div>
          </div>
        </NavLink>

      </Maybe>

    </div>
  )
}


export default NavigationLinks;
