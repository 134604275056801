import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { SelectInput, Input } from "components/common/form";
import { useSelector, useDispatch } from "react-redux";
import { transferNameEnquiry } from "store/transfer/action";
import * as banksActions from "store/ui/banks/action";
import { LoaderCircleIcon } from "lucide-react";
import { toast } from "react-toastify";

const accounts = [
  { label: "Savings Account", value: "SAVINGS" },
  { label: "Checking Account", value: "CHECKING" },
];
const BankSelector = () => {
  const dispatch = useDispatch();
  const { banksLoading, banks } = useSelector((s: any) => s.ui.banks);
  const { accountName, loadingName } = useSelector((s: any) => s.transfer);
  const { register, setValue, watch, getValues } = useFormContext();

  const bankCode = watch("bankCode");
  const accountType = watch("accountType");
  const accountNumber = watch("accountNumber");
  const country = watch("country");

  // Fetch banks if needed
  // useEffect(() => {
  //   if (!banksLoading && (Object.keys(banks).length === 0 || !banks?.success)) {
  //     dispatch(banksActions.getBanks(country));
  //   }
  // }, [banksLoading, banks, dispatch, country]);
  // useEffect(() => {
  //   if (!banksLoading && (!banks || !banks.success)) {
  //     dispatch(banksActions.getBanks(country));
  //   }
  // }, [banksLoading, banks, dispatch, country]);
  useEffect(() => {
    if (country) {
      dispatch(banksActions.getBanks(country));
    }
  }, [country, dispatch]);

  // Handle account number and bank code updates
  useEffect(() => {
    if (country === "NGN") {
      if (accountNumber && bankCode) {
        if (accountNumber.length === 10) {
          dispatch(transferNameEnquiry(accountNumber, bankCode));
        } else {
          setValue("accountName", "");
        }
      }
    }
  }, [accountNumber, bankCode, dispatch, setValue]);

  // Update accountName if necessary
  useEffect(() => {
    if (country === "NGN") {
      if (accountNumber.length === 10) {
        setValue("accountName", accountName);
        if (
          accountName.toLowerCase() !== getValues("accountName").toLowerCase()
        ) {
          toast.error("Account name does not match");
        }
      } else {
        setValue("accountName", "");
      }
    }
  }, [accountName, accountNumber, setValue, getValues]);

  return (
    <div>
      {country === "NGN" && (
        <SelectInput
          label="Bank Name"
          required
          isSearchable
          searchPlaceholder='Search banks'
          {...register("bankCode")}
          value={bankCode}
          onChange={(e: any) => {
            const selectedBankCode = e.target.value;
            const selectedBank = banks?.data.find(
              (bank: { bankCode: string }) => bank.bankCode === selectedBankCode
            );
            setValue("bankCode", selectedBank?.bankCode || "");
            setValue("bankName", selectedBank?.name || "");
          }}
        >
          <option value="" disabled>
            Select a bank
          </option>
          {banks?.data?.map((bank: any) => (
            <option value={bank?.bankCode} key={bank?.bankCode}>
              {bank?.name}
            </option>
          ))}
        </SelectInput>
      )}

      {country === "NGN" && (
        <div className="mb-[26px] relative">
          <div className="flex w-full justify-between items-center">
            <span className="text-shiga-gray-100">
              Account Number <span className="form-input-required">*</span>
            </span>
            {loadingName ? (
              <LoaderCircleIcon className="absolute top-0 right-0 animate-spin" />
            ) : (
              <p className="text-sm font-medium text-shiga-black uppercase">
                {getValues("accountName") || ""}
              </p>
            )}
          </div>
          <Input
            type="number"
            placeholder="01234567890"
            {...register("accountNumber")}
            maxLength={10}
            required
          />
        </div>
      )}

      {country === "USD" && (
        <div>
          <div>
            <Input
              required
              label="Bank Name"
              placeholder="Enter bankname"
              {...register("bankName")}
            />
            <Input
              required
              label="Account Name"
              placeholder="Enter accountName"
              {...register("accountName")}
            />
            <div className="flex items-center gap-x-6">
              <Input
                type="number"
                required
                label="Account number"
                placeholder="0000000000"
                {...register("accountNumber")}
              />
              <Input
                type="number"
                required
                label="ACH routing Number"
                placeholder="0000000000"
                maxLength={9}
                {...register("routingNo")}
              />
            </div>
            <SelectInput
              label="Account Type"
              placeholder="Select an option"
              required
              {...register("accountType")}
              value={accountType}
              onChange={(e: any) =>
                setValue("accountType", e.target.value, {
                  shouldValidate: true,
                })
              }
            >
              {accounts?.map((account: any) => (
                <option value={account?.value} key={account?.label}>
                  {account?.label}
                </option>
              ))}
            </SelectInput>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankSelector;
