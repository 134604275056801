import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as settingsActions from "store/entities/settings/action";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyUI from "components/common/EmptyUi";
import Maybe from "components/common/Maybe";
import { Menu } from "@headlessui/react";
import Dropdown from "utils/dropdown";
import DateFormat from "utils/dateFormat";
import { ReactComponent as IconStarCircle } from "assets/images/icons/StarCircle.svg";
import { ReactComponent as IconStar } from "assets/images/icons/Star.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as ProductImg } from "assets/images/icons/ProductsIcons.svg";
import EmptyOrder from "assets/images/icons/empty-order.svg";
import Pagination from "components/common/Pagination";
import Search from "components/common/Search";
import ChevronDownIcon from "assets/images/icons/ChevronDown";
import CloseBtnIcon from "assets/images/icons/CloseBtn";
import { CancelOrderModal } from "./CancelOrderModal";
import { useToggle } from "hooks/useToggle";

const ProductOrderTable = ({ orders, product }: any) => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<boolean | null>(null);
  const [showDelete, toggleDelete] = useToggle(false);
  const [currentOrder, setCurrentOrder] = useState<any>({});

  const handleShowDelete = (arg: any) => {
    setCurrentOrder(arg);
    toggleDelete();
  };

  //   const filteredProductList = products.data?.payload.filter((product: any) => {
  //     const matchesSearch = product.name
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase());

  //     const matchesStatus =
  //       statusFilter === null || product.status === statusFilter;

  //     return matchesSearch && matchesStatus;
  //   });
  const filteredProductOrders = orders.data.payload.filter(
    (order: any) => order.productName === product[0]?.name
  );

  //   const filteredProductList = orders.filter((product: any) => {
  //     const matchesSearch = product.name
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase());
  //     const matchesStatus =
  //       statusFilter === null || product.isVirtual === statusFilter;
  //     return matchesSearch && matchesStatus;
  //   });

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-12">
        <Pagination meta={orders.data.meta} limit={10} />

        {/* <p
          onClick={toggleDropdown}
          className="flex items-center pt-[0.5625rem] pb-[0.5625rem] px-4 rounded-[0.625rem] border border-[#e2e3e5] cursor-pointer text-[#343433] font-['Inter'] font-medium leading-[normal]"
        >
          Filter by
          <ChevronDownIcon className="ml-2" />
        </p> */}

        <div
        // className=" basis-2/5 flex items-right justify-between"
        >
          {/* <Pagination meta={orders.data.meta} limit={10} /> */}
          <Search
            placeholder="Search orders"
            value={searchQuery}
            onSearchChange={(e: any) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-6 ">
        <Table tableClass="pb-24">
          <Table.Head className="text-uppercase">
            <th>S/N </th>
            <th>Email Address</th>
            <th>NamE</th>
            <th>Amount</th>
            <th>Date Created</th>
            {/* <th></th> */}
          </Table.Head>
          <Table.Body className="">
            {/* <TableContent
              loading={loading}
              data={filteredProductOrders}
              total={filteredProductOrders?.length}
              colspan={4}
              loadingText="Loading product order accounts"
              emptyUI={
                <EmptyUI
                  icon={EmptyOrder}
                  header="No Orders yet"
                  subheader="You have not received any orders for this product yet"
                />
              }
            /> */}

            {/* {settlementAccounts?.data?.count > 0 && settlementAccounts?.data?.accounts?.map((account:any, index:number) => */}
            {filteredProductOrders.map((product: any, index: number) => (
              <tr key={index} className="">
                <td>{index + 1}</td>
                <td className="">
                  <div className="">
                    <div>
                      <div className="font-medium ">
                        {product?.email ?? "-"}
                      </div>
                    </div>
                  </div>
                </td>
                <td>{product?.name ?? "_-"}</td>
                <td>
                  {product?.amount ?? "-"} {product?.currency ?? "-"}
                </td>

                <td>
                  <div className="flex items-center">
                    {DateFormat(product?.dateCreated) ?? "-"}
                    <div className="ml-2">
                      <Dropdown dropdownClass="dropdown-right">
                        <></>
                        <div>
                          <Menu.Item
                            as="div"
                            className="dropdown-item dropdown-item-icon text-shiga-warn"
                            onClick={() => handleShowDelete(product)}
                          >
                            <IconClose />
                            Cancel Order
                          </Menu.Item>
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </td>
                {/* <td className="">
                        <Dropdown dropdownClass="dropdown-right">
                          <></>
                          <div>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon"
                            >
                              <IconStar />
                              View
                            </Menu.Item>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon text-shiga-warn"
                            >
                              <IconDelete />
                              Delete Product
                            </Menu.Item>
                          </div>
                        </Dropdown>
                      </td> */}
              </tr>
            ))}
          </Table.Body>
        </Table>
      </div>

      <CancelOrderModal
        order={currentOrder}
        open={showDelete}
        closeModal={toggleDelete}
      />
    </div>
  );
};

export default ProductOrderTable;
