import { ButtonLoader } from "components/common/form";
import * as React from "react";
import CheckCicle from "assets/images/icons/Check-Circle.svg"
import ErrorIcon from "assets/images/icons/warning.gif"

interface PaymentSuccessfulProps {
    amount: string;
    recipient: string;
    isOpen: boolean;
    isSuccessful: boolean;
    successfullmessage: string;
    succesPreText: string;
    errorMessage: string;
    onDone: () => void;
    buttonLabel?: string;
    buttonClass?: string;
    bodyClass?: string;
    iconClass?: string
}

const PaymentSuccessful: React.FC<PaymentSuccessfulProps> = ({ amount, recipient, isOpen, iconClass, buttonClass = "", bodyClass = "", isSuccessful, buttonLabel = "Done", successfullmessage, errorMessage, succesPreText, onDone }) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-900 opacity-50 backdrop-filter backdrop-blur-sm "></div>

            <div className="flex flex-col pt-8 bg-white max-w-[424px] rounded-[30px]  z-10">
                <div className={`flex flex-col px-14 mt-2.5 text-center ${bodyClass}`}>
                    <img
                        loading="lazy"
                        src={isSuccessful ? CheckCicle : ErrorIcon}
                        alt="Payment successful icon"
                        className={`self-center max-w-full aspect-square w-[75px] mb-6 ${iconClass}`}
                    />
                    {isSuccessful && <>
                        <h2 className="self-center text-2xl border-0 border-solid border-shiga-black border-opacity-0 text-shiga-dark-700 font-medium">
                            {successfullmessage}
                        </h2>
                        <p className="mt-3 text-base tracking-tight max-w-[312px] text-neutral-400">
                            {succesPreText} {amount}{recipient.length ? ` to ${recipient}` : ''}
                        </p>
                    </>}
                    {!isSuccessful && <>
                        <p className="mt-3 text-base text-center tracking-tight max-w-[312px] text-neutral-400">
                            {errorMessage}
                        </p>
                    </>}
                </div>
                <footer className="px-6 pt-1 pb-5 w-full flex flex-col justify-end border-t border-solid border-neutral-100 rounded-b-[30px]">
                    <ButtonLoader className={`w-full btn btn-lg btn-block py-2 mt-10 ${buttonClass.length ? buttonClass : 'bg-shiga-black text-white'}`} onClick={onDone}>
                        {buttonLabel}
                    </ButtonLoader>
                </footer>
            </div>
        </div>


    );
}



export default PaymentSuccessful;