import React  from "react";

declare global {
    interface String {
        isEmpty(): boolean;
    }
}


String.prototype.isEmpty = function (): boolean {
    // Check if the string is empty or contains only whitespace
    return this.trim().length === 0;
};