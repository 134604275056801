import * as config from "data/config";
import * as actions from "./actionTypes"
import { CurrencyType } from "types/misc";

export const getRate = (amount: string, currencyFrom: string, currencyTo: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetRates}?amount=${amount}&currencyFrom=${currencyFrom}&currencyTo=${currencyTo}`,
            method: "get",
            data: {},
            onStart: actions.CONVERT_GET_EXCHANGE_RATE_START,
            onSuccess: actions.CONVERT_GET_EXCHANGE_RATE,
            onError: actions.CONVERT_GET_EXCHANGE_RATE_FAILED,
        },
    }
}

export const updateConvert = (arg: {
    rate: number,
    receiveAmount: number,
    sendAmount: number,
    fromCurrency: CurrencyType,
    toCurrency: CurrencyType
}) => {
    return {
        type: actions.UPDATE_CONVERT,
        payload: arg
    }
}

export const convertMoneyApi = (amount: string, currencyFrom: string, currencyTo: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiPostConvert,
            method: "post",
            data: {
                amount: amount,
                currencyFrom: currencyFrom,
                currencyTo: currencyTo
            },
            onStart: actions.CONVERT_MONEY_API_START,
            onSuccess: actions.CONVERT_MONEY_API,
            onError: actions.CONVERT_MONEY_API_FAILED,
        },
    }
}


export const resetConvertData = () => {
    return {
        type: actions.RESET_CONVERT_DATA
    }
}