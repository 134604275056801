import React from "react";
import { useFormContext } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import CommandSelect from "components/ui/command-select";
import { CommandInput } from "components/ui/command";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { CircleCheck } from "lucide-react";
import { googleApiKey } from "data/config";

interface AddressSelectorProps {
  label: string;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({ label }) => {
  const { watch, setValue } = useFormContext();
  const [location, setLocation] = React.useState("");

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: googleApiKey,
    });

  const getPlaceDetails = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place: any, status: any) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place
      ) {
        const addressComponents = place.address_components;

        const getComponent = (type: string) =>
          addressComponents.find((component: any) =>
            component.types.includes(type)
          )?.long_name || "";

        const city = getComponent("locality");
        const state = getComponent("administrative_area_level_1");
        const postcode = getComponent("postal_code");

        setValue("bankState", state);
        setValue("bankCity", city);
        setValue("postCode", postcode);
        setValue(
          "bankAddress",
          {
            description: place.formatted_address,
            place_id: place.place_id,
          },
          { shouldValidate: true }
        );
      }
    });
  };

  return (
    <div className="">
      <div className="">
        <CommandSelect
          className="w-full mb-8"
          searchPlaceholder="Search Address"
          filterOption={() => 1}
          loading={isPlacePredictionsLoading}
          SearchInput={
            <CommandInput
              placeholder="Search Address"
              value={location}
              onValueChange={(e) => {
                setLocation(e);
                getPlacePredictions({ input: e });
              }}
            />
          }
          notFound="No Address found."
          label={label}
          options={placePredictions.map((item) => {
            // console.log("selcted address item", item);
            return {
              ...item,
              id: item.place_id,
              value: item.description,
            };
          })}
          SelectTrigger={() => (
            <span className="flex items-center gap-4 overflow-hidden text-base">
              {!watch("bankAddress.description") && (
                <p className="flex items-center text-form-gray text-base font-normal">
                  <IconSearch className="mr-3" />
                  (No)(Street Name)(State)(Country)
                </p>
              )}
              {watch("bankAddress.description")}
            </span>
          )}
          RenderOption={({ item }) => (
            <div className="w-full">
              <span className="flex text-left">{item.description}</span>
              {watch("bankAddress.description") === item.description && (
                <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
              )}
            </div>
          )}
          handleSelect={(select) => {
            getPlaceDetails(select.place_id);

            // setValue("bankAddress", {
            //   description: select.description,
            //   place_id: select.place_id,
            //   post_code: select.post_code,
            // });
          }}
        />
      </div>
    </div>
  );
};

export default AddressSelector;
