import { useNavigate } from "react-router-dom";
import useBackButton from "hooks/useBackButton";
import Maybe from "./Maybe";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";

interface Props {
  title: string | React.ReactNode;
  description?: string | React.ReactNode | undefined;
  showBackButton?: boolean;
  backButtonUrl?: string;
  utilityFn?: () => void;
}

const AuthPageTitle = (props: Props) => {
  const { title, description, showBackButton, backButtonUrl, utilityFn } =
    props;
  const navigate = useNavigate();
  const { goBack } = useBackButton();

  const back = () => {
    if (backButtonUrl !== undefined) {
      if (utilityFn) {
        utilityFn();
      }
      navigate(backButtonUrl);
    } else {
      goBack();
    }
  };

  return (
    <div>
      <Maybe condition={showBackButton === true}>
        <div className="w-9 h-9 mb-6 cursor-pointer" onClick={back}>
          <IconNavigation className="w-full h-full" />
        </div>
      </Maybe>
      <div className="page-title font-ojah">{title}</div>
      <div className="mt-3 flex text-[#99999C]">{description}</div>
    </div>
  );
};

export default AuthPageTitle;
