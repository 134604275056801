import { ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import TransfersContext from './TransfersContext';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getTransferBanks, getTransferBeneficiaryList, getTransferFee, getTransferLimit } from 'store/transfer/action';
import { defaultAudForm, defaultGbpForm, defaultNgnForm, defaultUsdForm } from './exports';
import { useSharedStore } from 'zustand-store';
import useToolkit from 'hooks/useToolkit';
import { initializeMFA } from 'store/auth/account/action';

type Props = {
   children: ReactNode;
   trfModalOpen: boolean;
   setTrfModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const TransfersProvider = ({ trfModalOpen, setTrfModalOpen, children }: Props) => {
   const { dispatch, refetchProfiles } = useToolkit();
   const { currencies } = useSharedStore();
   const [singleTrfStep, setSingleTrfStep] = useState(1);

   const { transferWallet } = useSelector((state: RootState) => state.auth.userPersist);
   const { transferBeneficiaries } = useSelector((state: RootState) => state.transfer);
   const { mfaInitialize } = useSelector((s: any) => s.auth.account);

   const trfCurrency = transferWallet?.currency;
   const businessVerificationRef = useRef<any>(null);
   const [bulkTrfStep, setBulkTrfStep] = useState(1);

   const currencyFlag = currencies?.find((curr: any) => curr?.currency === trfCurrency)?.icon || ''
   const [accountName, setAccountName] = useState('');
   const [transferType, setTransferType] = useState('');
   const [trfPin, setTrfPin] = useState('');
   const [beneficiaryQuery, setBeneficiaryQuery] = useState('');

   const [transferAmount, setTransferAmount] = useState('');
   const [transferFee, setTransferFee] = useState('');
   const [summedAmount, setSummedAmount] = useState("0.00");
   const [ngnForm, setNgnForm] = useState(defaultNgnForm);
   const [usdForm, setUsdForm] = useState(defaultUsdForm);
   const [audForm, setAudForm] = useState(defaultAudForm);
   const [gbpForm, setGbpForm] = useState(defaultGbpForm);

   const [twoFacPromptOpen, setTwoFacPromptOpen] = useState(false);
   const [twofacSetupOpen, setTwofacSetupOpen] = useState(false);
   const [twoFacInputOpen, setTwoFacInputOpen] = useState(false);
   const [transferNote, setTransferNote] = useState('');
   const [saveTrfBeneficiary, setSaveTrfBeneficiary] = useState(false);

   const toggleTwoFacPrompt = () => setTwoFacPromptOpen(!twoFacPromptOpen);
   const toggleTwoFacSetup = () => setTwofacSetupOpen(!twofacSetupOpen);
   const toggleTwoFacInput = () => setTwoFacInputOpen(!twoFacInputOpen);

   const isSingleTransfer = transferType === 'SINGLE';
   const isBulkTransfer = transferType === 'BULK';
   const isNgnTransfer = trfCurrency === 'NGN';
   const isUsdTransfer = trfCurrency === 'USD';
   const isGbpTransfer = trfCurrency === 'GBP';
   const isAudTransfer = trfCurrency === 'AUD';
   const isBulkPreviewPage = isBulkTransfer && bulkTrfStep === 2;

   const incrementSingleTrfStep = () => setSingleTrfStep((prev) => prev + 1)
   const decrementSingleTrfStep = () => setSingleTrfStep((prev) => prev - 1)
   const navigateSingleTrfStep = (step: number) => setSingleTrfStep(step);

   const incrementBulkTrfStep = () => setBulkTrfStep((prev) => prev + 1)
   const decrementBulkTrfStep = () => setBulkTrfStep((prev) => prev - 1)
   const navigateBulkTrfStep = (step: number) => setBulkTrfStep(step);

   const beneficiaries = transferBeneficiaries?.filter((item: any) => item.currency === transferWallet?.currency)
   const formattedWalletBalance = `${Number(transferWallet?.balance).toLocaleString()} ${transferWallet?.currency ?? ''}`

   const scrollToTop = () => {
      businessVerificationRef.current?.scroll({
         top: 0,
         behavior: "instant",
      });
   };

   const goToSingleTrfStep = (e: number) => {
      if (e >= 1 && e <= 5) {
         setSingleTrfStep(e);
         scrollToTop();
      }
   }

   const setupTwoFac = () => {
      setTwoFacPromptOpen(false);
      dispatch(initializeMFA());
   };

   const resetTransferProps = () => {
      setTransferType('');
      setSingleTrfStep(1);
      setNgnForm(defaultNgnForm);
      setUsdForm(defaultUsdForm);
      setGbpForm(defaultGbpForm);
      setAudForm(defaultAudForm);
      setTransferAmount('');
      setTransferNote('');
      setTransferFee('');
      setAccountName('');
      setSummedAmount('0.00')
   }

   const endSingleTransferSession = () => {
      setTwoFacInputOpen(false);
      // setTrfModalOpen(false);
      resetTransferProps();
   }

   useEffect(() => {
      dispatch(getTransferBeneficiaryList());
      dispatch(getTransferLimit(transferWallet?.currency, "transfer"));
      refetchProfiles();
      // eslint-disable-next-line
   }, [transferWallet])

   useEffect(() => {
      if (trfCurrency?.length > 0 && isNgnTransfer) {
         dispatch(getTransferBanks(trfCurrency));
      }
      // eslint-disable-next-line
   }, [trfCurrency])

   useEffect(() => {
      resetTransferProps()
      // eslint-disable-next-line
   }, [trfModalOpen])

   useEffect(() => {
      if (Number(transferAmount) > 0) {
         const payload = {
            feeServiceType: "TRANSFER",
            amount: transferAmount,
            currency: trfCurrency,
         };
         dispatch(getTransferFee(payload));
      }
      // eslint-disable-next-line
   }, [transferAmount]);


   useEffect(() => {
      if (mfaInitialize?.success === true) {
         setTwofacSetupOpen(true);
         setTwoFacPromptOpen(false);
      }
      //eslint-disable-next-line
   }, [mfaInitialize]);

   return (
      <TransfersContext.Provider
         value={{
            transferType,
            setTransferType,
            singleTrfStep,
            setSingleTrfStep,
            incrementSingleTrfStep,
            decrementSingleTrfStep,

            bulkTrfStep,
            setBulkTrfStep,
            goToSingleTrfStep,
            businessVerificationRef,
            isSingleTransfer,
            isBulkTransfer,

            transferWallet,
            trfCurrency,
            beneficiaries,
            navigateSingleTrfStep,
            ngnForm,
            setNgnForm,

            usdForm,
            setUsdForm,
            gbpForm,
            setGbpForm,
            audForm,
            setAudForm,

            formattedWalletBalance,
            currencyFlag,
            transferAmount,
            setTransferAmount,
            twoFacPromptOpen,
            toggleTwoFacPrompt,

            twofacSetupOpen,
            toggleTwoFacSetup,
            setupTwoFac,
            transferNote,
            setTransferNote,
            transferFee,

            setTransferFee,
            accountName,
            isNgnTransfer,
            isUsdTransfer,
            isGbpTransfer,
            isAudTransfer,

            trfPin,
            setTrfPin,
            twoFacInputOpen,
            toggleTwoFacInput,
            saveTrfBeneficiary,
            setSaveTrfBeneficiary,

            beneficiaryQuery,
            setBeneficiaryQuery,
            endSingleTransferSession,
            setTrfModalOpen,
            summedAmount,
            setSummedAmount,
            isBulkPreviewPage,

            incrementBulkTrfStep,
            decrementBulkTrfStep,
            navigateBulkTrfStep,
         }}>
         {children}
      </TransfersContext.Provider>
   )
}

export default TransfersProvider