
const UPPER_CASE_REGEX = /(?=.*[A-Z])/;
const LOWER_CASE_REGEX = /(?=.*[a-z])/;
const NUMBER_REGEX = /(?=.*[0-9])/;
const SPECIAL_CHARACTER_REGEX = /[^A-Za-z0-9]/;
const CHARACTERS_LENGTH_REGEX = /(?=.{8,})/;


export const getPasswordStrength = (password: string) => {
    let passwordStrength = {
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        hasCharacterLength: false,
    };

    if (UPPER_CASE_REGEX.test(password)) {
        passwordStrength = { ...passwordStrength, hasUpperCase: true }
    } else {
        passwordStrength = { ...passwordStrength, hasUpperCase: false }
    }

    if (LOWER_CASE_REGEX.test(password)) {
        passwordStrength = { ...passwordStrength, hasLowerCase: true }
    } else {
        passwordStrength = { ...passwordStrength, hasLowerCase: false }
    }

    if (NUMBER_REGEX.test(password)) {
        passwordStrength = { ...passwordStrength, hasNumber: true }
    } else {
        passwordStrength = { ...passwordStrength, hasNumber: false }
    }

    if (SPECIAL_CHARACTER_REGEX.test(password)) {
        passwordStrength = { ...passwordStrength, hasSpecialCharacter: true }
    } else {
        passwordStrength = { ...passwordStrength, hasSpecialCharacter: false }
    }

    if (CHARACTERS_LENGTH_REGEX.test(password)) {
        passwordStrength = { ...passwordStrength, hasCharacterLength: true }
    } else {
        passwordStrength = { ...passwordStrength, hasCharacterLength: false }
    }

    return passwordStrength;
};


export const getPasswordStrengthLevel = (passwordStrength: any) => {
    let levelIndicator = 0;
    let strengthLevel = 0;
    let displayBgColor = "bg-[#E2E3E5]";
    let displayTextColor = "text-[#B4B4B4]";
    let displayText = "Password Strength";

    const strengthPassed = Object.keys(passwordStrength).filter((e) => passwordStrength[e] === true);
    strengthLevel = strengthPassed.length ?? 0;

    if (strengthLevel === 1) {
        levelIndicator = 1;
        displayBgColor = "bg-[#FF4E4E]";
        displayTextColor = "text-[#FF4E4E]";
        displayText = "Weak Password";
    }
    else if (strengthLevel >= 2 && strengthLevel <= 4) {
        levelIndicator = 2;
        displayBgColor = "bg-[#FFBA0C]";
        displayTextColor = "text-[#FFBA0C]";
        displayText = "Moderate Password";
    }
    else if (strengthLevel === 5) {
        levelIndicator = 3;
        displayBgColor = "bg-[#00C46C]";
        displayTextColor = "text-[#00C46C]";
        displayText = "Strong Password";
    }
    return {
        level: levelIndicator,
        bgColor: displayBgColor,
        textColor: displayTextColor,
        text: displayText,
    };
};


export default getPasswordStrength;