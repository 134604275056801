import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as settingsActions from "store/entities/settings/action";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyUI from "components/common/EmptyUi";
import Maybe from "components/common/Maybe";
import { Menu } from "@headlessui/react";
import Dropdown from "utils/dropdown";
import DateFormat from "utils/dateFormat";
import { ReactComponent as IconStarCircle } from "assets/images/icons/StarCircle.svg";
import { ReactComponent as IconStar } from "assets/images/icons/Star.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as ProductImg } from "assets/images/icons/ProductsIcons.svg";
import IconBank from "assets/images/icons/Bank.png";
import Pagination from "components/common/Pagination";
import Search from "components/common/Search";
import ChevronDownIcon from "assets/images/icons/ChevronDown";
import { ReactComponent as IconOption } from "assets/images/icons/Option.svg";
import OrderDetailModal from "../OrderDetailModal";
import { useToggle } from "hooks/useToggle";

const OrderTable = ({ orders }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<boolean | null>(null);
  // const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [currentOrder, setCurrentOrder] = useState<any>({});

  //   const filteredProductList = products.data?.payload.filter((product: any) => {
  //     const matchesSearch = product.name
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase());

  //     const matchesStatus =
  //       statusFilter === null || product.status === statusFilter;

  //     return matchesSearch && matchesStatus;
  //   });

  const filteredProductList = orders.data?.payload.filter((product: any) => {
    const matchesSearch = product.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus =
      statusFilter === null || product.isVirtual === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const handleOnClick = (product: any) => {
    toggleShowDetails();
    setCurrentOrder(product);
  };

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-12">
        <div
          onClick={toggleDropdown}
          className="flex items-center pt-[0.5625rem] pb-[0.5625rem] px-4 rounded-[0.625rem] border border-[#e2e3e5] cursor-pointer text-[#343433] font-['Inter'] font-medium leading-[normal]"
        >
          Filter by
          <ChevronDownIcon className="ml-2" />
        </div>

        <div className=" basis-2/5 flex items-center justify-between">
          <div className="mr-6">
            <Pagination meta={orders.data.meta} limit={10} />
          </div>
          <Search
            placeholder="Search orders"
            value={searchQuery}
            onSearchChange={(e: any) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-6 ">
        <Table tableClass="pb-24">
          <Table.Head className="text-uppercase">
            <th>Products Name </th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Date Created</th>
            {/* <th></th> */}
          </Table.Head>
          <Table.Body className="">
            {/* <TableContent
                  loading={settlementAccountsLoading}
                //   data={settlementAccounts}
                  data={products.data?.payload}
                  total={products?.data?.payload?.length}
                  colspan={4}
                  loadingText="Loading settlement accounts"
                  emptyUI={
                    <EmptyUI
                      icon={IconBank}
                      header="No Account Added"
                      subheader="You have not added a settlement account to this account yet. Why not create one?"
                    />
                  }
                /> */}

            {/* {settlementAccounts?.data?.count > 0 && settlementAccounts?.data?.accounts?.map((account:any, index:number) => */}
            {filteredProductList
              ?.sort((a: any, b: any) =>
                a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1
              )
              .map((product: any, index: number) => (
                <tr key={index} className="">
                  <td className="">
                    <div className="flex items-center">
                      <div className="w-10 h-10 mr-4 bg-gray-200 rounded-2xl">
                        {/* <IconGTBank className="w-full h-full" /> */}
                        <ProductImg />
                      </div>
                      <div>
                        <div className="font-medium ">
                          {product?.name ?? "-"}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{product?.itemSold ?? "_-"}</td>
                  <td>
                    {product?.amount ?? "-"} {product?.currency ?? "-"}
                  </td>

                  <td>
                    <div className="flex items-center">
                      {DateFormat(product?.dateCreated) ?? "-"}{" "}
                      <div className="ml-2">
                        <IconOption
                          onClick={() => {
                            handleOnClick(product);
                          }}
                        />
                        {/* <Dropdown dropdownClass="dropdown-right">
                          <></>
                          <div>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon"
                            >
                              <IconStar />
                              View
                            </Menu.Item>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon text-shiga-warn"
                            >
                              <IconDelete />
                              Delete Product
                            </Menu.Item>
                          </div>
                        </Dropdown> */}
                      </div>
                    </div>
                    {/* <OrderDetailModal
                      isOpen={showDetails}
                      closeModal={toggleShowDetails}
                      order={currentOrder}
                    /> */}
                  </td>
                  {/* <td className="">
                        <Dropdown dropdownClass="dropdown-right">
                          <></>
                          <div>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon"
                            >
                              <IconStar />
                              View
                            </Menu.Item>
                            <Menu.Item
                              as="div"
                              className="dropdown-item dropdown-item-icon text-shiga-warn"
                            >
                              <IconDelete />
                              Delete Product
                            </Menu.Item>
                          </div>
                        </Dropdown>
                      </td> */}
                </tr>
              ))}
          </Table.Body>
        </Table>
      </div>

      <OrderDetailModal
        open={showDetails}
        closeModal={toggleShowDetails}
        order={currentOrder}
      />
    </div>
  );
};

export default OrderTable;
