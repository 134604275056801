import { useEffect, useState } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import Maybe from 'components/common/Maybe';
import { ReactComponent as ArrowIn } from "assets/images/icons/arrow-in.svg";
import SettlementsDark from "assets/images/icons/settlements-dark.svg"
import { ReactComponent as SettltmentsLg } from "assets/images/icons/settlements-logo-lg.svg";
import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import moment from 'moment';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import { SettlementDetailSidebar, SettlementDetailsPaginateSearch } from '../transactions/SettlementUtils';
import { getSettlementDetails } from 'store/transactions/action';
import EmptyUI from 'components/common/EmptyUi';
import { currencyFormat } from 'utils/CurrencyFormat';
import ContentLoading from 'components/common/ContentLoading';


const SettlementDetailsOverview = () => {
   const dispatch = useAppDispatch();
   const { id } = useParams();

   const navigate = useNavigate();
   const location = useLocation();

   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });

   const {
      settlementDetailsLoading,
      settlementDetailsData,
      selectedSettlement: selected
   } = useSelector((state: RootState) => state.transactions);
   const isNgnCur = selected?.currency === 'NGN';

   const transactionsData = settlementDetailsData?.data;
   const transactionsAreValid = settlementDetailsLoading === false &&
      transactionsData?.deposits && transactionsData?.deposits?.length > 0;

   const fetchDetails = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");

      setListParams(params);
      dispatch(getSettlementDetails({ id, params }));
   }


   useEffect(() => {
      fetchDetails();
      // eslint-disable-next-line
   }, [location]);

   return (
      <div className='dashboard-page-container'>

         <DynamicHeadTag
            headerText="Settlement Details"
         />

         <BackButton
            onClick={() => navigate(-1)}
            className='cursor-pointer'
         />

         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg-flex-nowrap lg:border-b lg:border-shiga-gray-50 pb-7 pt-8">

            <div className="flex items-center w-full lg:w-max">
               <SettltmentsLg className='hidden lg:inline' />

               <Maybe condition={isNgnCur}>
                  <div className="lg:ml-4">
                     <p className="mb-1 text-shiga-dark-100 text-lg lg:text-2xl font-ojah font-bold capitalize">
                        <ContentLoading data={transactionsData?.accountName?.toLowerCase()} loading={settlementDetailsLoading} />
                     </p>

                     <div className="flex items-center text-shiga-gray-200">
                        <p className="text-sm lg:text-plg">
                           <ContentLoading data={transactionsData?.bankName} loading={settlementDetailsLoading} />
                        </p>

                        <div className="h-1 w-1 rounded-full bg-shiga-gray-200 mx-2.5" />

                        <p className="text-sm lg:text-plg">
                           <ContentLoading data={transactionsData?.accountNumber} loading={settlementDetailsLoading} />
                        </p>
                     </div>
                  </div>
               </Maybe>

               <Maybe condition={!isNgnCur}>
                  <div className="lg:ml-4">
                     <p className="mb-1 text-shiga-dark-100 text-lg lg:text-2xl font-ojah font-bold">
                        To {selected?.currency} Balance
                     </p>

                     <div className="flex items-center text-shiga-gray-200 capitalize">
                        {selected?.narration}
                     </div>
                  </div>
               </Maybe>
            </div>

            <div className="w-full lg:w-max lg:text-right mt-8 lg:mt-0">

               <p className="mb-1 text-shiga-dark-100 text-lg lg:text-2xl font-ojah font-bold">
                  {currencyFormat(transactionsData?.bulkAmount, selected?.currency)}
               </p>

               <div className="text-shiga-gray-200">
                  <p className="text-sm lg:text-plg">
                     {moment(selected?.dateInitiated).format('MMM D, YYYY [at] h:mmA')}
                  </p>
               </div>
            </div>
         </div>

         <div className="pb-24 pt-8 lg:pt-12 flex items-start flex-wrap lg:flex-nowrap lg:justify-between">

            <div className="w-full lg:w-[23%]">
               <SettlementDetailSidebar
                  settlement={selected}
                  loading={settlementDetailsLoading}
                  transactions={transactionsData}
               />
            </div>

            <div className="w-full lg:w-[75%]">

               <Maybe condition={!settlementDetailsLoading}>
                  <SettlementDetailsPaginateSearch
                     transactions={settlementDetailsData}
                  />
               </Maybe>

               <Table>

                  <Maybe condition={(settlementDetailsLoading === false && !transactionsData) || (transactionsAreValid)}>
                     <Table.Head>
                        <th>Narration</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Date</th>
                     </Table.Head>
                  </Maybe>

                  <Table.Body>

                     <TableContent
                        total={transactionsData?.deposits?.length || 0}
                        loading={settlementDetailsLoading}
                        loadingText="Fetching settlement transactions"
                        data={transactionsData?.deposits}
                        colspan={4}
                        emptyUI={
                           <EmptyUI
                              icon={SettlementsDark}
                              header='No Transactions yet'
                              subheader='This settlement has no transactions'
                           />
                        }
                     />

                     {settlementDetailsLoading === false && transactionsAreValid && transactionsData?.deposits?.map((item: any) => {

                        const isBankTransfer = item?.depositType === 'BANK_TRANSFER';

                        return (
                           <tr key={item?.narration}>
                              <td>
                                 <div className="flex items-center">

                                    <div className='w-9 h-9 rounded-full flexed font-medium bg-shiga-gray-40'>
                                       <ArrowIn />
                                    </div>


                                    <div className="ml-3">
                                       <p className="mb-0 text-shiga-dark-100 font-medium lg:text-plg">
                                          {item?.narration}
                                       </p>
                                    </div>
                                 </div>
                              </td>

                              <td>
                                 <div className="font-medium lg:text-plg text-shiga-green-100">
                                    + {currencyFormat(item?.amount, selected?.currency)}
                                 </div>
                              </td>

                              <td>
                                 <div className="border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[14px] capitalize font-inter font-medium">
                                    <span className="text-shiga-gray-300">SDK:&nbsp;</span>
                                    {isBankTransfer ? 'Bank Transfer' : item?.depositType}
                                 </div>
                              </td>

                              <td>
                                 <div className='font-medium'>
                                    {moment(item?.createdAt).format('MMM D, YYYY [at] h:mmA')}
                                 </div>
                              </td>
                           </tr>
                        )
                     }
                     )}
                  </Table.Body>
               </Table>
            </div>

         </div>
      </div>
   )
}

export default SettlementDetailsOverview