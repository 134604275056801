import { useEffect, useState } from "react";


export const Countdown = (props) => {
    const { hours = 0, minutes = 0, seconds = 0, onExpiry, onTimerUpdate } = props;


    const [time, setTime] = useState({
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds),
    });

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
                clearInterval(countdownInterval);
                if (onExpiry) {
                    onExpiry();
                }
            }
            else {
                if (time.seconds > 0) {
                    setTime(prevTime => ({
                        ...prevTime,
                        seconds: prevTime.seconds - 1
                    }));
                }
                else {
                    if (time.minutes > 0) {
                        setTime(prevTime => ({
                            ...prevTime,
                            minutes: prevTime.minutes - 1,
                            seconds: 59,
                        }));
                    }
                    else {
                        if (time.hours > 0) {
                            setTime(prevTime => ({
                                ...prevTime,
                                hours: prevTime.hours - 1,
                                minutes: 59,
                                seconds: 59,
                            }));
                        }
                    }
                }
            }
        }, 1000);

        if (onTimerUpdate) {
            onTimerUpdate(time)
        }


        return () => clearInterval(countdownInterval);
        // eslint-disable-next-line
    }, [time]);


    return (
        <>
            {time.minutes.toString().padStart(2, '0')}
            :
            {time.seconds.toString().padStart(2, '0')}
        </>
    );
};