import { StepHeading } from "components/common/StepHeading";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { ArrowLeft, X } from "lucide-react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentType } from "./PaymentType";
import { PersonalizeLink } from "./PersonalizeLink";
import CREATE_PAYMENT_LINK_STEP from "constants/payments/create.payment.link.step";
import { PAYMENT_PATHS } from "../paths";
import useBackButton from "hooks/useBackButton";
import { usePaymentLinkStore } from "zustand-store/payments/links";

export const CreatePaymentLink = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || CREATE_PAYMENT_LINK_STEP[0].slug;
  return (
    <div className="space-y-4 w-full py-10">
      <Nav tab={tab} />
      <Separator />
      {getComp(tab)}
    </div>
  );
};

const Nav = ({ tab }: { tab: string }) => {
  const { completed } = usePaymentLinkStore();
  const { goBack } = useBackButton();
  const navigate = useNavigate()
  return (
    <div className="flex  justify-between w-full items-center">
      <Button
        size="icon"
        onClick={goBack}
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <ArrowLeft size={18} />
      </Button>

      <StepHeading
        completed={completed}
        path={PAYMENT_PATHS.CREATE_PAYMENT_LINK}
        tab={tab}
        steps={CREATE_PAYMENT_LINK_STEP}
      />
      <Button
        size="icon"
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
        onClick={()=>{
          // goBack()
          navigate("/payments/payment-link")
        }}
      >
        <X size={18} />
      </Button>
    </div>
  );
};

const getComp = (tab: string) => {
  switch (tab) {
    case "payment_type":
      return <PaymentType />;
    default:
      return <PersonalizeLink />;
  }
};
