import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { RootState } from "store/store";

interface InitialState {
  countriesLoading: boolean;
  countries: {
    data: {
      countries: {
        [k: string]: string;
      };
      message: string;
    };
  };
}

const defaultCountryStore = {
  data: {
    countries: {},
    message: "",
  },
};

const initialState: InitialState = {
  countriesLoading: false,
  countries: defaultCountryStore,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.GET_COUNTRIES:
      state = {
        ...state,
        countriesLoading: false,
        countries: action.payload,
      };
      return state;

    case actions.GET_COUNTRIES_START:
      state = {
        ...state,
        countriesLoading: true,
      };
      return state;

    case actions.GET_COUNTRIES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        countriesLoading: false,
        countries: defaultCountryStore,
      };
      return state;

    default:
      return state;
  }
}

export const getCountriesStore = (state: RootState) => state.ui.countries;
