import { useState } from "react";
import { DataTable } from "components/data-table/data-table";
import { RefundColumns } from "./table/RefundColumn";
import { Refund, defaultRefund } from "types/refund";
import { RefundDetails } from "./RefundDetails";
import Pagination from "components/common/Pagination";
import SearchBox from "components/common/SearchBox";
import CustomTableFilter from "components/common/CustomTableFilter";

export interface IMeta {
  nextPage: number;
  previousPage: number;
  size: number;
  totalItems: number;
}

type Props = {
  refunds: Refund[];
  data: any;
}

export const RefundsTable = ({ refunds, data }: Props) => {
  const [selectedRefund, setSelectedRefund] = useState<Refund>(defaultRefund);

  return (
    <div className="mt-6">
      <DataTable
        data={data?.payload}
        columns={RefundColumns}
        renderToolbar={(table) => (
          <FilterPaginateSearch
            data={data}
          />
        )}
        meta={{
          handleSelected: setSelectedRefund,
        }}
      />
      <RefundDetails
        selectedRefund={selectedRefund}
        isOpen={!!selectedRefund.id.length}
        setIsOpen={() => {
          setSelectedRefund(defaultRefund);
        }}
      />
    </div>
  );
};

const FilterPaginateSearch = ({ data }: { data: any }) => {

  return (
    <div className="flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-2/4">
        <CustomTableFilter
          dropdownClass=''
          filters={[
            {
              title: "Status",
              name: "status",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "Pending",
                  payload: "PENDING",
                },
                {
                  display: "Successful",
                  payload: "SUCCESSFUL",
                },
                {
                  display: "Failed",
                  payload: "FAILED",
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
        <Pagination meta={data?.meta} />

        <div className="lg:pb-[26px]">
          <SearchBox
            name='search'
            extraUrlParams='page=1'
            placeholder="Search refunds"
          />
        </div>
      </div>
    </div>
  )
}