import { useEffect, useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { settingsLinks } from "data/constants/settings";
import { Menu } from "@headlessui/react";
import Dropdown from "utils/dropdown";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconArrowUpDown } from "assets/images/icons/arrow-up-down.svg";


const SettingsLayout = () => {
    return (
        <div className="xl:flex pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
            <Sidebar view="web" />
            <div className="flex-grow xl:pl-20">
                <Sidebar view="mobile" />
                <Outlet />
            </div>
        </div>
    )
}


const Sidebar = (props:any) => {
    const location = useLocation();
    const path = location?.pathname;
    const [ currentSettings, setCurrentSettings ] = useState(settingsLinks[0].text);

    useEffect(() => {
        const thisSettings = settingsLinks.filter((link) => link.url === path);
        if (thisSettings){
            setCurrentSettings(thisSettings[0]?.text);
        }
        // eslint-disable-next-line
    }, [location])

    return (
        <>
            <Maybe condition={props.view === "web"}>
                <div className="w-64 hidden xl:block flex-shrink-0 border-r border-gray-100">
                    {settingsLinks.map((link) =>
                        <NavLink to={link.url} key={link.url} className="settings-sidebar-links">
                            {link.text}
                        </NavLink>
                    )}
                </div>
            </Maybe>

            <Maybe condition={props.view === "mobile"}>
                <div className="xl:hidden mb-6">
                    <Dropdown className="!w-full" dropdownClass="w-full">
                        <div className="w-full form-input flex items-center justify-between !bg-white !border !border-gray-200">
                            <div>
                                {currentSettings}
                            </div>
                            <IconArrowUpDown />
                        </div>
                        <div>
                            {settingsLinks.map((link) =>
                                <Menu.Item key={link.url}>
                                    <NavLink to={link.url} className="dropdown-item flex items-center justify-between">
                                        <div className="ellipsis">
                                            {link.text}
                                        </div>
                                        <div className="w-7 text-right flex-shrink-0">
                                            <Maybe condition={link.url === path}>
                                                <FontAwesomeIcon icon="check-circle" />
                                            </Maybe>
                                        </div>
                                    </NavLink>
                                </Menu.Item>
                            )}
                        </div>
                    </Dropdown>
                </div>
            </Maybe>
        </>
    )
}


export default SettingsLayout
