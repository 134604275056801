import { useEffect, useRef, useState } from "react";
import { Dialog, Menu } from '@headlessui/react';
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconVerify } from "assets/images/icons/Verify.svg";
import { ReactComponent as IconArrowCurve } from "assets/images/icons/ArrowCurve.svg";
import logo from "assets/images/icons/logo-black.svg";
import Maybe from "components/common/Maybe";
import BusinessInformation from "./BusinessInformation";
import BusinessDocuments from "./BusinessDocuments";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import OwnerInformation from "./OwnerInformation";
import ReviewFinish from "./ReviewFinish";
import AddDirectors from "./AddDirectors";
import Dropdown from "utils/dropdown";
import { ReactComponent as IconArrowUpDown } from "assets/images/icons/arrow-up-down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useToolkit from "hooks/useToolkit";
import { updateKybStep } from "store/auth/user/action";


const BusinessVerificationLayout = (props: any) => {
    const { isOpen, setIsOpen } = props;
    const { refetchProfiles, useSelector, dispatch } = useToolkit();
    const { currentKybStep } = useSelector((s: any) => s.auth.userPersist);
    const [activeStep, setActiveStep] = useState(currentKybStep);
    const businessVerificationRef = useRef<any>(null);
    const { completeKybData } = useSelector(
        (s: any) => s.entities.businessVerification
    );

    const goPrevious = () => {
        if (activeStep > 1) {
            const newStep = activeStep - 1
            dispatch(updateKybStep(newStep));
            setActiveStep(newStep);
            scrollToTop();
        }
    }

    const goTo = (e: number) => {
        if (e >= 1 && e <= 5) {
            const newStep = e;
            dispatch(updateKybStep(newStep))
            setActiveStep(e);
            scrollToTop();
        }
    }

    const goNext = () => {
        if (activeStep < 5) {
            const newStep = activeStep + 1;
            dispatch(updateKybStep(newStep))
            setActiveStep(newStep);
            scrollToTop();
        }
        else {
            setIsOpen(false);
        }
    }

    const scrollToTop = () => {
        businessVerificationRef.current?.scroll({
            top: 0,
            behavior: "instant",
        });
    };

    useEffect(() => {
        setActiveStep(currentKybStep);
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            refetchProfiles();
        }
        // eslint-disable-next-line
    }, [isOpen])

    useEffect(() => {
        if (completeKybData?.success === true) {
            if (isOpen === true) {
                setIsOpen(false)
            }
            setTimeout(() => {
                refetchProfiles();
            }, 2000);
        }
        // eslint-disable-next-line
    }, [completeKybData, isOpen])

    return (
        <>
            <DynamicHeadTag
                headerText="Business Verification"
            />

            {(isOpen === true) &&
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50">
                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
                    <div className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl rounded-tl-3xl overflow-hidden shadow-xl animate__animated ${isOpen ? "animate__slideInUp" : "animate__slideOutDown"} animate__faster`}>
                        <div ref={businessVerificationRef} className="w-full h-full bg-white overflow-y-auto">
                            <Dialog.Panel className="min-h-full flex flex-col">

                                <Header setIsOpen={setIsOpen} />

                                <div className="flex flex-1 lg:px-0">
                                    <Sidebar
                                        activeStep={activeStep}
                                        goTo={goTo}
                                    />

                                    <div className="mt-32 lg:ml-80 lg:pl-44 pb-20 lg:flex-grow max-w-[740px] lg:mx-0">
                                        <Maybe condition={activeStep === 1}>
                                            <BusinessInformation
                                                goPrevious={goPrevious}
                                                goNext={goNext}
                                            />
                                        </Maybe>

                                        <Maybe condition={activeStep === 2}>
                                            <BusinessDocuments
                                                goPrevious={goPrevious}
                                                goNext={goNext}
                                            />
                                        </Maybe>

                                        <Maybe condition={activeStep === 3}>
                                            <OwnerInformation
                                                goPrevious={goPrevious}
                                                goNext={goNext}
                                            />
                                        </Maybe>

                                        <Maybe condition={activeStep === 4}>
                                            <AddDirectors
                                                goPrevious={goPrevious}
                                                goNext={goNext}
                                            />
                                        </Maybe>

                                        <Maybe condition={activeStep === 5}>
                                            <ReviewFinish
                                                goTo={goTo}
                                                goNext={goNext}
                                            />
                                        </Maybe>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            }
        </>
    );
}


const Header = (props: any) => (
    <div className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 fixed w-full top-0">
        <div className="flex items-center">
            <div className="w-6 h-6 bg-black rounded-md overflow-hidden">
                <img src={logo} alt="logo" className="w-full h-full object-contain" />
            </div>
            <div className="pl-3 ml-3 text-xl text-shiga-gray font-ojah border-l border-[#E2E3E5]">
                Business Verification
            </div>
        </div>
        <div onClick={() => props.setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
            <IconClose className="m-auto text-white svg-stroke-gray" />
        </div>
    </div>
)


const Sidebar = (props: any) => {
    const { activeStep, goTo } = props;
    const allSteps = [
        "Business Information",
        "Business Documents",
        "Owner Information",
        "Add Directors",
        "Review & Finish",
    ]

    const gotoStep = (e: number) => {
        if (activeStep > e) {
            goTo(e);
        }
    }

    return (
        <>
            <div className="w-80 pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="w-8 h-8 mr-2 flex items-center justify-center overflow-hidden">
                            <IconVerify />
                        </div>
                        <div className="font-medium">
                            Your Progress
                        </div>
                    </div>
                    <div className="px-1.5 py-px text-sm text-[#99999C] border-[1px] border-[#E2E3E5] rounded-lg">
                        {activeStep * 20}%
                    </div>
                </div>

                <div className="mt-6">
                    {allSteps.map((step: string, index: number) =>
                        <div key={step} onClick={() => gotoStep(index + 1)} className={`flex items-center ${activeStep > (index + 1) && "cursor-pointer group"}`}>
                            <div className="w-8 h-12 mr-3 flex items-center justify-center overflow-hidden">
                                {activeStep > (index + 1) && <div className="w-0.5 h-full bg-shiga-purple opacity-35"></div>}
                                {activeStep === (index + 1) && <IconArrowCurve className="relative -top-4 left-2" />}
                            </div>
                            <div className={`
                                h-12 flex flex-grow items-center
                                ${activeStep === (index + 1) ? "text-shiga-purple" : "text-[#99999C]"}
                                group-hover:bg-gray-100
                            `}>
                                {step}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="lg:hidden mt-32">
                <Dropdown className="!w-full" dropdownClass="w-full">
                    <div className="w-full form-input flex items-center justify-between !bg-white !border !border-gray-200">
                        <div>
                            {allSteps[activeStep - 1]}
                        </div>
                        <IconArrowUpDown />
                    </div>
                    <div>
                        {allSteps.map((step: string, index: number) =>
                            <Menu.Item key={index}>
                                <div onClick={() => gotoStep(index + 1)} className="dropdown-item flex items-center justify-between">
                                    <div className={`ellipsis ${(activeStep < (index + 1)) ? "opacity-30" : ""}`}>
                                        {step}
                                    </div>
                                    <div className="w-7 text-right flex-shrink-0">
                                        <Maybe condition={activeStep === (index + 1)}>
                                            <FontAwesomeIcon icon="check-circle" />
                                        </Maybe>
                                    </div>
                                </div>
                            </Menu.Item>
                        )}
                    </div>
                </Dropdown>
            </div>
        </>
    )
}


export default BusinessVerificationLayout;
