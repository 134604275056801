import { useContext } from 'react'
import TransfersContext from './TransfersContext'

const useTransfersProvider = () => {
   const context = useContext(TransfersContext)

   if (!context) {
      throw new Error('useTransfersProvider must be used within a MyProvider');
   }
   return context;
}

export default useTransfersProvider