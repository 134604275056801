interface ChevronRightIconProps extends React.BaseHTMLAttributes<SVGElement> {}

const ChevronRightIcon = ({ ...props }: ChevronRightIconProps) => {
  return (
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.601052 1.05271C0.397956 1.25581 0.397956 1.58509 0.601052 1.78819L3.30109 4.48823L0.575906 7.21342C0.37281 7.41651 0.37281 7.7458 0.575906 7.94889C0.779002 8.15199 1.10829 8.15199 1.31138 7.94889L4.40431 4.85597C4.6074 4.65287 4.6074 4.32359 4.40431 4.12049L1.33653 1.05271C1.13343 0.849617 0.804148 0.849617 0.601052 1.05271Z"
        fill="#E2E3E5"
      />
    </svg>
  );
};

export default ChevronRightIcon;
