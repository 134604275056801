
import { ReactComponent as Refresh } from "assets/images/icons/refresh.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";

import Maybe from "components/common/Maybe";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/data-table/data-table-column-header";
import { Refund } from "types/refund";
import { copyText } from "utils/handleCopy";
import Tooltip from "components/common/Tooltip";
import moment from "moment";
// import { Tooltip } from "react-tooltip";

export const RefundColumns: ColumnDef<Refund>[] = [
    {
        accessorKey: "formattedName",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="NARRATION" />
        ),
        cell: ({ row, table }) => (
            <div className="flex items-center cursor-pointer" onClick={() => (table.options.meta as any).handleSelected(row.original)}>
                <div className="bg-outline-grey rounded-full p-2 mr-6">
                    <Refresh className="m-auto" />
                </div>
                <div>
                    <div className="text-sm text-shiga-gray">{row.original.status === "PENDING" ? "Refund to" : "Payment to"}</div>
                    <div className="font-medium">{row.original.formattedName}</div>
                </div>
            </div>
        ),
    },
    {
        accessorKey: "formattedAmount",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="AMOUNT" />
        ),
        cell: ({ row, table }) => (
            <span className="text-shiga-gray font-medium cursor-pointer"
                onClick={() => (table.options.meta as any).handleSelected(row.original)}
            >{row.original.formattedAmount}</span>
        ),
    },
    {
        accessorKey: "status",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="STATUS" />
        ),
        cell: ({ row, table }) => (
            <div className="cursor-pointer" onClick={() => (table.options.meta as any).handleSelected(row.original)}>
                <Maybe condition={row.original.status === "COMPLETED"}>
                    <div className="label-border !text-[#00C46C] !border-[#00C46C] !p-1">
                        <span className="font-medium">Completed</span>
                    </div>
                </Maybe>
                <Maybe condition={row.original.status === "PENDING"}>
                    <div className="label-border !text-[#F29100] !border-outline-orange !p-1">
                        <span className="font-medium">Pending</span>
                    </div>
                </Maybe>
            </div>
        ),
    },
    {
        accessorKey: "type",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="TYPE" />
        ),
        cell: ({ row, table }) => (
            <span className="cursor-pointer" onClick={() => (table.options.meta as any).handleSelected(row.original)}>
                {row.original.type}
            </span>
        ),
    },
    {
        accessorKey: "reference",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="REFERENCE" />
        ),
        cell: ({ row, table }) => (
            <div className="flex flex-nowrap items-center cursor-pointer"
                onClick={copyText(row.original.reference, "Refund reference")}>
                <span className="font-medium mr-1.5 truncate max-w-[60%]">{row.original.reference}</span>
                <IconCopy className="text-shiga-dark" />
            </div>
        ),
    },
    {
        accessorKey: "refunded on",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="REFUNDED ON" />
        ),
        cell: ({ row, table }) => <span className="font-medium cursor-pointer"
            onClick={() => (table.options.meta as any).handleSelected(row.original)}>
            {moment(row.original.createdAt).format('MMM D, YYYY [at] h:mmA')}
        </span>,
    },
    {
        accessorKey: "dropdown",
        header: ({ column }) => (<></>),
        cell: ({ row }) =>
            <div>
                <Tooltip
                    message={
                        <span>
                            Refund can take <br /> up to 21 days <br /> in some instances <br /> to be completed.
                        </span>
                    }>
                    <IconInfo id="refund-info" className="text-[#1F1F1E29]" />
                </Tooltip>
            </div >,
    },
];