import { useSelector } from "react-redux";
import { useSharedStore } from "zustand-store";

const NetwordLoading = () => {
  const { apiLoadingStatus } = useSelector((allState: any) => allState.loading);
  const { apiLoading } = useSharedStore();

  const displayClas = apiLoadingStatus || apiLoading ? "" : "hidden";
  return (
    <div
      className={`z-50 absolute w-[100vw] h-1 bg-gray-300 rounded-md overflow-hidden ${displayClas}`}
    >
      <div className="h-full bg-shiga-black animate-progress"></div>
    </div>
  );
};

export default NetwordLoading;
