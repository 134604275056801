import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLoginInfo from "hooks/useLoginInfo";
import CustomSwitch from "components/common/form/CustomSwitch";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import FormDrawer from "components/common/form-drawer";
import { ButtonLoader } from "components/common/form";
import { ReactComponent as IconSettings } from "assets/images/icons/Settings.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconNigeria } from "assets/images/flags/Nigeria (NG).svg";
import { ReactComponent as IconUKEU } from "assets/images/flags/UKEU.svg";
import { ReactComponent as IconGhana } from "assets/images/flags/Ghana (GH).svg";
import { ReactComponent as IconRwanda } from "assets/images/flags/Rwanda (RW).svg";
import { ReactComponent as IconUganda } from "assets/images/flags/Uganda (UG).svg";
import { ReactComponent as IconKenya } from "assets/images/flags/Kenya (KE).svg";
import { ReactComponent as IconTanzania } from "assets/images/flags/Tanzania (TZ).svg";
import { ReactComponent as IconMalawi } from "assets/images/flags/Malawi (MW).svg";
import { ReactComponent as IconZambia } from "assets/images/flags/Zambia (ZM).svg";
import * as settingsActions from "store/entities/settings/action";
import * as userActions from "store/auth/user/action";
import Maybe from "components/common/Maybe";
import PageContent from "components/common/PageContent";
import ContentLoading from "components/common/ContentLoading";


const PaymentMethods = () => {
    const dispatch = useDispatch();
    const { getBusinessProfile } = useLoginInfo();
    const { userInfo } = useSelector((s: any) => s.auth.userPersist);
    const { businessProfileLoading } = useSelector((s:any) => s.auth.account);
    const { paymentMethodUpdate } = useSelector((s: any) => s.entities.settings);

    const [isOpenBank, setIsOpenBank] = useState(false);
    const [isOpenMobileMoney, setIsOpenMobileMoney] = useState(false);
    const [formLoading, setFormLoading] = useState<any>([]);
    const [form, setForm] = useState({
        CARD: false,
        BANK_TRANSFER: false,
        MOBILE_MONEY: false,
        APPLE_PAY: false,
        GOOLE_PAY: false,
    })

    const populateForm = () => {
        let data:any = {};
        Object.keys(form).map((key:string) => 
            data = {...data, [key]: (userInfo?.businessProfile?.paymentMethods?.includes(key))}
        )
        setForm(data);
    }

    const updateData = (key:string, value:boolean) => {
        const params = {
            method: key,
            status: value ? "ENABLE" : "DISABLE",
        }
        setFormLoading([...formLoading, key]);
        dispatch(settingsActions.updatePaymentMethod(params));
    }

    const removeLoader = () => {
        let data = [...formLoading];
        data.shift();
        setFormLoading(data);
    }

    useEffect(() => {
        if (userInfo?.businessProfile === undefined){
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined){
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (paymentMethodUpdate?.success !== undefined){
            if (paymentMethodUpdate?.success === true){
                dispatch(userActions.updateUserInfo({
                    businessProfile: {
                        ...paymentMethodUpdate?.data ?? {},
                    },
                }));
                if (formLoading[0] && formLoading[0] === "BANK_TRANSFER" && paymentMethodUpdate?.data?.paymentMethods?.includes("BANK_TRANSFER")){
                    setIsOpenBank(true);
                }
            }
            dispatch(settingsActions.resetUpdatePaymentMethod());
            removeLoader();
        }
        // eslint-disable-next-line
    }, [paymentMethodUpdate]);

    return (
        <div>
            <DynamicHeadTag
                headerText="Payment Methods"
            />

            <BankPreferences
                isOpen={isOpenBank}
                setIsOpen={setIsOpenBank}
            />

            <MobileMoneyPreferences
                isOpen={isOpenMobileMoney}
                setIsOpen={setIsOpenMobileMoney}
            />

            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                Payment Methods
            </div>

            <PageContent
                loading={businessProfileLoading}
                data={userInfo?.businessProfile}
                loadingText="Loading payment methods..."
            />

            <Maybe condition={businessProfileLoading === false}>
                <div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Cards
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Visa, Mastercard, Discover, Verve.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.CARD}
                                onChange={(e:any) => updateData("CARD", e)}
                                disabled={formLoading.includes("CARD")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("CARD")} />
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Bank Transfers
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Allows you receive payments via bank transfers.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.BANK_TRANSFER}
                                onChange={(e:any) => updateData("BANK_TRANSFER", e)}
                                disabled={formLoading.includes("BANK_TRANSFER")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("BANK_TRANSFER")} />
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex pb-4 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Mobile Money
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Ghana, Kenya, Uganda, Zambia, Rwanda, Tanzania etc
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium">
                            <div className="flex items-center">
                                <CustomSwitch
                                    active={form.MOBILE_MONEY}
                                    onChange={(e:any) => updateData("MOBILE_MONEY", e)}
                                    disabled={formLoading.includes("MOBILE_MONEY")}
                                />
                                <div className="relative -top-3 left-4 text-xl">
                                    <ContentLoading loading={formLoading.includes("MOBILE_MONEY")} />
                                </div>
                            </div>
                            <div onClick={() => setIsOpenMobileMoney(true)} className="w-max ml-[3.25rem] relative -top-4 flex items-center text-xs text-[#99999C] border-b-2 border-transparent hover:border-[#99999C] cursor-pointer">
                                <IconSettings className="mr-0.5" />
                                Manage
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Apple Pay
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Support Apple payments from your customers via digital wallets
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.APPLE_PAY}
                                onChange={(e:any) => updateData("APPLE_PAY", e)}
                                disabled={formLoading.includes("APPLE_PAY")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("APPLE_PAY")} />
                            </div>
                        </div>
                    </div>

                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Google Pay
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Support Google payments from your customers via digital wallets
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.GOOLE_PAY}
                                onChange={(e:any) => updateData("GOOLE_PAY", e)}
                                disabled={formLoading.includes("GOOLE_PAY")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("GOOLE_PAY")} />
                            </div>
                        </div>
                    </div>

                </div>
            </Maybe>
        </div>
    )
}


const BankPreferences = (props:any) => {
    const { isOpen, setIsOpen } = props;
    const [form, setForm] = useState({
        nigeria: true,
        ukeu: false,
    })

    const submitForm = async (e:any) => {
        e.preventDefault();
        setIsOpen(false);
    }

    return (
        <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm}>
                <div className="p-8">
                    <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                        <div className="text-2xl font-ojah font-medium">
                            Bank Preferences
                        </div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                            <IconClose className="m-auto text-white svg-stroke-gray" />
                        </div>
                    </div>

                    <div className="mt-10">
                        <div className="flex justify-between space-x-8">
                            <div className="flex items-center">
                                <div className="w-10 h-10 mr-3 rounded-full">
                                    <IconNigeria className="w-full h-full" />
                                </div>
                                <div>
                                    <div className="font-medium">
                                        Nigeria
                                    </div>
                                    <div className="text-sm text-shiga-gray">
                                        Pay with banks in Nigeria
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CustomSwitch
                                    active={form.nigeria}
                                    onChange={(e:any) => setForm({...form, nigeria: !form.nigeria})}
                                    hideText={true}
                                />
                            </div>
                        </div>
                        <div className="pt-6 mt-6 flex justify-between space-x-8 border-t border-gray-100">
                            <div className="flex items-center">
                                <div className="w-10 h-10 mr-3 rounded-full">
                                    <IconUKEU className="w-full h-full" />
                                </div>
                                <div>
                                    <div className="font-medium">
                                        United Kingdom and European Union
                                    </div>
                                    <div className="text-sm text-shiga-gray">
                                        Pay with banks in UK/EU
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CustomSwitch
                                    active={form.ukeu}
                                    onChange={(e:any) => setForm({...form, ukeu: !form.ukeu})}
                                    hideText={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-100 p-8">
                    <ButtonLoader loading={false} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                        Save Changes
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>
    )
}


const MobileMoneyPreferences = (props:any) => {
    const { isOpen, setIsOpen } = props;
    const [form, setForm] = useState([
        {
            flag: <IconGhana className="w-full h-full" />,
            name: "Ghana",
            status: false,
        },
        {
            flag: <IconRwanda className="w-full h-full" />,
            name: "Rwanda",
            status: false,
        },
        {
            flag: <IconUganda className="w-full h-full" />,
            name: "Uganda",
            status: false,
        },
        {
            flag: <IconKenya className="w-full h-full" />,
            name: "Kenya",
            status: false,
        },
        {
            flag: <IconTanzania className="w-full h-full" />,
            name: "Tanzania",
            status: false,
        },
        {
            flag: <IconMalawi className="w-full h-full" />,
            name: "Malawi",
            status: false,
        },
        {
            flag: <IconZambia className="w-full h-full" />,
            name: "Zambia",
            status: false,
        },
    ])

    const updateStatus = (selectedCountry:any, selectedStatus:boolean) => {
        const newForm = form.map((country) => {
            if (country.name === selectedCountry.name){
                return {
                    ...country,
                    status: selectedStatus,
                };
            }
            else{
                return country;
            }
        })
        setForm(newForm);
    }

    const submitForm = async (e:any) => {
        e.preventDefault();
        setIsOpen(false);
    }

    return (
        <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm}>
                <div className="p-8">
                    <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                        <div className="text-2xl font-ojah font-medium">
                            Mobile Money Preferences
                        </div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                            <IconClose className="m-auto text-white svg-stroke-gray" />
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col hide-first-border">
                        
                        {form.map((country) =>
                            <div key={country.name} className="pt-3 mt-3 flex justify-between space-x-8 border-t border-gray-100">
                                <div className="flex items-center">
                                    <div className="w-10 h-10 mr-3 rounded-full">
                                        {country.flag}
                                    </div>
                                    <div>
                                        <div className="font-medium capitalize">
                                            {country.name}
                                        </div>
                                        <div className="text-sm text-shiga-gray">
                                            Receive mobile money from {country.name}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <CustomSwitch
                                        active={country.status}
                                        onChange={(e:any) => updateStatus(country, e)}
                                        hideText={true}
                                    />
                                </div>
                            </div>
                        )}

                    </div>
                </div>

                <div className="border-t border-gray-100 p-8">
                    <ButtonLoader loading={false} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                        Save Changes
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>
    )
}


export default PaymentMethods;