import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconProfile } from "assets/images/icons/profile.svg";
import { ReactComponent as Rectangle } from "assets/images/icons/rectangle.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createCard } from "store/card/action";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ShigaButton from "components/common/form/ShigaButton";

const cardContent =
{
  "Transaction Type": "",
  "Type": "",
  "Card Name": '',
  "Amount": "",
  "Fee": ""
}


export const ReviewCardAndCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState(cardContent);
  const [searchParams] = useSearchParams();
  const { createCardLoading } = useSelector((state: RootState) => state.cards)

  const label = searchParams.get("label");
  const amount = searchParams.get("amount");
  const cardType = searchParams.get("cardType");


  useEffect(() => {
    setCardDetails({
      "Card Name": label || "",
      "Transaction Type": "Card Creation",
      Amount: amount || "",
      Fee: "2 USD",
      Type: cardType || ""
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateCard = () => {
    const memberID = searchParams.get("memberID");
    dispatch(createCard({
      memberId: memberID || "",
      label: cardDetails["Card Name"],
      amount: cardDetails.Amount,
    }))

    setTimeout(() => {
      navigate("/cards?show-table")
    }, 2000);
  }

  return (
    <div className="space-y-8 pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      <div className="flex items-center justify-between pb-5 border-b border-gray-100">
        <div
          onClick={() => navigate("/cards/create-a-card")}
          className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
        >
          <IconNavigation className="w-full h-full" />
        </div>
        <div className="flex items-center space-x-3">
          <div className="flex space-x-2 items-center cursor-pointer">
            <div className="w-6 h-6 rounded-full flex items-center justify-center bg-black">
              <IconProfile />
            </div>
            <div className="text-black">Card Details</div>
          </div>
          <div>
            <IconArrowRight />
          </div>
          <div className="flex space-x-2 items-center cursor-pointer">
            <div className="w-6 h-6 rounded-full flex items-center justify-center bg-black">
              <Rectangle />
            </div>
            <div className="text-black">Review & Create</div>
          </div>
        </div>
        <div
          onClick={() => navigate("/cards")}
          className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
        >
          <IconClose className="m-auto svg-stroke-gray" />
        </div>
      </div>
      <div className="max-w-md space-y-8 mx-auto">
        <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
          Confirm the details for <br /> {label || 'Card'}
        </div>
        <div className="p-3 border-2 space-y-0.5 border-gray-100 rounded-3xl">
          {Object.entries(cardDetails).map(([key, value], idx) => (
            <div
              className={`flex w-full py-2 px-3 justify-between ${idx < 4 ? "border-b border-gray-100" : ""
                }`}
              key={idx}
            >
              <div>{key}</div>
              <div>{value}
                {key === 'Amount' && ' USD'}
              </div>
            </div>
          ))}
        </div>

        <ShigaButton
          darkBg
          fullWidth
          text="Confirm & Create Cared"
          loading={createCardLoading}
          onClick={handleCreateCard}
        />

      </div>
    </div>
  );
};
