import { Input, SelectInput } from "components/common/form";
import ShigaButton from "components/common/form/ShigaButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { transferNameEnquiry } from "store/transfer/action";
import useTransfersProvider from "../../context/useTransfersProvider";

const NgnAccountDetails = () => {
   const dispatch = useDispatch();
   const [enquiry, setEnquiry] = useState<any>(null);
   const { incrementSingleTrfStep, ngnForm, setNgnForm } = useTransfersProvider()
   const [isAcctValidated, setIsAcctValidated] = useState(false);
   const { bankList, loadingBank, accountName, loadingName } = useSelector((state: RootState) => state.transfer)

   const onSubmit = (e: any) => {
      e.preventDefault();
      incrementSingleTrfStep();
   }

   const resetValidation = () => {
      setEnquiry('');
      setIsAcctValidated(false);
   }

   useEffect(() => {
      if (ngnForm?.accountNumber?.length === 10 && ngnForm?.bankCode?.length > 0) {
         dispatch(transferNameEnquiry(ngnForm.accountNumber, ngnForm?.bankCode));
      }
      // eslint-disable-next-line
   }, [ngnForm?.accountNumber, ngnForm?.bankCode]);

   useEffect(() => {
      if (accountName?.length > 0) {
         setEnquiry(accountName);
         setIsAcctValidated(true);
         setNgnForm({ ...ngnForm, accountName })
      }
      // eslint-disable-next-line
   }, [accountName]);

   useEffect(() => {
      resetValidation();
      // eslint-disable-next-line
   }, []);

   return (
      <div>
         <form onSubmit={onSubmit}>
            <div className="w-full space-y-7">
               <SelectInput
                  label="Bank Name"
                  required
                  value={ngnForm.bankCode}
                  disabled={loadingBank}
                  onChange={(e: any) => {
                     const actual: any = bankList?.find((bank: any) => bank.bankCode === e.target.value)
                     setNgnForm({ ...ngnForm, bankName: actual?.name, bankCode: actual?.bankCode });
                     resetValidation()
                  }}>
                  <option value="" disabled>{loadingBank ? 'Fetching banks...' : 'Select a bank'}</option>
                  {bankList?.map((bank: any) => (
                     <option value={bank.bankCode} key={bank.bankCode}>
                        {bank.name}
                     </option>
                  ))}
               </SelectInput>

               <Input
                  required
                  type="number"
                  label="Account Number"
                  value={ngnForm.accountNumber}
                  placeholder="Enter account Number"
                  onChange={(e: any) => {
                     setNgnForm({ ...ngnForm, accountNumber: e.target.value });
                     resetValidation();
                  }}
               />

               <Input
                  required
                  readOnly
                  label="Account Name"
                  value={isAcctValidated ? enquiry : ''}
               />

               <ShigaButton
                  darkBg
                  fullWidth
                  type='submit'
                  disabled={!isAcctValidated}
                  text={loadingName ? 'Validating...' : 'Continue'}
               />
            </div>
         </form>
      </div>
   )
}

// ngn trf payload
// {
//    "accountName": "PAYSHIGA / DANIEL A.",
//       "accountNumber": "3123412341",
//          "amount": "500",
//             "bankCode": "000036",
//                "bankName": "OPTIMUS BANK",
//                   "currency": "NGN",
//                      "country": "NG",
//                         "saveBeneficiary": false,
//                            "narration": "asdf",
//                               "token": "066214",
//                                  "meta": {
//       "bankAddress": {
//          "description": "",
//             "place_id": ""
//       }
//    }
// }

export default NgnAccountDetails