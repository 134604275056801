import { Transaction } from "types/transaction";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";

type Props = {
  order: any;
  activeTab: string;
};

const OrderDetail = ({ order, activeTab }: Props) => {
  const { copyItem, showValue } = useToolkit();
  const initiator = `${order?.initiatedByAccount?.firstName || "N/A"} ${
    order?.initiatedByAccount?.lastName || ""
  }`;
  const showDetails = activeTab === "details";
  const showShipping = activeTab === "shipping";

  return (
    <div className="w-full">
      <Maybe condition={showDetails}>
        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Status</div>

          <div
            className={` ${
              order?.status === "Dispatched"
                ? "text-[#00C46C] border border-[#00C46C]"
                : "text-[#F90] border border-[#F90] "
            } transaction-detail-inline-value capitalize px-2 rounded-[6px]`}
          >
            {order?.status?.toLowerCase()}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Customer’s Name</div>

          <div className="flex items-center capitalize">{order?.name}</div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Customer’s Email</div>

          <div className="flex items-center">{order?.email}</div>
        </div>
        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Phone number</div>

          <div className="flex items-center">{order?.phoneNumber}</div>
        </div>

        <div className={`transaction-detail-inline-wrapper-last`}>
          <div className="transaction-detail-inline-key">Transaction ID</div>

          <div className="transaction-detail-inline-value">
            <div className="flex items-center font-medium">
              {order?.id}
              <button
                className="ml-1.5"
                onClick={() => copyItem(order?.id, "Transaction reference")}
              >
                <CopyIcon width={20} />
              </button>
            </div>
          </div>
        </div>
      </Maybe>

      <Maybe condition={showShipping}>
        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Card Type</div>

          <div className="flex items-center">
            {showValue(order?.cardTransaction?.accountName)}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Card Number</div>

          <div className="flex items-center">
            ****{showValue(order?.cardTransaction?.last4)}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Bank</div>

          <div className="flex items-center">
            {showValue(order?.cardTransaction?.bank)}
          </div>
        </div>

        <div className="transaction-detail-inline-wrapper">
          <div className="transaction-detail-inline-key">Country Code</div>

          <div className="flex items-center">
            {showValue(order?.cardTransaction?.countryCode)}
          </div>
        </div>

        <div className={`transaction-detail-inline-wrapper-last`}>
          <div className="transaction-detail-inline-key">IP Address</div>

          <div className="transaction-detail-inline-value">
            <div className="flex items-center font-medium space-x-2">
              {showValue(
                order?.cardTransaction?.ip?.split(", ")?.map((item: any) => (
                  <span key={item} className="underline text-shiga-purple-400">
                    {item}
                  </span>
                ))
              )}

              <button
                className="ml-1.5"
                onClick={() =>
                  copyItem(order?.cardTransaction?.ip, "IP address")
                }
              >
                <CopyIcon width={20} />
              </button>
            </div>
          </div>
        </div>
      </Maybe>
    </div>
  );
};

export default OrderDetail;
