import { getGiftCardsByCountry } from "lib/payments/giftCard";
import { toast } from "react-toastify";
import { GiftCard, defaultGiftCard } from "types/giftcard";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface GiftCardPayload {
  amount: number;
  currency: string;
  email: string;
  note: string;
  use_registered_email: boolean;
  giftCard: GiftCard;
  quantity: number;

}

interface GiftCardState {
  completed: string[];
  setCompleted: (slug: string) => void;
  giftCards: GiftCard[];
  getGiftCards: (country: string) => void;
  payload: GiftCardPayload;
  setPayload: (payload: GiftCardPayload) => void;
  loading: boolean;
}

const initialState = {
  completed: ["one"],
  giftCards: [] as GiftCard[],
  loading: false
}

export const useGiftCardStore = create(persist<typeof initialState & GiftCardState>(
  (set, get) => ({
    ...initialState,
    setCompleted: (slug) =>
      set((state) => ({ completed: [...state.completed, slug] })),
    getGiftCards: async (country: string) => {

      set({ loading: true });

      try {
        const { data } = await getGiftCardsByCountry(country);
        set({ giftCards: data });
      } catch (err: any) {
        toast.error("Unable to get giftcard", err.message)
      } finally {
        set({ loading: false });
      }
    },
    payload: {
      amount: 0,
      currency: "",
      email: "",
      note: "",
      use_registered_email: false,
      giftCard: defaultGiftCard,
      quantity: 0
    },
    setPayload: (payload) => set({ payload })
  }),
  {
    name: "giftcard-storage",
    storage: createJSONStorage(() => sessionStorage)
  }
));
