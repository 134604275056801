import { SelectInput, FileUploadForm } from 'components/common/form'
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import validate from 'utils/validate';
import { Dispatch, SetStateAction, useEffect } from 'react';
import ShigaButton from 'components/common/form/ShigaButton';
import useToolkit from 'hooks/useToolkit';
import { getBanks } from 'store/ui/banks/action';
import { RootState } from 'store/store';
import Papa from 'papaparse';


type Props = {
   goToNext: (value: SetStateAction<number>) => void;
   form: BulkTrfFormProps;
   setForm: Dispatch<SetStateAction<BulkTrfFormProps>>;
   tableItems: BulkTransferItemProps[] | undefined;
   setTableItems: Dispatch<SetStateAction<BulkTransferItemProps[] | undefined>>;
   file: any;
   setFile: Dispatch<any>;
}

const instructions = [
   "You can upload .xlsx or .csv file",
   "Make sure your file has correct headers such as Bank Account No, Bank Name, Account Name and Amount",
   "Number of row should not exceed 1,000"
]

const WalletBulkSheet = ({ form, setForm, goToNext, file, setFile, setTableItems }: Props) => {

   const { dispatch, useSelector, toastError } = useToolkit();
   const { banks } = useSelector((state: RootState) => state.ui.banks)

   const options = [
      {
         label: 'NGN',
         value: 'NGN',
      }
   ]

   useEffect(() => {
      dispatch(getBanks());
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      if (file !== null) {
         Papa.parse(file, {
            header: true,
            complete: (result) => {
               const validItems = result?.data?.filter((item: any) => item?.accountName?.length > 0 && item?.amount?.length > 0)?.map((item: any, idx) => {
                  const bank = banks?.data?.find((bank: any) => bank.bankCode === item?.bankCode);

                  return {
                     ...item,
                     bankName: bank?.name?.toLowerCase() || '',
                     index: idx + 1
                  }
               })

               if (validItems?.length > 0) {
                  setTableItems(validItems)
               } else if (validItems?.length < 1) {
                  toastError('CSV file does not contain any valid items')
               }
            },
            error: (error) => {
               toastError('Error parsing CSV:');
            }
         });
      }
      // eslint-disable-next-line
   }, [file])


   return (
      <div className="max-w-md space-y-8 mx-auto">
         <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
            Upload Bulk recipients
         </div>
         <div className="flex flex-col space-y-7">

            <div className="">
               <h6 className="text-shiga-dark font-medium mb-4">FOLLOW THE GUIDELINES BELOW</h6>
               {
                  instructions.map((item, idx) => (
                     <span key={idx} className="flex items-start mt-4">
                        <div className="pt-1 mr-2.5">
                           <IconInfo className="w-3 h-3" />
                        </div>
                        <p className="text-shiga-black text-sm">{item}</p>
                     </span>
                  ))
               }
            </div>

            <SelectInput
               disabled
               required
               type="text"
               label="Pay from"
               // placeholder="NGN"
               value={form.currency}
               onChange={(e: any) => { }}>
               {options.map((option: any) => (
                  <option key={option?.label} value={option?.label}>{option?.label}</option>
               ))}
            </SelectInput>

            <div className="w-full relative">


               <FileUploadForm
                  data=""
                  label="Upload a bulk sheet"
                  required
                  filename=''
                  checkFormValidation={validate}
                  placeholder={file?.name || ''}
                  onchange={(e: any) => setFile(e)}
                  filetype={["csv"]}
               />

               <div className="absolute right-0 top-0">

                  <a href="https://res.cloudinary.com/dvfxrgvcr/raw/upload/v1720538184/Bulk_transfer_sample_z7cfnz.csv" target="_blank" rel="noreferrer" download className="w-max text-xs text-shiga-purple-50 underline font-medium">
                     Sample CSV file
                  </a>

                  <a href="https://res.cloudinary.com/dvfxrgvcr/raw/upload/v1720538184/Bank_Codes_tqanpa.xlsx" target="_blank" rel="noreferrer" download className="w-max underline text-xs text-shiga-purple-50 font-medium ml-3.5">
                     Bank codes
                  </a>

               </div>
            </div>


         </div>

         <div className="w-full">
            <ShigaButton
               darkBg
               fullWidth
               text='Continue'
               disabled={file === null}
               onClick={() => goToNext((prevSt: number) => prevSt + 1)}
            />
         </div>

      </div>
   )
}

export default WalletBulkSheet