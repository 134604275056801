import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiResponseMeta } from "types/data-interface";
import Maybe from "./Maybe";
import useUrlNavigation from "hooks/useUrlNavigation";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

type Props = {
  meta?: ApiResponseMeta | undefined;
  limit?: number;
};

type PaginationProps = {
  start: number;
  end: number;
};

const Pagination = ({ meta, limit }: Props) => {
  limit = limit || 0;

  const { urlQuery } = useUrlNavigation();

  const nextPage = meta?.nextPage || 0;
  const prevPage = meta?.previousPage || 0;

  const hasNextPage = nextPage !== undefined && nextPage > 0;
  const hasPrevPage = prevPage !== undefined && prevPage > 0;

  const [pageProps, setPageProps] = useState<PaginationProps>();

  function getPaginationInfo(meta: any) {
    const itemsPerPage = 9;
    const currentPage = meta?.currentPage;
    const totalItems = meta?.size;

    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    setPageProps({
      start: startItem || 0,
      end: endItem || 0,
    });
  }

  useEffect(() => {
    getPaginationInfo(meta);
  }, [meta]);

  return (
    <div className="flex items-center space-x-2 font-inter font-medium">
      <Maybe condition={!hasPrevPage}>
        <div className="w-6 h-10 flex rounded-lg cursor-not-allowed">
          <FontAwesomeIcon
            icon="angle-left"
            className="text-shiga-gray m-auto"
          />
        </div>
      </Maybe>

      <Maybe condition={hasPrevPage}>
        <Link to={urlQuery("page=" + meta?.previousPage)}>
          <div className="w-6 h-10 hover:bg-gray-100 flex rounded-lg cursor-pointer">
            <FontAwesomeIcon icon="angle-left" className="m-auto" />
          </div>
        </Link>
      </Maybe>

      <span className="whitespace-nowrap">
        {`${pageProps?.start}-${pageProps?.end}`} of {meta?.size || 0}
      </span>

      <Maybe condition={!hasNextPage}>
        <div className="w-6 h-10 flex rounded-lg cursor-not-allowed">
          <FontAwesomeIcon
            icon="angle-right"
            className="m-auto text-shiga-gray"
          />
        </div>
      </Maybe>

      <Maybe condition={hasNextPage}>
        <Link to={urlQuery("page=" + meta?.nextPage)}>
          <div className="w-6 h-10 hover:bg-gray-100 flex rounded-lg cursor-pointer">
            <FontAwesomeIcon icon="angle-right" className="m-auto" />
          </div>
        </Link>
      </Maybe>
    </div>
  );
};

export default Pagination;
