import { ReactNode } from "react";

type Props = {
  heading: string;
  description: string;
  icon: ReactNode;
};
export const EmptyState = ({ heading, description, icon }: Props) => {
  return (
    <div className=" flex justify-center">
      <div className="w-[766px] h-[351px] pt-[92px] pb-2 bg-white flex-col justify-center items-center gap-5 inline-flex">
        <div className=" justify-center items-center  inline-flex">{icon}</div>
        <div className="h-[59px] flex-col justify-center items-center gap-[6px] flex">
          <div className="self-stretch h-[59px] flex-col justify-center items-center gap-2.5 flex">
            <div className="self-stretch font-ojah text-center text-zinc-800 text-[26px] font-normal ">
              {heading}
            </div>
            <div className="self-stretch text-center text-neutral-400 text-base font-normal font-['Inter']">
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
