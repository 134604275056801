import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Maybe from "./Maybe";

function useInlineRouter(defaultTabValue: string) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [activeTab, setActiveTab] = useState(defaultTabValue)

	const InlineRouter = ({ navItems, routerType }: InlineRouterProps) => {
		const tabMode = routerType === 'TAB';

		return (
			<div className="w-full">

				{/* <Maybe condition={!useInlineRouter}>
				<p className="pry-text text-xs font-medium tracking-wide mb-[3.5px] w-max relative">
					{text}
					<span className="absolute left-0 w-full pry-bg h-[2px] -bottom-[6px]" />
				</p>
				<div className="h-[1.5px] bg-gray-200" />
			</Maybe> */}

				<Maybe condition={tabMode}>
					<div className="flex items-center w-full lg:text-lg space-x-4 font-ojah">
						{navItems?.map(({ title, navValue }: InlineRouterNavItemProps, index) => {
							if (tabMode) {
								const isActive = activeTab === navValue;

								return (
									<div onClick={() => setActiveTab(navValue)} key={index + 3} className="w-max relative">
										<button className={`${isActive ? 'text-shiga-dark-100' : 'text-shiga-gray-100'}`}>
											{title}
										</button>

										<Maybe condition={isActive}>
											<div className="absolute left-0 w-full bg-black h-[2px] -bottom-[3px]" />
										</Maybe>
									</div>
								)
							}

							const isActive = pathname === navValue || pathname?.includes(navValue);

							return (
								<div onClick={() => navigate(navValue)} key={index + 3} className="w-max relative">
									<a onClick={(e) => e.preventDefault()} href={navValue} className={`${isActive ? 'text-tms-purple-200' : 'text-tms-purple-300'}`}>
										{title}
									</a>

									<Maybe condition={isActive}>
										<div className="absolute left-0 w-full pry-bg h-[2px] -bottom-[6px]" />
									</Maybe>
								</div>
							)

						})}
					</div>
				</Maybe>

			</div>
		)
	};


	return {
		InlineRouter,
		activeTab
	}
}

export default useInlineRouter;
