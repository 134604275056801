import axios from "axios";
import {
  apiBaseUrl,
  apiCreateInvoice,
  apiSendReminderInvoice,
  apiDeleteInvoice,
  apiFindAllInvoice,
  apiFindInvoice,
  apiUpdatePaidInvoice,
} from "data/config";
import { getToken } from "lib";
import {
  PaginatedApiResponse,
  PaginatedApiResponseNoMeta,
} from "types/data-interface";
import { BillingAddress, Invoice, InvoiceItem } from "types/payment/invoice";
import ObjectToQueryString from "utils/ObjectToQueryString";

export const updatePaidInvoice = async (invoiceID: any) =>
  axios
    .patch(
      apiBaseUrl + apiUpdatePaidInvoice(invoiceID),
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => res.data)
    .catch((error: any) => {
      throw new Error(error.response?.data?.message || error.message);
    });

// export const findAllInvoice = async (
//   params?: object
// ): Promise<PaginatedApiResponse<Invoice[]>> =>
//   axios
//     .get<PaginatedApiResponse<Invoice[]>>(
//       apiBaseUrl + apiFindAllInvoice + ObjectToQueryString(params),
//       {
//         headers: {
//           Authorization: "Bearer " + getToken(),
//         },
//       }
//     )
//     .then((res) => res.data);
export const findAllInvoice = async (
  params?: any
): Promise<PaginatedApiResponse<Invoice[]>> => {
  try {
    const query = params ? ObjectToQueryString(params) : "";
    const { data } = await axios.get<PaginatedApiResponse<Invoice[]>>(
      apiBaseUrl + apiFindAllInvoice + query,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error fetching invoices", error);
    throw error;
  }
};

interface CreateInvoiceOption extends Pick<InvoiceItem, "name" | "price"> {
  qty: number;
}
export const createInvoice = async (createInvoiceBody: {
  billingAddress: BillingAddress;
  items: CreateInvoiceOption[];
  title: string;
  imageUrl: string;
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
  note: string;
  currency: string;
  description: string;
  startDate: string;
  endDate: string;
  discount: number;
  vat: number;
}) =>
  axios
    .post(apiBaseUrl + apiCreateInvoice, createInvoiceBody, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((res) => res.data)
    .catch((error: any) => {
      throw new Error(error.response?.data?.message || error.message);
    });

export const findInvoice = async (
  findID: string
): Promise<PaginatedApiResponseNoMeta<Invoice>> =>
  axios
    .get(apiBaseUrl! + apiFindInvoice(findID), {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((res) => res.data);

export const removeInvoice = async (findID: string) =>
  axios
    .delete(apiBaseUrl! + apiDeleteInvoice(findID), {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((res) => res.data)
    .catch((error: any) => {
      throw new Error(error.response?.data?.message || error.message);
    });

export const sendReminderInvoice = async (findID: string) =>
  axios
    .post(
      apiBaseUrl! + apiSendReminderInvoice(findID),
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => res.data);
