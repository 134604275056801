import axios from "axios";
import { apiBaseUrl, apiBusinessGetWalletBalance } from "data/config";
import { getToken } from "lib";
import { Wallet } from "types/wallet";

export const getCurrentBalance = async (currency:string) =>
    axios.get(apiBaseUrl + apiBusinessGetWalletBalance + `/${currency}`, {
    headers: {
        Authorization: "Bearer " + getToken(),
    },
}).then(res => res.data as {
    data: Wallet;
    status: boolean;
    statusCode: number;
    success: boolean;
    message: string;
}).catch(error => {
    throw new Error(error.response?.data?.message || error.message);
})