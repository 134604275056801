import { ReactComponent as IconClockFill } from "assets/images/icons/clock-fill.svg";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import { ReactComponent as IconTriangleExclamation } from "assets/images/icons/triangle-exclamation.svg";
import { ReactComponent as IconWarning } from "assets/images/icons/Warning.svg";
import ShigaButton from "components/common/form/ShigaButton";
import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import { updateKybStep } from "store/auth/user/action";

type KybBannerProps = {
    onButtonClick: () => void
}


const KybStatusBanner = ({ onButtonClick }: KybBannerProps) => {
    const { businessProfile, isSandboxEnv, isLocalEnv, isProductionEnv, kybPending, kybNotStarted, kybRejected } = useToolkit();
    const businessStatusValid = businessProfile !== undefined && businessProfile?.verificationStatus?.length > 0;


    return (
        <div className="w-full">

            <Maybe condition={(isLocalEnv || isProductionEnv) && businessStatusValid}>

                <Maybe condition={kybPending}>
                    <KybInReview />
                </Maybe>

                <Maybe condition={kybNotStarted}>
                    <KybUnverified onButtonClick={onButtonClick} />
                </Maybe>

                <Maybe condition={kybRejected}>
                    <KybRejected onButtonClick={onButtonClick} />
                </Maybe>

            </Maybe>

            <Maybe condition={isSandboxEnv}>
                <SandboxKybBanner />
            </Maybe>

        </div>
    )
}


const KybRejected = ({ onButtonClick }: KybBannerProps) => {
    const { businessProfile, dispatch } = useToolkit();

    const showKybModal = () => {
        const firstStep = businessProfile?.rejectedSteps[0];

        dispatch(updateKybStep(Number(firstStep)));
        setTimeout(() => {
            onButtonClick()
        }, 500);
    }

    return (
        <div className="bg-shiga-warn px-6 py-3 flex items-center justify-center flex-wrap">
            <span className="flex items-start md:items-center text-white">
                <IconWarning className="hidden md:block" /> &nbsp;
                Your business verification request was rejected.
                &nbsp;
                <button onClick={showKybModal} className="underline underline-offset-4 decoration-[#FFFFFF66] decoration-2">
                    Review your submission
                </button>
                &nbsp;
                and try again
            </span>
        </div>
    )
}

const KybInReview = () => (
    <div className="bg-outline-orange px-6 py-3 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-shiga-orange">
            <IconClockFill className="hidden md:block" /> &nbsp;
            Your business documents are currently being reviewed.
        </span>
    </div>
)

const SandboxKybBanner = () => (
    <div className="bg-outline-orange px-6 py-3 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-shiga-orange">
            <IconInfoFillYellow className="hidden md:block" /> &nbsp;
            You are currently on sandbox mode.
        </span>
    </div>
)

// const Devmode = () => (
//     <div className="bg-[#F0FBF6] px-6 py-3 flex items-center justify-center flex-wrap">
//         <span className="flex items-start md:items-center text-[#00B866]">
//             <IconInfoFillGreen className="hidden md:block" /> &nbsp;
//             You are currently on Dev-mode.
//         </span>
//     </div>
// )

const KybUnverified = ({ onButtonClick }: KybBannerProps) => (
    <div className="bg-[#FF9505] px-6 py-3 flex items-center justify-between flex-wrap" >
        <span className="flex items-start md:items-center text-white">
            <IconTriangleExclamation className="hidden md:block" /> &nbsp;
            <p>
                Your business account is currently unverified. Verify now to start performing live transactions
            </p>
        </span>

        <ShigaButton
            whiteBg
            type="button"
            loading={false}
            text='Verify Business'
            onClick={onButtonClick}
            className="!py-3"
        />
    </div>
)

export default KybStatusBanner