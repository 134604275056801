export const GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW";
export const GET_DASHBOARD_OVERVIEW_START = "GET_DASHBOARD_OVERVIEW_START";
export const GET_DASHBOARD_OVERVIEW_FAILED = "GET_DASHBOARD_OVERVIEW_FAILED";
export const GET_DASHBOARD_OVERVIEW_RESET = "GET_DASHBOARD_OVERVIEW_RESET";

export const GET_DASHBOARD_CHART = "GET_DASHBOARD_CHART";
export const GET_DASHBOARD_CHART_START = "GET_DASHBOARD_CHART_START";
export const GET_DASHBOARD_CHART_FAILED = "GET_DASHBOARD_CHART_FAILED";
export const GET_DASHBOARD_CHART_RESET = "GET_DASHBOARD_CHART_RESET";

export const GET_DASHBOARD_CARD = "GET_DASHBOARD_CARD";
export const GET_DASHBOARD_CARD_START = "GET_DASHBOARD_CARD_START";
export const GET_DASHBOARD_CARD_FAILED = "GET_DASHBOARD_CARD_FAILED";
export const GET_DASHBOARD_CARD_RESET = "GET_DASHBOARD_CARD_RESET";

export const GET_DASHBOARD_WALLET = "GET_DASHBOARD_WALLET";
export const GET_DASHBOARD_WALLET_START = "GET_DASHBOARD_WALLET_START";
export const GET_DASHBOARD_WALLET_FAILED = "GET_DASHBOARD_WALLET_FAILED";
export const GET_DASHBOARD_WALLET_RESET = "GET_DASHBOARD_WALLET_RESET";

export const GET_DASHBOARD_ACTIVITIES = "GET_DASHBOARD_ACTIVITIES";
export const GET_DASHBOARD_ACTIVITIES_START = "GET_DASHBOARD_ACTIVITIES_START";
export const GET_DASHBOARD_ACTIVITIES_FAILED = "GET_DASHBOARD_ACTIVITIES_FAILED";
export const GET_DASHBOARD_ACTIVITIES_RESET = "GET_DASHBOARD_ACTIVITIES_RESET";

export const GET_BUSINESS_STATUS = "GET_BUSINESS_STATUS";
export const GET_BUSINESS_STATUS_START = "GET_BUSINESS_STATUS_START";
export const GET_BUSINESS_STATUS_FAILED = "GET_BUSINESS_STATUS_FAILED";
export const GET_BUSINESS_STATUS_RESET = "GET_BUSINESS_STATUS_RESET";