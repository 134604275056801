const BASE = "/payments";
export const PAYMENT_PATHS = {
  INDEX: BASE,
  // payment links
  PAYMENT_LINK: `${BASE}/payment-link`,
  CREATE_PAYMENT_LINK: `${BASE}/payment-link/create`,
  VIEW_ONE_PAYMENT_LINK: `${BASE}/payment-link/:id`,

  // tution
  TUITION: `${BASE}/tuition`,
  TUITION_STEPS: `${BASE}/tuition/steps`,

  // invoice
  INVOICE: `${BASE}/invoice`,
  CREATE_INVOICE: `${BASE}/invoice/create`,
  VIEW_INVOICE: `${BASE}/invoice/:id`,

  // gift cards
  GIFT_CARD: `${BASE}/gift-card`,
  GIFT_CARD_PURCHASE: `${BASE}/gift-card/purchase`,

  // book hotel
  HOTEL: `${BASE}/book-a-hotel`,

  // book flight
  FLIGHT: `${BASE}/book-a-flight`
};
