import { ReactComponent as IconEmptyInvoice } from "assets/images/icons/invoice-empty.svg";
import { DataTable } from "components/data-table/data-table";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceColumns, InvoiceDropdown } from "./table/InvoiceColumns";
import { PAYMENT_PATHS } from "../paths";
import { useRef, useEffect, useMemo, useState, RefObject } from "react";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { Invoice } from "types/payment/invoice";
import { DataTableHeader } from "components/data-table/data-table-header";
import { formatDate } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";
import { removeUnderscore } from "lib/remove-underscore";
import { useReactToPrint } from "react-to-print";
import { InvoiceFile } from "./table/InvoiceFile";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyTransactions from "pages/transactions/components/EmptyTransactions";
import { Badge } from "components/ui/badge";
import moment from "moment";
import { InvoiceFilterHandler } from "./table/InvoiceFilterHandler";
import { processParams } from "utils/processParams";
import useUrlQueryParams from "utils/useUrlQueryParams";
import { useLocation } from "react-router-dom";

export const InvoiceContainer = () => {
  const {
    fetchInvoices,
    createdInvoice: invoices,
    invoicesData,
  } = useInvoiceStore();
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  // const invoiceRef = useRef<HTMLDivElement>(null);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const { urlQueryParams } = useUrlQueryParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
  });

  const fetchLinks = async () => {
    setLoading(true);
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(
      urlQueryParams,
      params,
      "limit",
      "number",
      9,
      "limit"
    );
    params = processParams(
      urlQueryParams,
      params,
      "status",
      "string",
      "",
      "status"
    );

    params = processParams(
      urlQueryParams,
      params,
      "search",
      "string",
      "",
      "search"
    );
    params = processParams(
      urlQueryParams,
      params,
      "startDate",
      "string",
      "",
      "startDate"
    );
    params = processParams(
      urlQueryParams,
      params,
      "endDate",
      "string",
      "",
      "endDate"
    );

    setListParams(params);
    // fetchInvoices(params);
    try {
      await fetchInvoices(params);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = (invoice: Invoice) => {
    sessionStorage.setItem("view-invoice", invoice.id || "");
    navigate(`${PAYMENT_PATHS.INVOICE}/${invoice.invoiceNumber}`);
  };
  const date = new Date();

  const formattedInvoice = useMemo(
    () =>
      invoices?.map((item, idx) => ({
        ...item,
        formattedDate: formatDate(item.createdAt as unknown as string),
        formattedAmount: currencyFormat(
          item.total || 0,
          item?.currency || "NGN"
        ),
        status: removeUnderscore({ actionWord: item.status }).toLowerCase(),
        formattedHeader: `${item.invoiceNumber} ${item.recipientEmail}`,
      })),
    [invoices]
  );

  const filteredInvoices = useMemo(() => {
    if (filter && filter !== "All") {
      return formattedInvoice.filter(
        (invoice) => invoice.status.toLowerCase() === filter.toLowerCase()
      );
    }
    return formattedInvoice;
  }, [formattedInvoice, filter]);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef?.current,
    // documentTitle: "Shiga Business Invoice " + invoice.createdAt,
  });

  const getStatusColor = (type: string) => {
    switch (type) {
      case "paid":
        return "text-emerald-500 border-[#DCFAEC]";
      case "not paid":
        return "text-amber-500 border-outline-orange";
      default:
        return "text-red-400 border-[#FFEEE8]";
    }
  };

  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line
  }, [location]);

  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       await fetchInvoices();
  //     } catch (error) {
  //       console.error("Failed to fetch invoices on component mount", error);
  //     }
  //   };
  //   loadData();
  // }, [fetchInvoices]);

  // const handlePrint = useReactToPrint({
  //   content: () => document.getElementById(`invoice-${row.original.id}`),
  //   documentTitle: `Invoice - ${row.original.invoiceNumber}`,
  // });

  return (
    <div className="space-y-4 w-full p-10 ">
      <div className="flex justify-between">
        <div className="page-title">Invoices</div>
        <Button>
          <Link to={PAYMENT_PATHS.CREATE_INVOICE}>Create an Invoice</Link>
        </Button>
      </div>
      <Separator />

      <div>
        <InvoiceFilterHandler invoices={invoicesData} />

        <Table className="">
          {/* <Maybe
            condition={
              (transactionsLoading === false && !transactions) ||
              transactionsAreValid
            }
          > */}

          <Table.Head>
            <th>Invoice ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date Created</th>
            <th></th>
          </Table.Head>
          {/* </Maybe> */}

          <Table.Body className="">
            <TableContent
              total={invoicesData.payload?.length || 0}
              loading={loading}
              loadingText="Fetching Invoices"
              data={invoicesData.payload}
              colspan={6}
              emptyUI={<EmptyTransactions />}
            // customLoader={<Skeleton />}
            />

            {!loading &&
              formattedInvoice.map((invoice: any, idx: number) => {
                return (
                  <tr
                    key={idx * 2}
                    // onClick={() => handleOnClick(invoice)}
                    className="relative"
                  >
                    <td onClick={() => handleOnClick(invoice)}>
                      <div className="cursor-pointer flex-col justify-start items-start gap-2.5 inline-flex">
                        <div className="flex-col justify-start items-start gap-0.5 flex">
                          <div className="text-zinc-800 text-base font-medium font-['Inter']">
                            {invoice.invoiceNumber}
                          </div>
                          <div className="text-neutral-400 text-xs font-normal font-['Inter']">
                            {invoice.recipientEmail}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td onClick={() => handleOnClick(invoice)}>
                      <span className="text-shiga-black font-medium text-base cursor-pointer">
                        {invoice.formattedAmount}
                      </span>
                    </td>
                    <td onClick={() => handleOnClick(invoice)}>
                      <Badge
                        className={`rounded-md cursor-pointer capitalize ${getStatusColor(
                          invoice.status.toLowerCase()
                        )}`}
                        variant="outline"
                      >
                        {invoice.status}
                      </Badge>
                    </td>
                    <td onClick={() => handleOnClick(invoice)}>
                      <p className="font-medium   text-ellipsis cursor-pointer">
                        {moment(invoice.formattedDate).format(
                          "MMM D, YYYY [at] h:mma"
                        )}
                      </p>
                    </td>
                    <td>
                      <InvoiceDropdown
                        handlePrint={() => {
                          setSelectedInvoice(invoice);
                          setTimeout(() => {
                            handlePrint();
                          }, 1000);
                        }}
                        invoice={invoice}
                        setSelectedInvoice={setSelectedInvoice}
                      />
                    </td>
                  </tr>
                );
              })}
          </Table.Body>
        </Table>
      </div>

      {selectedInvoice && (
        <div style={{ display: "none" }}>
          <InvoiceFile invoiceRef={invoiceRef} invoice={selectedInvoice} />
        </div>
      )}
    </div>
  );
};
