import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeft.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import { ReactComponent as IconPlus } from "assets/images/icons/plus-icon.svg";
import { ReactComponent as IconNigeria } from "assets/images/flags/Nigeria (NG).svg";
import { ReactComponent as IconGhana } from "assets/images/flags/Ghana (GH).svg";
import { ReactComponent as IconZambia } from "assets/images/flags/Zambia (ZM).svg";
import { ButtonLoader } from "components/common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import NumberFormat from "utils/numberFormat";
import Maybe from "components/common/Maybe";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import AmountInput from "pages/bills/AmountInput";

export const AddMoney = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");

  const [currencies, setCurrencies] = useState([
    {
      currency: "NGN",
      icon: IconNigeria,
      selected: true,
    },
    {
      currency: "GHA",
      icon: IconGhana,
      selected: false,
    },
    {
      currency: "ZMA",
      icon: IconZambia,
      selected: false,
    },
  ]);
  return (
    <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      <div className="pb-5 mb-4 md:mb-8 lg:flex lg:items-center lg:justify-between border-b border-gray-100">
        <div onClick={() => navigate(-1)} className="size-8 flex items-center justify-center bg-shiga-dark/10 rounded-full cursor-pointer">
          <IconArrowLeft />
        </div>
        <div className="group size-8 flex items-center justify-center bg-shiga-dark/10 rounded-full cursor-pointer">
          <IconClose className="text-shiga-dark group-hover:text-shiga-black" />
        </div>
      </div>

      {/*  */}

      <div className="h-full flex items-center justify-center">
        <div className="px-5 py-5 max-w-md w-full">
          <h4 className="font-ojah text-4xl ">Enter Amount</h4>
          <div className="w-full h-px bg-[#F5F5F5] my-8"></div>

          <div>
            {/* <div className="flex justify-between items-center text-shiga-dark">
              <p>
                You Add <span className="text-shiga-warn">*</span>
              </p>
              <p className="flex items-center">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.44257 3.19336C2.57413 3.19336 1.87012 3.89737 1.87012 4.76581V12.2349C1.87012 13.1034 2.57413 13.8074 3.44257 13.8074H12.5628C13.4312 13.8074 14.1352 13.1034 14.1352 12.2349V7.43897C14.1352 6.57053 13.4312 5.86652 12.5628 5.86652H3.95361C3.58452 5.86652 3.28532 5.56732 3.28532 5.19823C3.28532 4.82914 3.58452 4.52994 3.95361 4.52994H11.8945C12.2636 4.52994 12.5628 4.23074 12.5628 3.86165C12.5628 3.49256 12.2636 3.19336 11.8945 3.19336H3.44257ZM11.6193 10.5839C12.1404 10.5839 12.5628 10.1615 12.5628 9.6404C12.5628 9.11933 12.1404 8.69693 11.6193 8.69693C11.0982 8.69693 10.6758 9.11933 10.6758 9.6404C10.6758 10.1615 11.0982 10.5839 11.6193 10.5839Z"
                    fill="#D9D9D9"
                  />
                </svg>
                You have{" "}
                <span className="text-shiga-purple mx-1">8,230.90 NGN</span>
                available
              </p>
            </div>
            <div className="relative border border-shiga-dark rounded-2xl p-1 flex justify-between mt-3">
              <input
                type="number"
                className="max-w-sm border-none focus-visible:outline-0 focus-visible:border-none text-4xl font-ojah placeholder:font-bold"
                placeholder="0.00"
                onChange={(e) => setAmount(e.target.value)}
                required={true}
              />
              <Menu
                as="div"
                className="absolute right-2 top-1/2 -translate-y-1/2"
              >
                <Menu.Button className="!w-fit form-input !bg-white shadow-none !text-sm flex items-center justify-between !rounded-xl">
                  <IconNigeria className="size-4 mr-1" />
                  <p className="font-medium text-xl">NGN</p>
                  <FontAwesomeIcon
                    icon="angle-down"
                    className="ml-2 text-shiga-dark"
                  />
                </Menu.Button>
                <Menu.Items as="div" className="dropdown">
                  {currencies.map(({ currency, ...rest }) => (
                    <Menu.Item key={currency}>
                      <div className="dropdown-item flex gap-2 items-center justify-between">
                        <rest.icon className="size-4" />
                        <div className="whitespace-nowrap">{currency}</div>
                        <div className="w-7 text-right flex-shrink-0">
                          <Maybe condition={rest.selected}>
                            <FontAwesomeIcon icon="check-circle" />
                          </Maybe>
                        </div>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            </div> */}
            <AmountInput 
             amountLeft={1234}
             isFieldRequired
             label=""
            />

            <div className="py-10 px-2 flex items-center gap-5">
              <div className="bg-shiga-dark/15 p-0.5 w-fit rounded-full">
                <IconPlus />
              </div>
              <p>
                <span className="font-medium text-shiga-black mr-1">2.5%</span>
                <span className="text-shiga-dark">Funding fee</span>
              </p>
            </div>

            <div className="flex items-center gap-5">
              <IconUserProfile className="rounded-full w-12" />
              <div>
                <p>
                  <span className="font-medium text-shiga-black mr-1">You</span>
                  <span className="text-shiga-dark">pay</span>
                </p>
                <p className="font-ojah text-xl mt-0,5">0.00 NGN</p>
              </div>
            </div>
          </div>

          <div className="flex items-center text-shiga-dark tracking-tight mt-8">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.69855 6.94989C9.69855 6.56329 9.38515 6.24989 8.99855 6.24989C8.61195 6.24989 8.29855 6.56329 8.29855 6.94989H9.69855ZM8.99855 9.21122H8.29855C8.29855 9.39687 8.3723 9.57492 8.50357 9.70619L8.99855 9.21122ZM9.91691 11.1195C10.1903 11.3929 10.6335 11.3929 10.9069 11.1195C11.1802 10.8462 11.1802 10.4029 10.9069 10.1296L9.91691 11.1195ZM2.85024 4.90024C2.57687 5.17361 2.57687 5.61683 2.85024 5.89019C3.12361 6.16356 3.56682 6.16356 3.84019 5.89019L2.85024 4.90024ZM5.53619 4.19419C5.80956 3.92083 5.80956 3.47761 5.53619 3.20424C5.26282 2.93088 4.81961 2.93088 4.54624 3.20424L5.53619 4.19419ZM14.1569 5.89019C14.4303 6.16356 14.8735 6.16356 15.1469 5.89019C15.4202 5.61683 15.4202 5.17361 15.1469 4.90024L14.1569 5.89019ZM13.4509 3.20424C13.1775 2.93088 12.7343 2.93088 12.4609 3.20424C12.1875 3.47761 12.1875 3.92083 12.4609 4.19419L13.4509 3.20424ZM13.3865 9.21122C13.3865 11.6346 11.422 13.5992 8.99855 13.5992V14.9992C12.1952 14.9992 14.7865 12.4078 14.7865 9.21122H13.3865ZM8.99855 13.5992C6.57512 13.5992 4.61055 11.6346 4.61055 9.21122H3.21055C3.21055 12.4078 5.80192 14.9992 8.99855 14.9992V13.5992ZM4.61055 9.21122C4.61055 6.78779 6.57512 4.82322 8.99855 4.82322V3.42322C5.80192 3.42322 3.21055 6.01459 3.21055 9.21122H4.61055ZM8.99855 4.82322C11.422 4.82322 13.3865 6.78779 13.3865 9.21122H14.7865C14.7865 6.01459 12.1952 3.42322 8.99855 3.42322V4.82322ZM8.29855 6.94989V9.21122H9.69855V6.94989H8.29855ZM8.50357 9.70619L9.91691 11.1195L10.9069 10.1296L9.49352 8.71624L8.50357 9.70619ZM3.84019 5.89019L5.53619 4.19419L4.54624 3.20424L2.85024 4.90024L3.84019 5.89019ZM15.1469 4.90024L13.4509 3.20424L12.4609 4.19419L14.1569 5.89019L15.1469 4.90024Z"
                fill="#99999C"
              />
            </svg>

            <p>
              Payment would be received{" "}
              <span className="font-medium text-shiga-black">instantly</span>
            </p>
          </div>
          <div className="py-8">
            <ButtonLoader
              loading={false}
              type="submit"
              disabled={!amount.trim().length}
              className="btn btn-lg btn-block btn-shiga-black"
            >
              {amount.trim().length ? `Pay ${NumberFormat(amount)} NGN` : "Pay"}
            </ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  );
};
