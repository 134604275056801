import { ReactComponent as FileType } from "assets/images/icons/FileType.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconFlag } from "assets/images/icons/flag.svg";
import { ReactComponent as IconShare } from "assets/images/icons/Share.svg";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/data-table/data-table-column-header";

const SelectedCardDropdown: React.FC<{
}> = () => {
    return (
        <Dropdown dropdownClass="dropdown-right">
            <></>
            <div>
                <Menu.Item
                    as="div"
                    className="dropdown-item dropdown-item-icon"
                >
                    <IconShare />
                    Share Receipt
                </Menu.Item>
                <Menu.Item
                    as="div"
                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                >
                    <IconFlag />
                    Report Transaction
                </Menu.Item>
            </div>
        </Dropdown>
    )
}

export const selectedCardTableColumns: ColumnDef<{}>[] = [
    {
        accessorKey: "narration",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="NARRATION" />
        ),
        cell: ({ row, table }) => (
            <div className="flex items-center">
                <div className="w-8 h-9 mr-3">
                    <FileType className="m-auto" />
                    {/* {index < 2 ? (
                    ) : (
                        <FileType2 className="m-auto" />
                    )} */}
                </div>
                <div>
                    <div className="text-sm text-shiga-gray">
                        Payment to
                    </div>
                    <div className="font-medium">NETFLIX.COM</div>
                </div>
            </div>
        ),
    },
    {
        accessorKey: "amount",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="AMOUNT" />
        ),
        cell: ({ row, table }) => (<span>53.39 USD</span>),
    },
    {
        accessorKey: "type",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="TYPE" />
        ),
        cell: ({ row, table }) => {
            return (
                <>
                    {/* <Maybe condition={index < 2}>
                        <div className="label-border !border-[#E2E3E5]">
                            Card Payment
                        </div>
                    </Maybe>
                    <Maybe condition={index >= 2}>
                    </Maybe> */}
                    <div className="label-border !border-[#E2E3E5]">
                        Card Check
                    </div>
                </>
            )
        },
    },
    {
        accessorKey: "reference",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="REFERENCE" />
        ),
        cell: ({ row, table }) => {
            const copyText = (text: string, label?: string | undefined) => () => {
                copy(text);
                toast.success("Copied: " + (label ?? text));
            };
            return (
                <>
                    <div
                        className="flex items-center cursor-pointer"
                        onClick={copyText("clp188ptj0001c90c", "Reference")}
                    >
                        <span>clp188ptj0001c90c</span>
                        <IconCopy className="w-4 h-4" />
                    </div>
                </>
            )
        },
    },
    {
        accessorKey: "date",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="DATE" />
        ),
        cell: ({ row, table }) => <span className="font-medium cursor-pointer">Nov 23, 2023</span>,
    },
    {
        accessorKey: "dropdown",
        header: ({ column }) => (<></>),
        cell: ({ row, table, getValue }) => {
            return (
                <SelectedCardDropdown/>
            )
        },
    },
];