import React from 'react';

interface CircularCardProps {
    imageSrc: string;
    title: string;
}

const CircularCard: React.FC<CircularCardProps> = ({ imageSrc, title }) => {
    return (
        <div className="relative w-16 h-16 rounded-full bg-card-grey">
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-4 text-white">
                <h3
                    style={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: 600,
                        letterSpacing: "-0.34px",
                    }}
                    className="text-lg font-bold"
                >
                    {title}
                </h3>
            </div>
            <div className="absolute z-10 p-2 -bottom-1 -right-1 w-8 h-8 rounded-full overflow-hidden">
                <img className="object-cover w-full h-full" src={imageSrc} alt={title} />
            </div>
        </div>
    );
};

export default CircularCard;
