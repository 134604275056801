import Maybe from 'components/common/Maybe';
import useTransfersProvider from '../context/useTransfersProvider'
import NgnTransfersHomepage from './ngn/NgnTransfersHomepage';
import UsdTransfersHomepage from './usd/UsdTransfersHomepage';
import AudTransfersHomepage from './aud/AudTransfersHomepage';
import GbpTransfersHomepage from './gbp/GbpTransfersHomepage';

const SingleTransferHomepage = () => {
   const { isNgnTransfer, isUsdTransfer, isGbpTransfer, isAudTransfer } = useTransfersProvider();

   return (
      <>
         <Maybe condition={isNgnTransfer}>
            <NgnTransfersHomepage />
         </Maybe>

         <Maybe condition={isUsdTransfer}>
            <UsdTransfersHomepage />
         </Maybe>

         <Maybe condition={isAudTransfer}>
            <AudTransfersHomepage />
         </Maybe>

         <Maybe condition={isGbpTransfer}>
            <GbpTransfersHomepage />
         </Maybe>
      </>

   )
}

export default SingleTransferHomepage