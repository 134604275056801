import { ReactComponent as EmptyPaymentLinkIcon } from "assets/images/icons/payment/empty-payment-links.svg";
import { EmptyState } from "components/common/EmptyState";
export const LinkPayments = () => {
  return (
    <div className=" flex items-center justify-center">
      <EmptyState
        heading="No Payment yet"
        description="You have not received any payment using this link yet"
        icon={<EmptyPaymentLinkIcon />}
      />
    </div>
  );
};
