import { CurrencyType } from "./misc"

export interface GiftCard {
    productId: number;
    productName: string;
    global: boolean;
    supportsPreOrder: boolean;
    senderFee: number;
    senderFeePercentage: number;
    discountPercentage: number;
    denominationType: "FIXED";
    recipientCurrencyCode: CurrencyType;
    minRecipientDenomination: null | string;
    maxRecipientDenomination: null | string;
    senderCurrencyCode: CurrencyType;
    minSenderDenomination: null | string;
    maxSenderDenomination: null | string;
    fixedRecipientDenominations: number[];
    fixedSenderDenominations: number[];
    fixedRecipientToSenderDenominationsMap: {
        [k:number]: number;
    }[];
    metadata: {};
    logoUrls: string[];
    brand: {
        brandId: number;
        brandName: string;
    };
    country: {
        isoName: CurrencyType;
        name: string;
        flagUrl: string
    },
    redeemInstruction: {
        concise: string
        verbose: string;
    }
}

export const defaultGiftCard: GiftCard = {
    brand: {
        brandId: 0,
        brandName: ""
    },
    country: {
        flagUrl: "",
        isoName: "",
        name: ""
    },
    denominationType: "FIXED",
    discountPercentage: 0,
    fixedRecipientDenominations: [],
    fixedRecipientToSenderDenominationsMap: [],
    fixedSenderDenominations: [],
    global: false,
    logoUrls: [],
    maxRecipientDenomination: "",
    maxSenderDenomination: "",
    metadata: {},
    minRecipientDenomination: "",
    minSenderDenomination: "",
    productId: 0,
    productName: "",
    recipientCurrencyCode: "NGN",
    redeemInstruction: {
        concise: "",
        verbose: ""
    },
    senderCurrencyCode: "GBP",
    senderFee: 0,
    senderFeePercentage:0,
    supportsPreOrder:false
}