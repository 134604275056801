import CenterModal from 'components/common/CenterModal'
import React, { RefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { Transaction } from 'types/transaction';
import { ReactComponent as IconOption } from "assets/images/icons/Option.svg";
import { TransactionNarration } from 'pages/transactions/TransactionUtils';
import moment from 'moment';
import BankTransferDetails from '../transaction-detai-types/BankTransferDetails';
import Maybe from 'components/common/Maybe';
import CustomNotePad from 'components/custom/CustomNotePad';
import BillPaymentDetails from '../transaction-detai-types/BillPaymentDetails';
import CurrencySwapDetails from '../transaction-detai-types/CurrencySwapDetails';
import DirectDebitDetails from '../transaction-detai-types/DirectDebitDetails';
import useInlineRouter from 'components/common/useInlineRouter';
import ApiCardTransactionDetails from '../transaction-detai-types/ApiCardTransactionDetails';
import TransactionPdfReceipt from '../transaction-detai-types/TransactionPdfReceipt';
import { ReactComponent as IconRefund } from "assets/images/icons/refresh-gray.svg";


type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   transaction: Transaction;
   receiptRef: RefObject<HTMLDivElement>;
   generateReceipt: () => void;
   triggerRefund: () => void;
}

const apiTrxNavItems: InlineRouterNavItemProps[] = [
   {
      title: 'More Details',
      navValue: 'details',
   },
   {
      title: 'Analytics',
      navValue: 'analytics',
   },
]

const TransactionDetailsModal = ({
   isOpen,
   setIsOpen,
   transaction,
   receiptRef,
   generateReceipt,
   triggerRefund
}: Props) => {
   const { InlineRouter, activeTab } = useInlineRouter(apiTrxNavItems[0]?.navValue)
   const [noteValue, setNoteValue] = useState('');
   const [optionShown, setOptionShown] = useState(false);

   const isCredit = transaction?.type === 'CREDIT';
   const isBankTransfer = transaction?.method === 'BANK_TRANSFER' || transaction?.channel === 'BANK_TRANSFER';
   const isBillPayment = transaction?.method === 'BILL_PAYMENT';
   const isGiftCard = transaction?.method === 'GIFT_CARD';
   const isCurrencySwap = transaction?.method === 'SWAP'
   const swapCurrencies = transaction?.description.split('>').map(currency => currency.trim());
   const [fromCurrency, toCurrency] = swapCurrencies;
   const isCardTransaction = transaction?.method === 'CARD';
   const isApiTrx = transaction?.medium === 'API'
   const isDirectDebit = transaction?.channel === 'DIRECT_DEBIT' || transaction?.deposit?.depositType === 'DIRECT_DEBIT';
   const isApiCardTrx = isApiTrx && transaction?.channel === 'CARD';
   const isApiBankTransfer = isApiTrx && transaction?.channel === 'BANK_TRANSFER';
   const isFailedTrx = transaction?.status === 'FAILED';

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         hasButton={isBankTransfer}
         buttonText='Get PDF Receipt'
         onButtonClick={generateReceipt}
         headerTitle='Transaction Details'>

         <div className="hidden">
            <TransactionPdfReceipt
               receiptRef={receiptRef}
               transaction={transaction}
            />
         </div>

         <div className='w-full'>

            <div className={`w-full flex-btw py-2 border-b border-shiga-gray-50 ${isCurrencySwap ? 'pb-5' : 'pb-3'}`}>
               <TransactionNarration
                  isDetailsView
                  transaction={transaction}
               />

               <div>
                  <div className={`font-medium text-right ${(isCredit && !isFailedTrx) && 'text-shiga-green-100'} ${isFailedTrx && 'line-through'}`}>
                     {isCredit && "+ "}{`${Number(transaction?.amount).toLocaleString()} ${isCurrencySwap ? fromCurrency : transaction?.currency ?? ''}`}
                  </div>

                  <div className='text-shiga-gray-300 text-sm text-right whitespace-nowrap'>
                     {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                  </div>
               </div>
            </div>

            <div className="mt-3 flex-btw pb-3 pt-2 relative">
               <Maybe condition={!isApiTrx}>
                  <div className='font-medium font-ojah lg:text-lg'>
                     More Details
                  </div>
               </Maybe>

               <Maybe condition={isApiTrx}>
                  <div className="pb-3">
                     <InlineRouter routerType='TAB' navItems={apiTrxNavItems} />
                  </div>
               </Maybe>

               <Maybe condition={isApiTrx}>
                  <div onClick={() => setOptionShown(!optionShown)} className='transform rotate-90 cursor-pointer'>
                     <IconOption />
                  </div>
               </Maybe>

               <Dropdown
                  triggerRefund={() => {
                     setIsOpen(false);
                     triggerRefund();
                  }}
                  optionShown={optionShown}
                  setOptionShown={setOptionShown}
               />
            </div>

            <div className="p-3.5 border border-shiga-gray-50 rounded-xl">
               <Maybe condition={isApiTrx}>

                  <Maybe condition={isDirectDebit || isApiBankTransfer}>
                     <DirectDebitDetails
                        activeTab={activeTab}
                        transaction={transaction}
                     />
                  </Maybe>

                  <Maybe condition={isApiCardTrx}>
                     <ApiCardTransactionDetails
                        activeTab={activeTab}
                        transaction={transaction}
                     />
                  </Maybe>
               </Maybe>

               <Maybe condition={!isApiTrx}>
                  <Maybe condition={isCurrencySwap}>
                     <CurrencySwapDetails
                        transaction={transaction}
                        fromCurrency={fromCurrency}
                        toCurrency={toCurrency}
                     />
                  </Maybe>

                  <Maybe condition={isBankTransfer || isCardTransaction}>
                     <BankTransferDetails
                        transaction={transaction}
                        isCardTransaction={isCardTransaction}
                     />
                  </Maybe>

                  <Maybe condition={isBillPayment || isGiftCard}>
                     <BillPaymentDetails
                        isGiftCard={isGiftCard}
                        transaction={transaction}
                     />
                  </Maybe>
               </Maybe>
            </div>

            <CustomNotePad
               noteValue={noteValue}
               setNoteValue={setNoteValue}
               noteIsRequired={false}
               notePadLeftLabel='Add a Note'
               notePadPlaceholder='Add a few notes to help you later'
            />
         </div>
      </CenterModal>
   )
}

const Dropdown = ({ optionShown, setOptionShown, triggerRefund }: {
   optionShown: boolean,
   setOptionShown: React.Dispatch<SetStateAction<boolean>>;
   triggerRefund: () => void;
}) => {

   const dropdownRef = useRef<HTMLDivElement>(null);

   const handleClickOutside = (event: any) => {
      if (dropdownRef.current && dropdownRef?.current !== null && !dropdownRef.current?.contains(event.target)) {
         setOptionShown(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line
   }, []);

   return optionShown ? (
      <div ref={dropdownRef} className={`absolute -top-5 right-0 z-50 transition-all duration-500 bottom-full bg-shiga-dark-500 flexed text-white text-sm rounded-xl p-5 ${optionShown ? 'opacity-100' : 'opacity-0 hidden'}`}>
         <button onClick={() => {
            setOptionShown(false);
            triggerRefund();
         }} className='flex items-center'>
            <IconRefund />
            <span className="ml-2">Refund payment</span>
         </button>
      </div>
   ) : <></>
}

export default TransactionDetailsModal