import { Link } from "react-router-dom";
import useBackButton from "hooks/useBackButton";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Error404 = () => {
    const {goBack} = useBackButton();
    const {userLogin, logoutUser} = useLoginInfo();

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="px-8 py-20 text-center max-w-md">

                <div>
                    <FontAwesomeIcon icon="user-times" className="text-8xl text-red-500" />
                </div>
                <div className="mt-8 text-4xl font-ojah font-bold">
                    Unauthorized Access
                </div>
                <div className="mt-4 text-shiga-gray">
                    Sorry, you are not permitted to access this page.
                    Please contact us if this is an error from us.
                </div>

                <div className="mt-8 space-y-2">
                    <button onClick={() => goBack()} className="btn btn-block btn-lg btn-shiga-black">
                        Back to previous page
                    </button>

                    <Maybe condition={userLogin}>
                        <button type="button" onClick={logoutUser} className="btn btn-block btn-lg btn-white">
                            Login as another user
                        </button>
                    </Maybe>

                    <Maybe condition={!userLogin}>
                        <Link to="/login" className="btn btn-block btn-lg btn-white">
                            Login to your account
                        </Link>
                    </Maybe>
                </div>

            </div>
        </div>
    )
}

export default Error404
