import * as actions from "./actionTypes";
import * as config from "data/config";

export function getUserProfile() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetUserProfile,
            method: "get",
            data: {},
            onStart: actions.GET_USER_PROFILE_START,
            onSuccess: actions.GET_USER_PROFILE,
            onError: actions.GET_USER_PROFILE_FAILED,
        }
    }
}

export function resetGetUserProfile() {
    return {
        type: actions.GET_USER_PROFILE_RESET,
        payload: {},
    }
}

export function getBusinessProfile() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetBusinessProfile,
            method: "get",
            data: {},
            onStart: actions.GET_BUSINESS_PROFILE_START,
            onSuccess: actions.GET_BUSINESS_PROFILE,
            onError: actions.GET_BUSINESS_PROFILE_FAILED,
        }
    }
}

export function resetGetBusinessProfile() {
    return {
        type: actions.GET_BUSINESS_PROFILE_RESET,
        payload: {},
    }
}

export function requestPasswordChange(params?: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRequestPasswordChange,
            method: "post",
            data: params,
            onStart: actions.REQUEST_PASSWORD_CHANGE_START,
            onSuccess: actions.REQUEST_PASSWORD_CHANGE,
            onError: actions.REQUEST_PASSWORD_CHANGE_FAILED,
        }
    }
}

export function resetRequestPasswordChange() {
    return {
        type: actions.REQUEST_PASSWORD_CHANGE_RESET,
        payload: {},
    }
}

export function initializeMFA() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiInitializeMFA,
            method: "get",
            data: {},
            onStart: actions.INITIALIZE_MFA_START,
            onSuccess: actions.INITIALIZE_MFA,
            onError: actions.INITIALIZE_MFA_FAILED,
        }
    }
}

export function resetInitializeMFA() {
    return {
        type: actions.INITIALIZE_MFA_RESET,
        payload: {},
    }
}

export function completeMFA(params: {
    token: string;
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompleteMFA,
            method: "post",
            data: params,
            onStart: actions.COMPLETE_MFA_START,
            onSuccess: actions.COMPLETE_MFA,
            onError: actions.COMPLETE_MFA_FAILED,
        }
    }
}

export function resetCompleteMFA() {
    return {
        type: actions.COMPLETE_MFA_RESET,
        payload: {},
    }
}

export function verifyMFA(params: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiVerifyMFA,
            method: "post",
            data: params,
            onStart: actions.VERIFY_MFA_START,
            onSuccess: actions.VERIFY_MFA,
            onError: actions.VERIFY_MFA_FAILED,
        }
    }
}

export function resetVerifyMFA() {
    return {
        type: actions.VERIFY_MFA_RESET,
        payload: {},
    }
}

export function disableMFA() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDisableMFA,
            method: "delete",
            data: {},
            onStart: actions.DISABLE_MFA_START,
            onSuccess: actions.DISABLE_MFA,
            onError: actions.DISABLE_MFA_FAILED,
        }
    }
}

export function resetDisableMFA() {
    return {
        type: actions.DISABLE_MFA_RESET,
        payload: {},
    }
}
