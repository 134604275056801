import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@radix-ui/react-label";
import { Upload } from "components/common/Upload";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { Switch } from "components/ui/switch";
import { Textarea } from "components/ui/textarea";
import { uploadFile } from "lib/files";
import {
  createPaymentLink,
  getPaymentLink,
  updatePaymentLink,
} from "lib/payments";
import { CheckCircle2, Info, Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PaymentLink } from "types/payment/payments.interface";
import { z } from "zod";
import { useSharedStore } from "zustand-store";
import { Input, InputAmount } from "components/common/form";
import CommandSelect from "components/ui/command-select";
import { CurrencyType } from "types/misc";
import useToolkit from "hooks/useToolkit";
import { getAllowedCurrencies } from "store/transactions/action";
import { RootState } from "store/store";

const PaymentDetailsStepFormSchema = z.object({
  name: z.string({ message: "name is required" }),
  amount: z.coerce.number().optional(),
  currency: z.string({ message: "currency is required" }),
  description: z.string().optional(),
  slug: z.string().optional(),
  acceptOtherCurrencies: z.boolean(),
});
const AmountHeader: React.FC<{
  balance: number;
  currency: CurrencyType;
}> = ({ balance, currency }) => {
  return (
    <FormLabel className="width-full">
      {/* <div className="w-[159px]">
        <span className="text-zinc-400 text-sm font-normal font-['Inter']">
          You send
        </span>
        <span className="text-zinc-400 text-sm font-normal font-['Inter']">
          {" "}
        </span>
        <span className="text-red-500 text-sm font-normal font-['Inter']">
          *
        </span>
      </div> */}
      <div className="text-left">
        <span className="text-neutral-400 text-sm font-normal font-['Inter']">
          You receive<span className="form-input-required">*</span>
        </span>
        {/* <span className="text-indigo-500 text-sm font-medium font-['Inter']">
          {balance && currency ? currencyFormat(balance, currency) : "0.00"}
        </span> */}
        {/* <span className="text-neutral-400 text-sm font-normal font-['Inter']">
          {" "}
          available
        </span> */}
      </div>
    </FormLabel>
  );
};

export const PersonalizeLink = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [link, setLink] = useState<PaymentLink | null>(null);

  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  const { dispatch, useSelector, toastError, toastSuccess } = useToolkit();
  const { allowedCurrenciesLoading, allowedCurrenciesData } = useSelector((state: RootState) => state.transactions)

  // Filter currencies based on wallet data
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  )?.map(item => {
    const match = allowedCurrenciesData?.data.find((secondItem: any) => secondItem.currency === item.currency);
    return match ? { ...item, ...match } : item;
  })?.filter((item: any) => item?.canCollect === true)

  const form = useForm<z.infer<typeof PaymentDetailsStepFormSchema>>({
    resolver: zodResolver(PaymentDetailsStepFormSchema),
    defaultValues: {
      name: link?.name,
      amount: link?.amount,
      currency: link?.currency || "NGN",
      description: link?.description,
      acceptOtherCurrencies: link?.acceptOthers || false,
    },
  });

  const slug = form.watch("slug");

  const [previewUrl, setPreviewUrl] = useState("");
  useEffect(() => {
    if (id) {
      getPaymentLink(id).then((res) => {
        const l = res.data.paymentLink;
        setLink(l);
        form.setValue("name", l.name);
        form.setValue("amount", l.amount);
        form.setValue("currency", l.currency);
        form.setValue("description", l.description);
        form.setValue("slug", l.slug);
        form.setValue("acceptOtherCurrencies", l?.acceptOthers);
        setPreviewUrl(l.logoUrl);
      });
    }
  }, [id, form]);

  const [type, setType] = useState<"FIXED" | "FLEXIBLE">("FIXED");

  const [file, setFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const handleUpdatePaymentLink = (
    data: z.infer<typeof PaymentDetailsStepFormSchema>
  ) => {
    return updatePaymentLink(link!.id, {
      description: data.description,
      amount: data?.amount,
      name: data.name,
    })
      .then((res) => {
        if (res.success) {
          toastSuccess("Payment link Updated Successfully.");
          navigate("/payments");
        } else {
          setDisabled(false);
        }
      })
      .catch((err) => {
        toastError(err.message);
      });
  };

  const handleUpload = (
    data: z.infer<typeof PaymentDetailsStepFormSchema>,
    file: File
  ) => {
    return uploadFile(file).then((res) => res.data.file.link);
  };

  async function onSubmit(data: z.infer<typeof PaymentDetailsStepFormSchema>) {
    if (link) {
      setDisabled(true);
      handleUpdatePaymentLink(data);
    } else {
      const selectedCurrency = data?.currency;
      const mirrorWallet = allowedCurrenciesData?.data?.find((item: any) => item.currency === selectedCurrency);

      if (mirrorWallet && mirrorWallet?.canCollect !== true) {
        toastError("You cannot receive payments in this currency.");
      } else if (!data?.amount || data?.amount < 1) {
        toastError("Please enter a valid amount");
      } else {
        setDisabled(true);
        let logoUrl = file ? await handleUpload(data, file) : "";
        createPaymentLink({
          ...data,
          amountType: type,
          currency: {
            value: data.currency || "NGN",
            type,
          },
          logoUrl,
          slug: data.slug,
          ...(type === "FIXED" ? { amount: data.amount } : {}),
          ...(data.amount ? { amount: data.amount * 100 } : {}),
        })
          .then((res) => {
            if (res.error) {
              toastError(res.error);
            } else if (res.success) {
              toastSuccess("Payment link Created Successfully.");

              navigate("/payments");
            }
          })
          .catch((err) => {
            toastError(err?.message);
            setDisabled(false);
          })
          .finally(() => {
            setDisabled(false);
          });

      }
    }
  }

  useEffect(() => {
    dispatch(getAllowedCurrencies());
    // eslint-disable-next-line
  }, [])

  return (
    <section className="mt-12 pt-12 px-8 lg:px-0 flex flex-col-reverse lg:flex-row lg:justify-around">
      <div className="space-y-7 mt-12 lg:mt-0 lg:w-[412px]">
        <Tabs defaultValue="money-in" className="w-full">
          <TabsList className="grid w-full grid-cols-2 rounded-3xl *:rounded-3xl">
            <TabsTrigger onClick={() => setType("FIXED")} value="money-in">
              Fixed Amount
            </TabsTrigger>
            <TabsTrigger onClick={() => setType("FLEXIBLE")} value="money-out">
              Flexible Amount
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel>
                    
                    <span className="text-warn ml-0.5">*</span>
                  </FormLabel> */}
                  <FormControl>
                    <Input label="Name of Payment Link" placeholder="Enter a name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {type === "FLEXIBLE" ? (
              <FormField
                control={form.control}
                name="currency"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <CommandSelect
                      filterOption={(value, search) => {
                        if (value.includes(search)) return 1;
                        return 0;
                      }}
                      notFound="No currency found."
                      label="Accept payment in"
                      options={filteredCurrencies.map((item) => ({
                        ...item,
                        id: item.id + "",
                        value: item.currency,
                      }))}
                      SelectTrigger={() => (
                        <span className="flex items-center">
                          <img
                            src={
                              filteredCurrencies.find(
                                (currency) => currency.currency === field.value
                              )?.icon || noisygradient
                            }
                            alt={field.value}
                            className="size-5 mr-3"
                          />
                          {field.value}
                        </span>
                      )}
                      RenderOption={({ item }) => (
                        <>
                          <span className="flex">
                            <img
                              src={item.icon || noisygradient}
                              alt={item.country}
                              className="size-5 mr-3"
                            />
                            {item.country}
                          </span>
                          {field.value === item.country && (
                            <CheckCircle2 className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                          )}
                        </>
                      )}
                      handleSelect={(select) => {
                        form.setValue("currency", select.value);
                      }}
                    />
                  </FormItem>
                )}
              />
            ) : (
              <div>
                {/* <InputAmount
                  currencies={currencies}
                  form={form as any}
                  minAmount={0}
                  label="You receive *"
                /> */}
                <InputAmount
                  currencies={filteredCurrencies}
                  form={form as any}
                  minAmount={0}
                  bottomText="The minimum amount you can receive is 1 NGN"
                  header={({ currentWallet }) => (
                    <AmountHeader
                      balance={currentWallet.balance}
                      currency={currentWallet.currency}
                    />
                  )}
                />
                <FormField
                  control={form.control}
                  name="acceptOtherCurrencies"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center w-full py-4 justify-between">
                          <div className="flex items-center gap-2">
                            <Info className="text-white fill-neutral-400" />
                            <Label htmlFor="acceptOtherCurrencies">
                              Accept payment in other currencies
                            </Label>
                          </div>
                          <Switch
                            disabled={link !== null || true}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            id="acceptOtherCurrencies"
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Add a Description (optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Say a bit more about this link"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator />
            <h4 className="font-medium">Advanced Customer Options:</h4>

            <FormField
              control={form.control}
              name="slug"
              render={({ field }) => (
                <FormItem>
                  {/* <FormLabel></FormLabel> */}
                  <FormControl>
                    <div className="relative">
                      <Input
                        label="Custom URL"
                        containerClass="mb-0"
                        disabled={link !== null}
                        {...field}
                        placeholder="abcd.xyz"
                      />
                      <span className="flex text-[#B4B4B4]">
                        http://checkout.payshiga.com/
                        <p className="text-[#1F1F1E]">{slug}</p>
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              disabled={allowedCurrenciesLoading || disabled}
              className="w-full">
              {disabled ? (
                <Loader className="animate-spin" />
              ) : link ? (
                "Update Link"
              ) : (
                "Create Link"
              )}
            </Button>
          </form>
        </Form>
      </div>
      <div className=" flex justify-center">
        <Upload
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          file={file}
          setFile={setFile}
        />
      </div>
    </section>
  );
};
