import { Steps } from "types/payment/steps";
import user from "assets/images/icons/user_circle.png";
import { ReactComponent as Note } from "assets/images/icons/note.svg";

export const PURCHASE_GIFTCARD_STEPS: Steps[] = [
  {
    name: "Payment Info",
    slug: "payment_info",
    icon: <Note />,
  },

  {
    name: "Review & Send",
    slug: "review_send",
    icon: <img src={user} alt="user" />,
  },
];
