export const RESET_RCN_VALIDATION = "RESET_RCN_VALIDATION";

export const VALIDATE_RCN_START = "VALIDATE_RCN_START";
export const VALIDATE_RCN_DONE = "VALIDATE_RCN_DONE";
export const VALIDATE_RCN_FAILED = "VALIDATE_RCN_FAILED";

export const COMPLETE_KYB_START = "COMPLETE_KYB_START";
export const COMPLETE_KYB_DONE = "COMPLETE_KYB_DONE";
export const COMPLETE_KYB_FAILED = "COMPLETE_KYB_FAILED";

export const DELETE_DIRECTOR_START = "DELETE_DIRECTOR_START";
export const DELETE_DIRECTOR_DONE = "DELETE_DIRECTOR_DONE";
export const DELETE_DIRECTOR_FAILED = "DELETE_DIRECTOR_FAILED";

export const UPDATE_BUSINESS_VERIFICATION = "UPDATE_BUSINESS_VERIFICATION";
export const UPDATE_BUSINESS_VERIFICATION_START = "UPDATE_BUSINESS_VERIFICATION_START";
export const UPDATE_BUSINESS_VERIFICATION_FAILED = "UPDATE_BUSINESS_VERIFICATION_FAILED";
export const UPDATE_BUSINESS_VERIFICATION_RESET = "UPDATE_BUSINESS_VERIFICATION_RESET";

export const ADD_DIRECTOR = "ADD_DIRECTOR";
export const ADD_DIRECTOR_START = "ADD_DIRECTOR_START";
export const ADD_DIRECTOR_FAILED = "ADD_DIRECTOR_FAILED";
export const ADD_DIRECTOR_RESET = "ADD_DIRECTOR_RESET";

export const DOWNLOAD_SAMPLE_DOCUMENTS = "DOWNLOAD_SAMPLE_DOCUMENTS";
export const DOWNLOAD_SAMPLE_DOCUMENTS_START = "DOWNLOAD_SAMPLE_DOCUMENTS_START";
export const DOWNLOAD_SAMPLE_DOCUMENTS_FAILED = "DOWNLOAD_SAMPLE_DOCUMENTS_FAILED";
