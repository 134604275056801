import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useLoginInfo from "hooks/useLoginInfo";


const Index = () => {

    const navigate = useNavigate();
    const { userLogin } = useLoginInfo();

    let url = "/login";

    if (userLogin) {
        url = "/dashboard";
    }

    useEffect(() => {
        navigate(url, { replace: true });
    })

    return (
        <div></div>
    )
}

export default Index