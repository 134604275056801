import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);


const BarGraph = ({ selectedCurrency, chartFeed }) => {

   const isMobile = window && window.innerWidth < 800;
   const symbols = {
      'NGN': '₦',
      'USD': '$',
      'GBP': '£',
      'AUD': 'A$',
   }

   const currency = symbols[selectedCurrency];

   const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: '',
            data: chartFeed,
            backgroundColor: '#FAFAFF',
            borderColor: '#5540EB',
            borderWidth: {
               top: 4
            },
            borderRadius: 4,
            hoverBackgroundColor: '#FAFAFF',
            cursor: 'pointer',
            barThickness: isMobile ? 20 : 40,
         },
      ],
   };

   const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         x: {
            grid: {
               display: false,
            },
         },
         y: {
            beginAtZero: false,
            ticks: {
               stepSize: 1000000,
               callback: (value) => currency + value.toLocaleString(),
               color: () => '#99999C',
            },
            grid: {
               drawTicks: false,
               drawBorder: false,
               color: () => '#F5F5F5',
            },
         },
      },
      plugins: {
         legend: {
            display: false,
            labels: {
               boxWidth: 8,
               boxHeight: 8,
            }
         },
         tooltip: {
            backgroundColor: '#343433',
            callbacks: {
               title: function (context) {
                  const label = context[0].label;
                  return `${label}`;
               },
               label: function (context) {
                  if (context.parsed.y !== null) {
                     return `${currency} ` + context.parsed.y.toLocaleString();
                  }
                  return '';
               },
            },
            yAlign: "bottom",
            xAlign: "center",
            displayColors: false,
         },
      },
   }


   return (
      <Bar
         data={data}
         options={options}
      />
   );
};

export default BarGraph