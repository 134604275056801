import { PAYMENT_PATHS } from "./paths";

export const paymentsLinks = [
  {
    url: PAYMENT_PATHS.PAYMENT_LINK,
    text: "Payment Link",
    available: true
  },
  {
    url: PAYMENT_PATHS.INVOICE,
    text: "Invoice",
    available: true
  },
  {
    url: PAYMENT_PATHS.TUITION,
    text: "Pay Tuition",
    available: true
  },
  {
    url: PAYMENT_PATHS.GIFT_CARD,
    text: "Buy gift cards",
    available: true
  },
  {
    url: PAYMENT_PATHS.HOTEL,
    text: "Book a hotel",
    available: false
  },
  {
    url: PAYMENT_PATHS.FLIGHT,
    text: "Book a flight",
    available: false
  },
];
