import { ExportOption } from "./interface"
import { ReactComponent as ConvertMoney } from "assets/images/icons/convert-money.svg";
import { ReactComponent as GetAStatement } from "assets/images/icons/get-a-statement.svg";
import { ReactComponent as SuccessRate } from "assets/images/icons/success-rate.svg";
import { DateFilter } from "types/misc";

export const transactionsRangeData: ExportOption<DateFilter>[] = [
    {
        label: "Last week",
        value: "last_week",
        selected: false,
    },
    {
        label: "Last month",
        value: "last_month",
        selected: false,
    },
    {
        label: "Last Year",
        value: "last_year",
        selected: false,
    },
]


export const exportOptionsData: ExportOption<"CREDIT" | "DEBIT">[] = [
    {
        label: "Money In",
        value: "CREDIT",
        selected: true,
    },
    {
        label: "Money Out",
        value: "DEBIT",
        selected: false,
    },
]

export const actionOptionsData = [
    {
        label: "Convert Money",
        desc: "Swap between different currencies",
        value: "convert-money",
        icon: ConvertMoney,
        selected: false,
    },
    {
        label: "Get a Statement",
        desc: "Generate an account statement",
        value: "get-a-statement",
        icon: GetAStatement,
        selected: false,
    },
    {
        label: "Success Rate",
        desc: "Track transaction performance",
        value: "success-rate",
        icon: SuccessRate,
        selected: false,
    },
]

export const data = [
    {
        period: "January",
        amount: 2400
    },
    {
        period: "February",
        amount: 2210
    },
    {
        period: "March",
        amount: 2290
    },
    {
        period: "April",
        amount: 2000
    }, {
        period: "May",
        amount: 2181
    }, {
        period: "June",
        amount: 2500
    },
    {
        period: "July",
        amount: 2100
    }, {
        period: "August",
        amount: 2400
    }, {
        period: "September",
        amount: 2210
    }, {
        period: "October",
        amount: 2290
    }, {
        period: "November",
        amount: 2000
    },
    {
        period: "December",
        amount: 2000
    }
]
