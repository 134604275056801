import { ReactComponent as IconCard } from "assets/images/icons/card-icon.svg";
import { Card } from "types/card";

export const DashboardCard: React.FC<{
    item: Card
  }> = ({ item }) => {
    return (
      <div
        className="flex justify-between py-3 items-center"
      >
        <div className="flex items-center gap-5">
          <IconCard
            className={
              item.currency === "NGN" ? "text-shiga-purple" : "text-shiga-black"
            }
          />
          <div className="space-y-1">
            <h6 className="text-shiga-black font-medium text-[16px] tracking-tight">
              {/* Naira Card */}
              {item.name}
            </h6>
            <p className="text-shiga-dark font-medium text-[13px]">
              {/* *** 8594 */}
              {item.number}
            </p>
          </div>
        </div>
        <div
          className={`rounded-md py-0.5 px-1 border font-medium tracking-tight ${item.status === "LOCKED"
            ? "border-shiga-orange/25 text-shiga-orange"
            : "border-shiga-green/25 text-shiga-green"
            }`}
        >
          {item.status}
          {/* {item % 2 === 0 ? "Locked" : "Active"} */}
        </div>
        <div className="font-medium text-[16px] trackti">
          {/* 5,500 NGN */}
          {item.balance}
        </div>
      </div>
    )
  }