import { useEffect } from "react";
import { NoCard } from "./NoCard";
import CardsTable from "./CardsTable";
import { useNavigate } from "react-router-dom";
import { useCardStore } from "zustand-store/cards";

const Index = () => {
  const navigate = useNavigate();
  const {cards, fetchCards} = useCardStore();

  useEffect(() => {
    fetchCards();
  }, []);

  return (
    <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
      <div className="flex items-center justify-between pb-5 mb-5 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Cards
        </div>
        <div>
          <button
            onClick={() => navigate("/cards/create-a-card")}
            className="btn btn-block btn-lg btn-shiga-black"
          >
            Create a Card
          </button>
        </div>
      </div>
      { cards.length ?
        <CardsTable cards={cards}
        /> :
        <NoCard />}
    </div>
  );
};

export default Index;
