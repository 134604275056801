import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentLoading from "components/common/ContentLoading";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import { Checkbox } from "components/common/form";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/common/Maybe";
import PageContent from "components/common/PageContent";
import * as settingsActions from "store/entities/settings/action";
import * as userActions from "store/auth/user/action";


const FeesEarnings = () => {
    const dispatch = useDispatch();
    const { getBusinessProfile } = useLoginInfo();
    const { userInfo } = useSelector((s: any) => s.auth.userPersist);
    const { businessProfileLoading } = useSelector((s: any) => s.auth.account);
    const { feesEarningsUpdate } = useSelector((s: any) => s.entities.settings);

    const [formLoading, setFormLoading] = useState<any>([]);
    const [form, setForm] = useState({
        businessNotifications: "",
        settlementMethod: "",
        feeChargeChannel: "",
        intlFeeChargeChannel: "",
    })

    const populateForm = () => {
        setForm({
            businessNotifications: "",
            settlementMethod: userInfo?.businessProfile?.settlementMethod ?? "",
            feeChargeChannel: userInfo?.businessProfile?.feeChargeChannel ?? "",
            intlFeeChargeChannel: userInfo?.businessProfile?.intlFeeChargeChannel ?? "",
        });
    }

    const updateData = (key: string, value: string) => {
        const params = {
            [key]: value,
        }
        setFormLoading([...formLoading, key]);
        dispatch(settingsActions.updateFeesEarnings(params));
    }

    const removeLoader = () => {
        let data = [...formLoading];
        data.shift();
        setFormLoading(data);
    }

    useEffect(() => {
        if (userInfo?.businessProfile === undefined) {
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined) {
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (feesEarningsUpdate?.success !== undefined) {
            if (feesEarningsUpdate?.success === true) {
                dispatch(userActions.updateUserInfo({
                    businessProfile: {
                        ...feesEarningsUpdate?.data ?? {},
                    },
                }));
            }
            dispatch(settingsActions.resetUpdateFeesEarnings());
            removeLoader();
        }
        // eslint-disable-next-line
    }, [feesEarningsUpdate]);

    return (
        <div>
            <DynamicHeadTag
                headerText="Fees & Earnings"
            />

            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                Fees & Earnings
            </div>

            <PageContent
                loading={businessProfileLoading}
                data={userInfo?.businessProfile}
                loadingText="Loading fees & earnings..."
            />

            <Maybe condition={businessProfileLoading === false}>
                <div>
                    {/* <div className="sm:flex sm:space-x-8">
                        <div className="sm:w-1/2 font-medium">
                            Business Notifications
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium">
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Send to the business email address only"
                                    checked={form.businessNotifications === "BUSINESS"}
                                    onChange={() => setForm({ ...form, businessNotifications: "BUSINESS" })}
                                    disabled={true}
                                    containerClass="!items-start"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Send to all dashboard users"
                                    checked={form.businessNotifications === "ALL"}
                                    onChange={() => setForm({ ...form, businessNotifications: "ALL" })}
                                    disabled={true}
                                    containerClass="!items-start"
                                />
                            </div>
                            <Maybe condition={formLoading.includes("businessNotifications")}>
                                <div className="flex items-center space-x-2 text-shiga-gray">
                                    <ContentLoading loading={true} />
                                    <div>
                                        Saving...
                                    </div>
                                </div>
                            </Maybe>
                        </div>
                    </div> */}

                    <div className="sm:flex pt-8 mt-8 sm:space-x-8 border-t border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Transaction Fees
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Fees applied by Shiga for processing your payments
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium">
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Charge me for this"
                                    checked={form.feeChargeChannel === "BUSINESS"}
                                    onChange={() => updateData("feeChargeChannel", "BUSINESS")}
                                    disabled={formLoading.includes("feeChargeChannel")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Charge my customers"
                                    checked={form.feeChargeChannel === "CUSTOMER"}
                                    onChange={() => updateData("feeChargeChannel", "CUSTOMER")}
                                    disabled={formLoading.includes("feeChargeChannel")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <Maybe condition={formLoading.includes("feeChargeChannel")}>
                                <div className="flex items-center space-x-2 text-shiga-gray">
                                    <ContentLoading loading={true} />
                                    <div>
                                        Saving...
                                    </div>
                                </div>
                            </Maybe>
                        </div>
                    </div>

                    <div className="sm:flex pt-8 mt-8 sm:space-x-8 border-t border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Earnings
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Profits realized from gift card sales and bill payments
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium">
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Deposit to Shiga wallet"
                                    checked={form.settlementMethod === "WALLET"}
                                    onChange={() => updateData("settlementMethod", "WALLET")}
                                    disabled={formLoading.includes("settlementMethod")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Deposit to settlement account"
                                    checked={form.settlementMethod === "BANK_ACCOUNT"}
                                    onChange={() => updateData("settlementMethod", "BANK_ACCOUNT")}
                                    disabled={formLoading.includes("settlementMethod")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <Maybe condition={formLoading.includes("settlementMethod")}>
                                <div className="flex items-center space-x-2 text-shiga-gray">
                                    <ContentLoading loading={true} />
                                    <div>
                                        Saving...
                                    </div>
                                </div>
                            </Maybe>
                        </div>
                    </div>

                    {/* <div className="sm:flex pt-8 mt-8 sm:space-x-8 border-t border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                International Processing Fees
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Fees for processing transactions in foreign countries.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium">
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Charge me for this"
                                    checked={form.intlFeeChargeChannel === "BUSINESS"}
                                    onChange={() => updateData("intlFeeChargeChannel", "BUSINESS")}
                                    disabled={formLoading.includes("intlFeeChargeChannel")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <div>
                                <Checkbox
                                    type="radio"
                                    label="Charge my customers"
                                    checked={form.intlFeeChargeChannel === "CUSTOMER"}
                                    onChange={() => updateData("intlFeeChargeChannel", "CUSTOMER")}
                                    disabled={formLoading.includes("intlFeeChargeChannel")}
                                    containerClass="!items-start"
                                />
                            </div>
                            <Maybe condition={formLoading.includes("intlFeeChargeChannel")}>
                                <div className="flex items-center space-x-2 text-shiga-gray">
                                    <ContentLoading loading={true} />
                                    <div>
                                        Saving...
                                    </div>
                                </div>
                            </Maybe>
                        </div>
                    </div> */}

                </div>
            </Maybe>
        </div>
    )
}

export default FeesEarnings;