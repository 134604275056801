import { useState } from 'react';

const usePillTabs = () => {
   const [activeTab, setActiveTab] = useState(0);

   const PillTabs = ({ tabs }: { tabs: PillTabProps[] }) => (
      <div className="w-full">
         <div className="flex rounded-full bg-gray-100 p-1">
            {tabs.map((tab, idx) => (
               <button
                  key={tab.label}
                  onClick={() => setActiveTab(tab.value)}
                  className={`flex-1 text-center py-2 rounded-full transition-all duration-500 focus:outline-none ${activeTab === tab.value ? 'bg-white text-black' : 'text-gray-400'}`}>
                  {tab.label}
               </button>
            ))}
         </div>
      </div>
   );

   return {
      PillTabs,
      activeTab
   }
};

export default usePillTabs;
