import { z } from "zod";

export const zodValidation = {
    balance: z.string().optional(),
    startDate: z.date({
        required_error: "Start Date  is required.",
    }),
    endDate: z.date({
        required_error: "End Date is required.",
    }),
}