import { Transaction } from 'types/transaction';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import Maybe from 'components/common/Maybe';


type Props = {
   transaction: Transaction;
   activeTab: string;
}

const DirectDebitDetails = ({ transaction, activeTab }: Props) => {
   const { copyItem, showValue } = useToolkit()
   // eslint-disable-next-line
   const initiator = `${transaction?.paymentCustomer?.firstName || 'N/A'} ${transaction?.paymentCustomer?.lastName || ''}`
   const payersMail = transaction?.paymentCustomer?.email
   const showDetails = activeTab === 'details';
   const showAnalytics = activeTab === 'analytics';
   const isBankTransfer = transaction?.channel === 'BANK_TRANSFER';

   return (
      <div className="w-full">

         <Maybe condition={showDetails}>
            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Status
               </div>

               <div className="transaction-detail-inline-value capitalize">
                  {transaction?.status?.toLowerCase()}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Channel
               </div>

               <div className="flex items-center">
                  {isBankTransfer ? 'Bank Transfer' : 'Direct Debit'}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  From
               </div>

               <div className="flex items-center">
                  {payersMail}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Fee
               </div>

               <div className="transaction-detail-inline-value">
                  {transaction?.fee} {transaction?.currency}
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper`}>
               <div className="transaction-detail-inline-key">
                  Transaction Ref
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium'>
                     {transaction.reference}
                     <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                        <CopyIcon width={20} />
                     </button>
                  </div>
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper-last'>
               <div className="transaction-detail-inline-key capitalize">
                  Narration
               </div>

               <div className="transaction-detail-inline-value">
                  {showValue(transaction?.bankTransfer?.narration)}
               </div>
            </div>
         </Maybe>

         <Maybe condition={showAnalytics}>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Account Name
               </div>

               <div className="flex items-center">
                  {showValue(transaction?.bankTransfer?.debitAccountName)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Account Number
               </div>

               <div className="flex items-center">
                  {showValue(transaction?.bankTransfer?.debitAccountNumber)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Bank
               </div>

               <div className="flex items-center">
                  {showValue(transaction?.bankTransfer?.debitBankName)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Country Code
               </div>

               <div className="flex items-center">
                  {showValue('N/A')}
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper-last`}>
               <div className="transaction-detail-inline-key">
                  IP Address
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium'>
                     {showValue('N/A')}
                     {/* <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                        <CopyIcon width={20} />
                     </button> */}
                  </div>
               </div>
            </div>
         </Maybe>

      </div>
   )
}

export default DirectDebitDetails