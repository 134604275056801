import { RootState } from "store/store";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

interface InitialState {
  loginLoading: boolean;
  loginResponse: {};
  loginForm: {};
  logoutLoading: boolean;
  registerLoading: boolean;
  registerForm: {};
  registerResponse: {};
  forgotPasswordLoading: boolean;
  forgotPassword: {};
  resetPasswordLoading: boolean;
  resetPassword: boolean;
  otpEmailSendLoading: boolean;
  otpEmailSend: {};
  otpEmailResendLoading: boolean;
  otpEmailResend: {};
  otpEmailVerificationLoading: boolean;
  otpEmailVerification: {};
  refreshTokenLoading: boolean;
  refreshTokenData: {};
  refreshTokenFailed: boolean;
  isIdleModalOpen: boolean;
}

const initialState: InitialState = {
  loginLoading: false,
  loginResponse: {},
  loginForm: {},
  registerResponse: {},
  logoutLoading: false,
  registerLoading: false,
  registerForm: {},

  // loggedIn: false,     // moved to persist
  // data: {},            // moved to persist

  forgotPasswordLoading: false,
  forgotPassword: {},
  resetPasswordLoading: false,
  resetPassword: false,

  otpEmailSendLoading: false,
  otpEmailSend: {},
  otpEmailResendLoading: false,
  otpEmailResend: {},
  otpEmailVerificationLoading: false,
  otpEmailVerification: {},

  refreshTokenLoading: false,
  refreshTokenData: {},
  refreshTokenFailed: false,

  isIdleModalOpen: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.SHOW_IDLE_TIMER:
      state = {
        ...state,
        isIdleModalOpen: true,
      };
      return state;

    case actions.HIDE_IDLE_TIMER:
      state = {
        ...state,
        isIdleModalOpen: false,
      };
      return state;

    case actions.GET_REFRESH_TOKEN_START:
      state = {
        ...state,
        refreshTokenLoading: true,
        refreshTokenData: {},
        refreshTokenFailed: false,
      };
      return state;

    case actions.GET_REFRESH_TOKEN_DONE:
      state = {
        ...state,
        refreshTokenLoading: false,
        refreshTokenData: action.payload,
        refreshTokenFailed: false,
      };
      return state;

    case actions.GET_REFRESH_TOKEN_FAILED:
      state = {
        ...state,
        refreshTokenLoading: false,
        refreshTokenData: {},
        refreshTokenFailed: true,
      };
      return state;

    case actions.LOGIN_USER:
      // if (action.payload.data?.refreshToken?.length > 0) {
      //   localStorage.setItem('refreshToken', action?.payload?.data?.refreshToken)
      // }
      state = {
        ...state,
        loginLoading: false,
        loginResponse: action.payload,
      };
      return state;

    case actions.LOGIN_USER_START:
      state = {
        ...state,
        loginLoading: true,
        loginResponse: action.payload,
      };
      return state;

    case actions.LOGIN_USER_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        loginLoading: false,
        loginResponse: action.payload,
      };
      return state;

    case actions.SAVE_LOGIN_USER_FORM:
      state = {
        ...state,
        loginForm: action.payload,
      };
      return state;

    case actions.FORGOT_PASSWORD:
      state = {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: action.payload,
      };
      return state;

    case actions.FORGOT_PASSWORD_START:
      state = {
        ...state,
        forgotPasswordLoading: true,
        forgotPassword: {},
      };
      return state;

    case actions.FORGOT_PASSWORD_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: action.payload,
      };
      return state;

    case actions.FORGOT_PASSWORD_RESET:
      state = {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: {},
      };
      return state;

    case actions.RESET_PASSWORD:
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPassword: action.payload,
      };
      return state;

    case actions.RESET_PASSWORD_START:
      state = {
        ...state,
        resetPasswordLoading: true,
        resetPassword: false,
      };
      return state;

    case actions.RESET_PASSWORD_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPassword: action.payload,
      };
      return state;

    case actions.RESET_PASSWORD_RESET:
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPassword: false,
      };
      return state;

    case actions.SEND_OTP_EMAIL:
      state = {
        ...state,
        otpEmailSendLoading: false,
        otpEmailSend: action.payload,
      };
      return state;

    case actions.SEND_OTP_EMAIL_START:
      state = {
        ...state,
        otpEmailSendLoading: true,
        otpEmailSend: {},
      };
      return state;

    case actions.SEND_OTP_EMAIL_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        otpEmailSendLoading: false,
        otpEmailSend: action.payload,
      };
      return state;

    case actions.SEND_OTP_EMAIL_RESET:
      state = {
        ...state,
        otpEmailSend: {},
      };
      return state;

    case actions.RESEND_OTP_EMAIL:
      state = {
        ...state,
        otpEmailResendLoading: false,
        otpEmailResend: action.payload,
      };
      return state;

    case actions.RESEND_OTP_EMAIL_START:
      state = {
        ...state,
        otpEmailResendLoading: true,
        otpEmailResend: {},
      };
      return state;

    case actions.RESEND_OTP_EMAIL_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        otpEmailResendLoading: false,
        otpEmailResend: action.payload,
      };
      return state;

    case actions.RESEND_OTP_EMAIL_RESET:
      state = {
        ...state,
        otpEmailResend: {},
      };
      return state;

    case actions.VERIFY_OTP_EMAIL:
      // toast.success("Password reset successful");
      state = {
        ...state,
        otpEmailVerificationLoading: false,
        otpEmailVerification: action.payload,
      };
      return state;

    case actions.VERIFY_OTP_EMAIL_START:
      state = {
        ...state,
        otpEmailVerificationLoading: true,
        otpEmailVerification: {},
      };
      return state;

    case actions.VERIFY_OTP_EMAIL_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        otpEmailVerificationLoading: false,
        otpEmailVerification: action.payload,
      };
      return state;

    case actions.VERIFY_OTP_EMAIL_RESET:
      state = {
        ...state,
        otpEmailVerification: {},
      };
      return state;

    case actions.REGISTER_USER:
      state = {
        ...state,
        registerLoading: false,
        registerResponse: action.payload,
      };
      return state;

    case actions.REGISTER_USER_START:
      state = {
        ...state,
        registerLoading: true,
      };
      return state;

    case actions.REGISTER_USER_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        registerLoading: false,
        registerResponse: action.payload,
      };
      return state;

    case actions.REGISTER_USER_RESET:
      state = {
        ...state,
        registerLoading: false,
      };
      return state;

    case actions.SAVE_REGISTER_USER_FORM:
      state = {
        ...state,
        registerForm: action.payload,
      };
      return state;

    case actions.LOGOUT_USER_START:
      state = {
        ...state,
        logoutLoading: true,
      };
      return state;

    case actions.LOGOUT_USER:
      state = {
        ...state,
        logoutLoading: false,
      };
      return state;
    case actions.RESET_LOGIN_DATA:
      state = {
        ...initialState,
      };
      return state;

    default:
      return state;
  }
}

export const getUserStore = (st: RootState) => st.auth.user;
