import * as actions from "./actionTypes";
import * as config from "data/config";

export function getRefunds() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetRefunds,
            method: "get",
            data: {},
            onStart: actions.GET_REFUNDS_START,
            onSuccess: actions.GET_REFUNDS,
            onError: actions.GET_REFUNDS_FAILED
        }
    }
}

export function createRefund(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateRefund,
            method: "post",
            customHeaders: {
                "Content-Type": "application/json"
            },
            data: payload,
            onStart: actions.CREATE_REFUND_START,
            onSuccess: actions.CREATE_REFUND_DONE,
            onError: actions.CREATE_REFUND_FAILED
        }
    }
}