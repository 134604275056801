import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContentLoading = (props) => {
    return (
        <>
            {(props.loading && props.loading === true) &&
                <FontAwesomeIcon icon="circle-notch" spin />
            }
            {(!props.loading || props.loading === false) &&
                <>{props?.data}</>
            }
        </>
    )
}

export default ContentLoading
