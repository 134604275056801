import { useState } from "react";
import { Input } from "./form";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";

type Props = {
  placeholder: string;
  value?:string;
  searchSubmit?: (val: string) => void;
   onSearchChange?: (val: string) => void;
};
const Search = (props: Props) => {
  const { placeholder,value, searchSubmit } = props;

  const [form, setForm] = useState({
    search: value ?? "",
  });

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    searchSubmit && searchSubmit(form?.search);
  };

  return (
    <div>
      <form onSubmit={submitForm}>
        <Input
          type="search"
          autoComplete="false"
          label=" "
          placeholder={placeholder}
          required={true}
          value={form.search}
          onChange={(e: any) => setForm({ ...form, search: e.target.value })}
          containerClass="!mb-0"
          leftIcon={<IconSearch />}
          leftIconClass="px-2.5"
        />
      </form>
    </div>
  );
};

export default Search;
