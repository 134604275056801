import { Dialog } from '@headlessui/react'
import React, { useEffect } from 'react'
import InlineTransfersHeader from './InlineTransfersHeader'
import InlineTransfersSidebar from './InlineTransfersSidebar'
import Maybe from 'components/common/Maybe'
import TransferTypeSelector from '../TransferTypeSelector'
import useTransfersProvider from '../context/useTransfersProvider'
import SingleTransferHomepage from '../single/SingleTransferHomepage'
import BulkTransferHomepage from '../bulk/BulkTransferHomepage'
import TwoFactorAuthenticationCard from 'pages/misc/TwoFactorAuthenticationCard'
import TwoFacSetupModal from 'components/auth/TwoFacSetupModal'
import { initializeMFA, resetInitializeMFA } from 'store/auth/account/action'
import { ReactComponent as IconIdle } from "assets/images/icons/transfer-type-nav.svg";
import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import useToolkit from 'hooks/useToolkit'
import TwoFacInputModal from 'components/auth/TwoFacInputModal'
import PATHS from "NavigationRoute";
import { useNavigate } from 'react-router-dom'
import { makeTransfer, requestTransferOtp, resetTransferParams } from 'store/transfer/action'


type Props = {
   setIsOpen: React.Dispatch<boolean>;
}

const LayoutDisplay = ({ setIsOpen }: Props) => {
   const navigate = useNavigate();
   const {
      refetchProfiles,
      dispatch,
      useSelector,
      mfaEnabled,
      showCustomSuccessToast
   } = useToolkit()

   const {
      transferType,
      businessVerificationRef,
      isSingleTransfer,
      isBulkTransfer,
      twoFacPromptOpen,
      toggleTwoFacPrompt,
      twofacSetupOpen,
      toggleTwoFacSetup,
      incrementSingleTrfStep,
      toggleTwoFacInput,
      transferAmount,
      trfCurrency,
      twoFacInputOpen,
      trfPin,
      setTrfPin,
      isNgnTransfer,
      ngnForm,
      saveTrfBeneficiary,
      transferNote,
      isAudTransfer,
      isGbpTransfer,
      audForm,
      gbpForm,
      usdForm,
      isUsdTransfer,
      singleTrfStep,
      decrementSingleTrfStep,
      setTransferType,
      endSingleTransferSession,
      setTrfModalOpen
   } = useTransfersProvider();
   const { mfaInitializeLoading, mfaInitialize } = useSelector((s: any) => s.auth.account);
   const { transferResponse, transferOtpLoading, transferLoading } = useSelector((allStates: any) => allStates.transfer);


   const commonPayload = {
      amount: transferAmount,
      currency: trfCurrency,
      saveBeneficiary: saveTrfBeneficiary,
      narration: transferNote || 'Sent from Shiga',
   }

   const authorizeTransfer = () => {
      let payload = {}

      if (isNgnTransfer) {
         payload = {
            ...commonPayload,
            ...ngnForm,
            token: trfPin
         }
      }

      if (isUsdTransfer) {
         const { accountName, accountNumber, bankName } = usdForm
         payload = {
            ...commonPayload,
            accountName,
            accountNumber,
            bankName,
            country: 'US',
            token: trfPin,
            transferType: 'ACH',
            meta: {
               routingNo: usdForm?.routingNo || '',
               bankAddress: {
                  description: usdForm?.meta?.bankAddress?.description || '',
                  place_id: usdForm?.meta?.bankAddress?.place_id || '',
               },
               bankState: usdForm?.meta?.bankState || '',
               postCode: usdForm?.meta?.postCode || '',
               city: usdForm?.meta?.city || '',
               accountType: usdForm?.meta?.accountType || ''
            }
         }
      }

      if (isAudTransfer) {
         console.log('audForm', audForm)
      }

      if (isGbpTransfer) {
         console.log('gbpForm', gbpForm)
      }

      dispatch(makeTransfer(payload));
   }

   const getAccountName = () => {
      let result = '';

      if (isNgnTransfer) {
         result = ngnForm?.accountName
      }
      if (isUsdTransfer) {
         result = usdForm?.accountName
      }
      if (isGbpTransfer) {
         result = gbpForm?.accountName
      }
      if (isAudTransfer) {
         result = audForm?.accountName
      }
      return result;
   }

   const handleReturn = () => {
      if (isSingleTransfer) {
         if (singleTrfStep === 1) {
            setTransferType('')
         } else {
            decrementSingleTrfStep()
         }
      } else if (isBulkTransfer) {
         // 
      }
   }

   useEffect(() => {
      if (
         transferResponse?.success === true &&
         transferResponse?.message === "Transfer In Progress"
      ) {
         endSingleTransferSession();
         setTrfModalOpen(false);
         showCustomSuccessToast({
            message: `You have successfully transferred ${Number(
               transferAmount
            )?.toLocaleString()} ${trfCurrency} to ${getAccountName()}`,
            onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
         });

         setTimeout(() => {
            dispatch(resetTransferParams());
         }, 1000);
      }
      // eslint-disable-next-line
   }, [transferResponse]);


   return (
      <div ref={businessVerificationRef} className="w-full h-full bg-white overflow-y-auto">
         <Dialog.Panel className="min-h-full flex flex-col">

            <InlineTransfersHeader
               setIsOpen={setIsOpen}
            />

            <div className="flex lg:px-0">

               <div className="w-2/5 relative">
                  <Maybe condition={transferType === ''}>
                     <div className="w-80 pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">
                        <IconIdle />
                     </div>
                  </Maybe>

                  <Maybe condition={isSingleTransfer || isBulkTransfer}>
                     <InlineTransfersSidebar />
                  </Maybe>

                  <Maybe condition={(isSingleTransfer && singleTrfStep >= 1)}>
                     <button onClick={handleReturn} className="sticky left-[350px] top-32">
                        <BackButton />
                     </button>
                  </Maybe>
               </div>

               <div className="w-3/5">

                  <Maybe condition={transferType === ''}>
                     <TransferTypeSelector />
                  </Maybe>

                  <Maybe condition={isSingleTransfer}>
                     <SingleTransferHomepage />
                  </Maybe>

                  <Maybe condition={isBulkTransfer}>
                     <BulkTransferHomepage />
                  </Maybe>

               </div>
            </div>

            <TwoFacInputModal
               modalLoading={mfaEnabled ? false : transferOtpLoading}
               showResend={!mfaEnabled}
               title={mfaEnabled ? "Enter 2FA Code" : "Enter Email OTP"}
               target="2FA Code"
               buttonText="Confirm Transfer"
               isOpen={twoFacInputOpen}
               setIsOpen={toggleTwoFacInput}
               loading={transferLoading}
               onSubmit={authorizeTransfer}
               onPinChange={(value: string) => setTrfPin(value)}
               onResendClick={() => {
                  if (!mfaEnabled) {
                     dispatch(requestTransferOtp());
                  }
               }}
            />

            <TwoFacSetupModal
               isOpen={twofacSetupOpen}
               setIsOpen={toggleTwoFacSetup}
               mfaInitialize={mfaInitialize}
               onClose={() => {
                  refetchProfiles();
                  dispatch(resetInitializeMFA());
               }}
            />

            <TwoFactorAuthenticationCard
               isOpen={twoFacPromptOpen}
               loading={mfaInitializeLoading}
               onClose={() => {
                  toggleTwoFacPrompt();
                  incrementSingleTrfStep();
               }}
               onBtnClick={() => dispatch(initializeMFA())}
            />

         </Dialog.Panel>
      </div>
   )
}

export default LayoutDisplay