import FormDrawer from "components/common/form-drawer";
import { ReactComponent as Refresh } from "assets/images/icons/refresh.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/Rectangle5.svg";
import { ReactComponent as IconFlag } from "assets/images/icons/flag.svg";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import Maybe from "components/common/Maybe";
import { Refund } from "types/refund";
import { formatDate } from "lib/format.date";
import { copyText } from "utils/handleCopy";

interface RefundTransactionsProps {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    selectedRefund: Refund
}

export const RefundDetails = ({
    isOpen,
    setIsOpen,
    selectedRefund
}: RefundTransactionsProps) => {
    return (
        <FormDrawer
            display="center"
            size="xl"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="p-8 pb-6">
                <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                    <div className="text-2xl font-ojah font-medium">
                        Refund Details
                    </div>
                    <div
                        onClick={() => setIsOpen(false)}
                        className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
                    >
                        <IconClose className="m-auto svg-stroke-gray" />
                    </div>
                </div>

                <div className="pt-6">
                    {/* Sent Money */}
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <div className="bg-outline-grey rounded-full p-3 mr-6">
                                <Refresh className="m-auto w-6 h-6" />
                            </div>
                            <div className="font-medium tracking-tight">
                                <h5 className="text-shiga-black text-[18px]">
                                    {`${selectedRefund.customer.firstName} ${selectedRefund.customer.lastName}`}
                                </h5>
                                <p className="text-[#99999C] text-[15px]">Refund</p>
                            </div>
                        </div>
                        <div className="tracking-tight flex flex-col items-end">
                            <h5 className="font-medium text-shiga-black text-[18px]">{`${selectedRefund.amount} ${selectedRefund.currency}`}</h5>
                            <p className="text-[#99999C] text-[15px]">
                                {formatDate(selectedRefund.createdAt)}
                            </p>
                        </div>
                    </div>
                    <div className="h-px w-full bg-gray-100 my-7"></div>

                    <div className="flex justify-between items-center my-7">
                        <h4 className="font-ojah text-[18px] leading-6">More Details</h4>
                        <Dropdown dropdownClass="dropdown-right" dropdownIconClassName="rotate-90">
                            <></>
                            <div>
                                <Menu.Item
                                    as="div"
                                    // onClick={() => setShowTransactionDetails(true)}
                                    className="dropdown-item dropdown-item-icon"
                                >
                                    <IconDownload />
                                    Share Details
                                </Menu.Item>
                                <Menu.Item
                                    as="div"
                                    // onClick={toggleRenameCard}
                                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                                >
                                    <IconFlag />
                                    Query Refund
                                </Menu.Item>
                            </div>
                        </Dropdown>
                    </div>

                    <div className="border border-[#F5F5F5] rounded-[20px] py-3 px-2 text-[16px] leading-[22px] tracking-tight">
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Status</p>
                            <div>
                                <Maybe condition={selectedRefund.status === "COMPLETED"}>
                                    <div className="label-border !text-[#00C46C] !border-[#00C46C] !p-1">
                                        <span className="font-medium">Completed</span>
                                    </div>
                                </Maybe>
                                <Maybe condition={selectedRefund.status === "PENDING"}>
                                    <div className="label-border !text-[#F29100] !border-outline-orange !p-1">
                                        <span className="font-medium">Pending</span>
                                    </div>
                                </Maybe>
                            </div>
                        </div>
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Initiated by</p>
                            <span className="flex items-center">
                                <div className="w-7 h-7 mr-3 rounded-md">
                                    <IconUserProfile className="w-full h-full" />
                                </div>
                                <p className="text-[#1F1F1E]">{`${selectedRefund.initiatedByAccount?.firstName} ${selectedRefund.initiatedByAccount?.lastName}`}</p>
                            </span>
                        </div>
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Date Refunded</p>
                            <p className="text-[#1F1F1E]">{selectedRefund.refundedAt ? formatDate(selectedRefund.refundedAt) : "--.--"}</p>
                        </div>
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Refund Type</p>
                            <p className="text-[#1F1F1E]">{selectedRefund.type}</p>
                        </div>
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Customer's Email</p>
                            <p className="text-[#1F1F1E]">{selectedRefund.customer.email}</p>
                        </div>
                        <div className="flex items-center justify-between px-3 py-3 border-b border-outline-grey">
                            <p className="text-[#999999]">Refund Reference Number</p>
                            <span className="flex flex-nowrap items-center cursor-pointer" onClick={copyText(selectedRefund.reference, "refund reference")}>
                                <p className="text-[#1F1F1E] mr-1.5">{selectedRefund.reference}</p>
                                <IconCopy className="text-shiga-dark" />
                            </span>
                        </div>

                        <div className="flex items-center justify-between px-3 py-3">
                            <p className="text-[#999999]">Transaction ID</p>
                            <span className="flex flex-nowrap items-center cursor-pointer  max-w-[50%]" onClick={copyText(selectedRefund.id, "refund id")}>
                                <p className="text-[#1F1F1E] mr-1.5 overflow-hidden text-ellipsis whitespace-nowrap">{selectedRefund.id}</p>
                                <IconCopy className="text-shiga-dark size-9" />
                            </span>
                        </div>
                    </div>
                    {
                        selectedRefund.note?.length ?
                        <div className="mt-6">
                            <div className="flex justify-between text-[#B4B4B4]">
                                <p>Merchant's Note</p>
                                <p>{`${selectedRefund.note?.length}/200`}</p>
                            </div>
                            <textarea disabled value={selectedRefund.note}
                                className="w-full !h-24 form-input resize-none mt-2"
                                placeholder="Add a few notes to help you later"
                            ></textarea>
                        </div> : <></>
                    }
                </div>
            </div>
        </FormDrawer>
    );
};