import { currencyFormat } from "lib/format.amount"
import { CurrencyType } from "types/misc"
import { InvoiceItem } from "types/payment/invoice"

export const ReviewItem: React.FC<{
    item: InvoiceItem,
    invoiceCurrency: CurrencyType
  }> = ({ item, invoiceCurrency }) => {
    return (
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
            {item.name}
          </div>
        </div>
        <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
            {currencyFormat(item.price, invoiceCurrency)}
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
          <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
            {item.qty}
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
          <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
            {currencyFormat(item.price * item.qty, invoiceCurrency)}
          </div>
        </div>
      </div>
    )
  }