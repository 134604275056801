import { ReactComponent as IconNoRefund } from "assets/images/icons/refund.svg";

export const NoRefund = () => {
    return (
        <>
            <div className="px-8 py-20 text-center max-w-md m-auto">
                <div className="px-7 py-6">
                    <IconNoRefund className="w-[75.9px] h-[82.5px] mx-auto" />
                </div>
                <div className="mt-8 text-4xl font-ojah font-bold">No Refunds yet</div>
                <div className="mt-4 text-shiga-gray max-w-xm">
                    You have not logged any refund on this account at this time
                </div>
            </div>
        </>
    );
};