export interface Card {
    id: string;
    balance: string;
    currency: "USD" | "NGN";
    status: "UNLOCKED" | "LOCKED";
    isVirtual: boolean;
    number: string;
    name: string;
    cvv: string;
    expiryMonth: string;
    expiryYear: string;
    brand: string;
    createdAt: string;
    updatedAt: string;
    transactions?: [];
    business?: {};
    businessId: string;
    image: string;
    reference?: string;
    metadata: {};
    customer: string;
    customerId: string;
    provider?: string;
    providerId?: string;
}

export const defaultCard: Card = {
    balance: "",
    brand: "",
    businessId: "",
    createdAt: "",
    currency: "USD",
    customer: "",
    customerId: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
    id: '',
    image: "",
    isVirtual: false,
    metadata: {},
    name: "",
    number: "",
    status: "LOCKED",
    updatedAt: "",
}