import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import { RootState } from "store/store";
import errorReducer from "utils/errorReducer";


interface InitialState {
    userProfileLoading: boolean;
    userProfile?: {
        success: boolean;
        data: any;
    },
    businessProfileLoading: boolean;
    businessProfile: {
        success: boolean;
        data: {}
    },

    updateProfileLoading: boolean;
    updateProfile: boolean;
    passwordChangeRequestLoading: boolean;
    passwordChangeRequest: {},

    mfaInitializeLoading: boolean;
    mfaInitialize: {},
    mfaCompleteLoading: boolean;
    mfaComplete: {},
    mfaVerifyLoading: boolean;
    mfaVerify: {},
    mfaDisableLoading: boolean;
    mfaDisable: {},
}

const initialState: InitialState = {
    userProfileLoading: false,
    userProfile: {
        data: {},
        success: false
    },
    businessProfileLoading: false,
    businessProfile: {
        success: false,
        data: {}
    },

    updateProfileLoading: false,
    updateProfile: false,
    passwordChangeRequestLoading: false,
    passwordChangeRequest: {},

    mfaInitializeLoading: false,
    mfaInitialize: {},
    mfaCompleteLoading: false,
    mfaComplete: {},
    mfaVerifyLoading: false,
    mfaVerify: {},
    mfaDisableLoading: false,
    mfaDisable: {},
};


export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case actions.GET_USER_PROFILE:
            state = {
                ...state,
                userProfileLoading: false,
                userProfile: action.payload,
            }
            return state;


        case actions.GET_USER_PROFILE_START:
            state = {
                ...state,
                userProfileLoading: true,
                userProfile: {
                    data: {},
                    success: false
                },
            }
            return state;


        case actions.GET_USER_PROFILE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userProfileLoading: false,
                userProfile: action.payload,
            }
            return state;


        case actions.GET_USER_PROFILE_RESET:
            state = {
                ...state,
                userProfileLoading: false,
                userProfile: {
                    data: {},
                    success: false
                },
            }
            return state;


        case actions.UPDATE_PROFILE:
            toast.success(action.payload.message);
            state = {
                ...state,
                updateProfileLoading: false,
                updateProfile: true,
            }
            return state;

        case actions.UPDATE_PROFILE_START:
            state = {
                ...state,
                updateProfileLoading: true,
            }
            return state;


        case actions.UPDATE_PROFILE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updateProfileLoading: false,
            }
            return state;


        case actions.REQUEST_PASSWORD_CHANGE:
            state = {
                ...state,
                passwordChangeRequestLoading: false,
                passwordChangeRequest: action.payload,
            }
            return state;

        case actions.REQUEST_PASSWORD_CHANGE_START:
            state = {
                ...state,
                passwordChangeRequestLoading: true,
            }
            return state;


        case actions.REQUEST_PASSWORD_CHANGE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                passwordChangeRequestLoading: false,
                passwordChangeRequest: action.payload,
            }
            return state;


        case actions.REQUEST_PASSWORD_CHANGE_RESET:
            state = {
                ...state,
                passwordChangeRequestLoading: false,
                passwordChangeRequest: {},
            }
            return state;


        case actions.GET_BUSINESS_PROFILE:
            state = {
                ...state,
                businessProfileLoading: false,
                businessProfile: action.payload,
            }
            return state;


        case actions.GET_BUSINESS_PROFILE_START:
            state = {
                ...state,
                businessProfileLoading: true,
                businessProfile: {
                    data: {},
                    success: false
                },
            }
            return state;


        case actions.GET_BUSINESS_PROFILE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                businessProfileLoading: false,
                businessProfile: action.payload,
            }
            return state;


        case actions.GET_BUSINESS_PROFILE_RESET:
            state = {
                ...state,
                businessProfileLoading: false,
                businessProfile: {
                    data: {},
                    success: false
                },
            }
            return state;







        case actions.INITIALIZE_MFA:
            state = {
                ...state,
                mfaInitializeLoading: false,
                mfaInitialize: action.payload,
            }
            return state;


        case actions.INITIALIZE_MFA_START:
            state = {
                ...state,
                mfaInitializeLoading: true,
                mfaInitialize: {},
            }
            return state;


        case actions.INITIALIZE_MFA_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                mfaInitializeLoading: false,
                mfaInitialize: action.payload,
            }
            return state;


        case actions.INITIALIZE_MFA_RESET:
            state = {
                ...state,
                mfaInitializeLoading: false,
                mfaInitialize: {},
            }
            return state;


        case actions.COMPLETE_MFA:
            // toast.success(action.payload.message);
            state = {
                ...state,
                mfaCompleteLoading: false,
                mfaComplete: action.payload,
            }
            return state;


        case actions.COMPLETE_MFA_START:
            state = {
                ...state,
                mfaCompleteLoading: true,
                mfaComplete: {},
            }
            return state;


        case actions.COMPLETE_MFA_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                mfaCompleteLoading: false,
                mfaComplete: action.payload,
            }
            return state;


        case actions.COMPLETE_MFA_RESET:
            state = {
                ...state,
                mfaCompleteLoading: false,
                mfaComplete: {},
            }
            return state;


        case actions.VERIFY_MFA:
            // toast.success(action.payload.message);
            state = {
                ...state,
                mfaVerifyLoading: false,
                mfaVerify: action.payload,
            }
            return state;


        case actions.VERIFY_MFA_START:
            state = {
                ...state,
                mfaVerifyLoading: true,
                mfaVerify: {},
            }
            return state;


        case actions.VERIFY_MFA_FAILED:
            // errorReducer(action.payload);
            state = {
                ...state,
                mfaVerifyLoading: false,
                mfaVerify: action.payload,
            }
            return state;


        case actions.VERIFY_MFA_RESET:
            state = {
                ...state,
                mfaVerifyLoading: false,
                mfaVerify: {},
            }
            return state;


        case actions.DISABLE_MFA:
            toast.success(action.payload.message);
            state = {
                ...state,
                mfaDisableLoading: false,
                mfaDisable: action.payload,
            }
            return state;


        case actions.DISABLE_MFA_START:
            state = {
                ...state,
                mfaDisableLoading: true,
                mfaDisable: {},
            }
            return state;


        case actions.DISABLE_MFA_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                mfaDisableLoading: false,
                mfaDisable: action.payload,
            }
            return state;


        case actions.DISABLE_MFA_RESET:
            state = {
                ...state,
                mfaDisableLoading: false,
                mfaDisable: {},
            }
            return state;


        default:
            return state;
    }
}

export const getAuthAccount = (s: RootState) => s.auth.account;
export const getAuthUser = (s: RootState) => s.auth.user;