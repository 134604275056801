import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as settingsActions from "store/entities/settings/action";
import ProductTable from "./table/ProductTable";
import OrderTable from "./table/OrderTable";
import { products } from "./Products";
import EmptyOrder from "assets/images/icons/empty-order.svg";

const Orders = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className=" items-center justify-between pb-12 mb-12 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Orders
        </div>
        {/* <div>
          <button
            onClick={() => navigate("/ojah/products/create-product")}
            className="btn btn-block btn-lg btn-shiga-black"
          >
            Add a Product
          </button>
        </div> */}
      </div>
      {orders.data.payload.length < 1 ? (
        <div className="empty_state flex flex-col justify-center items-center self-stretch pt-[5.75rem] pb-12 px-0 bg-white">
          <div className="side-bar-icons flex justify-center items-center p-6">
            <EmptyOrder />
          </div>
          <div className="text-container flex flex-col justify-center items-center w-[400px]">
            <div className="text-content flex flex-col justify-center items-center self-stretch">
              <div className="no-accounts-text self-stretch text-[#343433] text-center  text-[1.625rem] leading-normal">
                No Order yet
              </div>
              <div className="no-accounts-label self-stretch text-[#99999c] text-center font-['Inter'] leading-normal">
                You have not received any order on this account at this time
              </div>
            </div>
          </div>
        </div>
      ) : (
        <OrderTable orders={orders} />
      )}
    </div>
  );
};

export const orders = {
  data: {
    meta: {
      size: 5,
      totalItems: 5,
      nextPage: 1,
      previousPage: 0,
    },
    payload: [
      {
        id: 1,
        productName: "Burger King’s Hamburger",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Dispatched",
        time: "5:20pm",
        dateCreated: "2024-07-10",
      },
      {
        id: 2,
        productName: "Burger King’s Hamburger",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Dispatched",
        time: "5:20pm",
        dateCreated: "2024-07-10",
      },

      {
        id: 3,
        productName: "Burger King’s Sandwich",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 4.99,
        currency: "USD",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Pending",
        time: "5:20pm",
        dateCreated: "2024-07-10",
      },
      {
        id: 4,
        productName: "Burger King’s Snack",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 30000,
        currency: "NGN",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Pending",
        time: "5:20pm",
        dateCreated: "2024-07-12",
      },
      {
        id: 5,
        productName: "Combo Burger King’s",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 30000,
        currency: "NGN",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Pending",
        time: "5:20pm",
        dateCreated: "2024-07-13",
      },
      {
        id: 5,
        productName: "Combo Burger King’s",
        name: "Fred Amata",
        email: "abc.xyz@gmail.com",
        phoneNumber: "08101234567",
        amount: 30000,
        currency: "NGN",
        totalItem: 100,
        itemSold: 30,
        quantity: 3,
        status: "Pending",
        time: "5:20pm",
        dateCreated: "2024-07-14",
      },
    ],
  },
};
// orders: [
// {
//   id: 1,
//   productName: "Burger King’s Hamburger",
//   name: "Fred Amata",
//   email: "abc.xyz@gmail.com",
//   amount: 4.99,
//   currency: "USD",
//   totalItem: 100,
//   itemSold: 30,
//   status: "Outgoing",
//   dateCreated: "2024-07-10",
// },
// ],
export default Orders;
