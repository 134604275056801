import { useState } from "react";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconProfile } from "assets/images/icons/profile.svg";
import { ReactComponent as Rectangle } from "assets/images/icons/rectangle.svg";
import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-light-gray.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ButtonLoader } from "components/common/form";
import { useNavigate } from "react-router-dom";
import { EditTransfer } from "./EditTransfer";
import WalletBulkSheet from "./bulk/WalletBulkSheet";
import CompleteUpload from "./bulk/CompleteUpload";
import Maybe from "components/common/Maybe";
import BulkTrasnferReviewTable from "./bulk/BulkTrasnferReviewTable";
import { jsonToFormData } from "utils/jsonToFormData";
import { initiateBulkTransfer } from "store/transfer/action";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";


export interface BulkTransferType {
    name: string;
    amount: string;
    type: string;
    id: number;
}

const defaultBulkTransfer: BulkTransferType = {
    amount: "0",
    id: 0,
    name: "",
    type: ""
}


export const BulkTransfer = () => {
    const navigate = useNavigate();
    const [currentState, setCurrentState] = useState(1);
    const [selectedTransfer, setSelectedTransfer] = useState(defaultBulkTransfer);
    const [tableItems, setTableItems] = useState<BulkTransferItemProps[]>();
    const [file, setFile] = useState<any>(null);
    const { dispatch, useSelector } = useToolkit();
    // eslint-disable-next-line
    const { bulkTransferLoading: isLoading } = useSelector((state: RootState) => state.transfer)

    const [form, setForm] = useState<BulkTrfFormProps>({
        teamMember: "",
        cardLabel: "",
        email: "",
        password: "",
        currency: "NGN",
    });

    const processTransfer = () => {
        const params = {
            file
        }
        const requestParams = jsonToFormData(params);

        dispatch(initiateBulkTransfer(requestParams))
    }


    return (
        <div className="space-y-8 pt-5 px-6 pb-24 sm:px-14">
            <div className="flex items-center justify-between pb-5 border-b border-gray-100">
                <div
                    onClick={() => {
                        if (currentState === 1) {
                            navigate(-1)
                        } else {
                            setCurrentState(prevSt => prevSt - 1)
                        }
                    }}
                    className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                    <IconNavigation className="w-full h-full" />
                </div>


                <div className="flex items-center space-x-3">
                    <div className="flex space-x-2 items-center cursor-pointer">
                        <div
                            className={`w-6 h-6 rounded-full flex items-center justify-center ${currentState >= 1 ? "bg-black" : "bg-[#E7E7E7]"
                                }`}>
                            <IconProfile />
                        </div>
                        <div className={currentState >= 1 ? "text-black" : "text-[#E7E7E7]"}>
                            Upload Recipients
                        </div>
                    </div>
                    <div>
                        <IconRight />
                    </div>
                    <div className="flex space-x-2 items-center cursor-pointer">
                        <div
                            className={`w-6 h-6 rounded-full flex items-center justify-center ${currentState > 1 ? "bg-black" : "bg-[#E7E7E7]"
                                }`}>
                            <Rectangle />
                        </div>
                        <div className={currentState > 1 ? "text-black" : "text-[#E7E7E7]"}>
                            Review & Pay
                        </div>
                    </div>
                </div>


                <div
                    onClick={() => setCurrentState(1)}
                    className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                    <IconClose className="m-auto svg-stroke-gray" />
                </div>
            </div>

            <Maybe condition={currentState === 1}>
                <WalletBulkSheet
                    file={file}
                    setFile={setFile}
                    form={form}
                    setForm={setForm}
                    goToNext={setCurrentState}
                    tableItems={tableItems}
                    setTableItems={setTableItems}
                />
            </Maybe>


            <Maybe condition={currentState === 2}>

                <BulkTrasnferReviewTable
                    tableItems={tableItems}
                    setTableItems={setTableItems}
                    handleSelect={setSelectedTransfer}
                />

                <ButtonLoader
                    disabled={false}
                    type="submit"
                    onClick={() => setCurrentState(3)}
                    className="ml-auto btn btn-lg px-12 btn-shiga-black">
                    Continue
                </ButtonLoader>

            </Maybe>


            <Maybe condition={currentState === 3}>
                <CompleteUpload
                    tableItems={tableItems}
                    setTableItems={setTableItems}
                    confirm={processTransfer}
                />
            </Maybe>


            <EditTransfer
                isOpen={String(selectedTransfer.name).length > 0}
                setIsOpen={() => setSelectedTransfer(defaultBulkTransfer)}
            />
        </div>
    );
};