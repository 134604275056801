interface NigeriaFlagProps extends React.BaseHTMLAttributes<SVGElement> {}

const NigeriaFlag = ({ ...props }: NigeriaFlagProps) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6656 0.5H16V16.5H10.6656V0.5ZM0 0.5H5.33437V16.5H0V0.5Z"
        fill="#008753"
      />
    </svg>
  );
};

export default NigeriaFlag;
