import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import CustomSwitch from "components/common/form/CustomSwitch";
import useLoginInfo from "hooks/useLoginInfo";
import ContentLoading from "components/common/ContentLoading";
import Maybe from "components/common/Maybe";
import PageContent from "components/common/PageContent";
import * as settingsActions from "store/entities/settings/action";
import * as userActions from "store/auth/user/action";


const Notifications = () => {
    const dispatch = useDispatch();
    const { getBusinessProfile } = useLoginInfo();
    const { userInfo } = useSelector((s: any) => s.auth.userPersist);
    const { businessProfileLoading } = useSelector((s:any) => s.auth.account);
    const { notificationPreferenceUpdate } = useSelector((s: any) => s.entities.settings);

    const [formLoading, setFormLoading] = useState<any>([]);
    const [form, setForm] = useState({
        TRANSACTION_BUSINESS_EMAIL: false,
        TRANSACTION_CUSTOMER_EMAIL: false,
        TRANSFER_BUSINESS_EMAIL: false,
        TRANSFER_CUSTOMER_EMAIL: false,
        CARD_BUSINESS_EMAIL: false,
        CARD_CUSTOMER_EMAIL: false,
    })

    const populateForm = () => {
        let data:any = {};
        Object.keys(form).map((key:string) => 
            data = {...data, [key]: (userInfo?.businessProfile?.notificationChannels?.includes(key))}
        )
        setForm(data);
    }

    const updateData = (key:string, value:boolean) => {
        const params = {
            channel: key,
            status: value ? "ENABLE" : "DISABLE",
        }
        setFormLoading([...formLoading, key]);
        dispatch(settingsActions.updateNotificationPreference(params));
    }

    const removeLoader = () => {
        let data = [...formLoading];
        data.shift();
        setFormLoading(data);
    }

    useEffect(() => {
        if (userInfo?.businessProfile === undefined){
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined){
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (notificationPreferenceUpdate?.success !== undefined){
            if (notificationPreferenceUpdate?.success === true){
                dispatch(userActions.updateUserInfo({
                    businessProfile: {
                        ...notificationPreferenceUpdate?.data ?? {},
                    },
                }));
            }
            dispatch(settingsActions.resetUpdateNotificationPreference());
            removeLoader();
        }
        // eslint-disable-next-line
    }, [notificationPreferenceUpdate]);
    
    return (
        <div>
            <DynamicHeadTag
                headerText="Notification Preferences"
            />

            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                Notification Preferences
            </div>

            <PageContent
                loading={businessProfileLoading}
                data={userInfo?.businessProfile}
                loadingText="Loading notification preferences..."
            />

            <Maybe condition={businessProfileLoading === false}>
                <div>
                    <div className="text-2xl font-ojah font-bold text-[#B4B4B4] pb-6 mb-6">
                        Transactions
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Transaction emails
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Notify me for every transaction.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.TRANSACTION_BUSINESS_EMAIL}
                                onChange={(e:any) => updateData("TRANSACTION_BUSINESS_EMAIL", e)}
                                disabled={formLoading.includes("TRANSACTION_BUSINESS_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("TRANSACTION_BUSINESS_EMAIL")} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Customer receipt
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Email customers for every transaction.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.TRANSACTION_CUSTOMER_EMAIL}
                                onChange={(e:any) => updateData("TRANSACTION_CUSTOMER_EMAIL", e)}
                                disabled={formLoading.includes("TRANSACTION_CUSTOMER_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("TRANSACTION_CUSTOMER_EMAIL")} />
                            </div>
                        </div>
                    </div>

                    <div className="text-2xl font-ojah font-bold text-[#B4B4B4] pb-6 mb-6">
                        Transfers
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Debit emails
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Notify me for every transfer from my account.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.TRANSFER_BUSINESS_EMAIL}
                                onChange={(e:any) => updateData("TRANSFER_BUSINESS_EMAIL", e)}
                                disabled={formLoading.includes("TRANSFER_BUSINESS_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("TRANSFER_BUSINESS_EMAIL")} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Customer receipt
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Email my customers for every transaction.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.TRANSFER_CUSTOMER_EMAIL}
                                onChange={(e:any) => updateData("TRANSFER_CUSTOMER_EMAIL", e)}
                                disabled={formLoading.includes("TRANSFER_CUSTOMER_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("TRANSFER_CUSTOMER_EMAIL")} />
                            </div>
                        </div>
                    </div>

                    <div className="text-2xl font-ojah font-bold text-[#B4B4B4] pb-6 mb-6">
                        Card
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Card notifications
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Notify me for everytime a new card is created or terminated
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.CARD_BUSINESS_EMAIL}
                                onChange={(e:any) => updateData("CARD_BUSINESS_EMAIL", e)}
                                disabled={formLoading.includes("CARD_BUSINESS_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("CARD_BUSINESS_EMAIL")} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:flex pb-8 mb-8 sm:space-x-8 border-b border-gray-100">
                        <div className="sm:w-1/2">
                            <div className="font-medium">
                                Customer transactions
                            </div>
                            <div className="mt-1 text-sm text-shiga-gray">
                                Notify me of all card transactions from withdrawals, payments & funding.
                            </div>
                        </div>
                        <div className="mt-6 sm:mt-0 sm:w-1/2 space-y-6 font-medium flex items-center">
                            <CustomSwitch
                                active={form.CARD_CUSTOMER_EMAIL}
                                onChange={(e:any) => updateData("CARD_CUSTOMER_EMAIL", e)}
                                disabled={formLoading.includes("CARD_CUSTOMER_EMAIL")}
                            />
                            <div className="relative -top-3 left-4 text-xl">
                                <ContentLoading loading={formLoading.includes("CARD_CUSTOMER_EMAIL")} />
                            </div>
                        </div>
                    </div>

                </div>
            </Maybe>
        </div>
    )
}

export default Notifications;