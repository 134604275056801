import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import moment from "moment";
import ContentLoading from "components/common/ContentLoading";
import useToolkit from "hooks/useToolkit";
import Maybe from "components/common/Maybe";

type Props = {
   settlement: any;
   transactions: any;
   loading: boolean;
}

export const SettlementDetailSidebar = ({ settlement, transactions, loading }: Props) => {

   const { copyItem } = useToolkit();

   const isNgn = settlement?.currency === 'NGN';
   const isSuccessful = settlement?.status === 'SUCCESS';
   const isPending = settlement?.status === 'PENDING';
   const isFailed = settlement?.status === 'FAILED';

   return (
      <div className="w-full border-r">
         <p className="mb-6 font-ojah font-medium text-lg lg:text-xl">Details</p>

         <div className="mb-5">
            <p className="settlement-detail-key">Transaction count</p>
            <p className="settlement-detail-value">
               <ContentLoading
                  loading={loading}
                  data={transactions?.deposits?.length}
               />
            </p>
         </div>

         <div className="mb-5">
            <p className="settlement-detail-key">Type</p>
            <p className="settlement-detail-value">Settled to {isNgn ? 'bank account' : 'wallet'}</p>
         </div>

         <div>
            <p className="settlement-detail-key">Status</p>

            <div className={`border rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium mt-px 
            ${isSuccessful ? 'border-shiga-green-50 text-shiga-green-100'
                  : isPending ? 'text-shiga-orange-100 border-shiga-orange-50'
                     : isFailed ? 'border-shiga-red-40 text-shiga-red-100'
                        : ''
               }`}>
               {isSuccessful ? 'Completed' : settlement?.status?.toLowerCase()}
            </div>
         </div>

         <div className="border-t border-shiga-gray-50 my-5 pt-4">
            <p className="settlement-detail-key">Settlement ID</p>
            <p className="settlement-detail-value flex">
               {settlement?.settlementId?.substring(0, 15)}...

               <button className='ml-1.5' onClick={(e: any) => {
                  e.stopPropagation();
                  copyItem(settlement?.settlementId, 'Settlement ID');
               }}>
                  <CopyIcon />
               </button>
            </p>
         </div>

         <div className="mb-5">
            <p className="settlement-detail-key">Date Initiated</p>
            <p className="settlement-detail-value">
               {moment(settlement?.dateInitiated).format('MMM D, YYYY [at] h:mmA')}
            </p>
         </div>

         <Maybe condition={settlement?.dateCompleted}>
            <div className="mb-5">
               <p className="settlement-detail-key">Date Completed</p>
               <p className="settlement-detail-value">
                  {moment(settlement?.dateCompleted).format('MMM D, YYYY [at] h:mmA')}
               </p>
            </div>
         </Maybe>

      </div>
   )
}


export const SettlementDetailsPaginateSearch = ({ transactions }: { transactions: any }) => {

   return (
      <div className="flex items-start justify-start lg:justify-between flex-wrap lg:flex-nowrap mb-9">
         <Pagination meta={transactions?.data?.meta} />

         <SearchBox
            noTopMargin
            name='search'
            extraUrlParams='page=1'
            placeholder="Search"
         />
      </div>
   )
}


export const SettlementsFilterHandler = ({ transactions }: { transactions: any }) => {

   return (
      <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
         <div className="w-full lg:w-2/4">
            <CustomTableFilter
               dropdownClass=''
               filters={[
                  {
                     title: "Status",
                     name: "status",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "Completed",
                           payload: "SUCCESS",
                        },
                        {
                           display: "Failed",
                           payload: "FAILED",
                        },
                        {
                           display: "Pending",
                           payload: "PENDING",
                        },
                     ],
                  },
                  {
                     title: "Currency",
                     name: "currency",
                     dataType: "select",
                     options: [
                        {
                           display: "Show all",
                           payload: "-",
                        },
                        {
                           display: "NGN",
                           payload: "NGN",
                        },
                        {
                           display: "USD",
                           payload: "USD",
                        },
                        {
                           display: 'GBP',
                           payload: 'GBP',
                        }
                     ],
                  },
                  {
                     title: "Custom Date",
                     dataType: "date-range",
                     options: {
                        from: {
                           name: "startDate",
                           placeholder: "Start Date",
                        },
                        to: {
                           name: "endDate",
                           placeholder: "End Date",
                        },
                     },
                  },
               ]}
            />
         </div>

         <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
            <Pagination meta={transactions?.data?.meta} />

            <div className="lg:pb-[26px]">
               <SearchBox
                  name='search'
                  extraUrlParams='page=1'
                  placeholder="Search"
               />
            </div>
         </div>
      </div>
   )
}