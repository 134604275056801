import CustomToastItem from './CustomToastItem'
import { useCustomToast } from 'utils/context/custom-toast/ToastContext'

const CustomToastRenderer = () => {
   const { toasts } = useCustomToast();

   return (
      <div className="fixed top-0 left-0 right-0 space-y-4 bgs-shiga-dark-800 z-[9999] flexed flex-col pt-6">
         {toasts.map((toast,idx) => (
            <CustomToastItem toast={toast} key={idx *2} />
         ))}
      </div>
   )
}

export default CustomToastRenderer