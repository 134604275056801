import { getCountries, getCurrencies } from "lib/constants";
import { persist, createJSONStorage } from "zustand/middleware";
import { Currency } from "types/shared/countries.types";
import { create } from "zustand";
import { University } from "types/shared/university.type";
import { getUniversities } from "lib/payments/tutions";
import { toast } from "react-toastify";

interface SharedState {
  apiLoading: boolean;
  setApiLoading: (apiLoading: boolean) => void;
  currencies: Currency[];
  countries: string[]
  universities: University[];
  fetchUniversities: (country: string) => Promise<void>;
  initSharedData: () => Promise<void>;
}


export const useSharedStore = create(
  persist<SharedState>(
    (set, get) => ({
      apiLoading: false,
      setApiLoading: (apiLoading) => set({ apiLoading }),
      currencies: [] as Currency[],
      countries: [] as string[],
      universities: [] as University[],
      fetchUniversities: async (country) => {
        if (
          country === "NG" &&
          get().universities[0] &&
          get().universities[0].country === "NG"
        ) {
          return;
        } else {
          try {
            const { data: { universities } } = await getUniversities(country);
            set({ universities });
          } catch (err: any) {
            toast.error("Unable to get universities", err.message)
          }
        }
      },
      initSharedData: async () => {
        try {
          const currencies = await getCurrencies();
          const countries = await getCountries();
          set({ currencies, countries });
        } catch (err: any) {
          toast.error(`Error: ${err.message}`)
        }
      },
    }),
    {
      name: "shared-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);