import { Dialog } from '@headlessui/react';
import Maybe from "./Maybe";


export function FormDrawer(props) {
    const { isOpen, setIsOpen, children, size, display, isBackdropBlur, closeDialog, backdropClickable } = props;
    const sizeOptions = {
      "xs": "max-w-xs",
      "sm": "max-w-sm",
      "md": "max-w-md",
      "lg": "max-w-lg",
      "xl": "max-w-xl",
      "2xl": "max-w-2xl",
      "3xl": "max-w-3xl",
      "4xl": "max-w-4xl",
      "5xl": "max-w-5xl",
    }

    let sizeRender = sizeOptions[size] ?? "max-w-lg";
    let backdropBlur = isBackdropBlur === true ? "backdrop-blur-[3px]" : "";
    let isBackdropClickable = backdropClickable ?? true;
    
    const displayClassBackdrop = "fixed overflow-auto z-50 bg-gray-900 bg-opacity-25 inset-0 transform " + (isOpen ? "transition-opacity opacity-100 translate-x-0": "opacity-0 translate-x-full");
    const displayClassModal = "w-screen "+(sizeRender)+" right-0 absolute bg-white h-full shadow-xl transform  " + (isOpen ? "translate-x-0 " : "translate-x-full");
    const displayClassModalContent = "relative w-screen "+(sizeRender)+" flex flex-col space-y-6 overflow-y-scroll h-full";
    
    return (
        <>
            {display && display === "center" &&
                <>
                    {(isOpen === true) &&
                        <Dialog open={isOpen} onClose={() => isBackdropClickable && setIsOpen(false)} className="relative z-50">
                            <div className={`fixed inset-0 bg-black/40 ${backdropBlur}`} aria-hidden="true" /> {/* backdrop */}
                            <div className="fixed inset-0 w-screen overflow-y-auto"> {/* Full-screen scrollable container */}
                                <div className="flex min-h-full items-center justify-center py-8"> {/* Container to center the panel */}
                                    <Dialog.Panel className={`w-full mx-auto ${sizeRender} bg-white rounded-3xl shadow-xl`}>
                                        {children}
                                    </Dialog.Panel>
                                </div>
                            </div>
                        </Dialog>
                    }
                </>
            }

            {(display === undefined || display === "right") &&
                <div className={displayClassBackdrop}>
                    <div className={displayClassModal}>
                        <div className={displayClassModalContent}>
                            {children}
                        </div>
                    </div>
                    <Maybe condition={isBackdropClickable === true}>
                        <Maybe condition={closeDialog !== undefined}>
                            <div className="w-screen h-full" onClick={() => closeDialog()}></div>
                        </Maybe>
                        <Maybe condition={closeDialog === undefined}>
                            <div className="w-screen h-full" onClick={() => setIsOpen(false)}></div>
                        </Maybe>
                    </Maybe>
                </div>
            }
        </>
    )
}


export default FormDrawer;