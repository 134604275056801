import { SetStateAction } from "react";
import { Dialog } from '@headlessui/react';
import DynamicHeadTag from "components/common/DynamicHeadTag";
import TransfersProvider from "../context/TransfersProvider";
import LayoutDisplay from "./LayoutDisplay";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
}


const InlineTransfersLayout = ({ isOpen, setIsOpen }: Props) => {

    return (
        <TransfersProvider
            trfModalOpen={isOpen}
            setTrfModalOpen={setIsOpen}>

            <DynamicHeadTag
                headerText="Make a Transfer"
            />

            {(isOpen === true) &&
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50">
                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
                    <div className={`mt-24 w-screen bg-white fixed inset-0 rounded-tr-3xl rounded-tl-3xl overflow-hidden shadow-xl animate__animated ${isOpen ? "animate__slideInUp" : "animate__slideOutDown"} animate__faster`}>
                        <LayoutDisplay setIsOpen={setIsOpen} />
                    </div>
                </Dialog>
            }
        </TransfersProvider>
    );
}


export default InlineTransfersLayout;
