export const currencyFormat = (amount, currency, alwaysShowKobo = false) => {
   if (!isNaN(amount) && amount !== null && amount !== undefined) {
      let data = parseFloat(amount).toFixed(2);
      if (alwaysShowKobo === false) {
         data = parseFloat(data);                                    //removes trailing .00
      }
      data = `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currency}`
      return (data);
   }
   else {
      return amount
   }
};