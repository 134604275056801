import { toast } from "react-toastify";
import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";


const initialState = {
    businessVerificationUpdateLoading: false,
    businessVerificationUpdate: {},
    sampleDocumentsAllLoading: false,
    sampleDocumentsAll: [],
    directorsAddedLoading: false,
    directorsAdded: [],

    deleteDirectorLoading: false,
    deleteDirectorData: null,

    completeKybLoading: false,
    completeKybData: null,

    rcnAuthLoading: false,
    rcnAuthData: null,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.RESET_RCN_VALIDATION:
            state = {
                ...state,
                rcnAuthLoading: false,
                rcnAuthData: null,
            }
            return state;


        case actions.VALIDATE_RCN_START:
            state = {
                ...state,
                rcnAuthLoading: true,
                rcnAuthData: null,
            }
            return state;


        case actions.VALIDATE_RCN_DONE:
            toast.success(action.payload.message);
            state = {
                ...state,
                rcnAuthLoading: false,
                rcnAuthData: action.payload,
            }
            return state;


        case actions.VALIDATE_RCN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rcnAuthLoading: false,
                rcnAuthData: null,
            }
            return state;


        case actions.COMPLETE_KYB_START:
            state = {
                ...state,
                completeKybLoading: true,
                completeKybData: null,
            }
            return state;


        case actions.COMPLETE_KYB_DONE:
            toast.success(action.payload.message);
            state = {
                ...state,
                completeKybLoading: false,
                completeKybData: action.payload,
            }
            return state;


        case actions.COMPLETE_KYB_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                completeKybLoading: false,
                completeKybData: null,
            }
            return state;


        case actions.DELETE_DIRECTOR_START:
            state = {
                ...state,
                deleteDirectorLoading: true,
                deleteDirectorData: null,
            }
            return state;


        case actions.DELETE_DIRECTOR_DONE:
            state = {
                ...state,
                deleteDirectorLoading: false,
                deleteDirectorData: action.payload,
            }
            return state;


        case actions.DELETE_DIRECTOR_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                deleteDirectorLoading: false,
                deleteDirectorData: null,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION:
            toast.success(action.payload.message);
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_START:
            state = {
                ...state,
                businessVerificationUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_RESET:
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: {},
            }
            return state;


        case actions.ADD_DIRECTOR:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [
                    ...state.directorsAdded,
                    action.payload
                ],
            }
            return state;


        case actions.ADD_DIRECTOR_START:
            state = {
                ...state,
                directorsAddedLoading: true,
            }
            return state;


        case actions.ADD_DIRECTOR_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [
                    ...state.directorsAdded,
                    action.payload
                ],
            }
            return state;


        case actions.ADD_DIRECTOR_RESET:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [],
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS:
            state = {
                ...state,
                sampleDocumentsAllLoading: false,
                sampleDocumentsAll: [
                    ...state.sampleDocumentsAll,
                    action.payload?.data?.file,
                ],
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS_START:
            state = {
                ...state,
                sampleDocumentsAllLoading: true,
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                sampleDocumentsAllLoading: false,
                // sampleDocumentsAll: action.payload,
            }
            return state;


        default:
            return state;
    }
}