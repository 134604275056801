import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import {
  ButtonLoader,
  FileUploadForm,
  Input,
  SelectInput,
} from "components/common/form";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeft.svg";
import { meansOfIdentication } from "data/constants/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaService from "hooks/useMediaService";
import validate from "utils/validate";
import useLoginInfo from "hooks/useLoginInfo";
import { toast } from "react-toastify";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import * as businessVerificationActions from "store/entities/businessVerification/action";
// import useToolkit from "hooks/useToolkit";

const AddDirectors = (props: any) => {
  const { goPrevious, goNext } = props;
  const dispatch = useDispatch();
  const { userInfo, getBusinessProfile } = useLoginInfo();
  const { uploadFile, uploadedFileLoading } = useMediaService();
  const { directorsAddedLoading, directorsAdded } = useSelector(
    (s: any) => s.entities.businessVerification
  );
  const [proceedDisabled, setProceedDisabled] = useState(false);

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [directors, setDirectors] = useState<any>([]);
  const directorSample = {
    firstName: "",
    lastName: "",
    meansOfIdentification: "",
    identityDocument: "",
  };

  const addDirector = () => {
    const data = [...directors, directorSample];
    setDirectors(data);
    setIsFormValidated(false);
  };

  const updateDirector = (index: number, form: any) => {
    const data = directors.map((director: any, position: number) => {
      if (index === position) {
        return {
          ...director,
          ...form,
        };
      } else {
        return director;
      }
    });
    setDirectors(data);
  };

  const checkFormValidation = (
    data = directors,
    appendKey?: any,
    appendValue?: any,
    index?: any
  ) => {
    let errorResponses = 0;
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        let params = { ...data[i] };

        if (appendKey && index !== undefined && index === i) {
          params = { ...params, [appendKey]: appendValue };
        }

        const errors = validate(params);
        if (errors) {
          errorResponses++;
        }
      }
    }
    setIsFormValidated(errorResponses === 0 ? true : false);
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (directors?.length > 0) {
      if (isFormValidated === true || proceedDisabled === false) {
        for (let i = 0; i < directors?.length; i++) {
          const director = directors[i];

          let params = {
            firstName: director.firstName,
            lastName: director.lastName,
            document: director.identityDocument,
            idType: director.meansOfIdentification,
          };

          if (typeof director.identityDocument !== "string") {
            const response: any = await uploadFile(director.identityDocument);
            if (response?.success === true) {
              params = { ...params, document: response?.data?.file?.link };
            }
          }
          dispatch(businessVerificationActions.addDirectors(params));
        }
      } else {
        toast.error("Please fill all required fields");
      }
    } else {
      toast.error("Please fill your directors information");
    }
  };


  const populateDirectors = (savedDirectors: any) => {
    if (savedDirectors?.length > 0) {
      const populatedDirectors = savedDirectors?.filter((director: any) => director?.type === 'DIRECTOR')?.map((savedDirector: any) => {
        return {
          id: savedDirector?.id ?? "",
          firstName: savedDirector?.firstName ?? "",
          lastName: savedDirector?.lastName ?? "",
          meansOfIdentification: savedDirector?.idType ?? "",
          identityDocument: savedDirector?.document ?? "",
          type: savedDirector?.type ?? "",
        };
      });
      setDirectors(populatedDirectors);
      checkFormValidation(populatedDirectors);
    } else {
      const directorSampleTotal = ["value"].map(() => directorSample);
      setDirectors(directorSampleTotal);
      checkFormValidation([directorSample]);
    }
  };

  useEffect(() => {
    if (userInfo?.businessProfile !== undefined) {
      populateDirectors(userInfo?.businessProfile?.directors);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (directors?.length > 0) {
      let hasEmptyValues = false;

      directors.forEach((obj: any) => {
        for (const key in obj) {
          if (obj[key] === "") {
            hasEmptyValues = true;
            setProceedDisabled(true);
          }
        }
      });

      if (!hasEmptyValues) {
        setProceedDisabled(false)
      }

    }
    // eslint-disable-next-line
  }, [directors]);

  useEffect(() => {
    if (directorsAdded?.length === directors?.length) {
      const savedSubmission = directorsAdded.filter(
        (e: any) => e?.success === true
      );
      if (savedSubmission?.length >= 1) {
        toast.success("Directors added successfully");
        dispatch(businessVerificationActions.resetAddDirectors());
        getBusinessProfile();
        goNext();
      }
    }
    // eslint-disable-next-line
  }, [directorsAdded]);


  return (
    <div>
      <AuthPageTitle title="Share information about your directors" />

      <div className="">
        <form onSubmit={submitForm}>
          {directors.map((director: any, index: number) => (
            <>
              <DirectorForm
                key={index}
                index={index}
                director={director}
                checkFormValidation={(docs: any, name: string, value: string) =>
                  checkFormValidation(docs, name, value, index)
                }
                updateDirector={(e: any) => updateDirector(index, e)}
              />

              <div className="pt-3 flex items-center justify-end">
                <FontAwesomeIcon
                  icon='trash'
                  className="text-red-500 text-lg cursor-pointer"
                  onClick={() => {
                    const filtered = directors?.filter((person: any) => person?.firstName !== director?.firstName)
                    setDirectors(filtered);
                    dispatch(businessVerificationActions.deleteBusinessDirector(director?.id))
                  }}
                />
              </div>

            </>
          ))}

          <div className="mt-6 flex justify-end">
            <button
              type="button"
              onClick={addDirector}
              className="btn btn-lg btn-white"
            >
              <FontAwesomeIcon icon="plus" className="text-[#99999C] mr-1" />
              <span>Add Director</span>
            </button>
          </div>

          <div className="mt-10 flex space-x-2 items-center justify-between">
            <ButtonLoader
              type="button"
              onClick={goPrevious}
              loading={false}
              className="max-w-[10rem] btn btn-block btn-lg btn-white"
            >
              <IconArrowLeft className="mr-3" />
              <span>Back</span>
            </ButtonLoader>
            <ButtonLoader
              type="submit"
              loading={
                uploadedFileLoading === true || directorsAddedLoading === true
              }
              className="max-w-[16rem] btn btn-block btn-lg btn-primary"
              disabled={proceedDisabled}>
              Continue
            </ButtonLoader>
          </div>
        </form>
      </div>
    </div>
  );
};

const DirectorForm = (props: any) => {
  const { index, director, checkFormValidation, updateDirector } = props;

  const updateForm = (name: string, value: any) => {
    const data = {
      ...director,
      [name]: value,
    };
    updateDirector(data);
  };

  return (
    <div className="mt-10 pt-10 border-t border-gray-100">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
        <Input
          type="text"
          label="Director’s First Name"
          placeholder="First Name"
          required={true}
          value={director.firstName}
          onChange={(e: any) => updateForm("firstName", e.target.value)}
          validationName="firstName"
          checkFormValidation={checkFormValidation}
        />
        <Input
          type="text"
          label="Director’s Last Name"
          placeholder="Last Name"
          required={true}
          value={director.lastName}
          onChange={(e: any) => updateForm("lastName", e.target.value)}
          validationName="lastName"
          checkFormValidation={checkFormValidation}
        />
      </div>

      <SelectInput
        label="Means of identification"
        required={true}
        value={director.meansOfIdentification}
        onChange={(e: any) =>
          updateForm("meansOfIdentification", e.target.value)
        }
        validationName="meansOfIdentification"
        checkFormValidation={checkFormValidation}
      >
        <option value="" disabled>
          Select a Document
        </option>
        {Object.keys(meansOfIdentication).map((key) => (
          <option value={key} key={key}>
            {meansOfIdentication[key]}
          </option>
        ))}
      </SelectInput>

      <FileUploadForm
        label={
          "Upload your " +
          numberOrdinalSuffix(index + 1) +
          " director’s ID card"
        }
        required={true}
        data={director.identityDocument}
        onchange={(e: any) => updateForm("identityDocument", e)}
        filetype={["pdf", "csv"]}
        filename={numberOrdinalSuffix(index + 1) + " director ID"}
        validationName="identityDocument"
        checkFormValidation={checkFormValidation}
      />

    </div>
  );
};

export default AddDirectors;
