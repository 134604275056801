import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BillsNav from "./BillsNav";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/manage-bills") {
      navigate("/manage-bills/buy-airtime");
    }
  }, [navigate, pathname]);

  return (
    <div className="flex justify-start items-stretch">
      <BillsNav />
      <div className="flex-grow px-20 py-11">
        <Outlet />
      </div>
    </div>
  );
};

export default Index;
