import { Switch } from "@headlessui/react";
import { ButtonLoader } from "components/common/form";
import TransferHeader from "components/transfer/TransferHeader";
import PaymentSuccessful from "./status/PaymentSuccessful";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  makeTransfer,
  requestTransferOtp,
  resetTransferData,
} from "store/transfer/action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "NavigationRoute";
import useToolkit from "hooks/useToolkit";
import TwoFacInputModal from "components/auth/TwoFacInputModal";
import { useSharedStore } from "zustand-store";

interface TransactionDetailProps {
  label: string;
  value: string;
}

export const TransactionDetail: React.FC<TransactionDetailProps> = ({
  label,
  value,
}) => (
  <>
    <div className="flex gap-0 px-3 py-2 text-base tracking-tight leading-6">
      <div className="flex-1 text-neutral-400">{label}</div>
      <div className="flex-1 font-medium text-right text-stone-900 capitalize">
        {value}
      </div>
    </div>
    <div className="shrink-0 self-center mt-1.5 max-w-full h-px bg-neutral-100 w-[376px]" />
  </>
);

function TransferDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currencies } = useSharedStore();

  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [showStatusAlert, setShowStatusAlert] = useState(false);
  const [twoFacModalOpen, setTwoFacModalOpen] = useState(false);

  const { mfaEnabled, refetchProfiles, showCustomSuccessToast } = useToolkit();
  const [twoFacCode, setTwoFacCode] = useState("");

  const {
    formFields,
    selectedWallat,
    amount,
    transferNote,
    transferLoading,
    transferStatus,
    currency,
    narration,
    transferFeeData,
    transferResponse,
    transferAmount,
    transferOtpLoading,
  } = useSelector((allStates: any) => allStates.transfer);

  const {
    accountName,
    accountNumber,
    bankCode,
    bankName,
    transferType,
    routingNo,
    bankAddress,
    bankState,
    bankCity,
    country,
    postCode,
    bsbNumber,
    accountType,
    isBeneficiery,
    ...otherFields
  } = formFields;
  const authorizeTransfer = () => {
    // {
    //   accountName,
    //   accountNumber,
    //   amount,
    //   bankCode,
    //   bankName,
    //   selectedWallat.currency,
    //   saveBeneficiary,
    //   currency,
    //   transferNote,
    //   twoFacCode,
    //   ACHroutingNumber,
    //   BSBNumber,
    //   transferType,
    //   accountType
    // }

    const selectedCountryCode = currencies.filter(
      (item: any) => item.currency === currency
    );
    const transferData = {
      accountName: accountName,
      accountNumber: accountNumber,
      amount: amount,
      bankCode: bankCode,
      bankName: bankName,
      currency: country || selectedCountryCode[0]?.currency,
      country: selectedCountryCode[0]?.countryCode,
      saveBeneficiary: saveBeneficiary,
      narration: transferNote || "Sent from Shiga",
      token: twoFacCode,
      transferType: transferType,
      meta: {
        routingNo: routingNo,
        bankAddress: bankAddress,
        bankState: bankState,
        bankCity: bankCity,
        postCode: postCode,
        bsbNumber: bsbNumber,
        accountType: accountType,
      },
    };
    dispatch(makeTransfer(transferData));
  };

  const requestOtp = () => {
    dispatch(requestTransferOtp());
  };

  const sendTrasfer = () => {
    if (mfaEnabled) {
      setTwoFacModalOpen(true);
    } else {
      setTwoFacModalOpen(true);
      requestOtp();
    }
  };

  //Redirect to beginng if accountNumber or bankNameIs missing
  useEffect(() => {
    if (bankName?.isEmpty() || amount < 0 || accountName?.isEmpty()) {
      dispatch(resetTransferData());
      navigate(PATHS.TRANSFER);
    }
    // eslint-disable-next-line
  }, []);

  const transactionDetails = [
    {
      label: "Transaction Type",
      value: formFields.transferType
        ? formFields.transferType
        : "Bank Transfer",
    },
    {
      label: "Amount",
      value: `${Number(amount)?.toLocaleString()} ${currency}`,
    },
    ...((currency === "NGN" || currency === "USD") && currency !== "AUD"
      ? [{ label: "Bank Name", value: bankName?.toLowerCase() }]
      : []),
    { label: "Account Name", value: formFields?.accountName },
    // ...(currency === "AUD"
    //   ? [{ label: "Account Name", value: formFields?.accountName }]
    //   : []),
    { label: "Account Number", value: formFields?.accountNumber },
    ...(currency === "USD"
      ? [{ label: "ACH routing number", value: formFields?.routingNo }]
      : []),
    ...(currency === "USD"
      ? [{ label: "Account type", value: formFields?.accountType }]
      : []),
    ...(currency === "AUD"
      ? [{ label: "BSB Number", value: formFields?.bsbNumber }]
      : []),
    // { label: "ACH routing number", value: formFields?.ACHroutingNumber },
    // { label: "Account type", value: formFields?.accountType },
    {
      label: "Fee",
      value: `${transferFeeData?.data?.feeAmount || 0.88} ${currency}`,
    },
    { label: "Note", value: transferNote || "Sent from Shiga" },
  ];

  useEffect(() => {
    if (transferStatus > -1) {
      if (twoFacModalOpen) {
        setTwoFacModalOpen(false);
      }
      setShowStatusAlert(true);
    }
    // eslint-disable-next-line
  }, [transferStatus]);

  useEffect(() => {
    refetchProfiles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currency === "USD") {
      setSaveBeneficiary(true);
    }
    if (isBeneficiery) {
      setSaveBeneficiary(false);
    }
  }, [currency, isBeneficiery]);

  useEffect(() => {
    if (
      transferResponse?.success === true &&
      transferResponse?.message === "Transfer In Progress"
    ) {
      showCustomSuccessToast({
        message: `You have successfully transferred ${Number(
          amount
        )?.toLocaleString()} ${currency} to ${accountName}`,
        onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
      });
    }
    // eslint-disable-next-line
  }, [transferResponse]);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <TwoFacInputModal
        modalLoading={mfaEnabled ? false : transferOtpLoading}
        showResend={!mfaEnabled}
        title={mfaEnabled ? "Enter 2FA Code" : "Enter Email OTP"}
        target="2FA Code"
        buttonText="Confirm Transfer"
        isOpen={twoFacModalOpen}
        setIsOpen={setTwoFacModalOpen}
        loading={transferLoading}
        onSubmit={authorizeTransfer}
        onPinChange={(value: string) => setTwoFacCode(value)}
        onResendClick={() => {
          if (!mfaEnabled) {
            requestOtp();
          }
        }}
      />

      <PaymentSuccessful
        onDone={() => {
          if (transferStatus !== 0) {
            setShowStatusAlert(false);
          } else {
            dispatch(resetTransferData());
            navigate(PATHS.TRANSACTION_HOME);
          }
        }}
        isOpen={showStatusAlert}
        amount={`${amount} ${currency}`}
        recipient={formFields?.accountName}
        isSuccessful={transferStatus === 0}
        successfullmessage="Payment Successful"
        errorMessage="This transaction cannot be completed at this time."
        succesPreText="You paid"
      />

      <div className="transfer-container">
        <TransferHeader
          step={3}
          onClose={() => {
            dispatch(resetTransferData());
            navigate(PATHS.TRANSACTION_HOME);
          }}
        />
        <div className="transfer-wrapper">
          <header className="font-ojah font-bold transfer-page-title">
            Confirm your transaction to{" "}
            <span className="capitalize">
              {accountName?.toLowerCase() || bankName}
            </span>
          </header>
          <main>
            <section className="flex flex-col justify-center pt-3 mt-6 w-full rounded-3xl border border-solid border-neutral-100">
              {transactionDetails.map(({ label, value }) => (
                <TransactionDetail key={label} label={label} value={value} />
              ))}
              <div className="flex gap-2 justify-between px-5 py-6 w-full bg-neutral-50">
                <div className="text-base font-medium tracking-tight text-zinc-800">
                  Save as a beneficiary
                </div>
                <div className="flex items-center">
                  <Switch
                    as="button"
                    checked={saveBeneficiary}
                    disabled={country === "USD" || isBeneficiery}
                    onChange={(status) => {
                      setSaveBeneficiary(status);
                    }}
                    className={`relative inline-flex w-11 h-6 items-center rounded-full transition-all duration-300 ${
                      false && "cursor-not-allowed"
                    } ${saveBeneficiary ? "bg-[#00C46C]" : "bg-[#C8C8C8]"}`}
                  >
                    <span
                      className={`inline-block w-5 h-5 transform rounded-full bg-white ${
                        saveBeneficiary ? "absolute right-0.5" : "ml-0.5"
                      }`}
                    />
                  </Switch>
                </div>
              </div>
            </section>

            <ButtonLoader
              type="submit"
              onClick={sendTrasfer}
              className="btn bg-shiga-black text-white btn-lg btn-block mt-11"
            >
              Confirm & Send
            </ButtonLoader>
          </main>
        </div>
      </div>
    </div>
  );
}

export default TransferDetails;
