import { ReactComponent as IconAdd } from "assets/images/icons/Add.svg";
import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-gray.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-avatar.svg";
import useTransfersProvider from "../../context/useTransfersProvider";
import Maybe from "components/common/Maybe";

const BeneficiarySelectionView = () => {
   const {
      beneficiaries,
      trfCurrency,
      incrementSingleTrfStep,
      navigateSingleTrfStep,
      ngnForm,
      setNgnForm,
      isNgnTransfer,
      isUsdTransfer,
      isAudTransfer,
      isGbpTransfer,
      usdForm,
      setUsdForm,
      beneficiaryQuery,
   } = useTransfersProvider()

   const filteredBeneficiaries = beneficiaries?.filter((item: any) => {
      if (beneficiaryQuery === '') {
         return true;
      } else {
         return item?.accountName?.toLowerCase()?.includes(beneficiaryQuery.toLowerCase())
      }
   })

   const getInitials = (str: string) => {
      const words = str.replace(/[^a-zA-Z\s]/g, '').split(' ');
      const initials = words?.slice(0, 3).map(word => word.charAt(0)).join('');
      return initials.toUpperCase();
   }

   const searchIsActive = beneficiaryQuery?.length > 0;
   const emptySearchResults = searchIsActive && filteredBeneficiaries?.length === 0;

   const proceedWithBeneficiary = (beneficiary: any) => {

      if (isNgnTransfer) {
         const { accountName, accountBank: bankName, bankCode, accountNo: accountNumber } = beneficiary
         setNgnForm({
            ...ngnForm,
            accountName,
            bankName,
            bankCode,
            accountNumber
         })
      }

      if (isUsdTransfer) {
         const { accountName, accountBank: bankName, bankCode, accountNo: accountNumber } = beneficiary
         setUsdForm({
            ...usdForm,
            accountName,
            bankName,
            bankCode,
            accountNumber
         })
      }

      if (isGbpTransfer) {
         // 
      }

      if (isAudTransfer) {
         // 
      }
      navigateSingleTrfStep(3);
   }

   return (
      <div className='w-full'>

         <Maybe condition={!searchIsActive}>
            <div onClick={incrementSingleTrfStep} className="cursor-pointer w-full flex-between items-center py-7">
               <div className="flex items-center">
                  <div className="w-12 h-12 rounded-full bg-shiga-gray-25 flexed">
                     <IconAdd />
                  </div>

                  <p className="mb-0 ml-4 text-shiga-dark-100 lg:text-[15px]">Add a new recipient</p>
               </div>

               <IconRight />
            </div>
         </Maybe>

         <div className="mt-1">
            <p className="font-medium text-shiga-gray-200 border-b border-shiga-gray-50 pb-2">
               {emptySearchResults ? 'No results found' : searchIsActive ? 'Search results' : 'Saved beneficiaries'}
            </p>

            <div className="space-y-6 mt-6">
               {filteredBeneficiaries?.map((item: any) => (
                  <div onClick={() => proceedWithBeneficiary(item)} key={item?.id} className="cursor-pointer w-full flex-between items-center">

                     <div className="flex items-center">
                        <div className="w-12 h-12 rounded-full bg-card-grey flexed font-semibold relative">
                           {getInitials(item?.accountName)}

                           <div className="absolute -right-1.5 bottom-0">
                              <img
                                 width={15}
                                 alt="flag"
                                 src={`/flags/${trfCurrency?.toLowerCase()}.svg`}
                              />
                           </div>
                        </div>

                        <div className="ml-5">
                           <p className="mb-0 text-black lg:text-[15px] capitalize font-medium">{item?.accountName?.toLowerCase()}</p>
                           <p className="text-shiga-gray-300 text-sm">
                              {`${trfCurrency} account ending in ${item?.accountNo?.slice(-4)}`}
                           </p>
                        </div>

                     </div>
                     <IconRight />
                  </div>
               ))}
            </div>

            <Maybe condition={filteredBeneficiaries?.length === 0}>
               <div className="w-full flexed flex-col py-8">
                  <IconUserProfile />
                  <p className="text-shiga-gray-300 pt-5 text-center">
                     You currently have no beneficiary saved for this currency.
                  </p>
               </div>
            </Maybe>

            <Maybe condition={emptySearchResults}>
               <div onClick={incrementSingleTrfStep} className="mt-6">
                  <div className="cursor-pointer w-full flex-between items-center">

                     <div className="flex items-center">
                        <div className="w-12 h-12 rounded-full bg-shiga-gray-25 flexed">
                           <IconAdd />
                        </div>

                        <div className="ml-4">
                           <p className="mb-0 text-black lg:text-[15px] font-medium">Add a new recipient</p>
                           <p className="text-shiga-gray-300 text-sm">
                              Enter their bank account details
                           </p>
                        </div>

                     </div>
                     <IconRight />
                  </div>
               </div>
            </Maybe>

         </div>

      </div>
   )
}

export default BeneficiarySelectionView