import { useEffect, useState } from 'react'
import useTransfersProvider from '../context/useTransfersProvider'
import TransferFlowWrapper from '../layout/TransferFlowWrapper';
import Maybe from 'components/common/Maybe';
import UploadBulkRecipients from './UploadBulkRecipients';

const BulkTransferHomepage = () => {
   const { bulkTrfStep } = useTransfersProvider();
   const [header, setheader] = useState({ title: '', subtitle: '' });

   useEffect(() => {
      if (bulkTrfStep === 1) {
         setheader({ title: 'Upload Bulk Recipients', subtitle: '' });
      } else if (bulkTrfStep === 3) {
         setheader({ title: 'Confirm bulk payment to 25 recipients', subtitle: '' });
      }
      // eslint-disable-next-line
   }, [bulkTrfStep])

   return (
      <>
         <Maybe condition={bulkTrfStep !== 2}>
            <TransferFlowWrapper title={header.title} subtitle={header.subtitle}>

               <Maybe condition={bulkTrfStep === 1}>
                  <UploadBulkRecipients />
               </Maybe>

            </TransferFlowWrapper>
         </Maybe>

         <Maybe condition={bulkTrfStep === 2}>
            <p>Confirming bulk payment to 25 recipients...</p>
         </Maybe>
      </>
   )
}

export default BulkTransferHomepage