import { RootState } from "store/store";
import * as actions from "./actionTypes";
import { Refund } from "types/refund";
import { PaginatedApiResponse } from "types/data-interface";

interface InitialState {
    isLoadingRefund: boolean;
    refunds: PaginatedApiResponse<Refund[]> | null;
    createRefundLoading: boolean;
    createRefundData: any;
}

const initialState: InitialState = {
    isLoadingRefund: false,
    refunds: null,
    createRefundLoading: false,
    createRefundData: null
}


export default function reducer(state = initialState, action: any): InitialState {
    switch (action.type) {

        case actions.CREATE_REFUND_START:
            return {
                ...state,
                createRefundLoading: true,
                createRefundData: null
            }

        case actions.CREATE_REFUND_DONE:
            return {
                ...state,
                createRefundLoading: false,
                createRefundData: action.payload,
            }

        case actions.CREATE_REFUND_FAILED:
            return {
                ...state,
                createRefundLoading: false,
                createRefundData: action.payload,
            }

        case actions.GET_REFUNDS:
            return {
                ...state,
                refunds: action.payload,
                isLoadingRefund: false
            }
        default:
            return state;
    }
}


export const getRefundsStore = (state: RootState) => state.refunds;