import React from "react";
import isReactFragment from "utils/isReactFragment";
import { Menu } from "@headlessui/react";
import { ReactComponent as IconOption } from "assets/images/icons/Option.svg";
// import { ReactComponent as IconCaretDropdownIndicator } from "assets/images/icons/project-icons/caret-dropdown-indicator.svg";

/**
 * How to use
 * import Dropdown from "utils/dropdown";
 * <Dropdown>
    <></>
    <div>
        <div className="dropdown-item dropdown-item-icon">
            <IconResend />
            Resend Invite
        </div>
    </div>
</Dropdown>
 */

const Dropdown = (props) => {
  return (
    <Menu
      as="div"
      className={`relative ${props?.className ?? ""} ${
        props?.disabled ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <div className={"w-max group relative block " + (props.className ?? "")}>
        <Menu.Button
          as="div"
          // className="cursor-pointer"
          className={`cursor-pointer ${
            props?.disabled ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {isReactFragment(props.children[0].type) && (
            <IconOption
              style={{ height: "26px" }}
              className={`w-8 px-1.5 py-0.5 relative cursor-pointer group-hover:bg-gray-200 rounded-lg ${
                props?.rotate ? "transform rotate-90" : ""
              } ${props.dropdownIconClassName}`}
            />
          )}

          {!isReactFragment(props.children[0].type) && props.children[0]}
        </Menu.Button>

        <Menu.Items
          as="div"
          className={"dropdown z-[9999] " + (props.dropdownClass ?? "")}
        >
          <div className="dropdown-content">
            {/* <Menu.Item> */}
            {props.children ? props.children[1] : ""}
            {/* </Menu.Item> */}
          </div>
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default Dropdown;
