import { ReactComponent as EmptyPaymentLinkIcon } from "assets/images/icons/payment/empty-payment-links.svg";
import { EmptyState } from "components/common/EmptyState";
import { PageHeading } from "components/common/PageHeading";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { PAYMENT_PATHS } from "../paths";
import { useSharedStore } from "zustand-store";
import { PaymentLinkTable } from "./table/PaymentLinkTable";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import { processParams } from "utils/processParams";
import useUrlQueryParams from "utils/useUrlQueryParams";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { faTruckMedical } from "@fortawesome/free-solid-svg-icons";

export const PaymentLinks = () => {
  // const { apiLoading: loading } = useSharedStore();
  const [loading, setLoading] = useState(false);
  const { urlQueryParams } = useUrlQueryParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { fetchPaymentLinks, paymentLink, paymentLinks } =
    usePaymentLinkStore();
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 9,
  });

  const fetchLinks = async () => {
    setLoading(true);
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(
      urlQueryParams,
      params,
      "limit",
      "number",
      9,
      "limit"
    );
    params = processParams(
      urlQueryParams,
      params,
      "status",
      "string",
      "",
      "status"
    );

    params = processParams(
      urlQueryParams,
      params,
      "search",
      "string",
      "",
      "search"
    );
    params = processParams(
      urlQueryParams,
      params,
      "startDate",
      "string",
      "",
      "startDate"
    );
    params = processParams(
      urlQueryParams,
      params,
      "endDate",
      "string",
      "",
      "endDate"
    );

    setListParams(params);
    fetchPaymentLinks(params);

    try {
      await fetchPaymentLinks(params);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinks();
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="w-full">
      <div className="pt-5">
        <PageHeading
          title="Payment Link"
          cta={{
            name: "Create a link",
            link: PAYMENT_PATHS.CREATE_PAYMENT_LINK,
          }}
        />
      </div>

      <PaymentLinkTable paymentLinks={paymentLinks} loading={loading} />
    </div>
  );
};
