import FormHeader from "components/common/form/FormHeader";

interface PurchaseInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  info: InfoRowProps[];
  header?: string;
  transactionType?: string;
  children?: React.ReactNode;
  noTitle?: boolean;
}

const PurchaseInfo: React.FC<PurchaseInfoProps> = ({
  noTitle,
  info,
  className,
  transactionType,
  children,
  header,
  ...props
}) => {
  let items: InfoRowProps[] = [
    { attribute: "Transaction Type", value: transactionType } as InfoRowProps,
    ...info,
  ];
  if (noTitle) {
    items = [...info];
  }
  return (
    <>
      {header?.length ? <FormHeader>{header}</FormHeader> : <></>}
      <div
        className={`border border-outline-grey rounded-[20px] pt-3  ${
          className ?? ""
        }`}
        {...props}
      >
        {items.map((detail, index) => (
          <div key={index}>
            <InfoRow attribute={detail.attribute} value={detail.value} />
            {index !== items.length - 1 && (
              <div className="border-b border-outline-grey my-1 w-[376px] mx-auto" />
            )}
          </div>
        ))}
        {children}
      </div>
    </>
  );
};

export interface InfoRowProps {
  attribute: string;
  value: string | number | React.ReactNode;
}

const InfoRow = ({ attribute, value }: InfoRowProps) => {
  return (
    <div className="px-3 py-2 flex justify-between items-center gap-5 text-base">
      <p className="font-light text-shiga-dark-2">{attribute}</p>
      <p className="font-normal text-shiga-black-2 text-right max-w-[70%]">
        {value}
      </p>
    </div>
  );
};

export default PurchaseInfo;
