import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconCalendar } from "assets/images/icons/Calendar.svg";
import { ReactComponent as IconRectangle } from "assets/images/icons/Rectangle5.svg";
import DeleteCard from "assets/images/bgs/DeleteCardImg.png";
import { ReactComponent as IconDollar } from "assets/images/icons/Dollar.svg";
import { useCardStore } from "zustand-store/cards";
import { deleteCard } from "lib/cards";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { formatDate } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";

export const DeleteCardModal: React.FC<{
  isOpen: boolean;
  onDelete?: () => void;
  setIsOpen: (arg: boolean) => void;
}> = ({ setIsOpen, isOpen, onDelete }) => {
  const { selectedCard } = useCardStore();
  const handleDelete = () => {
    toast.success("Card successfully deleted")
    setIsOpen(false)
    if(onDelete){
      onDelete()
    }
    // deleteCard(selectedCard.id).catch(err => {
    //   toast.error(`Error: ${err.message}`)
    // })
  }
  const cardDetails = useMemo(() => [
    {
      heading: "Created",
      detail: selectedCard.createdAt ? formatDate(selectedCard.createdAt) : "02 Jan, 2024・22:48",
      icon: <IconCalendar className="w-4 h-4" />,
    },
    {
      heading: "Assigned to",
      detail: selectedCard.name || "John Doe",
      icon: <IconRectangle className="w-4 h-4" />,
    },
    {
      heading: "Total spend",
      detail: selectedCard.balance ? currencyFormat(Number(selectedCard.balance),  selectedCard.currency): "6,000 NGN",
      icon: <IconDollar className="w-4 h-4" />,
    },
  ], [])

  return (
    <FormDrawer
      display="center"
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="p-8 pb-6">
        <div className="pb-6 flex items-center justify-between">
          <div className="text-2xl font-ojah font-medium">
            Delete this Card
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
          >
            <IconClose className="m-auto svg-stroke-gray" />
          </div>
        </div>
        <div className="p-6 flex">
          <div className="w-[114px] h-[114px]">
            <img src={DeleteCard} alt="Dashboard" className="w-full h-full" />
          </div>
          <div className="ml-6">
            <div className="font-medium mb-3">Naira Card *** 9090</div>
            <div className="space-y-3">
              {cardDetails.map((item, idx) => (
                <div className="flex" key={idx}>
                  {item.icon}
                  <div className="ml-2 flex">
                    <div className="text-sm text-shiga-gray mr-1">
                      {item.heading}
                    </div>
                    <div className="text-sm text-[#99999C]">
                      {item.detail}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-100 p-6 pt-3">
        <button className="btn btn-block btn-lg btn-shiga-warn" onClick={handleDelete}>
          Yes, Delete
        </button>
      </div>
    </FormDrawer>
  );
};

export default DeleteCardModal;
