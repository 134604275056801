import { ReactComponent as IconTrailing } from "assets/images/icons/Trailing.svg";
import { Link } from "react-router-dom";

interface OverviewProps<K> {
  details: K[];
  containerStyle?: string;
  EmptyComponent: React.ReactNode;
  title: string;
  RenderComponent: React.FC<{
    item: K;
    onClick?: () => void
  }>;
  link: string;
  onClick?: (arg: K) => void;
}


export function OverviewCard<K>({ details, EmptyComponent, title, RenderComponent, onClick, containerStyle, link }: OverviewProps<K>) {

  return (
    <div className={containerStyle}>
      <div className="py-4 border-b border-gray-100 flex items-center justify-between">
        <h3 className="page-title !text-3xl font-ojah font-bold">{title}</h3>
        <Link to={link}>
          <button className="!w-fit form-input !h-10 !text-sm flex items-center justify-between !bg-white !border !border-gray-200 !rounded-xl">
            <p>See All</p>
            <div className="px-1.5">
              <IconTrailing />
            </div>
          </button>
        </Link>
      </div>
      {
        details.length ? <section className="flex flex-col">
          {details.map((item, idx) => (
            <RenderComponent item={item} onClick={onClick ? () => onClick(item) : undefined} key={idx} />
          ))}
        </section> : <div className="h-80">
          {EmptyComponent}
        </div>
      }
    </div>
  )
}
