import PATHS from 'NavigationRoute';
import useToolkit from './useToolkit';


const useRoutes = () => {
  const { kybVerified, isSandboxEnv } = useToolkit();

  const sandboxPermit = isSandboxEnv ? true : kybVerified;

  const dashboardRoutes: SidebarRouteProps[] = [
    {
      title: 'Dashboard',
      path: PATHS.DASHBOARD,
      permission: true,
    },
    {
      title: 'Transactions',
      path: PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE,
      permission: sandboxPermit
    },
    {
      title: 'Settlements',
      path: PATHS.SETTLEMENTS.SETTLEMENTS_HOMEPAGE,
      permission: sandboxPermit
    },
    {
      title: 'Refunds',
      path: PATHS.REFUNDS.REFUNDS_HOMEPAGE,
      permission: sandboxPermit
    },
    {
      title: 'Payments',
      path: PATHS.PAYMENTS.PAYMENT_LINK,
      permission: sandboxPermit
    },
    {
      title: 'Cards',
      path: PATHS.CARDS.CARDS_HOMEPAGE,
      permission: sandboxPermit
    },
    {
      title: 'Accounts',
      path: PATHS.ACCOUNTS,
      permission: sandboxPermit
    },
    {
      title: 'Manage Bills',
      path: PATHS.BILLS.AIRTIME,
      permission: sandboxPermit
    },
    {
      title: 'Ojah',
      path: '/ojahxyz',
      permission: false
    },
    {
      title: 'Settings',
      path: PATHS.SETTINGS.USER_PROFILE,
      permission: true
    }
  ]

  return { dashboardRoutes }
}

export default useRoutes