import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "components/ui/form";

import { Checkbox } from "components/ui/checkbox";
import { Switch } from "components/ui/switch";
import { useNavigate } from "react-router-dom";
import { defaultPayload, useTuitionStore } from "zustand-store/payments/tution";
import { useEffect, useState } from "react";
import { createTuition } from "lib/payments/tutions";
import PurchaseInfo, { InfoRowProps } from "pages/bills/PurchaseInfo";
import { toast } from "react-toastify";
import FormHeader from "components/common/form/FormHeader";
import { currencyFormat } from "lib/format.amount";
import useToolkit from "hooks/useToolkit";
import PATHS from "NavigationRoute";

const FormSchema = z.object({
  save_as_beneficiary: z.boolean().default(false).optional(),
  accept_tc: z.boolean().default(false),
});

export const ReviewPay = () => {
  const {
    payload: {
      paymentTo,
      university: { bankName, ...university },
      ...payload
    },
    setPayload,
  } = useTuitionStore();
  const [keyValue, setKeyValue] = useState<InfoRowProps[]>([]);
  const { showCustomSuccessToast } = useToolkit();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {},
  });

  const formattedAmount = payload?.currency
    ? currencyFormat(payload?.amount || 0, payload?.currency)
    : currencyFormat(payload?.amount || 0, "GBP");

  useEffect(() => {
    setKeyValue([
      {
        attribute: "Amount",
        value: formattedAmount,
      },
      { attribute: "Account Number", value: university.accountNumber },
      { attribute: "Sort Code", value: university.sortCode },
      { attribute: "Bank Name", value: bankName },
      {
        attribute: "Fee",
        value: "Free 😎",
      },
      {
        attribute: "Note",
        value: payload.student?.note || "----",
      },
    ]);
  }, [payload, bankName, university]);
  const navigate = useNavigate();
  const acceptTC = form.watch("accept_tc");
  const onSubmit = (data: z.infer<typeof FormSchema>) =>
    createTuition({
      ...payload,
      university: {
        ...university,
        name: bankName,
      },
      country: university.country,
      student: {
        ...payload.student,
        reference: payload?.student?.invoiceNumber || "100101",
      },
      type: paymentTo === "Accomodation" ? "ACCOMODATION" : "SCHOOL",
    })
      .then(() => {
        showCustomSuccessToast({
          message: `You have successfully sent ${payload.amount?.toLocaleString()} to ${paymentTo}`,
          onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);

        setPayload(defaultPayload);
      })
      .catch((err) => {
        toast.error(
          `Error: ${err.response.data.message.toString() || err.message}`
        );
      });

  return (
    <div className="w-full space-y-4 flex flex-col justify-center items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <FormHeader>
            {`Confirm your payment to ${university.universityName}`}
          </FormHeader>
          <PurchaseInfo transactionType="Tuition Payment" info={keyValue}>
            <FormField
              control={form.control}
              name="save_as_beneficiary"
              render={({ field }) => (
                <FormItem className="flex w-full rounded-b-[20px] px-[12px] py-6 flex-row items-center bg-neutral-50  justify-between ">
                  <div className="space-y-0.5">
                    <FormLabel className="text-zinc-800 text-base font-medium font-['Inter']">
                      Save as a beneficiary
                    </FormLabel>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </PurchaseInfo>
          <FormField
            control={form.control}
            name="accept_tc"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0  p-4">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel className="w-[378px] text-neutral-400 text-[13px] font-normal font-['Inter']">
                    By checking this box, you confirm that the information
                    provided for tuition payment is accurate and complete.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />

          <Button
            className="w-full"
            disabled={!acceptTC || form.formState.isSubmitting}
            isLoading={form.formState.isSubmitting}
            type="submit"
          >
            Confirm & Pay
          </Button>
        </form>
      </Form>
    </div>
  );
};
