import { useEffect } from "react";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import useToolkit from "hooks/useToolkit";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import SendToBankIconEnable from "assets/images/icons/send_to_bank_enable.png";
import { getNgnDepositAccount } from "store/accounts/action";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModalProps {
  close: () => void;
}
const NGNAddMoneyForm = ({ close }: ModalProps) => {

  const { toastInfo, dispatch, useSelector, businessProfile } = useToolkit();
  const { ngnDepositAccountLoading: loading, ngnDepositAccount } = useSelector((s: any) => s.accounts);

  const handleCopy = () => {
    navigator.clipboard.writeText(ngnDepositAccount?.data?.accountNumber);
    toastInfo('Account number copied!');
  }

  const idSource = businessProfile?.bankAccounts?.find((account: any) => account?.accountType === 'Current' && account?.currency === 'NGN')
  const depositAccountId = idSource?.id;

  useEffect(() => {
    dispatch(getNgnDepositAccount(depositAccountId));
    // eslint-disable-next-line
  }, []);


  return (
    <div className="w-full">

      <div className="pb-3 flex items-center justify-between border-b border-gray-100">
        <div className="text-2xl font-ojah font-medium">
          <img src="/flags/ngn.svg" alt="ngn-flag" />
        </div>
        <div onClick={() => close()}
          className="w-8 h-8 bg-shiga-gray-40 flex rounded-full cursor-pointer">
          <IconClose className="m-auto svg-stroke-gray" />
        </div>
      </div>

      <Maybe condition={loading}>
        <div className="py-5 flexed flex-col">
          <FontAwesomeIcon icon="spinner" spin className="text-5xl" />
          <span className="text-sm lg:text-lg mt-3">
            Fetching account details
          </span>
        </div>
      </Maybe>

      <Maybe condition={!loading}>

        <div className="w-full pt-5">
          <p className="mb-3 font-ojah font-bold text-lg lg:text-3xl">Your NGN account details</p>

          <p className="text-shiga-dark-100 text-sm lg:text-lg">
            Use the account below to receive payments in this currency in to your Shiga business account.
          </p>

          <div className="w-full bg-shiga-gray-40 p-4 pb-2 rounded-xl relative mt-10">

            <div className="w-full flex items-start justify-between font-medium mb-3">
              <p className="text-sm text-shiga-gray-300 whitespace-nowrap">Account Name</p>
              <p className="text-sm text-shiga-dark-100 text-right">
                {ngnDepositAccount?.data?.accountName}
              </p>
            </div>

            <div className="w-full flex items-center justify-between font-medium py-3 border-y border-shiga-gray-50 mb-3">
              <p className="text-sm text-shiga-gray-300">Account Number</p>
              <p className="text-sm text-shiga-dark-100 flex">
                {ngnDepositAccount?.data?.accountNumber}

                <Maybe condition={ngnDepositAccount?.data?.accountNumber?.length > 0} >

                  <span onClick={handleCopy} className="cursor-pointer ml-2 text-shiga-gray-100">
                    <IconCopy />
                  </span>
                </Maybe>
              </p>
            </div>

            <div className="w-full flex items-center justify-between font-medium mb-2">
              <p className="text-sm text-shiga-gray-300">Bank Name</p>
              <p className="text-sm text-shiga-dark-100">
                {ngnDepositAccount?.data?.bankName}
              </p>
            </div>
          </div>

          <div className="border-b w-full relative mt-7">
            <span className="absolute -bottom-3 left-1/2 transform -translate-1/2 bg-white px-2.5 text-shiga-gray-300 text-[13px]">
              OR
            </span>
          </div>

          <div className="w-full border-2 border-shiga-gray-50 mt-7 rounded-2xl px-3 py-4 flex items-center cursor-not-allowed">

            <div className="mr-2 w-1/5">
              <img src={SendToBankIconEnable} alt="acct" width={45} />
            </div>

            <div>
              <div className="flex items-center">
                <p className="mb-0 font-inter font-semibold lg:text-lg text-shiga-dark-100">
                  Fund with Direct Deposit
                </p>

                <span className="border border-shiga-gray-75 text-shiga-gray-300 rounded-md ml-3 py-px px-[5px] flexed w-max text-[9px] capitalize font-inter">
                  Coming Soon
                </span>
              </div>

              <p className="mb-0 text-shiga-gray-300 text-sm mt-1">
                Transfer money directly from your bank account to your business account.
              </p>

            </div>
          </div>
        </div>
      </Maybe>

    </div>
  );
};

export default NGNAddMoneyForm;
