import { currencyFormat } from "lib/format.amount";
import { formatDate } from "lib/format.date";
import { findInvoice } from "lib/payments/invoices";
import { useEffect, useMemo, useRef, useState, RefObject } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CurrencyType } from "types/misc";
import { ReviewItem } from "./forms/components/ReviewItem";
import { ReactComponent as IconEllipsisHorizontal } from "assets/images/icons/ellipsis-horizontal.svg";
import { Invoice, defaultInvoice } from "types/payment/invoice";
import { Separator } from "components/ui/separator";
import { ArrowLeft } from "lucide-react";
import { Button } from "components/ui/button";
import useBackButton from "hooks/useBackButton";
import { InvoiceDropdown, getStatusColor } from "./table/InvoiceColumns";
import { Badge } from "components/ui/badge";
import { removeUnderscore } from "lib/remove-underscore";
import { useAppSelector } from "store/hooks";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { InvoiceFile } from "./table/InvoiceFile";
import { useReactToPrint } from "react-to-print";

export const ViewInvoice = () => {
  const id = sessionStorage.getItem("view-invoice");
  const [invoice, setInvoice] = useState<Invoice>(defaultInvoice);
  const [loading, setLoading] = useState(false);
  const invoiceRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef?.current,
    documentTitle: "Shiga Business Invoice " + invoice.createdAt,
  });

  const {
    userInfo: { businessProfile },
  } = useAppSelector(getAuthPersist);

  const total = useMemo(() => {
    return invoice.items.length > 0
      ? invoice.items.reduce((acc, item) => acc + item.price * item.qty, 0)
      : 0;
  }, [invoice.items]);

  const additionalCalc = useMemo(
    () => ({
      discount: total * ((invoice.discount || 0) / 100),
      vat: total * ((invoice.vat || 0) / 100),
    }),
    [invoice.discount, invoice.vat, total]
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      findInvoice(id)
        .then((res) => {
          const date = new Date();
          setInvoice({
            ...res.data,
            status:
              date.getTime() > new Date(res.data.endDate).getTime() &&
              res.data.status !== "PAID"
                ? "DUE"
                : res.data.status.toLowerCase(),
          });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (!id) {
    return <Navigate to={"/payments"} />;
  } else if (loading) {
    return <div>loading....</div>;
  }

  return (
    <div className=" p-10 w-full space-y-4">
      <Header invoice={invoice} handlePrint={handlePrint} setSelectedInvoice={setInvoice}  />
      <Separator />
      {/* <div ref={componentRef}>
        <InvoiceFile invoice={invoice} />
      </div> */}
      <InvoiceFile invoice={invoice} invoiceRef={invoiceRef} />
    </div>
  );
};

const Header = ({
  invoice,
  handlePrint,
  setSelectedInvoice,
}: {
  invoice: Invoice;
  handlePrint?: () => void;
  setSelectedInvoice: (invoice: Invoice) => void;
}) => {
  const { goBack } = useBackButton();
  const navigate = useNavigate();

  return (
    <div className=" w-full grid grid-cols-2">
      <Button
        size="icon"
        onClick={goBack}
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <ArrowLeft size={18} />
      </Button>
      <div className="flex items-center gap-6 w-full justify-end">
        <div className="flex items-center gap-2">
          <p className="text-zinc-800 text-[15px] font-medium font-['Inter']">
            Status
          </p>
          <Badge
            className={`rounded-md capitalize ${getStatusColor(
              removeUnderscore({ actionWord: invoice.status })
            )}`}
            variant="outline"
          >
            {removeUnderscore({ actionWord: invoice.status })}
          </Badge>
        </div>
        <InvoiceDropdown
          DropdownTrigger={
            <Button
              variant="ghost"
              className="flex bg-[#f5f5f5] size-8 p-0 data-[state=open]:bg-muted"
            >
              <IconEllipsisHorizontal className="size-4" />
              <span className="sr-only">Open menu</span>
            </Button>
          }
          invoice={invoice}
          onDelete={() => {
            navigate("/payments/invoice");
          }}
          handlePrint={handlePrint}
          setSelectedInvoice={setSelectedInvoice}
        />
      </div>
    </div>
  );
};
