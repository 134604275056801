import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Separator } from "components/ui/separator";
import { CheckCircle2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "pages/payments/paths";
import { useSharedStore } from "zustand-store";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { Input, DateForm, SelectInput } from "components/common/form";
import CommandSelect from "components/ui/command-select";
import TextArea from "components/common/form/TextArea";

const formSchema = z.object({
  title: z.string().min(2, {
    message: "Title must be at least 2 characters.",
  }),
  country: z.string({
    required_error: "country must be at least 2 characters.",
  }),
  note: z.string().optional(),
  startDate: z.date({
    required_error: "Start Date  is required.",
  }),
  endDate: z.date({
    required_error: "End Date is required.",
  }),
});

export function AddInvoiceDetailsForm() {
  const { invoice, setCompleted, setInvoice } = useInvoiceStore();

  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  // Filter currencies based on wallet data
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: invoice?.title || "",
      country: invoice?.country || "",
      note: invoice?.description || "",
    },
  });
  const navigate = useNavigate();

  const startDate = form.watch("startDate");

  function onSubmit({
    country,
    endDate,
    startDate,
    title,
    note,
  }: z.infer<typeof formSchema>) {
    setInvoice({
      ...invoice,
      country,
      title,
      description: note || "",
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    setCompleted("invoice_details");
    navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=receipient`);
  }
  const handleDateDisable = (date: Date) => {
    if (startDate) {
      return date.getTime() < startDate?.getTime();
    }
    return date < new Date();
  };

  return (
    <div className="flex  justify-center">
      <div className="py-6 w-[412px] space-y-4 ">
        <div className="page-title text-[32px] font-normal">
          Add invoice details
        </div>
        <Separator />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      label="Invoice title"
                      required
                      placeholder="Enter a name"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <SelectInput
                    type="text"
                    label="Country"
                    placeholder="Search for Country"
                    required={true}
                    {...field}
                  >
                    {filteredCurrencies.map((item, index) => (
                      <option value={item.country} key={index}>
                        <div className="flex items-center gap-1.5">
                          <img
                            src={item?.icon || noisygradient}
                            alt={item.name}
                            className="size-5"
                          />
                          {item.country}
                        </div>
                      </option>
                    ))}
                  </SelectInput>
                </FormItem>
              )}
            />
            <div className="my-[1.75rem]">
              <TextArea
                placeholder="more information about this invoice"
                label="Description (Optional)"
                form={form as any}
              />
            </div>

            <div className="flex space-x-4 justify-between mb-[1.75rem]">
              <DateForm
                handleDisable={handleDateDisable}
                form={form as any}
                field="startDate"
                label="Start Date"
                placeholder="DD/MM/YY"
              />
              <DateForm
                handleDisable={handleDateDisable}
                form={form as any}
                placeholder="DD/MM/YY"
                field="endDate"
                label="End Date"
              />
            </div>
            <Button
              disabled={!form.formState.isValid}
              className="w-full mt-3"
              type="submit"
            >
              Continue
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
