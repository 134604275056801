import useTransfersProvider from './context/useTransfersProvider'
import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-gray.svg";
import singleTrf from "assets/images/icons/send_to_bank_enable.png";
import bulkTrf from "assets/images/icons/send_bulk_enabled.png";
import TransferFlowWrapper from './layout/TransferFlowWrapper';

const TransferTypeSelector = () => {

   const { setTransferType } = useTransfersProvider()

   const transferTypes = [
      {
         title: 'Send to a Bank Account',
         subtitle: 'Send money from your Shiga account to any bank account instantly',
         value: 'SINGLE',
         icon: singleTrf
      },
      {
         title: 'Make a Bulk Payment',
         subtitle: 'Send money from your Shiga account to multiple bank accounts at the same time',
         value: 'BULK',
         icon: bulkTrf
      }
   ]


   return (
      <TransferFlowWrapper
         title='Select a transfer type'
         subtitle='How would you like to make your transfer?'>
         <div className="space-y-6">
            {transferTypes.map((type, index) => (
               <div key={index}
                  className='w-full cursor-pointer flex items-center rounded-[20px] px-5 py-3 
                                                            border border-outline-grey-2 text-shiga-gray-300'
                  onClick={() => setTransferType(type.value)}>

                  <div className="mr-3.5">
                     <img
                        width={55}
                        alt="transfer"
                        src={type.icon}
                     />
                  </div>

                  <div>
                     <div className="text-[16px] text-shiga-dark-100 font-semibold">
                        {type.title}
                     </div>
                     <div className="text-sm font-medium text-shiga-gray-300">
                        {type.subtitle}
                     </div>
                  </div>

                  <div>
                     <IconRight />
                  </div>
               </div>
            ))}
         </div>
      </TransferFlowWrapper>
   )
}

export default TransferTypeSelector