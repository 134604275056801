import React from 'react';
import ReactDOM from 'react-dom/client';

import store, { persistor } from "store/store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './utils/stringUtils'


import App from './App';

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import 'react-tooltip/dist/react-tooltip.css'
import 'assets/css/index.css';
import 'assets/css/custom.css';
import 'animate.css';
import { CustomToastProvider } from 'utils/context/custom-toast/ToastContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
    <>
        <ToastContainer
            autoClose={5000}
            position="top-center"
            icon={({ type }) => {
                if (type === "success") return "✅";
                if (type === "error") return "🛑";
                else return "ℹ️";
            }}
        />

        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <CustomToastProvider>
                    <App />
                </CustomToastProvider>
            </PersistGate>
        </Provider>
    </>
    // </React.StrictMode>
);