import { ChangeEvent, useEffect, useMemo, useState } from "react";
import InfoIcon from "assets/images/icons/Info";
import {
  ButtonLoader,
  Checkbox,
  Input,
  SelectInput,
} from "components/common/form";
import { networkProviders } from "data/constants/common";
import PurchaseInfo from "./PurchaseInfo";
import StepHeader from "./StepHeader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getBillPaymentServices } from "store/bills-payment/reducer";
import { getAirtimeServices } from "store/bills-payment/actions";
import { getCurrenciesStore } from "store/ui/currencies/reducer";
import { z } from "zod";
import { CurrencyType } from "types/misc";
import { getCountriesStore } from "store/ui/countries/reducer";
import { getCountries } from "store/ui/countries/action";
// import { buyAirtime } from "lib/bill-payments";
import useBillsPayment from "lib/bill-payments/useBillsPayment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "components/common/form/FormHeader";
import { currencyFormat } from "lib/format.amount";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import CustomAmountField from "components/custom/CustomAmountField";
import { useSharedStore } from "zustand-store";
import { Wallet, defaultWallet } from "types/wallet";
import { getCurrentBalance } from "lib/business";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import useToolkit from "hooks/useToolkit";
import PATHS from "NavigationRoute";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface FormValues {
  amount: number;
  currency: string;
  mobileNo: string;
  debitAccountNumber: string;
  networkProviders: string;
}

const initialFormValues: FormValues = {
  amount: 0,
  currency: "NGN",
  mobileNo: "",
  debitAccountNumber: "",
  networkProviders: "",
};

const BuyAirtime = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageStep, setPageStep] = useState<number>(1);
  const { airtime: airtimeStore } = useAppSelector(getBillPaymentServices);
  const { currenciesList: currenciesListStore } =
    useAppSelector(getCurrenciesStore);
  const { countries: countriesStore } = useAppSelector(getCountriesStore);
  const {
    userInfo: { userProfile },
  } = useAppSelector(getAuthPersist);
  const [useRegisteredPhoneNo, setUseRegisteredPhoneNo] = useState(false);
  const { currencies } = useSharedStore();
  const [amount, setAmount] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [networkProviderName, setNetworkProviderName] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const [formV, setFormV] = useState<FormValues>(initialFormValues);
  const { buyAirtime, loadingAirtime } = useBillsPayment();
  const { showCustomSuccessToast } = useToolkit();
  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    dispatch(getAirtimeServices());
    // Load initial wallet balance
    updateCurrentBalance(formV.currency);
  }, [dispatch]);

  const airtime = useMemo(() => airtimeStore?.data || [], [airtimeStore]);

  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setAmount(value);
    setFormV((prevFormV) => ({
      ...prevFormV,
      amount: Number(value),
    }));
  };
  const updateCurrentBalance = (currency: string) => {
    getCurrentBalance(currency)
      .then((data: any) => {
        setCurrentWallet(data.data);
      })
      .catch((err) => {
        toast.error(`Unable to get balance: ${err.message}`);
      });
  };

  useEffect(() => {
    const curr = formV.currency;
    if (curr) {
      updateCurrentBalance(curr);
    }
  }, [formV.currency]);

  useEffect(() => {
    if (useRegisteredPhoneNo && userProfile?.phoneNumber) {
      setMobileNo(userProfile.phoneNumber);
      setFormV((prevFormV) => ({
        ...prevFormV,
        mobileNo: userProfile.phoneNumber,
      }));
    } else {
      setMobileNo("");
      setFormV((prevFormV) => ({
        ...prevFormV,
        mobileNo: "",
      }));
    }
  }, [useRegisteredPhoneNo, userProfile]);

  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(amount) > currentWallet?.balance;
  const insufficientAmount = amount.length > 0 && Number(amount) < 100;

  const isFormValid = () => {
    return (formV.amount && formV.currency && formV.mobileNo) ||
      formV.mobileNo.length < 11
      ? "Phone number must be at least 11 characters."
      : "" && formV.networkProviders;
  };
  useEffect(() => {
    isFormValid();
  }, [formV.mobileNo]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    const { amount, currency, mobileNo, networkProviders } = formV;
    if (mobileNo.length < 11) {
      setError("mobileNo", {
        type: "manual",
        message: "Phone number must be at least 11 characters",
      });
      return;
    }
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      buyAirtime({
        amount: Number(amount),
        currency: (currency as CurrencyType) || "NGN",
        networkType: networkProviders,
        phoneNumber: `${mobileNo}`,
        provider: networkProviders,
        debitAccountNumber: "0116359689",
        serviceCategoryId: networkProviders,
      })
        .then((data) => {
          showCustomSuccessToast({
            message: `You have successfully sent ${amount?.toLocaleString()} ${currency} ${networkProviderName} airtime to ${
              formV.mobileNo
            }`,
            onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
          });

          navigate("/dashboard");
        })
        .catch((err) => {
          toast.error(`Error: ${err.message}`);
        });
    }
  };

  return (
    <div>
      <StepHeader
        step={pageStep}
        goBack={() => {
          setPageStep((prev) => prev - 1);
        }}
        close={() => {
          setPageStep(1);
          setFormV(initialFormValues);
          setUseRegisteredPhoneNo(false);
        }}
      />

      <form onSubmit={onSubmit} className="w-[412px] mx-auto">
        <FormHeader>
          {pageStep >= 2
            ? `Confirm your purchase for ${networkProviderName} Airtime`
            : "Buy Airtime"}
        </FormHeader>

        {pageStep === 1 && (
          <>
            <div className="mb-[24px]">
              <SelectInput
                name="networkProviders"
                label="Select a Network provider"
                placeholder="Select a provider"
                required
                value={formV.networkProviders}
                onChange={(e: any) => {
                  const selectedProvider = airtime.filter(
                    (provider) => provider?.serviceCategoryId === e.target.value
                  );
                  setNetworkProviderName(selectedProvider[0].name);
                  setFormV((prevFormV) => ({
                    ...prevFormV,
                    networkProviders: e.target.value,
                  }));
                }}
              >
                {airtime.length ? (
                  airtime.map((provider, index) => {
                    return (
                      <option
                        key={provider.serviceCategoryId}
                        value={provider.serviceCategoryId}
                      >
                        <div className="flex items-center gap-1.5">
                          <img
                            src={provider?.logoUrl}
                            alt={provider.name}
                            className="size-5"
                          />
                          {provider?.name}
                        </div>
                      </option>
                    );
                  })
                ) : (
                  <option value="">No providers available</option>
                )}
              </SelectInput>
            </div>
            <div
            // className="mb-[24px]"
            >
              <div className="flex w-full justify-between">
                <span className=" text-shiga-gray-100">
                  Mobile Number <span className="form-input-required">*</span>
                </span>
                <Checkbox
                  type="checkbox"
                  label={"Use registered number"}
                  containerClass="!items-start text-shiga-gray text-sm"
                  checked={useRegisteredPhoneNo}
                  onChange={() => {
                    setUseRegisteredPhoneNo((prev) => !prev);
                    if (userProfile?.phoneNumber) {
                      setMobileNo(userProfile.phoneNumber);
                      setFormV((prevFormV) => ({
                        ...prevFormV,
                        mobileNo: userProfile.phoneNumber,
                      }));
                    } else {
                      setMobileNo("");
                      setFormV((prevFormV) => ({
                        ...prevFormV,
                        mobileNo: "",
                      }));
                    }
                  }}
                />
              </div>
              <Input
                type="number"
                disabled={useRegisteredPhoneNo}
                placeholder="08101234567"
                maxLength={11}
                value={formV.mobileNo}
                onChange={(e) => {
                  setFormV((prevFormV) => ({
                    ...prevFormV,
                    mobileNo: e.target.value,
                  }));
                }}
                // {...register("mobileNo", {
                //   required: "Mobile Number is required",
                //   minLength: { value: 10, message: "Mobile Number must be at least 10 characters" },
                //   maxLength: { value: 11, message: "Mobile Number cannot exceed 11 characters" },
                // })}
              />
              {/* {errors.mobileNo && (
                <span className="error">{errors.mobileNo.message}</span>
              )} */}
            </div>

            <div className="mb-[26px]">
              <CustomAmountField
                leftLabel="Amount"
                isFieldRequired
                setInputAmount={updateAmount}
                inputError={insufficientAmount || inputExceedsTrxAmount}
                errorText={
                  insufficientAmount
                    ? "The minimum amount you can purchase is 100 NGN"
                    : inputExceedsTrxAmount
                    ? "You cannot make a purchase more than your available balance"
                    : "The minimum amount you can purchase is 100 NGN"
                }
                flagIconUrl={NGN}
                inputAmount={amount.toString()}
                transactionCurrency={currentWallet?.currency || ""}
                rightLabel={
                  <p className="text-shiga-dark flex items-center font-inter">
                    You have &nbsp;
                    <span className="text-shiga-purple font-medium">
                      {`${Number(currentWallet?.balance).toLocaleString()} ${
                        currentWallet?.currency ?? ""
                      }`}
                    </span>
                  </p>
                }
              />
            </div>
          </>
        )}
        {pageStep >= 2 && (
          <PurchaseInfo
            transactionType="Bills Payment"
            info={[
              {
                attribute: "Amount",
                value: `${formV.amount} ${formV.currency}`,
              },
              {
                attribute: "Phone Number",
                value: formV.mobileNo,
              },
              {
                attribute: "Network Provider",
                value: networkProviderName,
              },
              {
                attribute: "Fee",
                value: "Free 😎",
              },
            ]}
            className="mb-[60px]"
          />
        )}
        <ButtonLoader
          type="submit"
          loading={loadingAirtime}
          disabled={
            !isFormValid() || inputExceedsTrxAmount || insufficientAmount
          }
          className="btn btn-lg btn-block btn-primary mt-3"
        >
          {pageStep >= 2 ? "Confirm & Purchase" : "Continue"}
        </ButtonLoader>
      </form>
    </div>
  );
};

export default BuyAirtime;
