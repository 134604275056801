import { ButtonLoader, Input, SelectInput } from "components/common/form";
import { z } from "zod";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import FormDrawer from "components/common/form-drawer";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormControl, FormField, FormItem } from "components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Currency } from "types/shared/countries.types";
// import { getCurrencies } from "lib/currencies";
import { getCountries } from "store/ui/countries/action";
import { getBanks } from "store/ui/banks/action";
import { getCurrencies } from "lib/constants";

const EditRecipientSchema = z.object({
    country: z.string(),
    currency: z.string(),
    bankName: z.string(),
    accountNumber: z.string()
})

export const EditTransfer: React.FC<{
    isOpen: boolean;
    setIsOpen: () => void;

}> = (props: any) => {
    const dispatch = useDispatch();
    const [currencies, setCurrencies] = useState<Currency[]>();
    const { banksLoading, banks } = useSelector((s: any) => s.ui.banks);
    const { countriesLoading, countries } = useSelector((s: any) => s.ui.countries);
    const { teamMemberFormLoading } = useSelector((s: any) => s.entities.settings);
    const { isOpen, setIsOpen } = props;

    const form = useForm<z.infer<typeof EditRecipientSchema>>({
        resolver: zodResolver(EditRecipientSchema),
        defaultValues: {
            accountNumber: "",
            bankName: "",
            country: "",
            currency: ""
        },
    });

    useEffect(() => {
        getCurrencies().then((res) => setCurrencies(res));
        dispatch(getCountries());
        dispatch(getBanks());
    }, []);

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        // const errors: any = validate(form);
        // if (errors) {
        //     for (var x in errors) {
        //         toast.error(errors[x]);
        //     };
        //     return;
        // }
        // else {
        //     if (type === "add") {
        //         dispatch(settingsActions.addTeamMember(form));
        //     }
        //     else if (type === "edit") {
        //         dispatch(settingsActions.editTeamMember(member?.id, form.role));
        //     }
        // }
    }

    return (
        <>
            <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="p-8 pb-6">
                    <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                        <div className="text-2xl font-ojah font-medium">
                            Edit Recipient
                        </div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                            <IconClose className="m-auto text-white svg-stroke-gray" />
                        </div>
                    </div>

                    <Form {...form}>
                        <form onSubmit={submitForm}>
                            <div className="mt-10 flex flex-col justify-between items-center space-y-6">
                                <div className="flex w-full space-x-6">
                                    <FormField
                                        control={form.control}
                                        name="country"
                                        render={({ field }) => (
                                            <SelectInput containerClass="w-[47%]" label="Country" required={true} value={field.value} onChange={field.onChange}>
                                                <option value="" disabled>Select a country</option>
                                                {countries?.data?.countries && Object.keys(countries?.data?.countries)?.map((key: string) =>
                                                    <option value={key} key={key}>{countries?.data?.countries[key]}</option>
                                                )}
                                            </SelectInput>
                                        )}
                                    />

                                    <FormField
                                        name="currency"
                                        control={form.control}
                                        render={({ field }) => (
                                            <SelectInput containerClass="w-[47%]" label="Currency" required={true} value={field.value} onChange={field.onChange}>
                                                <option value="" disabled>Select a currency</option>
                                                {
                                                    currencies?.map(item => (
                                                        <option value={item.country} key={item.id}>{item.country}</option>
                                                    ))
                                                }
                                            </SelectInput>
                                        )}
                                    />
                                </div>
                                <FormField
                                    control={form.control}
                                    name="bankName"
                                    render={({ field }) => (
                                        <SelectInput containerClass="w-full" label="Bank Name" required={true} value={field.value} onChange={field.onChange}>
                                            <option value="" disabled>Select a bank</option>
                                            {banks?.data && banks?.data?.map((bank: any) =>
                                                <option value={bank?.bankCode} key={bank?.bankCode}>{bank?.name}</option>
                                            )}
                                        </SelectInput>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="accountNumber"
                                    render={({ field }) => (
                                        <FormItem className="w-full">
                                            <FormControl>
                                                <Input
                                                    type="text"
                                                    label="Account Number"
                                                    value={field.value}
                                                    required={true}
                                                    containerClass="mb-0"
                                                    maxLength={10}
                                                />
                                            </FormControl>
                                            <p className="text-right font-semibold text-shiga-black">
                                                FEMI FATAMI FALASI
                                            </p>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </form>
                    </Form>
                </div>

                <div className="border-t border-gray-100 pt-6 p-8">
                    <ButtonLoader loading={teamMemberFormLoading} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                        Save Changes
                    </ButtonLoader>
                </div>
            </FormDrawer>
        </>
    )
}