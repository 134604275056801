import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import EcommerceNav from "./EcommerceNav";

export const OJAH_PATH = "/ojah";

const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === `${OJAH_PATH}`) {
      navigate(`${OJAH_PATH}/products`);
    }
  }, [navigate, pathname]);

  return (
    <div className="flex justify-start items-stretch">
      <EcommerceNav />
      <div className="flex-grow px-20 py-11">
        <Outlet />
      </div>
    </div>
  );
};

export default Index;
