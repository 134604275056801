import { RootState } from "store/store";
import * as actions from "./actionTypes";
import {
  Airtime,
  CablePackage,
  DataPlan,
  Electricity,
  Internet,
  TVService,
} from "types/billPayment";
import { PaginatedApiResponseNoMeta } from "types/data-interface";

interface InitialState {
  isLoadingAirtime: boolean;
  airtime: PaginatedApiResponseNoMeta<Airtime[]> | null;

  isLoadingInternet: boolean;
  internet: PaginatedApiResponseNoMeta<Internet[]> | null;

  isLoadingTvServices: boolean;
  tvService: PaginatedApiResponseNoMeta<TVService[]> | null;

  isLoadingElectricity: boolean;
  electricity: PaginatedApiResponseNoMeta<Electricity[]> | null;

  isLoadingDataPlan: boolean;
  dataPlan: PaginatedApiResponseNoMeta<DataPlan[]> | null;

  isLoadingCablePackages: boolean;
  cablePackages: PaginatedApiResponseNoMeta<CablePackage[]> | null;
}

const initialState: InitialState = {
  airtime: null,
  internet: null,
  tvService: null,
  electricity: null,
  dataPlan: null,
  cablePackages: null,
  isLoadingElectricity: false,
  isLoadingAirtime: false,
  isLoadingInternet: false,
  isLoadingTvServices: false,
  isLoadingDataPlan: false,
  isLoadingCablePackages: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.GET_AIRTIME_SERVICES_START:
      state = {
        ...state,
        isLoadingAirtime: true,
      };
      return state;

    case actions.GET_INTERNET_SERVICES_START:
      state = {
        ...state,
        isLoadingInternet: true,
      };
      return state;
    case actions.GET_TV_SERVICES_START:
      state = {
        ...state,
        isLoadingTvServices: true,
      };
      return state;
    case actions.GET_DATA_PLAN_START:
      state = {
        ...state,
        isLoadingDataPlan: true,
      };
      return state;
    case actions.GET_CABLE_PACKAGES_START:
      state = {
        ...state,
        isLoadingCablePackages: true,
      };
      return state;

    case actions.GET_ELECTRICITY_SERVICES_START:
      state = {
        ...state,
        isLoadingElectricity: true,
      };
      return state;

    case actions.GET_AIRTIME_SERVICES:
      state = {
        ...state,
        isLoadingAirtime: false,
        airtime: action.payload,
      };
      return state;

    case actions.GET_INTERNET_SERVICES:
      state = {
        ...state,
        isLoadingInternet: false,
        internet: action.payload,
      };

      return state;

    case actions.GET_TV_SERVICES:
      state = {
        ...state,
        isLoadingTvServices: false,
        tvService: action.payload,
      };

      return state;
    case actions.GET_DATA_PLAN:
      state = {
        ...state,
        isLoadingDataPlan: false,
        dataPlan: action.payload,
      };

      return state;

    case actions.GET_CABLE_PACKAGES:
      state = {
        ...state,
        isLoadingCablePackages: false,
        cablePackages: action.payload,
      };

      return state;
    case actions.GET_ELECTRICITY_SERVICES:
      state = {
        ...state,
        isLoadingElectricity: false,
        electricity: action.payload,
      };
      return state;

    default:
      return state;
  }
}

export const getBillPaymentServices = (state: RootState) => state.billsPayment;
