// AUDTransfer.tsx
import React from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "components/common/form";
import { ReactComponent as Lock } from "assets/images/icons/payment/lock.svg";

const AUDTransferFields = ({ form }: { form: any }) => {
  const { register } = useFormContext(); // Access the form context

  return (
    <div className="">
      <Input
        // labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
        placeholder="BSB Transfer"
        label="Transfer type"
        required
        disabled
        {...form.register("transferType")}
        rightIcon={<Lock />}
      />
      <Input
        required
        label="Account Name"
        placeholder="Last Name"
        {...form.register("accountName")}
      />
      <Input
        type="number"
        required
        label="Account Number"
        placeholder="0000000000"
        {...form.register("accountNumber")}
      />
      <Input
        type="number"
        required
        maxLength={7}
        label="BSB Number"
        placeholder="Enter BSB Number"
        {...register("bsbNumber")}
      />
    </div>
  );
};

export default AUDTransferFields;
