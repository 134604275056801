import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useMemo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

interface SuccessRateProps {
  open: boolean;
  closeModal: () => void;
  transactionRate: {
    failed: number;
    successful: number;
    total: number;
  }
}

export const SuccessRateModal = ({ open, closeModal, transactionRate }: SuccessRateProps) => {
  const data = useMemo(() => [
    {
      name: "Successful",
      value: transactionRate.successful,
      color: "#00C46C"
    },
    {
      name: "Failed",
      value: transactionRate.total - transactionRate.successful,
      color: "#FF4E4E"
    }
  ], [transactionRate])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <h3 className="text-2xl text-shiga-black font-ojah">
                      Success Rate
                    </h3>
                    <button
                      className="size-8 bg-input-bg hover:bg-gray-100 cursor-pointer text-shiga-dark flex items-center justify-center rounded-full"
                      onClick={closeModal}
                    >
                      <FontAwesomeIcon icon="xmark" className="stroke-2" />
                    </button>
                  </Dialog.Title>

                  <div className="w-full h-px bg-outline-grey my-7" />
                  <div className="px-2 space-y-8">
                    <div className="bg-[#FAFAFA] rounded-3xl w-full p-2 flex items-center">
                      <div className="flex-1">
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart>
                            <Pie
                              data={data}
                              outerRadius={70}
                              innerRadius={50}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {
                                data.map((entry, idx) => (
                                  <Cell key={`cell-${idx}`} fill={entry.color} />
                                ))
                              }
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div>
                        {
                          data.map((item, idx) => (
                            <span key={idx} className="flex items-center space-x-1">
                              <div className={`w-2.5 h-2.5 rounded-full bg-[${item.color}]`} />
                              <p className="shiga-black-2">{item.name}</p>
                              <p className="text-shiga-dark">{`・${Math.floor((item.value / transactionRate.total) * 100)}%`}</p>
                            </span>
                          ))
                        }
                      </div>
                    </div>
                    <p className="text-[16px] text-shiga-dark tracking-tight font-normal">
                      This chart collates and provides a holistic view of all
                      attempted transactions on your business dashboard in
                      percentage. For instance, if a customer initiates a
                      payment when they have insufficient funds in their wallet
                      account, that transaction would not count as a successful
                      transaction.
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
