import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as settingsActions from "store/entities/settings/action";
import ProductTable from "./table/ProductTable";
import DeliveryAgentTable from "./table/DeliveryAgentTable";

const DeliveryAgent = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-between pb-12 mb-12 border-b border-gray-100">
        <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
          Delivery Agent
        </div>
        {/* <div>
          <button
            onClick={() => navigate("/ojah/products/create-product")}
            className="btn btn-block btn-lg btn-shiga-black"
          >
            Add an agent
          </button>
        </div> */}
      </div>
      {agents.data.payload.length < 1 ? (
        <div className="empty_state flex flex-col justify-center items-center self-stretch pt-[5.75rem] pb-12 px-0 bg-white">
          <div className="side-bar-icons flex justify-center items-center p-6">
            <svg
              width="132"
              height="132"
              viewBox="0 0 132 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.9366 50.2565V71.8271C42.9366 72.2377 43.1654 72.6141 43.5299 72.8031L56.1725 79.3585C56.9043 79.738 57.7779 79.2069 57.7779 78.3826V57.1045L87.9973 41.1632L73.169 33.4683L42.9366 50.2565Z"
                fill="#F0F0F0"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.751 46.2387V85.9632C24.751 86.7626 25.1849 87.499 25.8841 87.8865L63.3704 108.708C64.6809 109.434 66.2709 109.443 67.5894 108.732L106.094 87.9597C106.806 87.576 107.249 86.8329 107.249 86.0246V45.7487C107.249 44.9539 106.82 44.2209 106.127 43.8317L68.2089 23.0239C67.5487 22.653 66.7441 22.6481 66.0793 23.0107L25.8968 44.3084C25.1905 44.6937 24.751 45.434 24.751 46.2387ZM42.9366 71.8271V50.2565L73.169 33.4683L87.9973 41.1632L57.7779 57.1045V78.3826C57.7779 79.2069 56.9043 79.738 56.1725 79.3585L43.5299 72.8031C43.1654 72.6141 42.9366 72.2377 42.9366 71.8271Z"
                fill="#99999C"
              />
            </svg>
          </div>
          <div className="text-container flex flex-col justify-center items-center w-[400px]">
            <div className="text-content flex flex-col justify-center items-center self-stretch">
              <div className="no-accounts-text self-stretch text-[#343433] text-center  text-[1.625rem] leading-normal">
                No Delivery Agents
              </div>
              <div className="no-accounts-label self-stretch text-[#99999c] text-center font-['Inter'] leading-normal">
                You have no delivery agent yet.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <DeliveryAgentTable agents={agents} />
      )}
    </div>
  );
};

export const agents = {
  data: {
    meta: {
      size: 5,
      totalItems: 5,
      nextPage: 1,
      previousPage: 0,
    },
    payload: [
      {
        id: 1,
        name: "Top Ship",
        status: "Active",
        dateCreated: "2024-07-10",
      },
      {
        id: 2,
        name: "Ship Fast Global",
        status: "Active",
        dateCreated: "2024-07-11",
      },
      {
        id: 3,
        name: "Topper Ship",
        status: "In-active",
        dateCreated: "2024-07-12",
      },
    ],
  },
};

export default DeliveryAgent;
