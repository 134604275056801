import { zodResolver } from "@hookform/resolvers/zod";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { PageHeading } from "components/common/PageHeading";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { ReactComponent as Lock } from "assets/images/icons/payment/lock.svg";
import { CircleCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { University } from "types/shared/university.type";
import { useSharedStore } from "zustand-store";
import { useTuitionStore } from "zustand-store/payments/tution";
import { PAYMENT_PATHS } from "../paths";
import CommandSelect from "components/ui/command-select";
import { Input } from "components/common/form";
import { isValid } from "date-fns";
import { CommandInput } from "components/ui/command";

const FormSchema = z.object({
  country: z.string().min(2, {
    message: "country must be at least 2 characters.",
  }),
  university: z.string(),
  address: z.string().min(2, {
    message: "Address must be at least 2 characters.",
  }),
});

export const Paytution = () => {
  const navigate = useNavigate();
  const { currencies, fetchUniversities, universities } = useSharedStore();
  const [university, setUniversity] = useState<University | null>(null);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      country: "GB",
    },
  });

  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  // Filter currencies based on wallet data
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );
  const GBCurrency = filteredCurrencies.filter(
    (currency) => currency.countryCode === "GB"
  );


  const { payload, setPayload } = useTuitionStore();

  const country = form.watch("country");
  const university_nme = form.watch("university");

  const getUniversityInitials = (universityName: string) => {
    const words = universityName.split(" ");

    if (words[0].toLowerCase() === "university" && words.length > 2) {
      return (
        words[2][0] + (words[3] ? words[3][0] : words[2][1])
      ).toUpperCase();
    } else {
      return (
        words[0][0] + (words[1] ? words[1][0] : words[0][1])
      ).toUpperCase();
    }
  };

  useEffect(() => {
    if (!!payload.university.id) {
      form.setValue("university", payload.university.universityName);
      form.setValue("address", payload.university.universityName);
      form.setValue("country", payload.university.country);
    }
  }, [payload, form]);

  useEffect(() => {
    fetchUniversities("GB" ?? "GB");
  }, [country, fetchUniversities]);

  const isFormValid =
    form.formState.errors.university && form.formState.errors.address;

  useEffect(() => {
    if (university_nme) {
      const fUni =
        universities?.find((uni) => uni.universityName === university_nme) ||
        ({} as University);
      form.setValue("address", fUni?.universityName || "");
      setUniversity(fUni);
    }
  }, [form, university_nme, universities]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (university) {
      setPayload({ university });
    }
    navigate(PAYMENT_PATHS.TUITION_STEPS);
  }

  return (
    <div className="w-full p-10  space-y-4 flex flex-col justify-center items-center">
      <PageHeading title="Add Institution’s Information" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-6">
                <CommandSelect
                  notFound="No cuntry found."
                  disableInput
                  label="Country"
                  handleSelect={(arg) => {
                    form.setValue("country", arg.countryCode);
                  }}
                  options={currencies.map((item) => ({
                    ...item,
                    id: item.name,
                    value: item.id + "",
                  }))}
                  SelectTrigger={() => (
                    <span className="flex items-center gap-4">
                      <img
                        src={
                          GBCurrency?.find(
                            (cur) => cur.countryCode === field.value
                          )?.icon || noisygradient
                        }
                        alt={field.value}
                        className="size-5"
                      />

                      {currencies?.find(
                        (cur) => cur.countryCode === field.value
                      )?.country || "GB"}
                    </span>
                  )}
                  RenderOption={(currency) => (
                    <>
                      <span className="flex items-center gap-1.5">
                        <img
                          src={currency.item.icon || noisygradient}
                          alt={currency.item.country}
                          className="size-5"
                        />
                        {currency.item.country}
                      </span>
                      {field.value === currency.item.country && (
                        <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                      )}
                    </>
                  )}
                />
                {/* <FormMessage className="text-shiga-dark">More countries coming soon...</FormMessage> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="university"
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-3">
                <CommandSelect
                  notFound="No university found."
                  label="Institution’s Name"
                  searchPlaceholder="Search an institution"
                  // placeholder="Select an institution"
                  options={universities.map((item) => ({
                    ...item,
                    id: item.id,
                    value: item.universityName,
                  }))}
                  SearchInput={
                    <CommandInput
                      placeholder="Search an institution"
                      value={form.getValues("university")}
                      onValueChange={(e) => {
                        form.setValue("university", e);
                      }}
                    />
                  }
                  SelectTrigger={() => (
                    <>
                      {!field.value ? (
                        <span className="text-form-gray text-base font-normal">
                          Select an institution
                        </span>
                      ) : (
                        <span className="flex items-center gap-4">
                          {/* <span>Search universities</span> */}
                          {field.value}
                        </span>
                      )}
                    </>
                  )}
                  RenderOption={({ item }) => (
                    <p className="flex items-center gap-1.5">
                      {/* <img
                        src={noisygradient}
                        alt={item.universityName}
                        className="size-5"
                      /> */}
                      <span className="w-8 h-8 p-[2px]  rounded-full bg-[#A2D2FF] flex items-center justify-center">
                        {getUniversityInitials(item.universityName)}
                      </span>
                     <span className="text-lg">{item.universityName} </span> 
                    </p>
                  )}
                  handleSelect={(select) => {
                    form.setValue("university", select.universityName);
                  
                  }}
                />
              </FormItem>
            )}
          />

          {university && (
            <>
              <Input
                labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
                placeholder="School address"
                label="Account Number"
                required
                disabled
                value={university?.accountNumber}
                rightIcon={<Lock />}
              />
              <Input
                labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
                placeholder="Sort code"
                label="Sort Code"
                required
                disabled
                value={university?.sortCode}
                rightIcon={<Lock />}
              />
            </>
          )}
          <FormField
            control={form.control}
            name="address"
            render={() => (
              <FormItem>
                <FormControl>
                  <div className="relative">
                    <Input
                      label="School Address"
                      disabled
                      value={university?.universityName}
                      placeholder="(No)(Street Name)(State)(Country)"
                      rightIcon={<Lock />}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <Button
            //  disabled={form.formState.isValid || !isFormValid}
            className="w-full"
            type="submit"
          >
            Continue
          </Button>
        </form>
      </Form>
    </div>
  );
};
