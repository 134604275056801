import React, { ReactNode, useState } from 'react';

type Props = {
   message: string | ReactNode;
   children: React.ReactNode;
}

const Tooltip = ({ message, children }: Props) => {
   const [isVisible, setIsVisible] = useState(false);

   return (
      <div
         className="relative flex flex-col items-center group"
         onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
         {children}

         <div className={`absolutes transition-all duration-500 bottom-full mb-3 w-max bg-shiga-dark-500 text-white text-xs rounded-xl p-3 ${isVisible ? 'opacity-100 inline absolute' : 'opacity-0 hidden'}`}>
            {message}
            <div className="absolute top-full left-[52%] transform -translate-x-[52%] w-0 h-0 border-x-8 border-x-transparent border-t-8 border-t-gray-800"></div>
         </div>
      </div>
   );
};

export default Tooltip;
