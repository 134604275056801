import { NavLink, useLocation } from "react-router-dom";

interface BillNavItem {
  label: string;
  url: `/manage-bills/${string}`;
}

const navItems: BillNavItem[] = [
  { label: "Buy Airtime", url: "/manage-bills/buy-airtime" },
  { label: "Mobile Data", url: "/manage-bills/mobile-data" },
  { label: "Cable TV", url: "/manage-bills/cable-tv" },
  { label: "Buy Electricity", url: "/manage-bills/buy-electricity" },
];

const BillsNav = () => {
  const { pathname } = useLocation();

  return (
    <div className="w-[260px] border-r border-outline-grey py-11 pl-5">
      <ul>
        {navItems.map((item, index) => (
          <NavItem key={index} isActive={pathname === item.url} url={item.url}>
            {item.label}
          </NavItem>
        ))}
      </ul>
    </div>
  );
};

interface NavItemProps {
  url: `/manage-bills/${string}`;
  isActive: boolean;
  children: React.ReactNode;
}

const NavItem = ({ children, isActive, url }: NavItemProps) => {
  return (
    <NavLink
      to={url}
      className={`${isActive ? "border-r-[6px] border-shiga-purple-2 bg-shiga-purple-accent rounded-s-md text-shiga-black" : "text-shiga-dark"} block px-2 py-[9px] font-normal text-base`}
    >
      {children}
    </NavLink>
  );
};

export default BillsNav;
