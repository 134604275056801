import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <div className="py-10 text-[#373737] text-center text-sm text-opacity-40">
            <div className="flex justify-center space-x-4">
                <Link to="https://payshiga.notion.site/PAYSHIGA-PRIVACY-POLICY-6165bd6474ad4297a2227a0f7c061a7b?pvs=4" target="_blank" className="auth-footer-links">
                    Privacy Policy
                </Link>
                <span className="flex">•</span>
                <Link to="https://payshiga.notion.site/PAYSHIGA-TERMS-OF-USE-c14e881663ec40c7875e61ff0eebc40b?pvs=4" target="_blank" className="auth-footer-links">
                    Terms of Service
                </Link>
            </div>
            <div className="mt-4">
                &#169; 2024 Payshiga Technologies.
            </div>
        </div>
    )
}


export default Footer