import axios from "axios";
import {
  apiBaseUrl,
  apiCreateAirtime,
  apiCreateElectricity,
  apiCreateInternetService,
  apiCreateTVService,
} from "data/config";
import { getToken } from "lib";
import React, { useState } from "react";
import { ValidateBill } from "types/billPayment";
import { CurrencyType } from "types/misc";

const useBillsPayment = () => {
  const [loadingAirtime, setLoadingAirtime] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingBuyCable, setLoadingBuyCable] = useState(false);
  const [loadingBuyElectricity, setLoadingElectricity] = useState(false);

  const buyAirtime = (body: {
    currency: CurrencyType;
    networkType: string;
    provider: string;
    debitAccountNumber: string;
    serviceCategoryId: string;
    phoneNumber: string;
    amount: number;
  }) => {
    setLoadingAirtime(true);
    return axios
      .post(apiBaseUrl + apiCreateAirtime, body, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setLoadingAirtime(false);
        return res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        };
      })
      .catch((err) => {
        setLoadingAirtime(false);
        throw err;
      });
  };

  const buyData = (body: {
    currency: string;
    networkType: string;
    packageName: string;
    provider: string;
    phoneNumber: string;
    amount: number;
    plan_id: string;
    debitAccountNumber: string;
    serviceCategoryId: string;
    bundleCode: string;
  }) => {
    setLoadingData(true);
    return axios
      .post(apiBaseUrl + apiCreateInternetService, body, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setLoadingData(false);
        return res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        };
      })
      .catch((err) => {
        setLoadingData(false);
        throw err;
      });
  };

  const buyCablePackage = (body: {
    currency: string;
    utilityBillName: string;
    packageName: string;
    provider: string;
    cardNumber: string;
    amount: string;
    plan_id: number;
    serviceCategoryId: string;
    debitAccountNumber: string;
    bundleCode: string;
  }) => {
    setLoadingBuyCable(true);
    return axios
      .post(apiBaseUrl + apiCreateTVService, body, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setLoadingBuyCable(false);

        return res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        };
      })
      .catch((err) => {
        setLoadingBuyCable(false);
        throw err;
      });
  };

  const buyElectricity = (body: {
    currency: CurrencyType;
    vendType: string;
    utilityBillName: string;
    provider: string;
    number: string;
    amount: number;
    serviceCategoryId: string;
    debitAccountNumber: string;
    meterNumber: string;
  }) => {
    setLoadingElectricity(true);
    return axios
      .post(apiBaseUrl + apiCreateElectricity, body, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setLoadingElectricity(false);
        return res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        };
      })
      .catch((err) => {
        setLoadingElectricity(false);
        throw err;
      });
  };

  return {
    buyAirtime,
    loadingAirtime,
    buyData,
    loadingData,
    buyCablePackage,
    loadingBuyCable,
    buyElectricity,
    loadingBuyElectricity
  };
};

export default useBillsPayment;
