import axios from "axios";
import { apiBaseUrl } from "data/config";
import { getToken } from "lib";

export const uploadFile = async (body: File) => {
  try {
    const formData = new FormData();
    formData.append("file", body);
    const res = await axios.post(apiBaseUrl + "/file/upload", formData, {
      headers: {
        Authorization: "Bearer " + getToken(),
        ContentType: "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    return { error: "An error occurred" };
  }
};
