import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { monthNames, PadMonthIndex } from "utils/dateFormat";
import { ReactComponent as IconDateArrow } from "assets/images/icons/filter-date-arrow.svg";
import { Menu } from "@headlessui/react";
import Maybe from "./Maybe";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LucideChevronDown } from 'lucide-react';



const CustomTableFilter = ({ filters, dropdownClass }) => {
  // eslint-disable-next-line
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [urlParams, setUrlParams] = useState({});
  const [filterMonthYear, setFilterMonthYear] = useState({});
  const [startDate, setStartDate] = useState(null);
  // eslint-disable-next-line
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [filtersShown, setFiltersShown] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const showFilterIndicator = () => {
    let isFilterActive = false;
    let filterNames = [];
    const qsObject = queryString.parse(location.search);

    if (filters) {
      filters.forEach((filter) => {
        if (filter.name !== undefined) {
          filterNames.push(filter.name);
        } else {
          if (filter.dataType === "number-range" || filter.dataType === "date-range") {
            filterNames.push(filter.options.from.name);
            filterNames.push(filter.options.to.name);
          } else if (filter.dataType === "month-year") {
            filterNames.push("month");
            filterNames.push("year");
          }
        }
      });

      filterNames.forEach((name) => {
        if (qsObject[name] !== undefined) {
          isFilterActive = true;
        }
      });
    }

    setIsFilterActive(isFilterActive);
  };

  const processUrlParams = () => {
    const params = new URLSearchParams(location.search);
    const urlParams = Object.fromEntries(params);
    setUrlParams(urlParams);

    if (urlParams.month && urlParams.year) {
      const monthIndex = PadMonthIndex(monthNames.indexOf(urlParams.month) + 1);
      const defaultDate = new Date(urlParams.year + "-" + monthIndex + "-01");
      monthYearSelection("month-year", defaultDate);
    } else {
      monthYearSelection("month-year", null);
    }
  };

  const gotoUrlQuery = (params) => {
    const qs = queryString.parse(location.search);

    Object.keys(params).forEach((paramName) => {
      delete qs[paramName];
    });

    let qsToUrl = new URLSearchParams(qs).toString();
    qsToUrl = location.pathname + "?" + (qsToUrl !== "" ? qsToUrl + "&" : "");

    Object.keys(params).forEach((paramName) => {
      if (params[paramName] !== "") {
        qsToUrl += paramName + "=" + params[paramName] + "&";
      }
    });

    qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
    navigate(qsToUrl);
  };

  // eslint-disable-next-line
  const submitFormSearch = (e) => {
    e.preventDefault();
    const searchInput = document.querySelector("#search");
    gotoUrlQuery({ [searchInput.name]: searchInput.value });
  };

  // eslint-disable-next-line
  const getFilterMonthYearValue = (name) => {
    if (name === "month-year") {
      let month = PadMonthIndex(monthNames.indexOf(urlParams["month"]) + 1);
      let year = urlParams["year"];
      return year + "-" + month;
    }
    return "";
  };

  const submitFormFilter = (e) => {
    e.preventDefault();
    let params = {};
    const inputSelect = document.querySelectorAll(".submitFormFilter select option:checked");
    const inputRadio = document.querySelectorAll(".submitFormFilter input[type='radio']:checked");
    const inputDate = document.querySelectorAll(".submitFormFilter input[type='date']");
    const inputNumber = document.querySelectorAll(".submitFormFilter input[type='number']");

    inputSelect.forEach((ele) => {
      if (ele.value === '-') {
        params[ele.id] = '';
      } else if (ele.value !== '-') {
        params[ele.id] = ele.value;
      }
    });
    inputRadio.forEach((ele) => {
      params[ele.name] = ele.value;
    });
    inputDate.forEach((ele) => {
      params[ele.name] = ele.value;
    });
    inputNumber.forEach((ele) => {
      params[ele.name] = ele.value;
    });

    if (Object.keys(filterMonthYear).length > 0) {
      for (let key in filterMonthYear) {
        if (key === "month-year") {
          params["month"] = filterMonthYear[key]["month"];
          params["year"] = filterMonthYear[key]["year"];
        }
      }
    }

    gotoUrlQuery(params);
  };

  const clearFilter = () => {
    const qsObject = queryString.parse(location.search);
    const preserveObjects = ["search", "limit", "employerId"];

    Object.keys(qsObject).forEach((qsObjectName) => {
      if (!preserveObjects.includes(qsObjectName)) {
        delete qsObject[qsObjectName];
      }
    });

    const inputSelect = document.querySelectorAll(".submitFormFilter select option:checked");
    const inputRadio = document.querySelectorAll(".submitFormFilter input[type='radio']:checked");
    const inputDate = document.querySelectorAll(".submitFormFilter input[type='date']");
    const inputNumber = document.querySelectorAll(".submitFormFilter input[type='number']");


    inputSelect.forEach((ele) => {
      ele.value = "";
    });
    inputRadio.forEach((ele) => {
      ele.checked = !ele.checked;
    });
    inputDate.forEach((ele) => {
      ele.value = "";
    });
    inputNumber.forEach((ele) => {
      ele.value = "";
    });
    monthYearSelection("", null);
    setSelectedTimeframe("");

    let qsToUrl = new URLSearchParams(qsObject).toString();
    qsToUrl = location.pathname + "?" + (qsToUrl !== "" ? qsToUrl + "&" : "");
    qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);

    navigate(qsToUrl);
  };

  // eslint-disable-next-line
  const resetSingleFilter = (queryParam) => {
    const qsObject = queryString.parse(location.search);
    const preserveObjects = ["search", "limit", "employerId"];

    Object.keys(qsObject).forEach((qsObjectName) => {
      if (!preserveObjects.includes(queryParam)) {
        delete qsObject[queryParam];
      }
    });

    let qsToUrl = new URLSearchParams(qsObject).toString();
    qsToUrl = location.pathname + "?" + (qsToUrl !== "" ? qsToUrl + "&" : "");
    qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
    navigate(qsToUrl);
  };

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const monthYearSelection = (name, valueDate) => {
    setStartDate(valueDate);
    if (valueDate !== "" && valueDate !== null) {
      setFilterMonthYear((prev) => ({
        ...prev,
        [name]: {
          month: moment(valueDate).format('MMMM'),
          year: moment(valueDate).format('yy'),
        },
      }));
    }
  };

  useEffect(() => {
    processUrlParams();
    showFilterIndicator();
    // eslint-disable-next-line
  }, [location]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFiltersShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const toggleFilterDisplay = () => {
    setFiltersShown(!filtersShown)
  };

  return (
    <form onSubmit={submitFormFilter} className="submitFormFilter">
      <Menu as="div">
        <Menu.Button
          onClick={toggleFilterDisplay}
          className="h-10 rounded-lg text-shiga-dark-100 font-inter font-medium flex items-center px-3 text-sm border border-shiga-gray-75 relative focus:ring-transparent">
          <span className='px-1'>Filter by</span>
          <LucideChevronDown className='text-shiga-gray-300' size='1.1rem' />
        </Menu.Button>

        <Maybe condition={filters?.length > 0}>

          <Menu.Items ref={dropdownRef} as="div" className={`dropdown ${dropdownClass || ""}`}
            style={{
              width: "230px",
              overflow: "visible",
              marginTop: '8px',
              backgroundColor: '#171819'
            }}>
            <div className="w-full p-5 text-xs">
              <div className="">
                {filters.map((filter, index) =>
                  <div key={index} className="mb-6">
                    <div className="mb-1 text-shiga-gray-100 w-full">
                      {filter.title}
                    </div>
                    {(() => {
                      switch (filter.dataType) {
                        case "select":
                          return (
                            <div className="w-full">
                              <select style={{
                                backgroundColor: '#202122',
                                border: 'none'
                              }} className='w-full table-filter-select rounded-lg text-sm' onChange={(e) => {
                                setUrlParams({
                                  [filter.name]: e.target.value,
                                })
                              }} value={urlParams[filter.name]} id={filter.name} name={filter.name}>
                                {filter.options.map((option, index) =>
                                  <option id={filter.name} key={index} value={option.payload}>{option.display}</option>
                                )}
                              </select>
                            </div>

                          );
                        case "radio":
                          return (
                            filter.options.map((option, index) =>
                              <label key={index}>
                                <input
                                  type="radio"
                                  name={filter.name}
                                  value={option}
                                />
                                {option}
                              </label>
                            )
                          );
                        case "date":
                          return (
                            <input
                              id={filter.name}
                              type="date"
                            />
                          );
                        case "number-range":
                          return (
                            <>
                              <input
                                id={filter.options.from.name}
                                type="number"
                                min={filter.options.from.min}
                                max={filter.options.from.max}
                              />
                              {/* <span className="p-2"><SwapRight /></span> */}
                              Icon
                              <input
                                id={filter.options.to.name}
                                type="number"
                                min={filter.options.to.min}
                                max={filter.options.to.max}
                              />
                            </>
                          );
                        case "date-range":
                          return (
                            <div className='w-full'>
                              <input
                                type="date"
                                className='table-filter__date'
                                id={filter?.options?.from?.name}
                                name={filter?.options?.from?.name}
                                onChange={(e) => {
                                  setUrlParams({
                                    ...urlParams,
                                    [filter?.options?.from?.name]: e.target.value
                                  })
                                }}
                              />

                              <div className="w-full relative">
                                <span className='absolute left-1/2 transform -translate-x-1/2 -top-px'>
                                  <IconDateArrow />
                                </span>

                                <input
                                  type="date"
                                  className='table-filter__date mt-2'
                                  id={filter?.options?.to?.name}
                                  name={filter?.options?.to?.name}
                                  onChange={(e) => {
                                    setUrlParams({
                                      ...urlParams,
                                      [filter?.options?.to?.name]: e.target.value
                                    })
                                  }}
                                />
                              </div>

                            </div>
                          );
                        case "month-year":
                          return (
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => monthYearSelection(filter.dataType, date)}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                              }) => (
                                <div className="flex items-center justify-between mb-2">
                                  <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    type="button"
                                    className={prevMonthButtonDisabled ? "text-gray-400" : ""}
                                  >
                                    <span>
                                      Icon here
                                    </span>
                                  </button>
                                  <div>
                                    <select
                                      value={moment(date).year()}
                                      onChange={({ target: { value } }) => changeYear(value)}
                                      className="select"
                                    >
                                      {Array.from({ length: 21 }, (_, i) => (
                                        <option key={i} value={2000 + i}>
                                          {2000 + i}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      value={monthNames[moment(date).month()]}
                                      onChange={({ target: { value } }) => changeMonth(monthNames.indexOf(value))}
                                      className="select"
                                    >
                                      {monthNames.map((month, index) => (
                                        <option key={index} value={month}>
                                          {renderMonthContent(index, month, month)}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                    type="button"
                                    className={nextMonthButtonDisabled ? "text-gray-400" : ""}
                                  >
                                    <span>
                                      Icon 2 here
                                    </span>
                                    {/* <IconRight className="transform rotate-180" /> */}
                                  </button>
                                </div>
                              )}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                )}
                <div className="flex justify-between border-t border-shiga-dark-900 pt-3">
                  <button
                    type="button"
                    onClick={clearFilter}
                    className="text-xs px-4 py-2 text-white border border-[#48484A] rounded-[10px]">
                    Clear
                  </button>
                  <button
                    type="submit"
                    className="text-xs px-4 py-2 bg-white text-shiga-dark-800 rounded-[10px]">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </Menu.Items>

        </Maybe>
      </Menu>
    </form>
  );

};

export default CustomTableFilter;
