

const defaultNgnForm = {
   accountNumber: '',
   accountName: '',
   bankName: '',
   bankCode: '',
}

const defaultUsdForm = {
   accountNumber: '',
   accountName: '',
   bankName: '',
   routingNo: '',
}

const defaultAudForm = {
   accountName: '',
   accountNumber: '',
   bsbNumber: ''
}

const defaultGbpForm = {
   accountName: '',
   accountNumber: '',
   sortCode: ''
}


export {
   defaultNgnForm,
   defaultUsdForm,
   defaultAudForm,
   defaultGbpForm
}