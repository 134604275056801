import React, { ChangeEvent, SetStateAction } from 'react'

type Props = {
   note: string;
   setNote: React.Dispatch<SetStateAction<string>>;
   label: string;
   placeholder: string;
   required?: boolean;
}

const TextAreaNote = ({ note, setNote, label, placeholder, required }: Props) => {
   const maxLength = 200

   const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;

      if (value?.length <= maxLength) {
         setNote(event.target.value)
      }
   };

   return (
      <div className='w-full'>
         <div className="w-full flex mt-10 justify-between text-sm">
            <p className="text-shiga-gray-100">{label}
               {required &&
                  <span className="form-input-required">
                     *
                  </span>
               }
            </p>
            <p>{note?.length}/{maxLength}</p>
         </div>
         <div className="w-full mt-2 text-shiga-gray">
            <textarea
               value={note}
               maxLength={200}
               placeholder={placeholder}
               onChange={handleNoteChange}
               style={{ minHeight: '80px' }}
               className="w-full border-0 py-3 px-5 rounded-2xl  bg-[#FAFAFB] text-shiga-dark-100 placeholder:text-shiga-gray-100 placeholder:font-normal font-medium"
            />
         </div>
      </div>
   )
}

export default TextAreaNote