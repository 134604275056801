import { ReactComponent as IconCards } from "assets/images/icons/nav-cards.svg";

export const NoCard = () => {
  return (
    <>
      <div className="px-8 py-20 text-center max-w-md m-auto">
        <IconCards className="w-36 h-36 mx-auto" />
        <div className="mt-8 text-4xl font-ojah font-bold">No Cards Yet</div>
        <div className="mt-4 text-shiga-gray whitespace-nowrap">
          You have not created any card from this account yet.
        </div>
      </div>
    </>
  );
};
