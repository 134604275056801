interface PaperPlaneIconProps extends React.BaseHTMLAttributes<SVGElement> {
  isActive: boolean;
}

const PaperPlaneIcon = ({ isActive, ...props }: PaperPlaneIconProps) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.490723"
        y="0.5"
        width="24"
        height="24"
        rx="12"
        fill={isActive ? "#343433" : "#E7E7E7"}
      />
      <path
        d="M14.5216 17.338L16.9508 9.19219C17.0499 8.8599 16.9565 8.53791 16.7559 8.3125L13.4649 11.5408C13.5028 11.5787 13.5069 11.6387 13.4745 11.6814L11.3258 14.5119C10.975 14.9741 11.0083 15.6219 11.4046 16.0457L12.9577 17.706C13.4463 18.2284 14.3172 18.0234 14.5216 17.338Z"
        fill="white"
      />
      <path
        d="M15.8081 8.03701L7.66227 10.3715C6.97724 10.5678 6.76124 11.4319 7.27332 11.9275L8.9482 13.5486C9.36798 13.9549 10.0205 13.9966 10.4886 13.6471L13.3238 11.5306C13.3669 11.4984 13.427 11.5028 13.4649 11.5408L16.7559 8.3125C16.5332 8.06216 16.1783 7.93093 15.8081 8.03701Z"
        fill="white"
      />
    </svg>
  );
};

export default PaperPlaneIcon;
