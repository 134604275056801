// AUDTransfer.tsx
import React from "react";
import { useFormContext } from "react-hook-form";
import { Input } from "components/common/form";
import { ReactComponent as Lock } from "assets/images/icons/payment/lock.svg";
import AddressSelector from "./AddressSelector";
import BankSelector from "./BankSelector";

const USDTransferFields = ({ form }: { form: any }) => {
  const { register } = useFormContext(); // Access the form context

  return (
    <div className="">
      <Input
        // labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
        placeholder="ACH"
        label="Transfer type"
        // required
        disabled
        rightIcon={<Lock />}
        {...form.register("transferType")}
      />
      <BankSelector />

      {/* <Input
        label="Account name"
        placeholder="Last Name"
        {...form.register("accountName")}
      />
      <div className="flex items-center gap-x-6">
        <Input
          label="Account number"
          placeholder="0000000000"
          {...form.register("accountNumber")}
        />
        <Input
          label="ACH routing Number"
          placeholder="0000000000"
          {...form.register("ACHroutingNumber")}
        />
      </div> */}
      <AddressSelector label="Bank Address" />

      <Input
        required
        label="State"
        placeholder="Select a state"
        {...register("bankState")}
        disabled
        rightIcon={<Lock />}
      />
      <div className="flex items-center gap-x-6">
        <Input
          type="number"
          required
          label="Post Code"
          placeholder="00000"
          {...register("postCode")}
          disabled
          rightIcon={<Lock />}
        />
        <Input
          required
          label="City"
          placeholder="Select an option"
          {...register("bankCity")}
          disabled
          rightIcon={<Lock />}
        />
      </div>
    </div>
  );
};

export default USDTransferFields;
