export const cableProvider = [
    {
        provider: "DSTV",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/DStv-logo_cQikOEkT9.jpg?updatedAt=1716468174465"
    },
    {
        provider: "GOTV",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/ida5qDSAhF_logos_bkeyuIM7M.svg?updatedAt=1716467937416"
    },
    {
        provider: "STARTIMES",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/1662508-middle_q-eYq1LVE.png?updatedAt=1716468174745"
    },
    {
        provider: "SHOWMAX",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/Logo_S7E0ggryy.svg?updatedAt=1716467938901"
    }
]

export const airtimeProvider = [
    {
        provider: "AIRTEL",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/bharti-airtel-limited_wNXnTtycR.svg?updatedAt=1716463426441",
    },
    {
        provider: "MTN",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/mtn-new-logo_L624eUgMd.svg?updatedAt=1716463427064",
    },
    {
        provider: "GLO",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/glo_wx2ZONiz5.png?updatedAt=1716463427036",
    },
    {
        provider: "9MOBILE",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/9mobile-seeklogo-2_gQedYpdWt.svg?updatedAt=1716463425897",
    }
]

export const electricityProvider = [
    {
        provider: "IKEDC",
        code: "ikeja-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/2472546-middle_lNymE6r8w.png?updatedAt=1716467940579",
        minAmount: "500"
    },
    {
        provider: "EKEDC",
        code: "eko-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/idIUIvxpbV_1716467695687_oxtXjNK7J.png?updatedAt=1716467938366",
        minAmount: "500"
    },
    {
        provider: "KEDCO",
        code: "kano-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/id7YxLrZhZ_logos_rXfDEUBAL.png?updatedAt=1716467937316",
        minAmount: "500"
    },
    {
        provider: "PHED",
        code: "portharcourt-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/idKmzUX0rz_logos_RGg0hJGIo.png?updatedAt=1716467937149",
        minAmount: "500"
    },
    {
        provider: "JED",
        code: "jos-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/qqSdt2Ir_400x400_Afp9ebL8U.jpg?updatedAt=1716468031843",
        minAmount: "500"
    },
    {
        provider: "IBEDC",
        code: "ibadan-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/id3ichM9Z0_1716467786533_8oz1X7-3z.png?updatedAt=1716467938039",
        minAmount: "500"
    },
    {
        provider: "KAEDCO",
        code: "kaduna-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/The-Kaduna-Electricity-Distribution-Company-KAEDCO_iOwu231va.jpg?updatedAt=1716467940090",
        minAmount: "500"
    },
    {
        provider: "AEDC",
        code: "abuja-electric",
        providerLogoUrl: "https://ik.imagekit.io/jumzeey/shiga/AEDC_1-e1708370547799_N10cuzScj.jpeg?updatedAt=1716467940558",
        minAmount: "500"
    }
]

export const currencies = [
    {
    id: 1,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/USD.svg",
    currency: "USD",
    countryCode: "US",
    name: "US Dollars",
    country: "United States of America",
    symbol: "$"
    },
    {
    id: 2,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/NGN.svg",
    currency: "NGN",
    countryCode: "NG",
    name: "Nigerian Naira",
    country: "Nigeria",
    symbol: "₦"
    },
    {
    id: 3,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/GBP.svg",
    currency: "GBP",
    countryCode: "GB",
    name: "British Pound",
    country: "United Kingdom",
    symbol: "£"
    },
    {
    id: 4,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/EUR.svg",
    currency: "EUR",
    countryCode: "EU",
    name: "Euro",
    country: "Germany",
    symbol: "€"
    },
    {
    id: 5,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/KES.svg",
    currency: "KES",
    countryCode: "KE",
    name: "Kenyan shillings",
    country: "Kenya",
    symbol: "Ksh"
    },
    {
    id: 6,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/TZS.svg",
    currency: "TZS",
    countryCode: "TZ",
    name: "Tanzania shillings",
    country: "Tanzania",
    symbol: "Tsh"
    },
    {
    id: 7,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/GHS.svg",
    currency: "GHS",
    countryCode: "GH",
    name: "Ghanaian cedi",
    country: "Ghana",
    symbol: "GH₵"
    },
    {
    id: 8,
    icon: "https://res.cloudinary.com/stefanpgr/image/upload/v1681066857/country-logo/RWF.svg",
    currency: "RWF",
    countryCode: "RW",
    name: "Rwandan franc",
    country: "Rwanda",
    symbol: "R₣"
    },
    {
    id: 9,
    icon: "https://ik.imagekit.io/jumzeey/shiga/Country%20(8)_edQ4wz3gSZ.svg?updatedAt=1701186572376",
    currency: "UGX",
    countryCode: "UG",
    name: "Uganda shillings",
    country: "Uganda",
    symbol: "USh"
    },
    {
    id: 10,
    icon: "https://ik.imagekit.io/jumzeey/shiga/Country%20(11)_XNFRbJ-lPF.svg?updatedAt=1701186573676",
    currency: "ZAR",
    countryCode: "ZA",
    name: "South African rand",
    country: "South Africa",
    symbol: "ZAR"
    },
]
