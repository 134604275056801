import TextArea from "components/common/form/TextArea";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Separator } from "components/ui/separator";
import { deletePaymentLink } from "lib/payments";
import { Loader } from "lucide-react";
import { EmptyLinkImage } from "pages/payments/payment-links/EmptyLinkImage";
import { useState } from "react";
import { toast } from "sonner";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import { ReactComponent as ProductImg } from "assets/images/icons/ProductsIcons.svg";

// import { EmptyLinkImage } from "./EmptyLinkImage";
// import { Stat } from "./Stat";
interface CancelOrderModalProps {
  open: boolean;
  closeModal: () => void;
  order: any;
}

export function CancelOrderModal(props: CancelOrderModalProps) {
  const { order: order } = props;
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const { updatePaymentLink } = usePaymentLinkStore();
  const handleDelete = async () => {
    setLoading(true);
    updatePaymentLink(order, "delete");
    const res = await deletePaymentLink(order.id);
    if (res.success) {
      toast("Payment link deleted successfully");
      props.closeModal();
    }
    setLoading(false);
  };

  return (
    <Dialog open={props.open} onOpenChange={() => props.closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel this Order</DialogTitle>
        </DialogHeader>
        <Separator orientation="horizontal" className="mb-4" />
        <div className="">
          {/* <div className="w-[114px] h-[114px]">
            {order.logoUrl ? (
              <img
                className="h-full w-full rounded-xl"
                src={order.logoUrl}
                alt={order.name}
              />
            ) : (
              <EmptyLinkImage />
            )}
          </div> */}
          {/* <div className="h-full">
            <div className="self-stretch pb-1.5 justify-start items-center gap-1.5 inline-flex">
              <div className="text-zinc-800 text-base font-medium font-['Inter']">
                {order.name}
              </div>
            </div>
            <Stat link={order} />
          </div> */}
          <div className="flex items-center">
            <div className="w-10 h-10 mr-4 bg-gray-200 rounded-2xl">
              {/* <IconGTBank className="w-full h-full" /> */}
              <ProductImg />
            </div>
            <div className="w-full flex justify-between pt-2">
              <div className="">
                <div className="font-medium ">{order?.productName ?? "-"}</div>
                <p className="text-[#99999C] ">Purchased</p>
              </div>
              <div className=" flex flex-col justify-end ">
                <div className="font-medium text-right ">
                  {order?.amount ?? "-"} {order?.currency ?? "-"}
                </div>
                <p className="text-[#99999C] ">
                  {order?.dateCreated} at {order?.time}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Separator className="mt-4" />

        <div className="">
          <TextArea
            placeholder="Add a reason for cancellation"
            label="Add a Description (optional)"
            // form={form as any}
            value={note}
          />
        </div>
        <Separator className="mt-4 mb-2" />
        <Button
          disabled={loading}
          onClick={handleDelete}
          className="w-full"
          variant="warn"
        >
          {loading ? (
            <Loader className="animate-spin" />
          ) : (
            "Cancel Order & Refund"
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
