import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import { ButtonLoader, Checkbox } from "components/common/form";
import { ReactComponent as IconFolderBusinessInfo } from "assets/images/icons/folder-business-info.svg";
import { ReactComponent as IconFolderBusinessDocs } from "assets/images/icons/folder-business-docs.svg";
import { ReactComponent as IconFolderDirectors } from "assets/images/icons/folder-directors.svg";
import { ReactComponent as IconFolderOwnerInfo } from "assets/images/icons/folder-owner-info.svg";
import useToolkit from "hooks/useToolkit";
import { completeKybSubmission } from "store/entities/businessVerification/action";
import { RootState } from "store/store";


const ReviewFinish = (props: any) => {
    const { goTo } = props;
    const [acceptAcknowledgement, setAcceptAcknowledgement] = useState(false);
    const { businessProfile, dispatch, useSelector } = useToolkit();
    const { completeKybLoading } = useSelector((state: RootState) => state.entities.businessVerification)


    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        if (acceptAcknowledgement === false) {
            toast.error("Please read and acknowledge the terms of use");
        }
        else {
            dispatch(completeKybSubmission(businessProfile?.id));
        }
    }

    return (
        <div>
            <AuthPageTitle
                title="Review & Submit"
                description="Confirm your compliance information and submit."
            />

            <div className="pt-10 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <div className="grid grid-cols-2 gap-x-12 gap-y-8 sm:gap-y-12">
                        <div className="flex justify-center">
                            <div onClick={() => goTo(1)} className="cursor-pointer">
                                <IconFolderBusinessInfo className="max-w-full" />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div onClick={() => goTo(2)} className="cursor-pointer">
                                <IconFolderBusinessDocs className="max-w-full" />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div onClick={() => goTo(3)} className="cursor-pointer">
                                <IconFolderOwnerInfo className="max-w-full" />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <div onClick={() => goTo(4)} className="cursor-pointer">
                                <IconFolderDirectors className="max-w-full" />
                            </div>
                        </div>
                    </div>

                    <div className="mt-12">
                        <Checkbox
                            type="checkbox"
                            label={<>
                                By submitting this form, you confirm that your information is accurate and you agree to&nbsp;
                                <Link to="https://payshiga.notion.site/PAYSHIGA-TERMS-OF-USE-c14e881663ec40c7875e61ff0eebc40b?pvs=4" target="_blank" className="border-b border-shiga-gray hover:border-shiga-black hover:text-shiga-black">
                                    Payshiga’s Terms of use
                                </Link>
                            </>}
                            checked={acceptAcknowledgement === true}
                            onChange={() => setAcceptAcknowledgement(!acceptAcknowledgement)}
                            readOnly={true}
                            containerClass="!items-start text-shiga-gray text-sm"
                        />
                    </div>

                    <div className="mt-10">
                        <ButtonLoader type="submit" loading={completeKybLoading} className="btn btn-block btn-lg btn-primary">
                            Confirm & Submit
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
}


export default ReviewFinish;