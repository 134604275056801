import { ReactComponent as IconInfo } from 'assets/images/icons/Info.svg'
import getPasswordStrength from 'utils/getPasswordStrength';

const PasswordStrengthIndicator = (props: any) => {
    const { password } = props;

    const activeClass = 'line-through font-medium text-shiga-dark-100'

    return (
        <div className="-mt-3.5 mb-5 text-sm text-shiga-gray-300 flex items-start justify-start font-inter">

            <IconInfo />

            <div className="ml-1">
                At least&nbsp;

                <span className={`${getPasswordStrength(password).hasCharacterLength ? activeClass : ''}`}>
                    8 characters,
                </span>

                &nbsp;containing&nbsp;

                <span className={`${getPasswordStrength(password).hasUpperCase ? activeClass : ''}`}>
                    an Uppercase letter,
                </span>&nbsp;

                <span className={`${getPasswordStrength(password).hasSpecialCharacter ? activeClass : ''}`}>
                    a symbol,
                </span>&nbsp; and&nbsp;

                <span className={`${getPasswordStrength(password).hasNumber ? activeClass : ''}`}>
                    a number.
                </span>

            </div>
        </div>
    )
}

export default PasswordStrengthIndicator
