import React, { useState, ChangeEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormMessage } from "components/ui/form";
import TransferHeader from "components/transfer/TransferHeader";
import PillTabs from "components/common/usePillTabs";
import { ButtonLoader, Input, SelectInput } from "components/common/form";
import CommandSelect from "components/ui/command-select";
import { CommandInput } from "components/ui/command";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { CircleCheck } from "lucide-react";
import { getAddressObject } from "lib/format.address";
import { toast } from "react-toastify";
import SavedBeneficiaryList from "../SavedBeneficiaryList";
import PATHS from "NavigationRoute";
import { getWalletsAndBalance } from "store/ui/dashboard/action";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  getTransferBanks,
  getTransferFields,
  resetTransferData,
  transferNameEnquiry,
  updateDynamicField,
  updateSelectedCurrency,
  updateSelectedWaletForTransfer,
} from "store/transfer/action";
import { useSharedStore } from "zustand-store";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { ReactComponent as Lock } from "assets/images/icons/payment/lock.svg";
import { googleApiKey } from "data/config";
import usePillTabs from "components/common/usePillTabs";
import AUDTransferFields from "./AUDTransferFields";
import NGNTransferFields from "./NGNTransferFields";
import USDTransferFields from "./USDTransferFields";
import BankSelector from "./BankSelector";
import { Separator } from "components/ui/separator";

const AUDTransferSchema = z.object({
  transferType: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
  bsbNumber: z.string().min(1, "BSB Number is required"),
});
const NGNTransferSchema = z.object({
  bankName: z.string(),
  bankCode: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
});

const USDTransferSchema = z.object({
  transferType: z.string(),
  bankName: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
  accountType: z.string(),
  bankAddress: z
    .object({
      description: z.string(),
      place_id: z.string(),
    })
    .optional(),
  bankState: z.string().optional(),
  bankCity: z.string().optional(),
  postCode: z.string().optional(),
  routingNo: z.string().min(1, "Routing Number is required"),
});

const FormSchema = z
  .object({
    country: z.string().optional(),
    transferType: z.string().optional(),
    accountType: z.string().optional(),
    bankCode: z.string().optional(),
    accountName: z.string().optional(),
    accountNumber: z.string().optional(),
    routingNo: z.string().optional(),
    bankName: z.string().optional(),
    bankAddress: z
      .object({
        description: z.string().optional(),
        place_id: z.string().optional(),
      })
      .optional(),
    bankState: z.string().optional(),
    bankCity: z.string().optional(),
    bsbNumber: z.string().optional(),
    postCode: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.country === "AUD") {
        return AUDTransferSchema.safeParse(data).success;
      }
      if (data.country === "USD") {
        return USDTransferSchema.safeParse(data).success;
      }
      if (data.country === "NGN") {
        return NGNTransferSchema.safeParse(data).success;
      }
      return true;
    },
    {
      message: "Invalid form data for the selected currency",
    }
  );

const Payout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [location, setLocation] = useState("");
  const [transferType, setTransferType] = useState("");

  const {
    currency,
    formFields,
    bankList,
    loadingBank,
    loadingName,
    accountName,
    transferFieldStatus,
    transferFields,
  } = useSelector((allStates: any) => allStates.transfer);

  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      country: "",
      transferType: "",
      bankName: "",
      accountName: "",
      accountType: "",
      bankCode: "",
      accountNumber: "",
      routingNo: "",
      bankAddress: {
        description: "",
        place_id: "",
      },
      bsbNumber: "",
      bankState: "",
      bankCity: "",
      postCode: "",
    },
    mode: "onTouched",
  });

  const {
    handleSubmit,
    setValue,
    getFieldState,
    getValues,
    setError,
    watch,
    formState,
  } = form;
  const { isSubmitting, isValid, touchedFields, errors } = formState;
  // const { errors } = formState;

  // Handle tab state
  const { activeTab, PillTabs } = usePillTabs();
  const [transferCurrency, setTransferCurrency] = useState("");

  // Determine if any fields have been touched
  const isTouched = Object.keys(touchedFields).length > 0;

  // Disable the button if form is not valid or no fields have been touched
  const isButtonDisabled = !isValid || !isTouched;

  // useEffect(() => {
  //   dispatch(resetTransferData());
  //   dispatch(getWalletsAndBalance());
  // }, [dispatch]);

  // const {
  //   currency,
  //   formFields,
  //   bankList,
  //   loadingBank,
  //   loadingName,
  //   accountName,
  //   transferFieldStatus,
  //   transferFields,
  // } = useSelector((state: any) => state.transfer);
  const country = watch("country");
  const allValues = watch();

  // useEffect(() => {
  // console.log("accountType changed:", accountType);
  // console.log("Country changed:", country);
  // console.log("bank name changed:", bankName);
  // console.log("bank code changed:", bankCode);
  //   console.log("All form values:", allValues);
  // }, [allValues]);

  // useEffect(() => {
  //   if (currency?.length > 0) {
  //     dispatch(getTransferBanks(currency));
  //   }
  // }, [currency, dispatch]);

  // useEffect(() => {
  //   if (
  //     formFields?.accountNumber?.length === 10 &&
  //     formFields?.bankName?.bankCode?.length > 0
  //   ) {
  //     dispatch(
  //       transferNameEnquiry(
  //         formFields.accountNumber,
  //         formFields.bankName?.bankCode
  //       )
  //     );
  //   }
  // }, [formFields?.accountNumber, formFields?.bankName?.bankCode, dispatch]);

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    const {
      country,
      transferType,
      bankName,
      accountName,
      accountType,
      bankCode,
      accountNumber,
      routingNo,
      bankAddress,
      bsbNumber,
    } = data;

    navigate(PATHS.TRANSFER_AMOUNT);
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== "") {
        dispatch(updateDynamicField(key, value));
      }
    });
    // dispatch(updateDynamicField(fieldName, value));
  };

  const updateFields = (fieldName: string, value: string) => {
    dispatch(updateDynamicField(fieldName, value));
  };

  const clickContinue = () => {
    navigate(PATHS.TRANSFER_AMOUNT);
    dispatch(getTransferFields(transferCurrency));
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCurrency = e.target.value;
    const newTransferType =
      selectedCurrency === "AUD"
        ? "BSB"
        : selectedCurrency === "USD"
        ? "ACH"
        : "";
    // setTransferCurrency(selectedCurrency);
    setTransferType(newTransferType);
    dispatch(
      updateSelectedCurrency({
        country: selectedCurrency,
        currency: selectedCurrency,
      })
    );
    const selectedWallet = wallet.find(
      (curr: any) => curr.currency === selectedCurrency
    );
    dispatch(updateSelectedWaletForTransfer(selectedWallet));
    form.setValue("country", selectedCurrency);
    form.setValue("transferType", transferType);
    form.reset({
      country: selectedCurrency,
      transferType: newTransferType,
      bankName: "",
      accountName: "",
      bankCode: "",
      accountNumber: "",
      routingNo: "",
      bankAddress: {
        description: "",
        place_id: "",
      },
      bsbNumber: "",
      bankCity: "",
      bankState: "",
      postCode: "",
    });
  };

  useEffect(() => {
    if (country && transferCurrency !== country) {
      setTransferCurrency(country);
    }
  }, [country]);

  const onClose = () => {
    form.reset({
      country: "",
      transferType: "",
      bankName: "",
      accountName: "",
      bankCode: "",
      accountNumber: "",
      routingNo: "",
      bankAddress: {
        description: "",
        place_id: "",
      },
      bsbNumber: "",
      bankCity: "",
      bankState: "",
      postCode: "",
    });
    navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE);
  };

  // useEffect(() => {
  //   // Sync transferCurrency with form value when country changes
  //   const countryValue = form.getValues("country");
  //   if (countryValue && countryValue !== transferCurrency) {
  //     setTransferCurrency(countryValue);
  //   }
  // }, [transferCurrency]);

  return (
    <div className="w-full transfer-container">
      <TransferHeader step={1} onClose={onClose} />
      <div className="flex flex-col justify-center items-center text-left ">
        {/* <div className="transfer-wrapper"> */}
        {activeTab === 0 && (
          <div
          // className="max-w-xl border-4 w-full mt-8 border-4"
          >
            <Form {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-[412px] space-y-4 mb-12"
              >
                <header className=" mt-8 text-[32px] font-ojah font-[400] text-left ">
                  Add a recipient
                </header>
                <Separator className="" />
                <div className="pt-4 pb-8">
                  <PillTabs
                    tabs={[
                      { label: "New Recipient", value: 0 },
                      { label: "Beneficiary", value: 1 },
                    ]}
                  />
                </div>
                <div className="mt-24">
                  <SelectInput
                    // type="text"
                    label="Country"
                    placeholder="Search for Country"
                    required={true}
                    {...form.register("country")}
                    onChange={handleCountryChange}
                    value={country}
                  >
                    {filteredCurrencies.map((item, index) => (
                      <option value={item.currency} key={index * 2}>
                        <p className="flex items-center gap-1.5">
                          <img
                            src={item?.icon || noisygradient}
                            alt={item.name}
                            className="size-5"
                          />
                          {item.country}
                        </p>
                      </option>
                    ))}
                  </SelectInput>

                  {form.getValues("country") === "USD" && (
                    <USDTransferFields form={form} />
                  )}

                  {form.getValues("country") === "AUD" && (
                    <AUDTransferFields form={form} />
                  )}
                  {form.getValues("country") === "NGN" && <BankSelector />}
                </div>

                <ButtonLoader
                  className="btn btn-lg btn-block btn-shiga-black mt-6"
                  type="submit"
                  // disabled={formState.isSubmitting}
                  disabled={isButtonDisabled || isSubmitting}
                >
                  Continue
                </ButtonLoader>
              </form>
            </Form>
          </div>
        )}

        {activeTab === 1 && (
          <SavedBeneficiaryList
            className="max-w-xl w-full mt-16"
            onClick={clickContinue}
          />
        )}
      </div>
    </div>
  );
};

export default Payout;
