import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function getNgnDepositAccount(id: any) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetNgnDepositAccount,
      method: "get",
      data: {},
      onStart: actions.GET_NGN_DEPOSIT_ACCOUNT_START,
      onSuccess: actions.GET_NGN_DEPOSIT_ACCOUNT_DONE,
      onError: actions.GET_NGN_DEPOSIT_ACCOUNT_FAILED,
    },
  };
}

export function getIssuedAccounts(params: any) {
  const query = params ? ObjectToQueryString(params) : "";
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetIssuedAccounts + query,
      method: "get",
      data: {},
      onStart: actions.GET_ISSUED_ACCOUNTS_START,
      onSuccess: actions.GET_ISSUED_ACCOUNTS,
      onError: actions.GET_ISSUED_ACCOUNTS_FAILED,
    },
  };
}
