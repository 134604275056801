import { ButtonLoader, Input, SelectInput } from "components/common/form";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import PurchaseInfo from "./PurchaseInfo";
import StepHeader from "./StepHeader";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getCurrenciesStore } from "store/ui/currencies/reducer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { getElectricityServices } from "store/bills-payment/actions";
import { getBillPaymentServices } from "store/bills-payment/reducer";
import { getCurrencies } from "store/ui/currencies/action";
import InputAmount, {
  InputAmountHeader,
} from "components/common/form/InputAmount";
import { validateElectricity } from "lib/bill-payments";
import {
  MeterType,
  ValidateElectricity,
  defaultEletricity,
} from "types/billPayment";
import { toast } from "react-toastify";
import { LoaderCircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import FormHeader from "components/common/form/FormHeader";
import { currencyFormat } from "lib/format.amount";
import InfoIcon from "assets/images/icons/Info";
import CustomAmountField from "components/custom/CustomAmountField";
import { useSharedStore } from "zustand-store";
import { Wallet, defaultWallet } from "types/wallet";
import { getCurrentBalance } from "lib/business";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import useBillsPayment from "lib/bill-payments/useBillsPayment";
import PATHS from "NavigationRoute";
import useToolkit from "hooks/useToolkit";

const formSchema = z.object({
  number: z.string(),
  provider: z.string(),
  amount: z.number(),
  meterType: z.string(),
  country: z.string().optional(),
  currency: z.string(),
});

type FormType = z.infer<typeof formSchema>;

const BuyElectricity = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [validating, setValidating] = useState(false);
  const [pageStep, setPageStep] = useState<number>(1);
  const [validatedElectricity, setValidatedElectricity] =
    useState<ValidateElectricity>(defaultEletricity);
  const [amount, setAmount] = useState("");
  const [providerName, setProviderName] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const { buyElectricity, loadingBuyElectricity } = useBillsPayment();
  const { showCustomSuccessToast } = useToolkit();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: "",
      provider: "",
      currency: "NGN",
    },
    context: "onChange",
    criteriaMode: "all",
    mode: "onChange",
  });

  const { currenciesList: currenciesListStore } =
    useAppSelector(getCurrenciesStore);
  const { electricity: electricityStore } = useAppSelector(
    getBillPaymentServices
  );

  const electricity = useMemo(
    () => electricityStore?.data || [],
    [electricityStore]
  );
  // const currenciesList = useMemo(
  //   () => currenciesListStore?.data.data || [],
  //   [currenciesListStore]
  // );
  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 0) {
      const value = event.target.value;
      setAmount(value);
      form.setValue("amount", Number(value));
    }
  };

  useEffect(() => {
    const curr = form.getValues("currency");
    if (curr) {
      getCurrentBalance(curr)
        .then((data: any) => {
          setCurrentWallet(data.data);
          // form.setValue("balance" as Path<K>, String(data.data.balance) as any);
        })
        .catch((err) => {
          form.setValue("currency", currentWallet.currency as any);
          toast.error(`Unable to get balance ${err.message}`);
        });
    }
  }, [currentWallet.currency, form]);

  // const amount = form.getValues("amount")
  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(amount) > currentWallet?.balance;
  const insufficientAmount = amount.length > 0 && Number(amount) < 100;

  const smartCardNumber = form.watch("number");
  const provider = form.watch("provider");
  const meterType = form.watch("meterType");

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getElectricityServices());
  }, []);

  const handleValidateMeterNumber = () => {
    const selectedElectricityProvider = electricity.filter(
      (item) => item?.serviceCategoryId === provider
    );

    setProviderName(selectedElectricityProvider[0].name);
    setValidating(true);
    validateElectricity({
      entityNumber: Number(smartCardNumber),
      serviceCategoryId: provider,
      type: meterType as MeterType,
    })
      .then((data) => {
        if (data.success) {
          setValidatedElectricity(data.data);
        } else {
          form.setError("number", {
            message: `There is something wrong with smartcard number ${data.message}`,
          });
        }
      })
      .catch((err) => {
        setValidatedElectricity(defaultEletricity);
        toast.error(`Error ${err.message}`);
      })
      .finally(() => {
        setValidating(false);
      });
  };

  useEffect(() => {
    if (smartCardNumber.length > 9) {
      if (!provider.length) {
        toast.info("Please select a provider");
      } else {
        handleValidateMeterNumber();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, smartCardNumber, meterType]);

  const onSubmit = ({ number, provider, amount, meterType }: FormType) => {
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      return buyElectricity({
        amount,
        currency: "NGN",
        number,
        provider,
        utilityBillName: provider,
        vendType: meterType,
        serviceCategoryId: provider,
        debitAccountNumber: provider,
        meterNumber: number,
      })
        .then((data) => {
          showCustomSuccessToast({
            message: `You have successfully paid ${amount?.toLocaleString()} ${
              currentWallet?.currency
            } to ${providerName} ${meterType}`,
            onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        })
        .catch((err) => {
          toast.error(`Error: ${err.message}`);
        });
    }
  };

  return (
    <div>
      <StepHeader
        step={pageStep}
        goBack={() => setPageStep((prev) => prev - 1)}
        close={() => {
          setPageStep((prev) => prev - 1);
          form.reset();
        }}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] mx-auto"
        >
          <FormHeader>
            {pageStep >= 2
              ? `Confirm your purchase for ${providerName} Prepaid plan`
              : "Electricity"}
          </FormHeader>
          {pageStep === 1 && (
            <>
              <div className="mb-[26px]">
                <FormField
                  control={form.control}
                  name="provider"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Select a Service provider"
                          placeholder="Select a provider"
                          required={true}
                          {...field}
                        >
                          {electricity.map((provider, index) => (
                            <option
                              value={provider?.serviceCategoryId}
                              key={index}
                            >
                              <div className="flex items-center gap-1.5">
                                <img
                                  src={provider.logoUrl}
                                  alt={provider.name}
                                  className="size-5"
                                />
                                {provider.name}
                              </div>
                            </option>
                          ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mb-[26px]">
                <FormField
                  control={form.control}
                  name="meterType"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Select a Meter type"
                          placeholder="Select an option"
                          required={true}
                          {...field}
                        >
                          {["PREPAID", "POSTPAID"].map((provider, index) => (
                            <option value={provider} key={index}>
                              {provider}
                            </option>
                          ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mb-[26px] relative">
                {validating ? (
                  <LoaderCircleIcon className="absolute top-0 right-0 animate-spin" />
                ) : validatedElectricity?.Customer_Name ? (
                  <p className="text-sm font-light text-shiga-dark absolute top-0 right-0 uppercase">
                    {validatedElectricity.Customer_Name}
                  </p>
                ) : (
                  <></>
                )}
                <FormField
                  control={form.control}
                  name="number"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="number"
                          label="Meter Number"
                          placeholder="081012345678"
                          required={true}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="pb-12">
                <CustomAmountField
                  leftLabel="Amount"
                  isFieldRequired
                  setInputAmount={updateAmount}
                  inputError={insufficientAmount || inputExceedsTrxAmount}
                  errorText={
                    insufficientAmount
                      ? "The minimum amount you can purchase is 100 NGN"
                      : inputExceedsTrxAmount
                      ? "You cannot make a purchase more than your available balance"
                      : "The minimum amount you can purchase is 100 NGN"
                  }
                  flagIconUrl={NGN}
                  inputAmount={amount}
                  transactionCurrency={currentWallet?.currency || ""}
                  rightLabel={
                    <p className="text-shiga-dark flex items-center font-inter">
                      Transaction amount:&nbsp;
                      <span className="text-shiga-purple font-medium">
                        {`${Number(currentWallet?.balance).toLocaleString()} ${
                          currentWallet?.currency ?? ""
                        }`}
                      </span>
                    </p>
                  }
                />

                {/* <InputAmount
                  form={form as any}
                  disableCurrency
                  currencies={currenciesList}
                  minAmount={0}
                  header={InputAmountHeader}
                />
                <div className="flex justify-start items-center mt-2">
                  <InfoIcon className="m-[3px]" />
                  <p className="text-[13px] font-light text-shiga-dark">
                    The minimum amount you can purchase is 100 NGN
                    <span className="font-normal text-shiga-black">
                      100 NGN
                    </span>
                  </p>
                </div> */}
              </div>
            </>
          )}
          {pageStep >= 2 && (
            <PurchaseInfo
              transactionType="Bills Payment"
              info={[
                {
                  attribute: "Amount",
                  value: `${form.getValues("amount")} NGN`,
                },
                { attribute: "Meter Number", value: form.getValues("number") },
                {
                  attribute: "Cable Provider",
                  value: providerName,
                },
                {
                  attribute: "Fee",
                  value: "Free 😎",
                },
              ]}
              className="mb-[60px]"
            />
          )}
          <ButtonLoader
            disabled={!form.formState.isValid}
            loading={loadingBuyElectricity}
            type="submit"
            className="btn btn-lg btn-block btn-primary mt-3"
          >
            {pageStep >= 2 ? "Confirm & Purchase" : "Continue"}
          </ButtonLoader>
        </form>
      </Form>
    </div>
  );
};

export default BuyElectricity;
