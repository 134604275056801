import axios from "axios";
import {
  apiBaseUrl,
  apiCreateAirtime,
  apiCreateElectricity,
  apiCreateInternetService,
  apiCreateTVService,
  apiValidateElectricity,
  apiValidateTV,
} from "data/config";
import { getToken } from "lib";
import {
  MeterType,
  ValidateBill,
  ValidateElectricity,
} from "types/billPayment";
import { CurrencyType } from "types/misc";

export const validateTV = async (body: {
  entityNumber: number;
  serviceCategoryId: string;
  provider: string;
}) =>
  axios
    .post(apiBaseUrl + apiValidateTV, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
        }
    );

export const validateElectricity = async (body: {
  entityNumber: number;
  serviceCategoryId: string;
  type: MeterType;
}) =>
  axios
    .post(apiBaseUrl + apiValidateElectricity, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateElectricity;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        }
    );

export const buyAirtime = (body: {
  currency: CurrencyType;
  networkType: string;
  provider: string;
  debitAccountNumber: string;
  serviceCategoryId: string;
  phoneNumber: string;
  amount: number;
}) =>
  axios
    .post(apiBaseUrl + apiCreateAirtime, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        }
    );

export const buyData = (body: {
  currency: string;
  networkType: string;
  packageName: string;
  provider: string;
  phoneNumber: string;
  amount: number;
  plan_id: string;
  debitAccountNumber: string;
  serviceCategoryId: string;
  bundleCode: string;
}) =>
  axios
    .post(apiBaseUrl + apiCreateInternetService, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        }
    );

export const buyCablePackage = (body: {
  currency: string;
  utilityBillName: string;
  packageName: string;
  provider: string;
  cardNumber: string;
  amount: string;
  plan_id: number;
  serviceCategoryId: string;
  debitAccountNumber: string;
  bundleCode: string;
}) =>
  axios
    .post(apiBaseUrl + apiCreateTVService, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        }
    );

export const buyElectricity = (body: {
  currency: CurrencyType;
  vendType: string;
  utilityBillName: string;
  provider: string;
  number: string;
  amount: number;
}) =>
  axios
    .post(apiBaseUrl + apiCreateElectricity, body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then(
      (res) =>
        res.data as {
          data: ValidateBill;
          status: boolean;
          statusCode: number;
          success: boolean;
          message: string;
        }
    );
