import axios from "axios";
import { apiBaseUrl, apiFundGBP, getWalletFundStatus } from "data/config";
import { getToken } from "lib";
import { handleError } from "lib/format.error";
import { CurrencyType, STATUS } from "types/misc";



export const fundGBPWallet = async (fundGBP: {
    currency: CurrencyType,
    amount: number;
}) =>
    axios.post(apiBaseUrl + apiFundGBP, fundGBP, {
        headers: {
            Authorization: "Bearer " + getToken(),
        },
    }).then(res => res.data as {
        data: {
            reference: string;
            url: string;
        };
        message: string;
        statusCode: number;
        success: boolean;
    }).catch(handleError)


export const getGBPWalletFundStatus = async (referenceID: string) =>
    axios.get(apiBaseUrl + getWalletFundStatus + `/${referenceID}`, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(res => res.data as {
        data: {
            status: STATUS
        }
    }).catch(handleError)
