import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader, Input } from "components/common/form";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import getPasswordStrength, { getPasswordStrengthLevel } from "utils/getPasswordStrength";
import PasswordStrengthIndicator from "components/common/PasswordStrengthIndicator";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import * as userActions from "store/auth/user/action";


const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { forgotPassword, resetPasswordLoading, resetPassword } = useSelector((s: any) => s.auth.user);

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [passwordStrengthLevel, setShowPasswordLevel] = useState<any>({
        level: 0,
        bgColor: "bg-[#E2E3E5]",
        textColor: "text-[#B4B4B4]",
        text: "Password Strength",
    });
    const [passwordStrength, setPasswordStrength] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        hasCharacterLength: false,
    });
    const [form, setForm] = useState({
        password: "",
        confirmPassword: "",
        token: "",
    })

    const updatePassword = (password: string) => {
        let newPasswordStrength = getPasswordStrength(password);
        let newPasswordLevel = getPasswordStrengthLevel(newPasswordStrength);
        setPasswordStrength(newPasswordStrength);
        setShowPasswordLevel(newPasswordLevel);
        setForm({ ...form, password });
    }

    const isFormValid = () => {
        let response = true;
        if (!(
            passwordStrength.hasUpperCase === true &&
            passwordStrength.hasLowerCase === true &&
            passwordStrength.hasNumber === true &&
            passwordStrength.hasSpecialCharacter === true &&
            passwordStrength.hasCharacterLength === true
        )) {
            response = false;
            toast.error("Please enter a strong password")
        }
        return response;
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors: any = validate(form);
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            if (isFormValid() === true) {
                dispatch(userActions.resetPassword(form));
            }
        }
    }

    useEffect(() => {
        if (forgotPassword?.success !== true) {
            navigate("/forgot-password");
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (resetPassword?.success === true) {
            dispatch(userActions.resetForgotPassword());
            dispatch(userActions.resetResetPassword());
            navigate("/reset-password/success");
        }
        // eslint-disable-next-line
    }, [resetPassword])

    const submitDisabled = !getPasswordStrength(form.password).hasCharacterLength
        || !getPasswordStrength(form.password).hasLowerCase
        || !getPasswordStrength(form.password).hasUpperCase
        || !getPasswordStrength(form.password).hasNumber
        || !getPasswordStrength(form.password).hasSpecialCharacter
        || form.confirmPassword !== form.password;

    return (
        <>
            <DynamicHeadTag
                headerText="Create new password"
            />

            <AuthPageTitle
                title="Create new password"
                description="Choose a new password for your business account."
                showBackButton={true}
                backButtonUrl="/forgot-password"
                utilityFn={()=> dispatch(userActions.resetForgotPassword())}
            />

            <div>
                <form onSubmit={submitForm}>
                    <div className="mt-10">

                        <Input
                            type={showPassword1 ? "text" : "password"}
                            autoComplete="false"
                            label="Enter new password"
                            placeholder="Password"
                            required={true}
                            value={form.password} onChange={(e: any) => updatePassword(e.target.value)}
                            rightIcon={showPassword1 ? <IconEyeOpen /> : <IconEyeClose />}
                            rightIconClicked={() => setShowPassword1(!showPassword1)}
                            rightIconClass="px-3"
                        />

                        <div className="-mt-2 mb-5">
                            <PasswordStrengthIndicator
                                password={form.password}
                                passwordStrengthLevel={passwordStrengthLevel}
                            />
                        </div>

                        <Input
                            type={showPassword2 ? "text" : "password"}
                            autoComplete="false"
                            label="Confirm new password"
                            placeholder="Password"
                            required={true}
                            value={form.confirmPassword} onChange={(e: any) => setForm({ ...form, confirmPassword: e.target.value })}
                            rightIcon={showPassword2 ? <IconEyeOpen /> : <IconEyeClose />}
                            rightIconClicked={() => setShowPassword2(!showPassword2)}
                            rightIconClass="px-3"
                        />

                        <Input
                            type="number"
                            label="Enter OTP sent to Email"
                            placeholder="6 Digit Code"
                            required={true}
                            value={form.token}
                            onChange={(e: any) => setForm({ ...form, token: e.target.value })}
                        />

                        <ButtonLoader disabled={submitDisabled} type="submit" loading={resetPasswordLoading} className="btn btn-lg btn-block btn-primary mt-8">
                            Reset Password
                        </ButtonLoader>

                    </div>
                </form>
            </div>
        </>
    )
}


export default ResetPassword;