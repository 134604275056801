import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Separator } from "components/ui/separator";
import { deletePaymentLink } from "lib/payments";
import { Loader } from "lucide-react";
import { useState } from "react";
import { PaymentLink } from "types/payment/payments.interface";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import { EmptyLinkImage } from "./EmptyLinkImage";
import { Stat } from "./Stat";
import { toast } from "react-toastify";
interface DeletePaymentLinkModalProps {
  open: boolean;
  closeModal: () => void;
  paymentLink: PaymentLink;
}

export function DeletePaymentLinkModal(props: DeletePaymentLinkModalProps) {
  const { paymentLink: link } = props;
  const [loading, setLoading] = useState(false);
  const { updatePaymentLink } = usePaymentLinkStore();

  const handleDelete = async () => {
    setLoading(true);
    updatePaymentLink(link, "delete");
    const res = await deletePaymentLink(link.id);
    if (res.success) {
      toast.success("Payment link deleted successfully");
      props.closeModal();
    }
    setLoading(false);
  };

  return (
    <Dialog open={props.open} onOpenChange={() => props.closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete this Link</DialogTitle>
        </DialogHeader>
        <Separator orientation="horizontal" />
        <div className="flex gap-3">
          <div className="w-[114px] h-[114px]">
            {link.logoUrl ? (
              <img
                className="h-full w-full rounded-xl"
                src={link.logoUrl}
                alt={link.name}
              />
            ) : (
              <EmptyLinkImage />
            )}
          </div>
          <div className="h-full">
            <div className="self-stretch pb-1.5 justify-start items-center gap-1.5 inline-flex">
              <div className="text-zinc-800 text-base font-medium font-['Inter']">
                {link.name}
              </div>
            </div>
            <Stat link={link} />
          </div>
        </div>
        <Separator className="mt-4 mb-2" />
        <Button
          disabled={loading}
          onClick={handleDelete}
          className="w-full"
          variant="warn"
        >
          {loading ? <Loader className="animate-spin" /> : "Yes, Delete"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
