import ShigaButton from "components/common/form/ShigaButton";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

type Props = {
   confirm: () => void;
   tableItems: BulkTransferItemProps[] | undefined;
   setTableItems: Dispatch<SetStateAction<BulkTransferItemProps[] | undefined>>;
}

const CompleteUpload = ({ confirm, tableItems }: Props) => {
   const { bulkTransferLoading: isLoading } = useSelector((state: RootState) => state.transfer)


   const transferContent = [
      {
         title: "Transaction Type",
         detail: "Bulk Payment",
      },
      {
         title: "Sent to",
         detail: `${tableItems?.length} recipients`,
      },
      {
         title: "From",
         detail: "NGN Wallet",
      },
      {
         title: "Fee",
         detail: "Free 😎",
      },
   ];

   return (
      <div className="max-w-md space-y-8 mx-auto">
         <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
            Complete bulk payments to {tableItems?.length} recipients
         </div>
         <div className="max-w-md space-y-8 mx-auto">
            <div className="p-3 border-2 space-y-0.5 border-gray-100 rounded-3xl">
               {transferContent.map((item, idx) => (
                  <div
                     className={`flex w-full py-2 px-3 justify-between ${idx < 3 ? "border-b border-gray-100" : ""
                        }`}
                     key={idx}
                  >
                     <div>{item.title}</div>
                     <div>{item.detail}</div>
                  </div>
               ))}
            </div>

            <div className="pt-4">
               <ShigaButton
                  darkBg
                  fullWidth
                  loading={isLoading}
                  text="Confirm & Pay"
                  onClick={confirm}
               />
            </div>

         </div>
      </div>
   )
}

export default CompleteUpload;