import { useEffect, useRef } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import ShigaButton from 'components/common/form/ShigaButton';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { getTransactionsStore } from 'store/transactions/reducer';
import EmptyTransactions from './components/EmptyTransactions';
import Maybe from 'components/common/Maybe';
import { currencyFormat } from 'utils/CurrencyFormat';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { TransactionType, TransactionNarration, TransactionFilterHandler } from './TransactionUtils';
import TableDropdown from 'components/common/TableDropdown';
import { ReactComponent as IconShare } from "assets/images/icons/Share.svg";
import { ReactComponent as IconRefund } from "assets/images/icons/refresh-gray.svg";
import { useReactToPrint } from 'react-to-print';
import { Transaction, defaultTransaction } from 'types/transaction';
import PaymentRefundModal from './components/table/PaymentRefundModal';
import { getTransactions } from 'store/transactions/action';
import TransactionDetailsModal from './components/table/TransactionDetailsModal';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import TransactionPdfReceipt from './components/transaction-detai-types/TransactionPdfReceipt';
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { GenerateStatementModal } from 'pages/dashboard/components/GenerateStatementModal';
import { getDashboardWallets } from 'store/dashboard/action';
import { RootState } from 'store/store';
import PATHS from 'NavigationRoute';


const TransactionsHomepage = () => {
   const navigate = useNavigate();
   const { copyItem, useState } = useToolkit();
   const dispatch = useAppDispatch();
   const receiptRef = useRef<HTMLDivElement>(null);
   const [selected, setSelected] = useState<Transaction>();

   const [refundOpen, setRefundOpen] = useState(false);
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [receiptDate, setReceiptDate] = useState('');
   const location = useLocation();
   const { urlQueryParams } = useUrlQueryParams();
   const [statementOpen, setStatementOpen] = useState(false);

   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });

   const { transactions, isLoadingTransactions: transactionsLoading } = useSelector(getTransactionsStore);
   const { wallets } = useSelector((state: RootState) => state.dashboard);

   const transactionsAreValid = transactionsLoading === false &&
      transactions?.data?.payload && transactions?.data?.payload?.length > 0;

   const handlePrint = useReactToPrint({
      content: () => receiptRef?.current,
      documentTitle: 'Shiga Business Transaction receipt - ' + receiptDate,
   });

   const generateReceipt = () => {
      if (receiptRef) {
         handlePrint();
      }
   }

   const triggerRefund = () => {
      setRefundOpen(true);
   }

   const fetchTransactions = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "currency", "string", "", "currency");
      params = processParams(urlQueryParams, params, "channel", "string", "", "channel");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "method", "string", "", "method");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
      params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

      setListParams(params);
      dispatch(getTransactions(params));
      dispatch(getDashboardWallets());
   }

   useEffect(() => {
      fetchTransactions();
      // eslint-disable-next-line
   }, [location]);

   useEffect(() => {
      if (selected) {
         const formattedDate = moment(selected?.createdAt).format('MMM D, YYYY HH:mm:ss')
         setReceiptDate(formattedDate)
      }
   }, [selected])

   return (
      <div className='dashboard-page-container'>

         <DynamicHeadTag
            headerText="Transactions"
         />

         <GenerateStatementModal
            currentWallet=''
            open={statementOpen}
            wallets={wallets?.data || []}
            closeModal={() => setStatementOpen(false)}
         />

         <TransactionDetailsModal
            isOpen={detailsOpen}
            receiptRef={receiptRef}
            setIsOpen={setDetailsOpen}
            generateReceipt={generateReceipt}
            triggerRefund={triggerRefund}
            transaction={selected || defaultTransaction}
         />

         <PaymentRefundModal
            isOpen={refundOpen}
            setIsOpen={setRefundOpen}
            transaction={selected}
         />

         <div className="hidden">
            <TransactionPdfReceipt
               receiptRef={receiptRef}
               transaction={selected || defaultTransaction}
            />
         </div>

         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg-flex-nowrap lg:border-b lg:border-shiga-gray-50 pb-10">
            <div className="page-title w-full lg:w-max">
               Transactions
            </div>

            <div className="w-full lg:w-max pt-5 lg:pt-0 flex items-center space-x-3">
               <ShigaButton
                  darkBg
                  text="Make a Transfer"
                  onClick={() => navigate(PATHS.TRANSFERS.MAKE_A_TRANSFER)}
               />

               <ShigaButton
                  whiteBg
                  text="Generate Statement"
                  onClick={() => setStatementOpen(true)}
               />
            </div>
         </div>

         <div className="pb-24 pt-6">

            <Maybe condition={!transactionsLoading}>
               <TransactionFilterHandler transactions={transactions} />
            </Maybe>

            <Table>

               <Maybe condition={(transactionsLoading === false && !transactions) || (transactionsAreValid)}>
                  <Table.Head>
                     <th>Narration</th>
                     <th>Amount</th>
                     <th>Type</th>
                     <th>Reference</th>
                     <th>Date</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     total={transactions?.data?.payload?.length || 0}
                     loading={transactionsLoading}
                     loadingText="Fetching transactions"
                     data={transactions?.data?.payload}
                     colspan={6}
                     emptyUI={
                        <EmptyTransactions />
                     }
                  />

                  {transactionsLoading === false && transactions?.data?.payload?.map((transaction) => {
                     const isCredit = transaction?.type === 'CREDIT';
                     const isCurrencySwap = transaction?.method === 'SWAP';
                     const isApiTrx = transaction?.medium === 'API'
                     const isApiNgnTransfer = transaction?.currency === 'NGN' && isApiTrx && transaction?.channel === 'BANK_TRANSFER';
                     const isFailedTransaction = transaction?.status === 'FAILED'
                     const isBankTransfer = transaction?.method === 'BANK_TRANSFER' || transaction?.channel === 'BANK_TRANSFER';


                     const isNgnDirectDebit = transaction?.currency === 'NGN' && transaction?.channel === 'DIRECT_DEBIT' && transaction?.deposit?.depositType === 'CARD';

                     const isApiCardDebit = transaction?.type === 'DEBIT' && transaction?.deposit?.depositType === 'CARD';

                     const allowRefund = isApiNgnTransfer || isNgnDirectDebit || isApiCardDebit

                     return (
                        <tr className='cursor-pointer font-inter' key={transaction.id} onClick={() => {
                           setSelected(transaction);
                           setDetailsOpen(true);
                        }}>
                           <td>
                              <TransactionNarration transaction={transaction} />
                           </td>
                           <td className={`font-medium ${((isCredit || isCurrencySwap) && !isFailedTransaction) ? 'text-shiga-green-100' : 'text-shiga-gray-300'} ${isFailedTransaction && 'line-through'}`}>
                              {isCredit && "+ "}
                              {currencyFormat(transaction?.amount, transaction?.currency)}
                           </td>
                           <td>
                              <TransactionType transaction={transaction} />
                           </td>
                           <td>
                              <div className='flex items-center font-medium'>
                                 {transaction.reference}
                                 <button className='ml-1.5' onClick={(e: any) => {
                                    e.stopPropagation();
                                    copyItem(transaction?.reference, 'Transaction reference');
                                 }}>
                                    <CopyIcon width={20} />
                                 </button>
                              </div>
                           </td>
                           <td>
                              <div className='font-medium'>
                                 {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <div className='hover:bg-gray-200 py-1 px-px rounded-md' onClick={(e: any) => e.stopPropagation()}>
                                 <TableDropdown
                                    allowClick={isBankTransfer}
                                    onRowClick={() => setSelected(transaction)}
                                    options={[
                                       {
                                          permission: isBankTransfer,
                                          title: 'Share receipt',
                                          Icon: IconShare,
                                          onClick: () => generateReceipt(),
                                       },
                                       {
                                          permission: allowRefund,
                                          title: 'Refund payment',
                                          Icon: IconRefund,
                                          onClick: () => triggerRefund()
                                       },
                                    ]}
                                 />
                              </div>
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>
      </div>
   )
}

export default TransactionsHomepage