import axios from "axios";
import { apiBaseUrl, getGiftCardsByCountry as apiGetGiftCardsByCountry, apiOrderGiftCard } from "data/config";
import { getToken } from "lib";

export const getGiftCardsByCountry = async (
  country: string
): Promise<any> =>
  axios.get(
    apiBaseUrl + `${apiGetGiftCardsByCountry}/${country}`,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  ).then(res => res.data)


export const orderGiftCard = async (
  orderCard: {
    amount: number;
    currency: string;
    email: string;
    productId: number;
    quantity: number;
    note: string;
    reference?: string;
  }
): Promise<any> =>
  axios.post(
    apiBaseUrl + apiOrderGiftCard,orderCard,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  ).then(res => res.data)