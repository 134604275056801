import { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSharedStore } from "zustand-store";
import { paymentsLinks } from "./payments-routes";
import { PAYMENT_PATHS } from "./paths";
import { Badge } from "components/ui/badge";

export const PaymentsContainer = () => {
  const location = useLocation();
  const { fetchUniversities } = useSharedStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUniversities("NG");
  }, [fetchUniversities]);

  useEffect(() => {
    if (location.pathname === PAYMENT_PATHS.INDEX) {
      navigate(PAYMENT_PATHS.PAYMENT_LINK);
    }
  }, [location, navigate]);

  const active =
    paymentsLinks.find((link) => location.pathname === link.url) ||
    paymentsLinks.find((link) => location.pathname.includes(link.url)) ||
    paymentsLinks[0];

  return (
    <div className=" xl:flex  sm:px-5 gap-[55px] ">
      <div className="w-[260px]  py-[28px] bg-white border-r border-neutral-100 flex-col justify-start items-start gap-1 inline-flex">
        <div className="self-stretch  flex-col justify-start items-start gap-1 flex">
          {paymentsLinks.map((link) => {
            const isActive = active?.url === link.url;
            return (
              link.available ?
                <NavLink
                  to={link.url}
                  id={link.url}
                  key={link.url}
                  className={`self-stretch py-2 px-2 rounded-tl-md rounded-bl-md justify-start items-center gap-1.5 inline-flex ${isActive ? "bg-slate-50 border-r-4 border-indigo-600" : ""
                    }`}
                >
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <div
                      className={`${isActive ? "text-zinc-800" : "text-neutral-400"
                        } text-sm font-medium font-['Inter']`}
                    >
                      {link.text}
                    </div>
                  </div>
                </NavLink> :
                <div
                  key={link.url}
                  className={`self-stretch py-2 px-2 cursor-not-allowed  rounded-tl-md rounded-bl-md  justify-start items-center gap-1.5 inline-flex ${isActive ? "bg-slate-50 border-r-4 border-indigo-600" : ""
                    }`}
                >
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                    <div
                      className={`${isActive ? "text-zinc-800" : "text-neutral-400"
                        } text-sm font-medium font-['Inter']`}
                    >
                      {link.text}
                      <Badge variant="outline" className="ml-2">Coming soon</Badge>
                    </div>
                  </div>
                </div>
            );
          })}
        </div>
      </div>

      <Outlet />
    </div>
  );
};
