import { ReactComponent as IconEmptyTransactions } from "assets/images/icons/empty-transactions.svg";

const EmptyAccounts = () => {
  return (
    <div className="flex items-center justify-center flex-col mt-40 text-center">
      <svg
        width={84}
        height={84}
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Add descriptive comments for each path */}
        <path
          d="M82.139 30.4588L44.8556 1.75081C43.1884 0.416395 40.8116 0.416397 39.1444 1.75081L1.86101 30.4588C-0.315498 32.2008 0.923226 35.6957 3.71717 35.6957H10.1196H19.2283H28.337H37.4458H46.5545H55.6632H64.7719H73.8806H80.2828C83.0768 35.6957 84.3155 32.2008 82.139 30.4588Z"
          fill="#99999C"
        />
        <path
          d="M55.6632 68.5295V35.6957H46.5545V68.5295H55.6632Z"
          fill="#E2E3E5"
        />
        <path
          d="M28.337 35.6957V68.5295H37.4458V35.6957H28.337Z"
          fill="#E2E3E5"
        />
        <path
          d="M10.1196 35.6957V68.5295H19.2283V35.6957H10.1196Z"
          fill="#E2E3E5"
        />
        <path
          d="M73.8806 68.5295V35.6957H64.7719V68.5295H73.8806Z"
          fill="#E2E3E5"
        />
        <path
          d="M10.1196 68.5295H8.27153C5.75622 68.5295 3.71717 70.5571 3.71717 73.0583V80.9837C3.71717 82.2343 4.73669 83.2481 5.99435 83.2481H78.0057C79.2633 83.2481 80.2828 82.2343 80.2828 80.9837V73.0583C80.2828 70.5571 78.2438 68.5295 75.7285 68.5295H73.8806H64.7719H55.6632H46.5545H37.4458H28.337H19.2283H10.1196Z"
          fill="#99999C"
        />
      </svg>
      <h4 className="font-ojah text-[26px] leading-8 mt-6 mb-2">
        No Issued Accounts Yet
      </h4>
      <p className="text-shiga-dark w-full">
        No bank account has currently been issued at this time.
      </p>
    </div>
  );
};

export default EmptyAccounts;
