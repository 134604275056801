export const settingsLinks = [
    {
        url: "/settings/profile",
        text: "My Profile",
    },
    {
        url: "/settings/business-profile",
        text: "Business Profile",
    },
    {
        url: "/settings/settlement-accounts",
        text: "Settlement Accounts",
    },
    {
        url: "/settings/team",
        text: "Team",
    },
    {
        url: "/settings/fees-earnings",
        text: "Fees & Earnings",
    },
    {
        url: "/settings/payment-methods",
        text: "Payment Methods",
    },
    {
        url: "/settings/notifications",
        text: "Notification Preferences",
    },
    {
        url: "/settings/keys-webhooks",
        text: "API Keys & Webhooks",
    },
]