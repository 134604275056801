import BackBtnIcon from "assets/images/icons/BackBtn";
import ChevronRightIcon from "assets/images/icons/ChevronRight";
import CloseBtnIcon from "assets/images/icons/CloseBtn";
import MoneyIcon from "assets/images/icons/Money";
import PaperPlaneIcon from "assets/images/icons/PaperPlane";

interface StepHeaderProps {
  step: number;
  goBack: () => void;
  close: () => void;
  title?: boolean;
}

const StepHeader = ({ step, goBack, close, title }: StepHeaderProps) => {
  return (
    <header
      className={`pb-7 border-b border-outline-grey flex ${
        step >= 2 ? "justify-between" : "justify-center"
      } items-center relative`}
    >
      {step >= 2 && <BackBtnIcon className="cursor-pointer" onClick={goBack} />}
     <div className=" gap-5 flex justify-center items-center relative">
        <div className="flex justify-center  items-center gap-3">
          <MoneyIcon isActive={step >= 1} className="w-[22px] h-[22px]" />
          <p
            className={`text-[14px] font-[500] ${
              step >= 1 ? "text-shiga-black" : "text-shiga-gray"
            }`}
          >
            Payment Info
          </p>
        </div>
        <ChevronRightIcon />
        <div className="flex justify-center items-center gap-3">
          <PaperPlaneIcon isActive={step >= 2} className="w-[22px] h-[22px]" />
          <p
            className={`text-[14px] font-[500] ${
              step >= 2 ? "text-shiga-black" : "text-shiga-gray"
            }`}
          >
            Review & Purchase
          </p>
        </div>
      </div>
      {step >= 2 && <CloseBtnIcon className="cursor-pointer" onClick={close} />}
      {/* {step >= 2 && (
        <div className=" flex justify-between place-items-center border-4">
          <BackBtnIcon className="cursor-pointer" onClick={goBack} />
          <CloseBtnIcon className="cursor-pointer" />
        </div>
      )} */}
    </header>
  );
};

export default StepHeader;
