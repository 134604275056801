import NigeriaFlag from "assets/images/icons/NigeriaFlag";
import WalletIcon from "assets/images/icons/Wallet";

interface AmountInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isFieldRequired: boolean;
  amountLeft: number;
}

const AmountInput = ({
  label,
  amountLeft,
  isFieldRequired,
  ...props
}: AmountInputProps) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <label
          htmlFor="purchase-amount"
          className="font-light text-sm text-shiga-gray"
        >
          {label}
          {isFieldRequired && <span className="form-input-required">*</span>}
        </label>
        <div className="flex justify-end items-center">
          <WalletIcon className="mr-[2px]" />
          <p className="text-sm text-shiga-dark font-light">
            You have{" "}
            <span className="font-normal text-shiga-purple">{`${amountLeft}NGN`}</span>{" "}
            available
          </p>
        </div>
      </div>
      <div className="flex justify-start items-center my-3 border border-outline-grey-2 py-5 pl-[14px] pr-[9px] rounded-2xl h-[77px]">
        <input
          type="number"
          id="purchase-amount"
          placeholder="0.00"
          {...props}
          className="border-none w-[316px] font-bold font-ojah text-[32px] p-0 placeholder:text-shiga-gray"
        />
        <div className="flex flex-grow justify-end items-center px-1 py-[2.5px]">
          <NigeriaFlag className="rounded-full mr-1" />
          <p className="font-normal text-xl text-shiga-black">NGN</p>
        </div>
      </div>
    </>
  );
};

export default AmountInput;
