import { currencyFormat } from "lib/format.amount";
import { getTransactionAmountStyle, getTransactionHeading, getTransactionIcon } from "pages/transactions/utils";
import { Transaction } from "types/transaction";



export const DashboardActivity: React.FC<{
  item: Transaction;
  onClick?: () => void;
}> = ({ item, onClick }) => {
  return (
    <div
      className={`flex justify-between py-3 items-center ${onClick ? "cursor-pointer" : ""}`} onClick={onClick}
    >
      <div className="flex items-center gap-3">
        {getTransactionIcon(item, "size-10")}
        <div className="space-y-1">
          <h6 className="text-shiga-dark text-xs tracking-tight">
            {getTransactionHeading(item)}
          </h6>
          <p className="text-shiga-black font-medium text-base">
            {item.description}
          </p>
        </div>
      </div>
      <div
        className={`font-medium text-[16px] tracking-tight ${getTransactionAmountStyle(item)}`}
      >
        {item.type === "CREDIT" ? "+" : ""} {currencyFormat(item.amount, item.currency)}
      </div>
    </div>)
}
