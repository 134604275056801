import { Navigate, Outlet, useLocation } from "react-router-dom";
import Maybe from "components/common/Maybe";
import useLoginInfo from "hooks/useLoginInfo";

interface IAuthenticationLayer {
    userAuth: string,
    userType?: string[],
    userRole?: string[],
}

const AuthenticationLayer = (props: IAuthenticationLayer) => {
    const location = useLocation();
    const { userLogin, userInfo } = useLoginInfo();


    return (
        <>

            <Maybe condition={props.userAuth === "loggedIn"}>
                <Maybe condition={userLogin}>

                    <Maybe condition={props.userType ? true : false}>
                        <Maybe condition={props.userType?.includes(userInfo?.userProfile?.type || "")}>
                            <Outlet />
                        </Maybe>
                        <Maybe condition={!props.userType?.includes(userInfo?.userProfile?.type || "")}>
                            <Navigate to="/401" replace />
                        </Maybe>
                    </Maybe>

                    <Maybe condition={!props.userType ? true : false}>
                        <Outlet />
                    </Maybe>

                </Maybe>
                <Maybe condition={!userLogin}>
                    <Navigate to="/login" state={{ previousUrl: location }} replace />
                </Maybe>
            </Maybe>

            <Maybe condition={props.userAuth === "loggedOut"}>
                <Maybe condition={userLogin}>
                    <Navigate to="/" state={{ previousUrl: location }} replace />
                </Maybe>
                <Maybe condition={!userLogin}>
                    <Outlet />
                </Maybe>
            </Maybe>

        </>
    )
}

export default AuthenticationLayer
