import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonLoader, Input } from "components/common/form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import * as userActions from "store/auth/user/action";
import { useSharedStore } from "zustand-store";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gotoUrl = location.state?.previousUrl?.pathname ?? "/";
  const { loginLoading, loginResponse } = useSelector((s: any) => s.auth.user);

  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const { setApiLoading, apiLoading } = useSharedStore();
  useEffect(() => {
    if (apiLoading) {
      setApiLoading(false);
    }
  }, [apiLoading, setApiLoading]);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    sessionStorage.removeItem("token");
    const errors: any = validate(form);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      dispatch(userActions.login(form));
    }
  };

  useEffect(() => {
    if (loginResponse?.success === true) {
      if (loginResponse?.data?.action === "VERIFY_MFA") {
        dispatch(userActions.saveLoginForm({ email: form.email }));
        navigate("/login/verify");
      } else if (loginResponse?.data?.action === "VERIFY_AUTH_TOKEN") {
        dispatch(userActions.saveLoginForm({ email: form.email }));
        navigate("/login/verify/otp");
      } else if (loginResponse?.data?.token !== undefined) {
        navigate(gotoUrl, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [loginResponse]);



  return (
    <>
      <DynamicHeadTag headerText="Login" />

      <AuthPageTitle
        title="Login to your account"
        description={
          <>
            <span className="mr-1">New to Shiga Business?</span>
            <Link
              to="/register"
              className="text-shiga-purple font-medium hover-border hover:border-shiga-purple"
            >
              Create an Account
            </Link>
          </>
        }
      />

      <div>
        <form onSubmit={submitForm}>
          <div className="mt-10">
            <Input
              type="email"
              label="Email Address"
              placeholder="Email Address"
              required={true}
              value={form.email}
              onChange={(e: any) => setForm({ ...form, email: e.target.value })}
            />

            <Input
              type={showPassword ? "text" : "password"}
              autoComplete="false"
              label="Password"
              placeholder="Password"
              required={true}
              value={form.password}
              onChange={(e: any) =>
                setForm({ ...form, password: e.target.value })
              }
              rightIcon={showPassword ? <IconEyeOpen /> : <IconEyeClose />}
              rightIconClicked={() => setShowPassword(!showPassword)}
              rightIconClass="px-3"
            />

            <div className="text-right relative -top-3">
              <Link
                to="/forgot-password"
                className="text-shiga-gray text-sm hover:text-shiga-black"
              >
                I forgot my password
              </Link>
            </div>

            <ButtonLoader
              type="submit"
              loading={loginLoading}
              className="btn btn-lg btn-block btn-primary mt-8"
            >
              Log In
            </ButtonLoader>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
