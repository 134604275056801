import { ChangeEvent, useEffect, useMemo, useState } from "react";
import InfoIcon from "assets/images/icons/Info";
import {
  ButtonLoader,
  Checkbox,
  Input,
  SelectInput,
} from "components/common/form";
import { networkProviders } from "data/constants/common";
import PurchaseInfo from "./PurchaseInfo";
import StepHeader from "./StepHeader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getBillPaymentServices } from "store/bills-payment/reducer";
import {
  getAirtimeServices,
  getDataPlan,
  getInternetServices,
} from "store/bills-payment/actions";
import { getCurrenciesStore } from "store/ui/currencies/reducer";
import { getCountriesStore } from "store/ui/countries/reducer";
import { getCurrencies } from "store/ui/currencies/action";
import { getCountries } from "store/ui/countries/action";
// import { buyData } from "lib/bill-payments";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "components/common/form/FormHeader";
import { currencyFormat } from "lib/format.amount";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import CustomAmountField from "components/custom/CustomAmountField";
import { useSharedStore } from "zustand-store";
import { Wallet, defaultWallet } from "types/wallet";
import { getCurrentBalance } from "lib/business";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import useBillsPayment from "lib/bill-payments/useBillsPayment";
import PATHS from "NavigationRoute";
import useToolkit from "hooks/useToolkit";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface FormValues {
  amount: number;
  currency: string;
  mobileNo: string;
  networkProviders: string;
  subscriptionPlan: string;
}

const initialFormValues: FormValues = {
  amount: 0,
  currency: "NGN",
  mobileNo: "",
  networkProviders: "",
  subscriptionPlan: "",
};

const MobileData = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageStep, setPageStep] = useState<number>(1);
  const {
    airtime: airtimeStore,
    dataPlan: dataPlanStore,
    internet: internetStore,
  } = useAppSelector(getBillPaymentServices);
  const { currenciesList: currenciesListStore } =
    useAppSelector(getCurrenciesStore);
  const { countries: countriesStore } = useAppSelector(getCountriesStore);
  const {
    userInfo: { userProfile },
  } = useAppSelector(getAuthPersist);
  const [useRegisteredPhoneNo, setUseRegisteredPhoneNo] = useState(false);
  const { currencies } = useSharedStore();
  const [amount, setAmount] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const [formV, setFormV] = useState<FormValues>(initialFormValues);
  const [networkProviderName, setNetworkProviderName] = useState("");
  const [networkServiceProviderId, setNetworkServiceProviderId] = useState("");
  const { loadingData, buyData } = useBillsPayment();
  const { showCustomSuccessToast } = useToolkit();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    dispatch(getAirtimeServices());
    dispatch(getInternetServices());
    dispatch(getCurrencies());
    dispatch(getCountries());
    // Load initial wallet balance
    updateCurrentBalance(formV.currency);
  }, [dispatch]);

  const airtime = useMemo(() => airtimeStore?.data || [], [airtimeStore]);
  const internet = useMemo(() => internetStore?.data || [], [internetStore]);
  const dataPlan = useMemo(() => dataPlanStore?.data || [], [dataPlanStore]);
  const countries: string[] = useMemo(
    () => Object.values(countriesStore.data.countries),
    [countriesStore]
  );
  // const currenciesList = useMemo(
  //   () => currenciesListStore?.data.data || [],
  //   [currenciesListStore]
  // );

  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setAmount(value);
    setFormV((prevFormV) => ({
      ...prevFormV,
      amount: Number(value),
    }));
  };

  const updateCurrentBalance = (currency: string) => {
    getCurrentBalance(currency)
      .then((data: any) => {
        setCurrentWallet(data.data);
      })
      .catch((err) => {
        toast.error(`Unable to get balance: ${err.message}`);
      });
  };

  useEffect(() => {
    const curr = formV.currency;
    if (curr) {
      updateCurrentBalance(curr);
    }
  }, [formV.currency]);
  useEffect(() => {
    if (useRegisteredPhoneNo && userProfile?.phoneNumber) {
      setMobileNo(userProfile.phoneNumber);
      setFormV((prevFormV) => ({
        ...prevFormV,
        mobileNo: userProfile.phoneNumber,
      }));
    } else {
      setMobileNo("");
      setFormV((prevFormV) => ({
        ...prevFormV,
        mobileNo: formV.mobileNo,
      }));
    }
  }, [useRegisteredPhoneNo, userProfile]);

  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(amount) > currentWallet?.balance;
  const insufficientAmount = amount.length > 0 && Number(amount) < 50;

  useEffect(() => {
    const foundSubscription = dataPlan?.find(
      (item) => item.bundleCode === formV.subscriptionPlan
    );
    if (foundSubscription?.amount) {
      setFormV((prevFormV) => ({
        ...prevFormV,
        amount: Number(foundSubscription.amount),
      }));
      setAmount(foundSubscription.amount.toString());
    }
  }, [formV.subscriptionPlan, dataPlan]);

  useEffect(() => {
    if (formV.networkProviders.length) {
      dispatch(getDataPlan(networkServiceProviderId));
    }
  }, [dispatch, formV.networkProviders]);

  const isFormValid = () => {
    return (
      formV.amount &&
      formV.currency &&
      formV.mobileNo &&
      formV.networkProviders &&
      formV.subscriptionPlan
    );
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    const { amount, currency, mobileNo, networkProviders, subscriptionPlan } =
      formV;
    if (mobileNo.length < 11) {
      setError("mobileNo", {
        type: "manual",
        message: "Phone number must be at least 11 characters",
      });
      return;
    }
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      const foundPackage = dataPlan.find(
        (item) => item.name === subscriptionPlan
      );

      return buyData({
        amount,
        currency,
        networkType: networkProviders,
        phoneNumber: mobileNo,
        packageName: foundPackage?.name || "",
        plan_id: (foundPackage?.id || "") + "",
        provider: networkProviders,
        debitAccountNumber: "0116359689",
        serviceCategoryId: networkProviders,
        bundleCode: subscriptionPlan,
      })
        .then((data) => {
          showCustomSuccessToast({
            message: `You have successfully sent ${subscriptionPlan} ${networkProviderName} Data plan to ${formV.mobileNo}`,
            onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE),
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        })
        .catch((err) => {
          toast.error(`Error: ${err.message}`);
        });
    }
  };

  return (
    <div>
      <StepHeader
        step={pageStep}
        goBack={() => setPageStep((prev) => prev - 1)}
        close={() => {
          setPageStep(1);
          setFormV(initialFormValues);
          setUseRegisteredPhoneNo(false);
        }}
      />

      <form onSubmit={onSubmit} className="w-[412px] mx-auto">
        <FormHeader>
          {pageStep >= 2
            ? `Confirm your purchase for ${networkProviderName} Data Plan`
            : "Mobile Data"}
        </FormHeader>

        {pageStep === 1 && (
          <>
            <div className="mb-[26px]">
              <SelectInput
                name="networkProviders"
                label="Select a Network provider"
                placeholder="Select a provider"
                required
                value={formV.networkProviders}
                onChange={(e: any) => {
                  const selectedProvider = internet.filter(
                    (provider) => provider?.serviceCategoryId === e.target.value
                  );
              
                  setNetworkProviderName(selectedProvider[0]?.name);
                  setNetworkServiceProviderId(
                    selectedProvider[0]?.serviceCategoryId
                  );
                  setFormV((prevFormV) => ({
                    ...prevFormV,
                    networkProviders: e.target.value,
                  }));
                }}
              >
                {internet.length ? (
                  internet.map((provider: any, index: any) => (
                    <option key={provider.serviceCategoryId} value={provider.serviceCategoryId}>
                      <div className="flex items-center gap-1.5">
                        <img
                          src={provider.logoUrl}
                          alt={provider.name}
                          className="size-5"
                        />
                        {provider.name}
                      </div>
                    </option>
                  ))
                ) : (
                  <option value="">No providers available</option>
                )}
              </SelectInput>
            </div>
            <div className="mb-[26px]">
              <SelectInput
                name="subscriptionPlan"
                label="Select a Plan"
                placeholder="Select a plan"
                required
                value={formV.subscriptionPlan}
                onChange={(e: any) => {
                  setFormV((prevFormV) => ({
                    ...prevFormV,
                    subscriptionPlan: e.target.value,
                  }));
                }}
              >
                {dataPlan.length ? (
                  dataPlan.map((plan, index) => (
                    <option key={index} value={plan.bundleCode}>
                      {plan.bundleCode} {plan.validity} {plan.amount}
                    </option>
                  ))
                ) : (
                  <option value="">No plans available</option>
                )}
              </SelectInput>
            </div>
            <div className="mb-[26px]">
              <div className="flex w-full justify-between">
                <span className="text-shiga-gray-100">Mobile Number</span>
                <Checkbox
                  type="checkbox"
                  label={"Use registered number"}
                  containerClass="!items-start text-shiga-gray text-sm"
                  checked={useRegisteredPhoneNo}
                  onChange={() => {
                    setUseRegisteredPhoneNo((prev) => !prev);
                    if (userProfile?.phoneNumber) {
                      setMobileNo(userProfile.phoneNumber);
                      setFormV((prevFormV) => ({
                        ...prevFormV,
                        mobileNo: userProfile.phoneNumber,
                      }));
                    } else {
                      setMobileNo("");
                      setFormV((prevFormV) => ({
                        ...prevFormV,
                        mobileNo: "",
                      }));
                    }
                  }}
                />
              </div>
              <Input
                {...control}
                type="number"
                disabled={useRegisteredPhoneNo}
                placeholder="081012345678"
                maxLength={11}
                value={mobileNo}
                onChange={(e: any) => {
                  const value = e.target.value;
                  setMobileNo(value);
                  setFormV((prevFormV) => ({
                    ...prevFormV,
                    mobileNo: value,
                  }));
                }}
              />
            </div>
            <div className="pb-12">
              <CustomAmountField
                leftLabel="Amount"
                isFieldRequired
                setInputAmount={updateAmount}
                inputError={insufficientAmount || inputExceedsTrxAmount}
                disabled
                errorText={
                  insufficientAmount
                    ? "The minimum amount you can purchase is 50 NGN"
                    : inputExceedsTrxAmount
                    ? "You cannot make a purchase more than your available balance"
                    : "The minimum amount you can purchase is 50 NGN"
                }
                flagIconUrl={NGN}
                inputAmount={amount}
                transactionCurrency={currentWallet?.currency || ""}
                rightLabel={
                  <p className="text-shiga-dark flex items-center font-inter">
                    You have &nbsp;
                    <span className="text-shiga-purple font-medium">
                      {`${Number(currentWallet?.balance).toLocaleString()} ${
                        currentWallet?.currency ?? ""
                      }`}
                    </span>
                  </p>
                }
              />
            </div>
          </>
        )}
        {pageStep >= 2 && (
          <PurchaseInfo
            transactionType="Bills Payment"
            info={[
              {
                attribute: "Amount",
                value: `${formV.amount} ${formV.currency}`,
              },
              {
                attribute: "Plan",
                value: formV.subscriptionPlan,
              },
              {
                attribute: "Phone Number",
                value: formV.mobileNo,
              },
              {
                attribute: "Network Provider",
                value: networkProviderName,
              },
              {
                attribute: "Fee",
                value: "Free 😎",
              },
            ]}
            className="mb-[60px]"
          />
        )}
        <ButtonLoader
          disabled={!isFormValid()}
          loading={loadingData}
          type="submit"
          className="btn btn-lg btn-block btn-primary mt-3"
        >
          {pageStep >= 2 ? "Confirm & Purchase" : "Continue"}
        </ButtonLoader>
      </form>
    </div>
  );
};

export default MobileData;
