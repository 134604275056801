
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { PaginatedApiResponseNoMeta } from "types/data-interface";
import { Currency } from "types/shared/countries.types";
import { RootState } from "store/store";
import { currencies } from "utils/data"

interface InitialState {
    currenciesLoading: boolean;
    currenciesList: PaginatedApiResponseNoMeta<{ data: Currency[] }> | null;
    currencies: any;
    // currencyFieldValues : {
    //     [string:any] :any
    // }
}

const initialState: InitialState = {
    currenciesLoading: false,
    currencies: {},
    currenciesList: {
        data: {
            data: currencies
        },
        message: "",
        statusCode: 201,
        success: true
    },
};


export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case actions.GET_CURRENCIES:
            state = {
                ...state,
                currenciesLoading: false,
                currencies: action.payload,
                currenciesList: action.payload.data
            }
            return state;


        case actions.GET_CURRENCIES_START:
            state = {
                ...state,
                currenciesLoading: true,
            }
            return state;


        case actions.GET_CURRENCIES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                currenciesLoading: false,
                currencies: {},
            }
            return state;


        default:
            return state;
    }
}

export const getCurrenciesStore = (state: RootState) => state.ui.currencies;