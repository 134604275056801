import * as actions from "./actionTypes";
import * as config from "data/config";

export function getWalletsAndBalance() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWalletAndBalnce,
            method: "get",
            data: {},
            onStart: actions.GET_WALLETS_AND_BALANCE_START,
            onSuccess: actions.GET_WALLETS_AND_BALANCE,
            onError: actions.GET_WALLETS_AND_BALANCE_FAILED,
        },
    }
}
