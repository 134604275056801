import * as actions from "./actionTypes";
import * as config from "data/config";

export function getCurrencies() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCurrencies, 
            method: "get",
            data: {},
            onStart: actions.GET_CURRENCIES_START,
            onSuccess: actions.GET_CURRENCIES,
            onError: actions.GET_CURRENCIES_FAILED,
        },
    }
}
