import { useMemo, useState } from "react";
import { ReactComponent as IconMore } from "assets/images/icons/More.svg";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconBlackCard } from "assets/images/icons/CardBlack.svg";
import { ReactComponent as IconBlueCard } from "assets/images/icons/CardBlue.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit2.svg";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconLock } from "assets/images/icons/Lock.svg";
import { ReactComponent as IconUnlock } from "assets/images/icons/unlock.svg";
import { ButtonLoader } from "components/common/form";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import CardDetailsModal from "./CardDetails";
import { useToggle } from "hooks/useToggle";
import CardRenameModal from "./RenameCard";
import FundCardModal from "./FundCard";
import DeleteCardModal from "./DeleteCard";
import { DataTable } from "components/data-table/data-table";
import { selectedCardTableColumns } from "./selectedCardTable/CardColumn";
import { useCardStore } from "zustand-store/cards";
import { toast } from "react-toastify";
import DivInput from "components/ui/div-input";
import { formatDate } from "lib/format.date";
import { DataTableHeader } from "components/data-table/data-table-header";
import { currencyFormat } from "lib/format.amount";

export const SelectedCardDetails = () => {
  const navigate = useNavigate();
  const [lock, setLock] = useState(true);
  const cardInfo = new Array(10);
  const { selectedCard, updateCard } = useCardStore();
  const [showCardDetails, toggleShowCardDetails] = useToggle();
  const [showCardRename, toggleShowCardRename] = useToggle();
  const [showCardFund, toggleShowCardFund] = useToggle();
  const [showCardDelete, toggleShowCardDelete] = useToggle();
  const [filter, setFilter] = useState("");

  function goBack() {
    navigate("/cards?show-table");
  }

  const handleLockCard = () => {
    updateCard({
      ...selectedCard,
      status: "LOCKED",
    });
    setLock(true);
    toast.success(`Card Successfully locked`);
    // uncomment out when API is ready
    // lockCard(selectedCard.id).catch(err => {
    //   toast.error(`Error: ${err.message}`);
    //   updateCard({
    //     ...selectedCard,
    //     status: "UNLOCKED"
    //   })
    // })
  };
  const handleUnlockCard = () => {
    updateCard({
      ...selectedCard,
      status: "UNLOCKED",
    });
    toast.success(`Card Successfully unlocked`);
    setLock(false);
    // uncomment out when API is ready
    // unLockCard(selectedCard.id).catch(err => {
    //   toast.error(`Error: ${err.message}`);
    //   updateCard({
    //     ...selectedCard,
    //     status: "LOCKED"
    //   })
    // })
  };
  const filteredCardInfo = useMemo(() => {
    if (filter && filter !== "All") {
      return cardInfo.filter(
        (card) => card.status.toLowerCase() === filter.toLowerCase()
      );
    }
    return cardInfo;
  }, [cardInfo, filter]);

  const cardAction = [
    {
      label: "Card Label",
      onClick: toggleShowCardRename,
      icon: (
        <IconEdit className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
      ),
    },
    {
      label: "Show Details",
      onClick: toggleShowCardDetails,
      icon: (
        <IconEyeOpen className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
      ),
    },
    {
      ...(lock
        ? {
            label: "Unlock Card",
            onClick: handleUnlockCard,
            icon: (
              <IconUnlock className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
            ),
          }
        : {
            label: "Lock Card",
            onClick: handleLockCard,
            icon: (
              <IconLock className="w-8 h-8 p-1.5 bg-[#F5F5F5] text-shiga-dark rounded-full cursor-pointer outline-none" />
            ),
          }),
    },
  ];

  return (
    <>
      <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
        <div className="flex items-center justify-between mb-5 pb-5 border-b border-gray-100">
          <div
            onClick={goBack}
            className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
          >
            <IconNavigation className="w-full h-full" />
          </div>
          <Dropdown className="" dropdownClass="dropdown-right">
            <IconMore />
            <div>
              <Menu.Item
                onClick={toggleShowCardDelete}
                as="div"
                className="dropdown-item dropdown-item-icon text-shiga-warn"
              >
                <IconDelete />
                Delete Card
              </Menu.Item>
            </div>
          </Dropdown>
        </div>
        <div className="flex flex-col md:flex-row mb-6 pb-5 border-b border-gray-100">
          <div className="sm:mr-24">
            <div className="w-[312px] h-[200px] mb-4">
              {selectedCard.currency === "USD" ? (
                <IconBlackCard />
              ) : (
                <IconBlueCard />
              )}
            </div>
            <div className="flex justify-between">
              {cardAction.map((item, idx) => (
                <div
                  key={idx}
                  onClick={item.onClick}
                  className="flex flex-col items-center space-y-2"
                >
                  {item.icon}
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between pb-5 border-b border-gray-100">
              <div className="flex flex-col space-y-1">
                <span className="text-shiga-gray">Card Balance</span>
                <span className="text-3xl font-ojah">
                  {currencyFormat(240.85, selectedCard.currency)}
                </span>
              </div>
              <div>
                <ButtonLoader
                  onClick={toggleShowCardFund}
                  type="submit"
                  className="btn btn-lg btn-block btn-shiga-black"
                >
                  Fund Card
                </ButtonLoader>
              </div>
            </div>
            <div className="pt-5 grid grid-cols-1 sm:grid-cols-2 gap-x-6">
              <DivInput label="Created by" value={selectedCard.name} />
              <DivInput
                label="Date Created"
                value={formatDate(selectedCard.createdAt)}
              />
              <DivInput label="Assigned to" value={selectedCard.customer} />
              <DivInput label="Type" value={`${selectedCard.currency} Card`} />
            </div>
          </div>
        </div>
        <div className="mt-6">
          <DataTable
            data={filteredCardInfo}
            columns={selectedCardTableColumns}
            renderToolbar={(table) => (
              <DataTableHeader
                filterOptions={["All", "Active", "Inactive"]}
                setFilter={setFilter}
                placeholder="Search transactions"
                table={table}
              />
            )}
          />
        </div>
      </div>
      <CardDetailsModal
        isOpen={showCardDetails}
        setIsOpen={toggleShowCardDetails}
      />
      <CardRenameModal
        isOpen={showCardRename}
        setIsOpen={toggleShowCardRename}
      />
      <FundCardModal isOpen={showCardFund} setIsOpen={toggleShowCardFund} />
      <DeleteCardModal
        onDelete={() => {
          navigate("/cards");
        }}
        isOpen={showCardDelete}
        setIsOpen={toggleShowCardDelete}
      />
    </>
  );
};
