import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { googleApiKey } from "data/config";
import { CircleCheck } from "lucide-react";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "../paths";
import { defaultPayload, useTuitionStore } from "zustand-store/payments/tution";
import { useEffect, useMemo, useState } from "react";
import { ButtonLoader, Input } from "components/common/form";
import FormHeader from "components/common/form/FormHeader";
import TextArea from "components/common/form/TextArea";
import CommandSelect from "components/ui/command-select";
import { CommandInput } from "components/ui/command";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { getAddressObject } from "lib/format.address";
import { toast } from "react-toastify";
import { PageHeading } from "components/common/PageHeading";

const FormSchema = z.object({
  email: z.string().email(),
  address: z.object({
    description: z.string().min(1, "Please select an address"),
    place_id: z.string().min(1, "Please select an address"),
  }),
  invoiceNumber: z.string().min(2, {
    message: "Invoice must be at least 2 characters.",
  }),
  firstName: z.string().min(2, {
    message: "First Name must be at least 2 characters.",
  }),
  lastName: z.string().min(2, {
    message: "Last Name must be at least 2 characters.",
  }),
  note: z.string().optional(),
});

export const StudentInfo = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [isPostalCode, setIsPostalCode] = useState(false);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleApiKey,
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      address: {
        description: "",
        place_id: "",
      },
      invoiceNumber: "",
      firstName: "",
      lastName: "",
      note: "",
    },
  });

  const { setCompleted, payload, setPayload } = useTuitionStore();

  useEffect(() => {
    if (payload.student?.email) form.setValue("email", payload.student.email);
    if (payload.student?.address) {
      form.setValue("address", {
        description: payload.student.address.description,
        place_id: payload.student.address.place_id,
      });
    }
    if (payload.student?.invoiceNumber)
      form.setValue("invoiceNumber", payload.student.invoiceNumber);
    if (payload.student?.firstName)
      form.setValue("firstName", payload.student.firstName);
    if (payload.student?.lastName)
      form.setValue("lastName", payload.student.lastName);
    if (payload.student?.note) form.setValue("note", payload.student.note);
  }, [payload, form]);

  useEffect(() => {
    if (form.watch("address.place_id")) {
      const placeId = form.watch("address.place_id");
      if (!placesService) return;
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ["address_components", "adr_address", "formatted_address"],
        },
        (placeDetails: any) => {
          const addressComponents = placeDetails.address_components;
          const postalCodeComponent = addressComponents.find((component: any) =>
            component.types.includes("postal_code")
          );

          if (!postalCodeComponent) {
            setIsPostalCode(true);
            toast.error("Postal code is missing in the selected address.");
          } else {
            setIsPostalCode(false);
          }

          // Clear address error if a valid address is selected
          form.clearErrors("address");
        }
      );
    }
  }, [form.watch("address.place_id"), placesService]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setCompleted("student_info");
    const placeId = form.watch("address.place_id");

    return placesService?.getDetails(
      {
        placeId: placeId,
      },
      (placeDetails: any) => {
        const addressObject = getAddressObject(placeDetails.address_components);

        if (!addressObject.postal_code) {
          form.setError("address.description", {
            message: "Please select an address",
          });
        } else {
          setPayload({
            ...payload,
            student: {
              ...data,
              address: {
                ...data.address,
                city: addressObject.city,
                country: addressObject.country,
                postCode: addressObject.postal_code,
                state: addressObject.region,
              },
              note: data.note || "",
              invoiceNumber: data.invoiceNumber || "",
            },
          });

          navigate(`${PAYMENT_PATHS.TUITION_STEPS}?tab=amount`);
        }
      }
    );
  }

  const addressError = useMemo(
    () => form.formState.errors["address"],
    [form.formState.errors["address"]]
  );

  return (
    <div className="w-full  space-y-4 flex flex-col justify-center items-center">
      <div className=" w-[412px] flex-col justify-start items-start gap-2.5 inline-flex">
        <PageHeading title="Add Student's Information" />
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-[412px]">
          <div className="flex justify-between gap-x-6">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Student First Name"
                      placeholder="First Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Student Last Name"
                      placeholder="Last Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    label="Student Email address"
                    placeholder="Email address"
                    {...field}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mb-[1.75rem]">
            <FormField
              control={form.control}
              name="invoiceNumber"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Invoice Number"
                      placeholder="Invoice Number"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-3">
                <CommandSelect
                  searchPlaceholder="Search Address"
                  filterOption={() => 1}
                  loading={isPlacePredictionsLoading}
                  SearchInput={
                    <CommandInput
                      placeholder="Search Address"
                      value={location}
                      onValueChange={(e) => {
                        setLocation(e);
                        getPlacePredictions({ input: e });
                      }}
                    />
                  }
                  notFound="No Address found."
                  label="Student's Address"
                  options={placePredictions.map((item) => ({
                    ...item,
                    id: item.place_id + "",
                    value: item.description,
                  }))}
                  SelectTrigger={() => (
                    <span className="flex items-center gap-4 overflow-hidden text-base">
                      {!field.value.description && (
                        <p className="flex items-center text-form-gray text-base font-normal">
                          <IconSearch className="mr-3" />
                          {"(No)(Street Name)(State)(Country)"}
                        </p>
                      )}
                      {field.value.description}
                    </span>
                  )}
                  RenderOption={({ item }) => (
                    <>
                      <span className="flex text-left">{item.description}</span>
                      {field.value.description === item.description && (
                        <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                      )}
                    </>
                  )}
                  handleSelect={(select) => {
                    form.setValue("address", {
                      description: select.description,
                      place_id: select.place_id,
                    });
                    form.clearErrors("address");
                  }}
                />
                {addressError?.description?.message?.length ? (
                  <p className={"text-sm font-medium text-warn"}>
                    {addressError.description.message}
                  </p>
                ) : (
                  <></>
                )}
              </FormItem>
            )}
          />
          <div className="my-[1.75rem]">
            <TextArea
              label="Additional Information (Optional)"
              placeholder="ie your registration number, application number etc"
              form={form as any}
            />
          </div>
          <ButtonLoader
            className="btn btn-lg btn-block btn-shiga-black mt-6"
            type="submit"
          >
            Continue
          </ButtonLoader>
        </form>
      </Form>
    </div>
  );
};
