import React from 'react';

interface NoAccountAddedProps {
  icon: string;
  header: string;
  subheader: string;
}

const EmptyUI: React.FC<NoAccountAddedProps> = ({ icon, header, subheader }) => {
  return (
    <div className="max-w-xs py-10 mx-auto text-center">
      <div className="w-24 h-24 mx-auto">
        <img src={icon} alt="Icon" className="w-full h-full object-contain" />
      </div>
      <div className="mt-4 text-2xl font-bold font-ojah">
        {header}
      </div>
      <div className="mt-2 text-sm text-shiga-gray whitespace-normal">
        {subheader}
      </div>
    </div>
  );
};

export default EmptyUI;
