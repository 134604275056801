import React, { useState } from "react";
import "./ImageCarousel.css";
import Img from "assets/images/blog-img-1.png";
import Img2 from "assets/images/blog-img-2.png";
import { ReactComponent as ArrowRight } from "assets/images/icons/long-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/images/icons/long-arrow-left.svg";

const ImageCarousel = () => {
  const images = [Img, Img, Img2]; // Replace with your image URLs
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="bg-shiga-black w-full h-full rounded-2xl mb-6 relative overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
        />
      ))}
      <div className="px-4">
        <button
          onClick={prevSlide}
          style={{ backgroundColor: "rgba(31, 31, 30, 0.60)" }}
          className="absolute top-1/2 left-5 transform -translate-y-1/2  p-3 rounded-full"
        >
          <ArrowLeft />
        </button>
        <button
          onClick={nextSlide}
          style={{ backgroundColor: "rgba(31, 31, 30, 0.60)" }}
          className="absolute top-1/2 right-5 transform -translate-y-1/2  p-3 rounded-full"
        >
          <ArrowRight />
        </button>
      </div>
    </div>
  );
};

export default ImageCarousel;
