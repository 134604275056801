interface BackBtnIconProps extends React.BaseHTMLAttributes<SVGElement> {}

const BackBtnIcon = ({ ...props }: BackBtnIconProps) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="32"
        height="32.0014"
        rx="16"
        transform="matrix(-1 0 0 1 32 0.5)"
        fill="#F5F5F5"
      />
      <path
        d="M14.9164 11.6785C15.2503 11.3029 15.8255 11.269 16.2011 11.6029C16.5767 11.9368 16.6106 12.512 16.2767 12.8876L13.8734 15.5913H20.1532C20.6558 15.5913 21.0632 15.9987 21.0632 16.5013C21.0632 17.0038 20.6558 17.4113 20.1532 17.4113H13.8734L16.2767 20.1149C16.6106 20.4905 16.5767 21.0657 16.2011 21.3996C15.8255 21.7335 15.2503 21.6997 14.9164 21.324L11.1669 17.1058C10.8604 16.761 10.8604 16.2415 11.1669 15.8967L14.9164 11.6785Z"
        fill="#99999C"
      />
    </svg>
  );
};

export default BackBtnIcon;
