import { Transaction } from 'types/transaction';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import Maybe from 'components/common/Maybe';


type Props = {
   transaction: Transaction;
   activeTab: string;
}

const ApiCardTransactionDetails = ({ transaction, activeTab }: Props) => {
   const { copyItem, showValue } = useToolkit()
   const initiator = `${transaction?.paymentCustomer?.firstName || 'N/A'} ${transaction?.paymentCustomer?.lastName || ''}`
   const showDetails = activeTab === 'details';
   const showAnalytics = activeTab === 'analytics';

   return (
      <div className="w-full">

         <Maybe condition={showDetails}>
            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Status
               </div>

               <div className="transaction-detail-inline-value capitalize">
                  {transaction?.status?.toLowerCase()}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Channel
               </div>

               <div className="flex items-center capitalize">
                  {transaction?.channel?.toLowerCase()}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  From
               </div>

               <div className="flex items-center">
                  {showValue(initiator)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Fee
               </div>

               <div className="transaction-detail-inline-value">
                  {transaction?.fee} {transaction?.currency}
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper-last`}>
               <div className="transaction-detail-inline-key">
                  Transaction Ref
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium'>
                     {transaction.reference}
                     <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                        <CopyIcon width={20} />
                     </button>
                  </div>
               </div>
            </div>
         </Maybe>

         <Maybe condition={showAnalytics}>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Card Type
               </div>

               <div className="flex items-center capitalize">
                  {showValue(transaction?.cardTransaction?.brand)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Card Number
               </div>

               <div className="flex items-center">
                  ****{showValue(transaction?.cardTransaction?.last4)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Bank
               </div>

               <div className="flex items-center">
                  {showValue(transaction?.cardTransaction?.bank)}
               </div>
            </div>

            <div className='transaction-detail-inline-wrapper'>
               <div className="transaction-detail-inline-key">
                  Country Code
               </div>

               <div className="flex items-center">
                  {showValue(transaction?.cardTransaction?.countryCode)}
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper-last`}>
               <div className="transaction-detail-inline-key">
                  IP Address
               </div>

               <div className="transaction-detail-inline-value">
                  <div className='flex items-center font-medium space-x-2'>
                     {showValue(transaction?.cardTransaction?.ip?.split(', ')?.map((item: any) => (
                        <span key={item} className='underline text-shiga-purple-400'>
                           {item}
                        </span>
                     )))}

                     <button
                        className='ml-1.5'
                        onClick={() => copyItem(transaction?.cardTransaction?.ip, 'IP address')}>
                        <CopyIcon width={20} />
                     </button>
                  </div>
               </div>
            </div>
         </Maybe>

      </div>
   )
}

export default ApiCardTransactionDetails