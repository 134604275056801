import Table from "components/common/Table";
import { BulkTransferType } from "../BulkTransfer";
import { Dispatch, SetStateAction } from "react";


type TableProps = {
    handleSelect: (arg: BulkTransferType) => void;
    tableItems: BulkTransferItemProps[] | undefined;
    setTableItems: Dispatch<SetStateAction<BulkTransferItemProps[] | undefined>>
}



export const BulkTrasnferReviewTable = ({ handleSelect, tableItems }: TableProps) => {


    return (
        <div className="w-full">


            {/* <div className="w-full sm:flex sm:items-center sm:justify-between">
                    Sort, Paginate, Search
                </div> */}


            <div className="mt-10">
                <Table>
                    <Table.Head>
                        <th>S/N</th>
                        {/* <th>ACCOUNT NAME</th> */}
                        <th>BANK NAME</th>
                        <th>ACCOUNT NUMBER</th>
                        <th>AMOUNT</th>
                        {/* <th>STATUS</th> */}
                        <th></th>
                    </Table.Head>

                    <Table.Body className="overflow-auto">
                        {tableItems?.map((item: BulkTransferItemProps, index) => (
                            <tr key={index} className="cursor-pointers">
                                <td>
                                    <span className="font-medium">0{item?.index}</span>
                                </td>

                                <td>
                                    <span className="font-medium capitalize">{item?.bankName}</span>
                                </td>

                                <td>
                                    <span className="font-medium">{item?.accountNumber}</span>
                                </td>

                                <td>
                                    <span className="font-medium mr-1.5 truncate max-w-[60%]">
                                        {Number(item?.amount)?.toLocaleString()} NGN
                                    </span>
                                </td>

                                {/* <td>
                                        <Maybe condition={index % 2 === 0}>
                                            <div className="label-border !text-[#00C46C] !border-[#00C46C] !p-1">
                                                <span className="font-medium">Verified</span>
                                            </div>
                                        </Maybe>
                                        <Maybe condition={index % 2 === 1}>
                                            <div className="label-border !text-[#DA2F20] !border-[#FFEEE8] !p-1">
                                                <span className="font-medium">Invalid</span>
                                            </div>
                                        </Maybe>
                                    </td> */}

                                {/* <td>
                                        <TableMoreDropdown handleSelected={handleSelected({
                                            name: "Johnson Doe",
                                            amount: "500 GBP",
                                            type: "Pending",
                                            id: index
                                        })} />
                                    </td> */}
                            </tr>
                        ))}
                    </Table.Body>

                </Table>
            </div>
        </div>
    );
};


export default BulkTrasnferReviewTable;