import { Transaction } from "types/transaction";
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconFlag } from "assets/images/icons/flag.svg";
import { ReactComponent as IconGiftCard } from "assets/images/icons/Gift-Card.svg";
// import { ReactComponent as IconTransferCredit } from "assets/images/icons/add-dark.svg";
import { ReactComponent as IconTransferDebit } from "assets/images/icons/arrow-out.svg";
import { ReactComponent as IconInwardTransfer } from "assets/images/icons/arrow-in.svg";
import { ReactComponent as IconSwap } from "assets/images/icons/swap.svg";
import { ReactComponent as IconCurSwap } from "assets/images/icons/trx-swap.svg";
import { ReactComponent as IconGotv } from "assets/images/icons/bills/gotv.svg";
import { ReactComponent as IconDstv } from "assets/images/icons/bills/dstv.svg";
import { ReactComponent as IconStartimes } from "assets/images/icons/bills/Startimes.svg";
import { ReactComponent as IconGlo } from "assets/images/icons/bills/glo.svg";
import { ReactComponent as IconMtn } from "assets/images/icons/bills/mtn.svg";
import { ReactComponent as IconIkedc } from "assets/images/icons/bills/ikedc.svg";
import { ReactComponent as IconAirtel } from "assets/images/icons/bills/airtel.svg";
import { ReactComponent as IconWarning } from "assets/images/icons/warning-triangle.svg";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import Maybe from "components/common/Maybe";
import SearchBox from "components/common/SearchBox";
import Pagination from "components/common/Pagination";
import CustomTableFilter from "components/common/CustomTableFilter";

export const InvoiceFilterHandler = ({
  invoices,
}: {
  invoices: any;
}) => {
  return (
    <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-2/4">
        <CustomTableFilter
          dropdownClass=""
          filters={[
            {
              title: "Status",
              name: "status",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "paid",
                  payload: "PAID",
                },
                {
                  display: "not paid",
                  payload: "NOT_PAID",
                },
                {
                  display: "due",
                  payload: "DUE",
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 lg:pt-0 lg:space-x-5 lg:justify-end">
        <Pagination meta={invoices?.meta} />
        <SearchBox
          name="search"
          extraUrlParams="page=1"
          placeholder="Search Invoice"
        />
      </div>
    </div>
  );
};
