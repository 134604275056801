import { ReactComponent as Bank } from "assets/images/icons/bank-filled.svg";
import { ReactComponent as IconArrowCurve } from "assets/images/icons/ArrowCurve.svg";
import bulkImg from "assets/images/icons/send_bulk_enabled.png";
import Maybe from 'components/common/Maybe';
import useTransfersProvider from "../context/useTransfersProvider";


const InlineTransfersSidebar = () => {
   const {
      singleTrfStep,
      isSingleTransfer,
      isBulkTransfer,
      bulkTrfStep,
      navigateSingleTrfStep,
      navigateBulkTrfStep
   } = useTransfersProvider();

   const allSingleTrfSteps = [
      "Add Recipient",
      "Account Details",
      "Enter Amount",
      "Review & Pay",
   ]

   const allBulkTrfSteps = [
      "Upload Recipient",
      "Review Recipients",
      "Confirm & Pay",
   ]

   return (
      <div className="w-80 pt-10 pl-10 pr-6 min-h-screen flex-shrink-0 hidden lg:block border-r border-gray-100 fixed top-20">

         <Maybe condition={true}>
            <div className="flex items-center justify-between">
               <div className="flex items-center">
                  <div className="w-8 h-8 mr-2 flex items-center justify-center overflow-hidden">
                     {isSingleTransfer ? <Bank /> : <img src={bulkImg} alt='group' />}
                  </div>
                  <div className="font-medium">
                     {isSingleTransfer ? 'Send to Bank Account' : 'Make a Bulk Transfer'}
                  </div>
               </div>
            </div>

            <Maybe condition={isSingleTransfer}>
               <div className="mt-3">
                  {allSingleTrfSteps.map((step: string, index: number) => {

                     return (
                        <div
                           key={step}
                           onClick={() => navigateSingleTrfStep(index + 1)}
                           className={`flex items-center ${singleTrfStep > (index + 1) && "cursor-pointer group"}`}>
                           <div className="w-8 h-12 mr-3 flex items-center justify-center overflow-hidden">
                              {singleTrfStep > (index + 1) && <div className="w-0.5 h-full bg-shiga-purple opacity-35"></div>}
                              {singleTrfStep === (index + 1) && <IconArrowCurve className="relative -top-4 left-2" />}
                           </div>
                           <div className={`
                              h-12 flex flex-grow items-center
                              ${singleTrfStep === (index + 1) ? "text-shiga-purple" : "text-[#99999C]"}
                              group-hover:bg-gray-100`}>
                              {step}
                           </div>
                        </div>
                     )
                  })}
               </div>
            </Maybe>

            <Maybe condition={isBulkTransfer}>
               <div className="mt-3">
                  {allBulkTrfSteps.map((step: string, index: number) =>
                     <div
                        key={step}
                        onClick={() => navigateBulkTrfStep(index + 1)}
                        className={`flex items-center ${bulkTrfStep > (index + 1) && "cursor-pointer group"}`}>
                        <div className="w-8 h-12 mr-3 flex items-center justify-center overflow-hidden">
                           {bulkTrfStep > (index + 1) && <div className="w-0.5 h-full bg-shiga-purple opacity-35"></div>}
                           {bulkTrfStep === (index + 1) && <IconArrowCurve className="relative -top-4 left-2" />}
                        </div>
                        <div className={`
                              h-12 flex flex-grow items-center
                              ${bulkTrfStep === (index + 1) ? "text-shiga-purple" : "text-[#99999C]"}
                              group-hover:bg-gray-100`}>
                           {step}
                        </div>
                     </div>
                  )}
               </div>
            </Maybe>
         </Maybe>
      </div>
   )
}


export default InlineTransfersSidebar