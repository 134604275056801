import axios from "axios";
import { apiBaseUrl, apiGetRates } from "data/config";
import { getToken } from "lib";
import { handleError } from "lib/format.error";
import { PaginatedApiResponseNoMeta } from "types/data-interface";

interface GetRateResponse {
    currencyPair: string;
    id: string;
    rate: number;
    receiveAmount: number;
    sendAmount: number
}

export const getRate = async ({amount, currencyFrom, currencyTo}: {
    amount: number;
    currencyFrom: string;
    currencyTo: string
}) => axios.get<PaginatedApiResponseNoMeta<{
    rate: number;
    receiveAmount: number;
    sendAmount: number;data: GetRateResponse
}>>(
  apiBaseUrl + `${apiGetRates}?amount=${amount}&currencyFrom=${currencyFrom}&currencyTo=${currencyTo}`,
  {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }
).then(res => res.data.data).catch(handleError)