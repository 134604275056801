import { Link } from "react-router-dom";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import {ReactComponent as IconCheck } from "assets/images/icons/Check-Circle.svg";


const ResetPasswordSuccess = () => {
    return (
        <>
            <DynamicHeadTag
                headerText="Reset Successful"
            />

            <div className="text-center">
                <div>
                    <IconCheck className="w-24 h-24 mx-auto" />
                </div>
                <div className="mt-8 text-4xl font-ojah font-bold">
                    Reset Successful
                </div>
                <div className="mt-2 text-shiga-gray">
                    Your password has been changed successfully. 
                </div>

                <div className="mt-8 flex flex-col space-y-2">
                    <Link to="/login" className="btn btn-block btn-lg btn-shiga-black">
                        Go to Login
                    </Link>
                </div>

            </div>
        </>
    )
}


export default ResetPasswordSuccess;