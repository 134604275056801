import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getIssuedAccounts } from "store/accounts/action";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import moment from "moment";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyAccounts from "./EmptyAccounts";
import { AccountFilterHandler } from "./AccountFilterHandler";
import { processParams } from "utils/processParams";
import useUrlQueryParams from "utils/useUrlQueryParams";
import { useLocation, useNavigate } from "react-router-dom";
import { getDashboardWallets } from "store/dashboard/action";
import { useAppSelector } from "store/hooks";
import { getDashboard } from "store/dashboard/reducer";
import Maybe from "components/common/Maybe";

const EmptyIssuedAccount = () => {
  const dispatch = useDispatch();
  const { accounttList, loadingAccounts, accounttListData } = useSelector(
    (s: any) => s.accounts
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<boolean | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('NGN')
  const { wallets } = useAppSelector(getDashboard);
  const currencies = wallets?.data?.map((item: any) => item.currency)

  const { urlQueryParams } = useUrlQueryParams();
  const location = useLocation();
  // const navigate = useNavigate();
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 9,
  });

  const fetchLinks = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(
      urlQueryParams,
      params,
      "limit",
      "number",
      9,
      "limit"
    );
    params = processParams(
      urlQueryParams,
      params,
      "isVirtual",
      "string",
      "",
      "isVirtual"
    );

    params = processParams(
      urlQueryParams,
      params,
      "search",
      "string",
      "",
      "search"
    );
    params = processParams(
      urlQueryParams,
      params,
      "startDate",
      "string",
      "",
      "startDate"
    );
    params = processParams(
      urlQueryParams,
      params,
      "endDate",
      "string",
      "",
      "endDate"
    );

    setListParams(params);
    dispatch(getIssuedAccounts(params));
  };

  //   const filteredAccountList = accounttList.filter(
  //     (account: any) =>
  //       account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       account.reference.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       account.bank.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       statusFilter === "" ||
  //       account.status.toLowerCase() === statusFilter.toLowerCase() // Filter by status
  //   );

  const filteredAccountList = accounttList.filter((account: any) => {
    const matchesSearch =
      // account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      account.reference.toLowerCase().includes(searchQuery.toLowerCase()) ||
      account.bank.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesStatus =
      statusFilter === null || account.isVirtual === statusFilter;

    return matchesSearch && matchesStatus;
  });

  const currencyAccounts = filteredAccountList?.filter((account: any) => account?.currency === selectedCurrency)

  const handleCopyClick = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        copy(textToCopy);
        toast.info("Reference copied");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
        toast.error("Could not copy reference");
      });
  };

  useEffect(() => {
    // dispatch(getIssuedAccounts());
    fetchLinks();
    dispatch(getDashboardWallets());
  }, [location]);

  const handleStatusFilterClick = (filterValue: boolean | null) => {
    setStatusFilter(filterValue); // Update status filter based on selection
    // setCurrentPage(1); // Reset page to 1 when filter changes
    setIsVisible(false); // Close dropdown after selecting option
  };

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="space-y-4 w-full px-10 lg:px-24 ">
      {/* Header section */}
      <div className=" flex items-center justify-between self-stretch pb-6 border-b border-b-[#f5f5f5] pt-[45px]">
        <div className="header-text flex flex-col items-start gap-2.5 text-[#343433] font-ojah font-bold text-[32px] leading-normal">
          Accounts
        </div>

        <div className="w-full lg:w-2/4 flex items-center justify-end space-x-5">
          {currencies?.map((item: string) => {
            const isActive = selectedCurrency === item;

            return (
              <button key={item} onClick={() => setSelectedCurrency(item)} className={`uppercase font-ojah active:scale-90 transition-transform ${isActive ? 'bg-shiga-dark-100 py-1.5 px-3 rounded-full text-white' : 'text-shiga-gray-100'}`}>
                {item}
              </button>
            )
          })}
        </div>

      </div>

      <Maybe condition={!loadingAccounts && currencyAccounts?.length === 0}>
        <EmptyAccounts />
      </Maybe>


      <Maybe condition={loadingAccounts || (!loadingAccounts && currencyAccounts?.length > 0)}>

        <AccountFilterHandler accounts={accounttListData} />

        <div>
          <Table>
            <Table.Head>
              <th className="pl-12"> Account Information</th>
              <th>Reference</th>
              <th>Status</th>
              <th>Date Issued</th>
              <th></th>
            </Table.Head>
            <Table.Body>
              <TableContent
                total={accounttListData.payload?.length || 0}
                loading={loadingAccounts}
                loadingText="Fetching accounts"
                data={accounttListData.payload}
                colspan={6}
                emptyUI={<EmptyAccounts />}
              />

              {!loadingAccounts && filteredAccountList.map((data: any, index: number) => (
                <tr
                  key={index * 2}
                  className="bg-white !py-12 px-4 rounded-md border-b"
                >
                  <td className="text-left flex items-center gap-2 py-4">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.6492 0H3.35079C1.5002 0 0 1.49238 0 3.33333V28.6667C0 30.1217 0.93721 31.3591 2.24388 31.8138C4.27749 26.1889 9.68781 22.1667 16.0425 22.1667C22.387 22.1667 27.7901 26.176 29.8313 31.7867C31.0986 31.3113 32 30.0937 32 28.6667V3.33333C32 1.49238 30.4998 0 28.6492 0ZM16.1263 20C19.9662 20 23.0791 16.9033 23.0791 13.0833C23.0791 9.26336 19.9662 6.16667 16.1263 6.16667C12.2863 6.16667 9.17339 9.26336 9.17339 13.0833C9.17339 16.9033 12.2863 20 16.1263 20Z"
                        fill="#F3F4F5"
                      />
                      <path
                        d="M23.0791 13.0833C23.0791 16.9033 19.9662 20 16.1263 20C12.2863 20 9.17339 16.9033 9.17339 13.0833C9.17339 9.26336 12.2863 6.16667 16.1263 6.16667C19.9662 6.16667 23.0791 9.26336 23.0791 13.0833Z"
                        fill="#99999C"
                      />
                      <path
                        d="M3.35079 32H28.6492C29.0653 32 29.4637 31.9246 29.8313 31.7867C27.7901 26.176 22.387 22.1667 16.0425 22.1667C9.68781 22.1667 4.27749 26.1889 2.24388 31.8138C2.5904 31.9344 2.96289 32 3.35079 32Z"
                        fill="#99999C"
                      />
                    </svg>
                    <div className="flex flex-col">
                      <div className="font-medium text-[#343433]">
                        {data.accountNumber}
                      </div>
                      <div className="text-[#99999c] text-sm">
                        {data.accountName}
                      </div>
                    </div>
                  </td>
                  <td className="text-left">
                    <div className="flex">
                      <div className="font-medium text-[#343433]">
                        {data.reference.length > 15
                          ? `${data.reference.slice(0, 15)}`
                          : data.reference}
                      </div>
                      <div
                        className="flex justify-center items-center ml-[8px] cursor-pointer"
                        onClick={() => handleCopyClick(data.reference)}
                      >
                        <svg
                          width={12}
                          height={12}
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.12001 3.87922V2.81922C8.12001 1.64838 7.17086 0.699219 6.00001 0.699219H2.82001C1.64917 0.699219 0.700012 1.64838 0.700012 2.81922V5.99922C0.700012 7.17006 1.64917 8.11922 2.82001 8.11922H3.88001M8.12001 3.87922H6.00001C4.82917 3.87922 3.88001 4.82838 3.88001 5.99922V8.11922M8.12001 3.87922H9.18001C10.3509 3.87922 11.3 4.82838 11.3 5.99922V9.17922C11.3 10.3501 10.3509 11.2992 9.18001 11.2992H6.00001C4.82917 11.2992 3.88001 10.3501 3.88001 9.17922V8.11922"
                            stroke="#99999C"
                            strokeWidth="1.4"
                          />
                        </svg>
                      </div>
                    </div>
                  </td>
                  <td className="text-left">
                    <div className="flex items-center gap-1.5 p-1 rounded-md">
                      <div
                        className={`text-sm font-medium px-2 py-1 rounded-xl ${data.isVirtual
                          ? "text-[#00c46c] border border-[#DCFAEC]"
                          : "text-[#da2f20] border border-[#FFEEE8]"
                          }`}
                      >
                        {data.isVirtual ? "Active" : "Inactive"}
                      </div>
                    </div>
                  </td>
                  <td className="text-left font-medium">
                    {moment(data?.createdAt).format('MMM D, YYYY [at] h:mmA')}
                  </td>
                </tr>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Maybe>
    </div>
  );
};

export default EmptyIssuedAccount;
