import { FC } from 'react';
import { useCustomToast } from 'utils/context/custom-toast/ToastContext';
import { ReactComponent as IconSuccess } from "assets/images/icons/custom-toast-success.svg";
import Maybe from '../Maybe';


interface ToastProps {
   toast: CustomToastItemProps
}

const CustomToastItem: FC<ToastProps> = ({ toast }) => {
   // eslint-disable-next-line
   const { removeToast } = useCustomToast();

   // const getToastStyles = (type: string) => {
   //    switch (type) {
   //       case 'success':
   //          return 'bg-green-500';
   //       case 'warning':
   //          return 'bg-yellow-500';
   //       case 'error':
   //          return 'bg-shiga-dark-800';
   //       case 'info':
   //          return 'bg-blue-500';
   //       default:
   //          return '';
   //    }
   // };

   return (
      <div className={`flex items-start bg-shiga-dark-800 text-white font-inter relative p-4 pt-1 h-[85px] w-[380px] rounded-[18px] animate-customToastIn`}>

         <div className="mr-3 pt-px">
            <IconSuccess width={25} />
         </div>

         <span className="flex-1 pt-2">{toast.message}</span>

         <Maybe condition={toast.onClick !== undefined}>
            <div className="absolute bottom-3.5 right-4">
               <button onClick={toast.onClick} className='font-medium text-sm bg-shiga-dark-50 px-3.5 py-1.5 rounded-xl'>
                  Show
               </button>
            </div>
         </Maybe>

      </div>
   );
};

export default CustomToastItem;
