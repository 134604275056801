import { Menu } from "@headlessui/react";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroupSelector } from "components/common/form";
import Dropdown from "utils/dropdown";
import { ReactComponent as IconNoChart } from "assets/images/icons/nochart.svg";
import { ExportOption } from "../interface";
import { Period } from "types/overview";
import { useMemo } from "react";
import BarGraph from './BarGraph'

type Props = {
    data: Period[]
    activeCur: string;
    currentTab: string;
    exportOptions: ExportOption[];
    updateExportOptions: (e: any) => void;
    updateTransactionsRange: (e: any) => void
    transactionsRange: ExportOption<string>[];
}

export const DashboardChart = ({
    data,
    exportOptions,
    transactionsRange,
    updateExportOptions,
    updateTransactionsRange,
    activeCur }: Props) => {

    const availableData = useMemo(() => !!data.find(item => item.amount > 0), [data]);

    return (
        <>
            <div className="pt-8 mt-8 flex items-center justify-between border-t border-gray-100">
                <div className="w-56 p-1 bg-gray-50 rounded-full">
                    <ButtonGroupSelector
                        selectOptions={exportOptions}
                        onValueChange={(e: any) => updateExportOptions(e)}
                        design="slide-tab-toggle"
                    />
                </div>
                <div>
                    <Dropdown dropdownClass="min-w-[150px] dropdown-right">
                        <div className="w-full form-input !h-10 !text-sm flex items-center justify-between !bg-white !border !border-gray-200 !rounded-xl">
                            {transactionsRange.filter((e: any) => e.selected === true)[0]?.label || 'Last week'}
                            <FontAwesomeIcon
                                icon="angle-down"
                                className="ml-2 text-shiga-dark"
                            />
                        </div>
                        <div>
                            {transactionsRange.map((range) => (
                                <Menu.Item key={range.value}>
                                    <div
                                        onClick={() => updateTransactionsRange(range)}
                                        className="dropdown-item flex items-center justify-between"
                                    >
                                        <div className="whitespace-nowrap">{range.label}</div>
                                        <div className="w-7 text-right flex-shrink-0">
                                            <Maybe condition={range.selected === true}>
                                                <FontAwesomeIcon icon="check-circle" />
                                            </Maybe>
                                        </div>
                                    </div>
                                </Menu.Item>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>

            <div style={{ height: '430px' }} className="py-7 lg:py-8 px-5">
                {availableData
                    ?
                    <BarGraph
                        selectedCurrency={activeCur || 'NGN'}
                        chartFeed={data?.map((item: any) => item.amount)}
                    />
                    :
                    <div className="w-full flex items-center justify-center flex-col py-12 lg:py-20">
                        <IconNoChart />
                        <span className="text-sm text-shiga-dark mt-5">
                            There is currently no graph data to
                            <br />display for your account at this time
                        </span>
                    </div>
                }
            </div>
        </>
    )
}