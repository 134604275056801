import React from "react";
import { useMemo } from "react";
import { Invoice } from "types/payment/invoice";
import { useSharedStore } from "zustand-store";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { Separator } from "components/ui/separator";
import { ReactComponent as IconItem } from "assets/images/icons/items.svg";
import { Button } from "components/ui/button";
import { Pen, X } from "lucide-react";
import { currencyFormat } from "lib/format.amount";

export const ItemList = ({ invoice }: { invoice: Invoice }) => {
  const { removeItem, setEditIndex } = useInvoiceStore();
  const { currencies } = useSharedStore();
  const total = useMemo(() => {
    return invoice.items.length > 0
      ? invoice.items.reduce((acc, item) => acc + item.price * item.qty, 0)
      : 0;
  }, [invoice.items]);
  const handleRemove = (index: number) => {
    removeItem(index);
  };
  const handleEdit = (index: number) => {
    setEditIndex(index);
  };
  const foundCurrency = useMemo(
    () => currencies.find((item) => item.country === invoice.country),
    [currencies, invoice.country]
  );

  return (
    <div className="w-full lg:w-[284px] h-max px-2 py-3 rounded-2xl border border-neutral-100 flex-col justify-center items-center gap-0.5 inline-flex">
      <div className="self-stretch px-3 py-2 justify-between items-start inline-flex">
        <div className="grow shrink basis-0 text-zinc-400 text-xl font-normal font-['Ojah Display']">
          Items
        </div>
        <p>{invoice.items.length}</p>
      </div>
      <Separator />
      {invoice.items.length < 1 ? (
        <div className="self-stretch h-[150px] px-3 py-6 flex-col justify-center items-center gap-4 flex">
          <div className="w-16 h-16 relative">
            <IconItem />
          </div>
          <div className="w-[197px] h-[22px] text-center text-neutral-400 text-sm font-normal font-['Inter'] leading-snug">
            No item has been added yet{" "}
          </div>
        </div>
      ) : (
        <div>
          {invoice.items.map((item, i) => (
            <React.Fragment key={item.name}>
              <div
                key={i}
                className="w-[258px] h-[68px]  my-2 flex-col justify-center items-center inline-flex"
              >
                <div className="self-stretch px-3 py-1.5 justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-stone-900 text-sm font-medium font-['Inter'] leading-snug">
                    {item.name}
                  </div>
                  <Button
                    onClick={() => handleEdit(i)}
                    className="  bg-stone-500 p-1 h-max rounded-[13.50px] justify-center items-center flex"
                  >
                    <Pen
                      size={8}
                      fill="white"
                      className="text-white "
                      color="white"
                    />
                  </Button>
                  <Button
                    onClick={() => handleRemove(i)}
                    className="bg-stone-500 h-[18px] p-1 rounded-[13.50px] justify-center items-center flex"
                  >
                    <X
                      size={8}
                      fill="white"
                      className="text-white"
                      color="white"
                    />
                  </Button>
                </div>
                <div className="self-stretch px-3 py-1.5 justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-400 text-sm font-normal font-['Inter'] leading-snug">
                    {item.qty} X {item.price} {foundCurrency?.currency}
                  </div>
                  <div className="grow shrink basis-0 text-right text-stone-900 text-sm font-medium font-['Inter'] leading-snug">
                    {currencyFormat(
                      item.qty * item.price,
                      foundCurrency?.currency || "NGN"
                    )}
                  </div>
                </div>
              </div>
              <Separator />
            </React.Fragment>
          ))}
        </div>
      )}
      <div className="self-stretch px-3 py-2 justify-start items-start inline-flex">
        <div className="grow shrink basis-0 text-neutral-400 text-sm font-normal font-['Inter'] leading-snug">
          Total
        </div>
        <div className="grow shrink basis-0 text-right text-stone-900 text-sm font-medium font-['Inter'] leading-snug">
          {total.toLocaleString()} {foundCurrency?.currency || "NGN"}
        </div>
      </div>
    </div>
  );
};
