import { getLinks } from "lib/payments";
import { PaginationResponse } from "types/data-interface";
import {
  PaymentLink,
  defaultPaymentLink,
} from "types/payment/payments.interface";
import { create } from "zustand";
interface PaymentLinksState {
  refetch: boolean;
  completed: string[];
  selectedType: "one-time" | "tuition" | null;
  setSelectedType: (type: "one-time" | "tuition") => void;
  setCompleted: (slug: string) => void;
  paymentLink: PaymentLink[];
  paymentLinks: PaginationResponse<PaymentLink[]>;
  fetchPaymentLinks: (params?: any) => void;
  updatePaymentLink: (arg: PaymentLink, action?: string) => void;
  currentPaymentLink: PaymentLink;
  setCurrentPaymentLink: (arg: PaymentLink) => void;
  setRefetch: (value: boolean) => void;
}

export const usePaymentLinkStore = create<PaymentLinksState>()((set, get) => ({
  refetch: false,
  paymentLink: [],
  paymentLinks: {
    meta: {
      size: 0,
      totalItems: 0,
      nextPage: 0,
      previousPage: 0,
    },
    payload: [],
  },
  selectedType: "one-time",
  setSelectedType: (type) => set({ selectedType: type }),
  completed: ["one"],
  setCompleted: (slug) =>
    set((state) => ({ completed: [...state.completed, slug] })),
  updatePaymentLink: (arg: PaymentLink, action?: string) => {
    const { paymentLink } = get();
    const newPaymentLinks = [...paymentLink];
    const foundPaymentLink = paymentLink.findIndex(
      (item) => item.id === arg.id
    );
    if (foundPaymentLink > -1) {
      if (action === "delete") {
        newPaymentLinks.splice(foundPaymentLink, 1);
      } else {
        newPaymentLinks.splice(foundPaymentLink, 1, arg);
      }
      set({ paymentLink: newPaymentLinks });
    }
    set({ refetch: true });
  },
  fetchPaymentLinks: async (params: any) => {
    try {
      const { data } = await getLinks(params);
      set({
        paymentLink: data.payload.map((item) => ({
          ...item,
        })),
      });
      set({
        paymentLinks: data,
      });
    } catch (error) {}
  },
  currentPaymentLink: defaultPaymentLink,
  setCurrentPaymentLink: (arg: PaymentLink) => {
    set({ currentPaymentLink: arg });
  },
  setRefetch: (value: boolean) => {
    set({refetch:value})
  }
}));
