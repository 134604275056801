import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "components/ui/button";
import { Form } from "components/ui/form";

import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "../paths";
import PurchaseInfo from "pages/bills/PurchaseInfo";
import { useGiftCardStore } from "zustand-store/payments/gift-cards";
import { orderGiftCard } from "lib/payments/giftCard";
import { toast } from "react-toastify";
import { currencyFormat } from "lib/format.amount";
import { useMemo } from "react";
import useToolkit from "hooks/useToolkit";
import PATHS from "NavigationRoute";

const FormSchema = z.object({});

export const ReviewAndSend = () => {
  const navigate = useNavigate();
  const { payload } = useGiftCardStore();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });
  const { showCustomSuccessToast, getCurrencySymbol } = useToolkit()

  const parsePayload = useMemo(() => ({
    Amount: currencyFormat(payload.amount, payload.currency),
    Product: payload.giftCard.brand.brandName,
    "Sent to": payload.email,
    Fee: "Free 😎"

  }), [payload])


  function onSubmit(data: z.infer<typeof FormSchema>) {
    const { amount, currency, email, giftCard, note } = payload

    return orderGiftCard({
      amount,
      currency,
      email,
      note,
      productId: giftCard.productId,
      quantity: 1
    }).then(() => {

      showCustomSuccessToast({
        message: `You have successfully paid ${amount} ${currency} for ${getCurrencySymbol(currency)}${amount} ${payload?.giftCard?.brand?.brandName} giftcard`,
        onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE)
      })
      setTimeout(() => {
        navigate(PAYMENT_PATHS.GIFT_CARD);
      }, 1500);
    }).catch(err => {
      toast.error(`Error ordering card: ${err.message}`)
    })
  }

  return (
    <div className="w-full p-10  space-y-4 flex flex-col justify-center items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <PurchaseInfo header={`Confirm your purchase of ${payload.giftCard.brand.brandName} GiftCard`} transactionType="Gift Card Payment"
            info={Object.entries(parsePayload).map(([attribute, value]) => ({
              attribute,
              value
            }))}
          />

          <Button className="w-full" type="submit" isLoading={form.formState.isSubmitting}>
            Confirm & Pay
          </Button>
        </form>
      </Form>
    </div>
  );
};
