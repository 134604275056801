import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { useCallback, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Tooltip } from "react-tooltip";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";

const fileTypes = ["JPG", "PNG"];
type Props = {
  file: File | null;
  setFile: (file: File | null) => void;
  previewUrl: string;
  setPreviewUrl: (val: string) => void;
  heightAndWidth?: string;
  placeholder?: string;
};
export const Upload = ({
  file,
  setFile,
  previewUrl,
  setPreviewUrl,
  heightAndWidth,
  placeholder,
}: Props) => {
  const handleChange = (file: File) => {
    setFile(file);
  };

  const handlePreview = useCallback(
    async (file: File) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const imageUrl = event.target.result as string;
          setPreviewUrl(imageUrl); // Update component state with preview URL
        }
      };
      reader.readAsDataURL(file);
    },
    [setPreviewUrl]
  );

  useEffect(() => {
    if (file) {
      handlePreview(file); // Trigger preview on file change
    }
  }, [file, handlePreview]);
  return (
    <div
      className={`relative ${
        heightAndWidth ? heightAndWidth : "w-[274px] h-[274px]"
      }  border rounded-3xl flex flex-col justify-center items-center`}
    >
      <Tooltip id="dp-edit" className="custom-tooltip-ui" />
      <Tooltip id="dp-remove" className="custom-tooltip-ui" />

      {file && (
        <>
          <div className="absolute bottom-2 right-2">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            >
              <IconEdit
                data-tooltip-id="dp-remove"
                data-tooltip-content="Change Image"
                className="w-8 h-8 p-1.5 bg-black bg-opacity-50  z-[1]  rounded-full hover:bg-opacity-100 cursor-pointer outline-none"
              />
            </FileUploader>
          </div>
          <IconRemove
            onClick={() => setFile(null)}
            data-tooltip-id="dp-remove"
            data-tooltip-content="Remove Image"
            className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 left-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white"
          />
        </>
      )}
      {file ? (
        <img
          src={previewUrl || ""}
          alt={file.name}
          className="h-[97%] rounded-3xl w-[97%] object-fill "
        />
      ) : (
        <div
          className={`relative ${
            heightAndWidth ? heightAndWidth : " w-[274px] h-[274px]"
          }  border rounded-3xl flex flex-col items-center justify-center`}
        >
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          >
            <div className="flex cursor-pointer flex-col justify-center items-center ">
              <IconFileUpload />
              <p className="text-xs text-form-gray text-center max-w-48">
                {placeholder
                  ? placeholder
                  : "Drag and drop files or click here to upload"}
              </p>
            </div>
          </FileUploader>
        </div>
      )}
    </div>
  );
};
