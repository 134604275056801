import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Button } from "components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { useNavigate } from "react-router-dom";
import { Upload } from "components/common/Upload";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { useEffect, useMemo, useState } from "react";
import { PAYMENT_PATHS } from "pages/payments/paths";
import { phoneRegex } from "constants/phone_nuber.regxp";
import TextArea from "components/common/form/TextArea";
import { Input } from "components/common/form";
import { googleApiKey } from "data/config";
import CommandSelect from "components/ui/command-select";
import { CircleCheck } from "lucide-react";
import { CommandInput } from "components/ui/command";
import { getAddressObject } from "lib/format.address";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { toast } from "react-toastify";

const formSchema = z.object({
  recipientName: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),

  note: z.string().optional(),
  // recipientPhone: z.string().regex(phoneRegex, "Invalid Number!"),
  recipientPhone: z.string().regex(phoneRegex, "Invalid Number!"),
  // address: z.object({
  //   description: z.string(),
  //   place_id: z.string(),
  // }),
  address: z.object({
    description: z.string().min(1, {
      message: "Please select an address",
    }),
    place_id: z.string().min(1, {
      message: "Please select an address",
    }),
  }),
  // postCode: z.string(),
  recipientEmail: z.string().email(),
});

export function ReciepientForm() {
  const [location, setLocation] = useState("");
  const { invoice, setInvoice, setCompleted } = useInvoiceStore();
  const [isPostalCode, setIsPostalCode] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      recipientName: invoice?.reciepient?.recipientName || "",
      note: invoice?.reciepient?.note || "",
      recipientPhone: invoice?.reciepient?.recipientPhone || "",
      address: {
        description: "",
        place_id: "",
      },
      recipientEmail: invoice?.reciepient?.recipientEmail || "",
    },
  });
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleApiKey,
  });

  useEffect(() => {
    if (invoice.imageUrl) {
      setPreviewUrl(invoice.imageUrl);
    }
  }, []);

  useEffect(() => {
    if (previewUrl.length) {
      setInvoice({
        ...invoice,
        imageUrl: previewUrl,
      });
    }
  }, [previewUrl]);

  const navigate = useNavigate();
  function onSubmit(values: z.infer<typeof formSchema>) {
    setCompleted("receipient");
    // navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=add_items`);

    // if (!values.address.place_id) {
    //   form.setError("address.description", {
    //     message: "Please select an address",
    //   });
    // }

    return placesService.getDetails(
      {
        placeId: values.address.place_id,
        fields: ["address_components", "adr_address", "formatted_address"],
      },
      (placeDetails: any) => {
        const addresObject = getAddressObject(placeDetails.address_components);

        if (!addresObject.postal_code) {
          form.setError("address.description", {
            message: "Please select an address",
          });
        }

        setInvoice({
          ...invoice,
          reciepient: {
            ...invoice.reciepient,
            ...values,
            address: {
              address: values.address.description,
              city: addresObject.city,
              country: addresObject.country,
              postCode: addresObject.postal_code,
              state: addresObject.region,
            },
          },
        });
        navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=add_items`);
      }
    );
  }

  const addressError = useMemo(
    () => form.formState.errors["address"],
    [form.formState.errors["address"]]
  );
  // Update isPostalCode when address changes
  useEffect(() => {
    if (form.watch("address.place_id")) {
      const placeId = form.watch("address.place_id");
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ["address_components", "adr_address", "formatted_address"],
        },
        (placeDetails: any) => {
          const addressComponents = placeDetails.address_components;
          const postalCodeComponent = addressComponents.find((component: any) =>
            component.types.includes("postal_code")
          );

          if (!postalCodeComponent) {
            setIsPostalCode(true);
            toast.error("(No)(Street Name)(State)(Country)");
          } else {
            setIsPostalCode(false);
          }

          // Clear address error if a valid address is selected
          form.clearErrors("address");
        }
      );
    }
  }, [form.watch("address.place_id"), placesService]);

  const showPostalCodeField =
    form.watch("address.place_id") && !addressError && isPostalCode === true;

  return (
    <div className="flex flex-col-reverse lg:flex-row p-6 justify-between items-center lg:items-start">
      <div className="mt-5 lg:0 w-full lg:w-[450px] space-y-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="">
            <FormField
              control={form.control}
              name="recipientName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Recipient's Name"
                      placeholder="Enter a name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-between gap-4 w-full mb-[1.75rem]">
              <FormField
                control={form.control}
                name="recipientEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        label="Email address"
                        placeholder="Enter email address"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="recipientPhone"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="number"
                        required
                        label="Phone number"
                        min={10}
                        maxLength={11}
                        placeholder="Enter phone number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-[1.75rem]">
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="flex flex-col space-y-3">
                    <CommandSelect
                      searchPlaceholder="Search Address"
                      filterOption={() => 1}
                      loading={isPlacePredictionsLoading}
                      SearchInput={
                        <CommandInput
                          placeholder="Search Address"
                          value={location}
                          onValueChange={(e) => {
                            setLocation(e);
                            getPlacePredictions({ input: e });
                          }}
                        />
                      }
                      notFound="No Billing Address found."
                      label="Billing Address"
                      options={placePredictions.map((item) => ({
                        ...item,
                        id: item.place_id + "",
                        value: item.description,
                      }))}
                      SelectTrigger={() => (
                        <span className="flex items-center gap-4 overflow-hidden text-base">
                          {!field.value.description && (
                            <p className="flex items-center text-form-gray text-base font-normal">
                              <IconSearch className="mr-3" />
                              {"(No)(Street Name)(State)(Country)"}
                            </p>
                          )}
                          {field.value.description}
                        </span>
                      )}
                      RenderOption={({ item }) => (
                        <>
                          <span className="flex text-left">
                            {item.description}
                          </span>
                          {field.value === item.description && (
                            <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                          )}
                        </>
                      )}
                      handleSelect={(select) => {
                        form.setValue("address", {
                          description: select.description,
                          place_id: select.place_id,
                        });
                        form.clearErrors("address");
                      }}
                    />
                    {addressError?.description?.message?.length ? (
                      <p className={"text-sm font-medium text-warn"}>
                        {addressError.description.message}
                      </p>
                    ) : (
                      <></>
                    )}
                  </FormItem>
                )}
              />
            </div>
            {/* {showPostalCodeField && (
              <FormField
                control={form.control}
                name="postCode"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        label="Postal code"
                        placeholder="Enter your address postal code"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )} */}
            <div className="mb-[1.75rem]">
              <TextArea
                form={form as any}
                placeholder="Say a bit more about this invoice"
                label="Add a Note (optional)"
              />
            </div>
            <Button
              className="w-full mt-3"
              // disabled={!placesService.getDetails}
              // disabled={showPostalCodeField || form.formState.isValid}
              disabled={!form.formState.isValid && isPostalCode}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </Form>
      </div>
      <Upload
        previewUrl={previewUrl || invoice.imageUrl}
        setPreviewUrl={setPreviewUrl}
        file={file}
        setFile={setFile}
      />
    </div>
  );
}
