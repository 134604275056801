import moment from 'moment';
// return moment.utc(date).local().format()
// return moment.utc(date).format()


export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
]

export const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


export const MonthName = (month) => {
    const monthIndex = month - 1;
    return (monthNames[monthIndex]);
}


export const MonthIndex = (monthName) => {
    return (monthNames.indexOf(monthName) + 1);
}


export const PadDigits = (month) => {
    if ((month > 0) && (month < 10)) {
        month = "0" + month;
    }
    return (month);
}


export const DateTimeFormat = (datetime) => {
    if (datetime !== undefined && datetime !== null) {
        /*
        let d = new Date(datetime),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            year = "" + d.getFullYear(),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (day.length < 2)
            day = '0' + day;

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        return [monthNames[month] + " " + day + ", " + year + " at " + hour12 + ":" + min + " " + am_pm];
        */

        return moment.utc(datetime).format("MMMM DD, YYYY [at] hh:mm A");
    }
};


export const DateTimeMiniFormat = (datetime) => {
    if (datetime !== undefined && datetime !== null) {
        /*
        let d = new Date(datetime),
            month = "" + d.getMonth(),
            day = "" + d.getDate(),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (day.length < 2)
            day = '0' + day;

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        return [day + " " + monthNamesShort[month] + ". " + hour12 + ":" + min + " " + am_pm];
        */

        return moment.utc(datetime).format("DD MMM. hh:mm A");
    }
};


export const DateFormat = (date, format = '') => {
    if (date !== undefined && date !== null) {
        /*
        let d = new Date(date),
            month = '' + d.getMonth(),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (day.length < 2)
            day = '0' + day;

        if (format === "DD-MM") {
            return [monthNamesShort[month] + " " + day];
        }
        return [monthNamesShort[month] + " " + day + ", " + year];
        */

        if (format === "DD-MM") {
            return moment.utc(date).format("MMM DD");
        }
        else if (format === "MMMM") {
            return moment.utc(date).format("MMMM DD, YYYY");
        }
        else {
            return moment.utc(date).format("MMM DD, YYYY");
        }
    }
    else {
        return date;
    }
};


export const DateFullFormat = (date, format = '') => {
    if (date !== undefined && date !== null) {
        // let d = new Date(date),
        //     month = '' + d.getMonth(),
        //     day = '' + d.getDate(),
        //     year = d.getFullYear();

        // if (day.length < 2)
        //     day = '0' + day;

        // if (format === "DD-MM") {
        //     return [monthNames[month] + " " + day];
        // }
        // return [monthNames[month] + " " + day + ", " + year];

        if (format === "DD-MM") {
            return moment.utc(date).format("MMM DD");
        }
        return moment.utc(date).format("MMM DD, YYYY");
    }
};


export const TimeFormat = (time) => {
    if (time !== undefined && time !== null) {
        /*
        let d = new Date(time),
            hour = "" + d.getHours(),
            hour12 = 0,
            am_pm = "",
            min = "" + d.getMinutes(),
            sec = "" + d.getSeconds();

        if (hour > 12) {
            hour12 = (hour - 12);
            am_pm = "PM";
        }
        else {
            hour12 = hour;
            am_pm = "AM";
        }

        if (min.length < 2)
            min = '0' + min;

        if (sec.length < 2)
            sec = '0' + sec;

        return [hour12 + ":" + min + " " + am_pm];
        */

        return moment.utc(time).format("hh:mm A");
    }

};


export const DateFormatDMY = (date) => {
    if (date !== undefined && date !== null) {

        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;

        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
};


export const PadMonthIndex = (month) => {
    if ((month > 0) && (month < 10)) {
        month = "0" + month;
    }
    return (month);
}


export function DateDisplay(date) {
    let readable;
    const today = moment().format("DD-MM-YYYY");
    const yesterday = moment().subtract(1, 'days').format("DD-MM-YYYY");
    const valueDate = DateFormatDMY(date)

    if (valueDate === today) {
        readable = 'Today';
    }
    else if (valueDate === yesterday) {
        readable = 'Yesterday';
    }
    else {
        readable = DateFormat(date, "MMMM");
    }

    return readable;
}


export default DateFormat;