import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularCard from "./CircularCard";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface SavedBeneficiaryItemProps {
  accountName: string;
  image: string;
  detail: string;
  onClick: () => void;
}
const extractInitials = (accountName: string) => {
  if (!accountName) {
    return "";
  }

  const sanitizedAccountName = accountName.replace(/[^a-zA-Z\s]/g, " ");

  const names = sanitizedAccountName.split(" ").filter(Boolean);

  if (names.length < 2) {
    return names.length === 1 ? names[0][0].toUpperCase() : "";
  }

  return `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`;
};
const SavedBeneficiaryItem: React.FC<SavedBeneficiaryItemProps> = ({
  accountName,
  image,
  detail,
  onClick,
}) => {
  // const names = accountName?.split(" ");
  // const firstTwo = `${names[0][0]}${names[names?.length - 1][0]}`;
  const firstTwo = extractInitials(accountName);

  return (
    <div
      className="flex items-center justify-between hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <CircularCard imageSrc={image} title={firstTwo} />
        <div className="space-y-1 min-w-3.5">
          <h4
            style={{
              fontSize: "16px",
              color: "#0C0C0C",
              fontWeight: 500,
            }}
          >
            {accountName}
          </h4>
          <p
            style={{
              color: "#99999C",
              fontSize: "13px",
              fontWeight: 400,
            }}
          >
            {detail}
          </p>
        </div>
      </div>
      <FontAwesomeIcon className="ml-10" icon={faChevronRight} />
    </div>
  );
};

export default SavedBeneficiaryItem;
