import { ReactComponent as IconTrendArrows } from "assets/images/icons/TrendArrows.svg";
import { ReactComponent as IconTrendArrowsDown } from "assets/images/icons/TrendArrowsDown.svg";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";
import { Tooltip } from "react-tooltip";
// import { useMemo } from "react";

// const calPercentChange = (today: number, previous: number) => ((today - previous) / previous) * 100;

export const DashboardTrend: React.FC<{
    title: string;
    currentTrend: number;
    heading: string;
    prevTrend: number;
    tooltip: any;
    id: number;
}> = ({ currentTrend, heading, prevTrend, title, tooltip, id }) => {
    // const trend = useMemo(() => calPercentChange(currentTrend, prevTrend) || 0, [currentTrend, prevTrend])

    const trend = currentTrend !== 0 && !isNaN(currentTrend) && !isNaN(prevTrend)
        ? ((currentTrend - prevTrend) / currentTrend) * 100
        : 0;

    return (
        <div className="w-full lg:w-[31.5%]">
            <div className="font-medium text-shiga-dark flex items-center space-x-1" >
                <span>{title}</span>
                <IconInfo className="text-shiga-dark block" id={`refund-info-${id}`} />
                <Tooltip anchorSelect={`#refund-info-${id}`} place="top" className="custom-tooltip-ui">
                    {tooltip}
                </Tooltip>
            </div>
            <div className="mt-4 text-3xl md:text-4xl font-semibold">
                {heading}
            </div>
            {

                <div className="mt-2 flex items-center text-shiga-gray">
                    <span className={`${trend > 0 ? 'text-shiga-green' : 'text-shiga-warn'} flex items-center`}>
                        {
                            trend > 0 ?
                                <IconTrendArrows className="mr-1" /> : <IconTrendArrowsDown className="mr-1" />
                        }
                        <p>{`${trend?.toString()?.includes('-') && trend < 1 ? '100' : Math.floor(trend)}% Past Day`}</p>
                    </span>
                </div>
            }
        </div>
    )
}
