import { PaginatedApiResponseNoMeta } from "types/data-interface";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { Bank } from "types/bank";
import { RootState } from "store/store";

interface InitialState {
    banksLoading: boolean;
    banks: PaginatedApiResponseNoMeta<Bank[]> | null
}

const initialState: InitialState = {
    banksLoading: false,
    banks: null,
};


export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case actions.GET_BANKS:
            state = {
                ...state,
                banksLoading: false,
                banks: action.payload,
            }
            return state;


        case actions.GET_BANKS_START:
            state = {
                ...state,
                banksLoading: true,
            }
            return state;


        case actions.GET_BANKS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                banksLoading: false,
                banks: null,
            }
            return state;


        default:
            return state;
    }
}

export const getBanks = (s: RootState) => s.ui.banks;