import axios from "axios";
import { apiBaseUrl } from "data/config";
import { getToken } from "lib";
import { handleError } from "lib/format.error";

export const generateAccountStatement = async (body: {
   fileType: "CSV" | "PDF" | string;
   walletId: string;
   end: string;
   start: string;
}) => axios.post(apiBaseUrl + "/transaction/statement", body, {
    headers: {
        Authorization: "Bearer " + getToken(),
    },
}).then(res => res.data).catch(handleError)
