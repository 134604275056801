/**
 * urlQueryParams = object of url query string
 * params = payload object to update and return
 * urlkey = the url query key to search for
 * type = if the url query value should be a number or string
 * defaultValue = default query value if not explicitly specified in the url query params
 * requestKey = the key name to use when sending request to backend
 */
export const processParams = (urlQueryParams, params, urlkey, type, defaultValue, requestKey) => {
    const data = urlQueryParams[urlkey];

    if (data !== undefined && data !== null) {
        if (type === "number" && !isNaN(data) && data > 0) {
            params = { ...params, [requestKey]: parseInt(data) }
        }
        else if (type === "string") {
            params = { ...params, [requestKey]: data }
        }
    }
    else {
        if (defaultValue === "" || defaultValue === undefined) {
            if (params[requestKey]) {
                delete params[requestKey];
            }
        }
        else {
            params = { ...params, [requestKey]: defaultValue }
        }
    }

    return params;
}


export default processParams;