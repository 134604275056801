import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | "className" | "onClick"> {
    disabled?: boolean;
    loading?: boolean;
    danger?: boolean;
    lightGrayBg?: boolean;
    whiteBg?: boolean;
    darkBg?: boolean;
    fullWidth?: boolean;
    text: string;
}

export const ShigaButton: React.FC<ButtonProps> = ({
    type,
    loading,
    disabled,
    className,
    text,
    danger,
    lightGrayBg,
    whiteBg,
    darkBg,
    fullWidth,
    onClick,
}) => {

    return (
        <button
            disabled={loading || disabled}
            type={type}
            onClick={onClick}
            className={`disabled:bg-gray-500 custom-button px-[15px] py-3 flexed nowrap rounded-[14px] text-[12px] md:text-[13px] font-inter font-medium tracking-wide active:scale-90 transition-all durationn-150
            ${danger ? "border border-[#20546B] bg-white text-black"
                    : lightGrayBg ? 'bg-[#f2efff] text-bp-purple-600'
                        : whiteBg ? 'bg-white text-shiga-dark-100 border border-shiga-gray-75'
                            : darkBg ? 'bg-shiga-dark-100 text-white'
                                : "bg-shiga-purple-400 text-white"
                } 
            ${fullWidth && "w-full"} ${className}`}>
            {loading && <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (loading ? "inline" : "hidden")} />}
            {text}
        </button>
    )
}

export default ShigaButton
