import { LOADING_STATUS } from "store/middlewares/loadingActions";

const initialState = {
    apiLoadingStatus:  false
}
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case LOADING_STATUS:
            state = {
                apiLoadingStatus: action.payload
            }
            return state
        default: 
            return state    
    }
}