import * as actions from "./actionTypes";
import * as config from "data/config";

export function getCountries() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCountries,
            method: "get",
            data: {},
            onStart: actions.GET_COUNTRIES_START,
            onSuccess: actions.GET_COUNTRIES,
            onError: actions.GET_COUNTRIES_FAILED,
        },
    }
}
