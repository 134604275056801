import { Input } from "components/common/form";
import ShigaButton from "components/common/form/ShigaButton";
import { useEffect, useState } from "react";
import useTransfersProvider from "../../context/useTransfersProvider";
import { defaultUsdForm } from "../../context/exports";
import { ReactComponent as IconLock } from "assets/images/icons/Lock.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/check-black.svg";
import { AddressField, googleAddressPayload } from "components/custom/GoogleAddressField";


const UsdAccountDetails = () => {
   const { incrementSingleTrfStep, usdForm, setUsdForm } = useTransfersProvider()
   const [location, setLocation] = useState('');
   const [formValid, setFormValid] = useState(false);

   const btnDisabled = !formValid || !location?.length || !usdForm?.meta || !usdForm?.meta?.accountType?.length;

   const getPlaceDetails = (placeId: string) => {
      const service = new window.google.maps.places.PlacesService(document.createElement("div"));

      service.getDetails({ placeId }, (place: any, status: any) => {

         if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
            const addressComponents = place.address_components;

            const getComponent = (type: string) =>
               addressComponents.find((component: any) => component.types.includes(type))?.long_name || "";

            const city = getComponent("locality");
            const state = getComponent("administrative_area_level_1");
            const postCode = getComponent("postal_code");
            const meta = {
               routingNo: usdForm?.routingNo,
               bankAddress: {
                  description: googleAddressPayload?.description,
                  place_id: placeId
               },
               bankState: state || '',
               postCode: postCode || '',
               city,
               accountType: ''
            }
            setUsdForm({ ...usdForm, meta })
         }
      });
   };

   const onSubmit = (e: any) => {
      e.preventDefault();
      incrementSingleTrfStep();
   }

   const accountTypes = [
      { label: "Checking Account", value: "CHECKING" },
      { label: "Savings Account", value: "SAVINGS" },
   ];

   useEffect(() => {
      setUsdForm(defaultUsdForm)
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      let hasEmptyValues = false;

      for (let key in usdForm) {
         if (usdForm[key] === "" || usdForm[key].length < 4) {
            setFormValid(false)
            hasEmptyValues = true;
         }
      }

      if (!hasEmptyValues) {
         setFormValid(true);
      }
      // eslint-disable-next-line
   }, [usdForm])

   return (
      <div>
         <form onSubmit={onSubmit}>
            <div className="w-full space-y-7s">

               <Input
                  required
                  type="text"
                  label="Account Name"
                  value={usdForm.accountName}
                  placeholder="Enter account Name"
                  onChange={(e: any) => setUsdForm({ ...usdForm, accountName: e.target.value })}
               />

               <Input
                  required
                  type="text"
                  label="Bank Name"
                  value={usdForm.bankName}
                  placeholder="Enter bank Name"
                  onChange={(e: any) => setUsdForm({ ...usdForm, bankName: e.target.value })}
               />

               <div className="flex items-center justify-between">
                  <div className="w-[48%]">
                     <Input
                        required
                        type="number"
                        label="Account Number"
                        value={usdForm.accountNumber}
                        placeholder="0000000000"
                        onChange={(e: any) => setUsdForm({ ...usdForm, accountNumber: e.target.value })}
                     />
                  </div>

                  <div className="w-[48%]">
                     <Input
                        required
                        type="number"
                        label="ACH Routing Number"
                        value={usdForm.routingNo}
                        placeholder="0000000000"
                        onChange={(e: any) => setUsdForm({ ...usdForm, routingNo: e.target.value })}
                     />
                  </div>
               </div>

               <div className="flex items-end justify-between mb-7">
                  {accountTypes.map(({ label, value }) => {
                     const isInitial = label?.includes('Check')
                     const isActive = usdForm?.meta?.accountType === value;

                     return (
                        <div onClick={() => {
                           setUsdForm({
                              ...usdForm,
                              meta: { ...usdForm?.meta, accountType: value }
                           })
                        }} key={value} className="w-[48%]">
                           <Input
                              readOnly
                              value={label}
                              required={isInitial}
                              label={isInitial ? 'Account Type' : ''}
                              inputClass="cursor-pointer"
                              rightIcon={isActive ? <IconCheck width={17} /> : <div className="h-5 w-5 rounded-full border border-[#d9d9d9]" />}
                           />
                        </div>
                     )
                  })}
               </div>

               <div className="pb-2 border-b border-shiga-gray-50">
                  <p className="mb-0 text-shiga-gray-200 font-medium text-xs">
                     Bank Address
                  </p>
               </div>

               <AddressField
                  isRequired
                  addressValue={location}
                  inputLabel="Bank Address"
                  setAddressValue={setLocation}
                  handleAddressSelection={(address) => getPlaceDetails(address?.place_id)}
               />

               <Input
                  required
                  readOnly
                  type="text"
                  label="State"
                  value={usdForm?.meta?.bankState}
                  placeholder="Enter bank Name"
                  rightIcon={<IconLock width={20} />}
               />

               <div className="flex items-center justify-between mb-10">
                  <div className="w-[48%]">
                     <Input
                        required
                        readOnly
                        label="Post Code"
                        value={usdForm?.meta?.postCode}
                        rightIcon={<IconLock width={20} />}
                     />
                  </div>

                  <div className="w-[48%]">
                     <Input
                        required
                        readOnly
                        label="City"
                        value={usdForm?.meta?.city}
                        rightIcon={<IconLock width={20} />}
                     />
                  </div>
               </div>

               <ShigaButton
                  darkBg
                  fullWidth
                  type='submit'
                  text='Continue'
                  disabled={btnDisabled}
               />
            </div>
         </form>

      </div>
   )
}

export default UsdAccountDetails