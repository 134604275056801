import { ReactComponent as BackButton } from "assets/images/icons/arrow-left-bordered.svg";
import UserIcon from "assets/images/icons/user_profile_icon.png";
import TransHeaderItem from "./TransHeaderItem";
import CloseButton from "assets/images/icons/close.png";
import DollarIconGray from "assets/images/icons/dollar_icon_gray.png";
import DollarIcon from "assets/images/icons/dollar_step_2.png";
import SendMoneyIconGray from "assets/images/icons/send_icon_gray.png";
import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-light-gray.svg";

import React from "react";
import { useNavigate } from "react-router-dom";

interface TransferHeaderProps {
  step: number;
  onClose: () => void;
}

const TransferHeader: React.FC<TransferHeaderProps> = ({ step, onClose }) => {
  const navigator = useNavigate();
  return (
    <div className="flex w-full justify-between items-center max-w-full border-b border-[#f5f5f5] pb-6">
      <div
        onClick={() => {
          navigator(-1);
        }}
      >
        <BackButton className="cursor-pointer" />
      </div>

      <div className="flex items-center flex-grow justify-center space-x-2 lg:space-x-7 max-w-[405px]">
        <TransHeaderItem
          image={UserIcon}
          image2={{}}
          title="Recipient"
          selected={true}
          step={step}
        />

        <IconRight />

        <TransHeaderItem
          image={DollarIcon}
          image2={DollarIconGray}
          title="Amount to Send"
          selected={step >= 2}
          step={step}
        />

        <IconRight />

        <TransHeaderItem
          image={UserIcon}
          image2={SendMoneyIconGray}
          title="Review & Pay"
          selected={step >= 3}
          step={step}
        />
      </div>

      <div>
        <img
          src={CloseButton}
          alt="Close Button"
          className="cursor-pointer"
        //   onClick={onClose}
          onClick={() => onClose()}
        />
      </div>
    </div>
  );
};

export default TransferHeader;
