import { getCards } from "lib/cards";
import { Card, defaultCard } from "types/card";
import { create } from "zustand";

interface CardState {
  completed: string[];
  selectedType: "one-time" | "tuition" | null;
  setSelectedType: (type: "one-time" | "tuition") => void;
  setCompleted: (slug: string) => void;
  selectedCard: Card;
  cards: Card[];
  fetchCards: () => void;
  updateCard: (arg: Card, action?: string) => void;
  setCurrentSelectedCard: (arg: Card) => void;
}

export const useCardStore = create<CardState>()((set, get) => ({
  cards: [],
  selectedType: "one-time",
  setSelectedType: (type) => set({ selectedType: type }),
  completed: ["one"],
  setCompleted: (slug) =>
    set((state) => ({ completed: [...state.completed, slug] })),
  updateCard: (arg, action) => {
    const {cards} = get()
    const newCard = [...cards]
    const foundCard = cards.findIndex(item => item.id === arg.id);
    if (foundCard > -1) {
      if (action === "delete") {
        newCard.splice(foundCard, 1);
      } else {
        newCard.splice(foundCard, 1, arg);
      }
      set({cards: newCard})
    }
  },
  fetchCards: async () => {
    // const { data: {cards} } = await getCards();
    // delete when get cards is working
    const { data } = await getCards();
    set({ cards: (data as any).map((item: Card) => ({
      ...item,
    })) });
  },
  selectedCard: defaultCard,
  setCurrentSelectedCard: (arg:Card) => {
    set({selectedCard: arg})
  }
}));
