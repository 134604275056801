import { ReactComponent as IconEmptyTransactions } from "assets/images/icons/empty-transactions.svg";


const EmptyTransactions = () => {
   return (
      <div className="flex items-center justify-center flex-col mt-40 text-center">
         <IconEmptyTransactions />
         <h4 className="font-ojah text-[26px] leading-8 mt-6 mb-2">
            No Transactions yet
         </h4>
         <p className="text-shiga-dark w-full">
            You have no recorded transaction on this account at this time
         </p>
      </div>
   )
}

export default EmptyTransactions