import { ReactNode } from 'react'
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import useTransfersProvider from '../context/useTransfersProvider';
import Maybe from 'components/common/Maybe';


type Props = {
   children: ReactNode;
   title: string;
   subtitle: string;
}

const TransferFlowWrapper = ({ children, title, subtitle }: Props) => {
   const { beneficiaryQuery, setBeneficiaryQuery, isSingleTransfer, singleTrfStep } = useTransfersProvider()
   const isBeneficiaryView = isSingleTransfer && singleTrfStep === 1;

   return (
      <div className="max-w-[550px] pb-20">

         <div className="w-full lg:w-5/6 sticky top-32 left-0 bg-white z-20">

            <div className="text-lg lg:text-4xl text-shiga-dark-100 font-ojah font-bold">
               {title}
            </div>

            <div className="text-shiga-gray-300 mt-2 pb-3">
               {subtitle}
               <hr className={`w-5/6 h-[1px] border-b border-shiga-gray-50 ${isSingleTransfer ? 'mt-1.5' : 'mt-4'}`} />
            </div>

            <Maybe condition={isBeneficiaryView}>
               <div className="w-5/6 pt-5">
                  <div className="relative">
                     <input
                        type="text"
                        value={beneficiaryQuery}
                        placeholder='Search beneficiaries'
                        onChange={(e) => setBeneficiaryQuery(e.target.value)}
                        className="min-w-[200px] form-input pl-12 bg-shiga-gray-25"
                        style={{ paddingLeft: "0", border: "none", outline: "none" }}
                     />

                     <div className={`absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500`}>
                        <IconSearch />
                     </div>
                  </div>
               </div>
            </Maybe>

         </div>

         <hr className='h-12 sticky top-20 z-[15] bg-white border-none' />

         <div className={`w-full lg:w-4/6 ${isSingleTransfer ? 'pt-24' : 'pt-28'}`}>
            {children}
         </div>
      </div>
   )
}

export default TransferFlowWrapper