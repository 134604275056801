interface CloseBtnIconProps extends React.BaseHTMLAttributes<SVGElement> {}

const CloseBtnIcon = ({ ...props }: CloseBtnIconProps) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.00146484"
        y="0.5"
        width="29.9986"
        height="29.9986"
        rx="14.9993"
        fill="#F5F5F5"
      />
      <path
        d="M11.1113 19.388L15.0008 15.4985M15.0008 15.4985L18.8895 11.6098M15.0008 15.4985L11.1117 11.6094M15.0008 15.4985L18.8899 19.3875"
        stroke="#99999C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseBtnIcon;
