import React from "react";
import { Helmet } from "react-helmet";

const DynamicHeadTag = (props) => {
	const { headerText, headerDescription } = props;

	const title = headerText ? headerText + " - Shiga Business" : "Shiga Business - One Wallet, Global Payment";
	const description = headerDescription ?? "Shiga by Payshiga is the easiest way to make hassle-free cross-border payments on the go.";
	
	return (
		<Helmet>
			<title>{title}</title>

			<meta name="description" content={description} />
			<meta itemprop="description" content={description} />

			<meta property="og:url" content="https://business.payshiga.com" />
			<meta property="og:type" content="business" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			<meta property="og:image" content="https://res.cloudinary.com/walex996/image/upload/v1711983536/shiga-banner-mini_eah1hm.png" />
			<meta property="og:image:url" content="https://res.cloudinary.com/walex996/image/upload/v1711983536/shiga-banner-mini_eah1hm.png" />
			<meta property="og:image:secure_url" content="https://res.cloudinary.com/walex996/image/upload/v1711983536/shiga-banner-mini_eah1hm.png" />
		</Helmet>
	);
};

export default DynamicHeadTag;