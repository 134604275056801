import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { NUMBER_REGEX } from "constants/number.regex";
import { CheckCircle2 } from "lucide-react";
import { toast } from "react-toastify";
import { Input } from "components/ui/input";
import { Currency } from "types/shared/countries.types";
import { useEffect, useState } from "react";
import { getCurrentBalance } from "lib/business";
import { Wallet, defaultWallet } from "types/wallet";
import CommandSelect from "components/ui/command-select";
import { cn } from "lib/utils";
import { currencyFormat } from "lib/format.amount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronDown from "assets/images/icons/ChevronDown";
import { NumericFormat } from "react-number-format";
import { ReactComponent as WarningGrey } from "assets/images/icons/warning-gray.svg";
import { ReactComponent as WarningRed } from "assets/images/icons/warning-red.svg";

export const InputAmountHeader: React.FC<{
  currentWallet: Wallet;
  label?: string;
  showCurrency?: boolean;
}> = ({ currentWallet: { currency, balance }, label, showCurrency }) => {
  return (
    <div className="flex mb-2 justify-between">
      <p className="truncate text-[14px] text-shiga-gray-100">
        {label ? label : "Amount"}
        <span className="form-input-required">*</span>
      </p>
      {!showCurrency && balance && currency ? (
        <p className="text-[14px] text-shiga-dark truncate w-3/5">
          <span>
            <FontAwesomeIcon icon={faWallet} />
          </span>
          You have{" "}
          <span className="text-shiga-purple ">
            {currencyFormat(balance, currency)}
          </span>{" "}
          available
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

interface InputAmountProps<K extends FieldValues> {
  form: UseFormReturn<K, any, undefined>;
  minAmount: number;
  label?: string;
  bottomText?: string;
  header?: (arg: {
    currentWallet: Wallet;
    label?: string;
    showCurrency?: boolean;
  }) => React.ReactNode;
  footer?: (arg: { currentWallet: Wallet }) => React.ReactNode;
  currencies: Currency[];
  disableAmount?: boolean;
  disableCurrency?: boolean;
  formKey?: K;
}
export function InputAmount<K extends FieldValues>({
  form,
  label,
  disableAmount,
  currencies,
  disableCurrency,
  header,
  formKey,
  bottomText,
  footer,
}: InputAmountProps<K>) {
  const formValues = Object.keys(
    formKey
      ? formKey
      : {
        currency: "",
        amount: "",
      }
  );
  const currency = formValues[0] as Path<K>;
  const amount = formValues[1] as Path<K>;
  const currentCurrency = form.watch(currency);
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const { errors } = form.formState;

  useEffect(() => {
    if (currentCurrency && header) {
      getCurrentBalance(currentCurrency)
        .then((data) => {
          setCurrentWallet(data.data);
          form.setValue("balance" as Path<K>, String(data.data.balance) as any);
        })
        .catch((err) => {
          form.setValue(currency, currentWallet.currency as any);
          toast.error(`Unable to get balance ${err.message}`);
        });
    }
  }, [currentCurrency, header]);

  const hasError = !!errors[amount];

  return (
    <FormField
      control={form.control}
      name={amount}
      render={({ field }) => (
        <FormItem className="relative">
          {header && header({ currentWallet: currentWallet, label: label })}
          <FormControl>
            <div
              className={`relative flex rounded-2xl border ${hasError ? "border-shiga-red-50" : "border-zinc-200"
                } `}
            >
              {/* <Input
                // disabled={disableAmount}
                style={{
                  color: disableAmount ? "black !important" : "inherit",
                  pointerEvents: disableAmount ? "none" : "auto",
                  outline: "none",
                  boxShadow: "none",
                  border: "none",
                }}
                className={`h-[77px] text-[32px] text-black bg-white font-ojah rounded-2xl ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium aria-[invalid=true]:ring-0 aria-[invalid=true]:ring-warn focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-0  focus-visible:ring-offset-0 ${
                  disableAmount ? "text-black" : "black"
                }`}
                placeholder={"0"}
                {...field}
                type="text"
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (NUMBER_REGEX.test(inputValue)) {
                    if (inputValue.length > 1 && inputValue[0] === "0") {
                      inputValue = inputValue.slice(1);
                    }
                    form.setValue(amount, Number(inputValue) as any);
                  }
                }}
              /> */}
              <NumericFormat
                value={field.value}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator=","
                valueIsNumericString={false}
                style={{
                  color: disableAmount ? "black !important" : "inherit",
                  pointerEvents: disableAmount ? "none" : "auto",
                  outline: "none",
                  boxShadow: "none",
                  // border: "none",
                  border: hasError ? "border border-shiga-red-50" : "none",
                  flex: 1,
                  padding: "0 1rem",
                  fontSize: "32px",
                  fontWeight: "400",
                }}
                className={`h-[77px] text-[32px] text-black bg-white font-ojah rounded-2xl ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium aria-[invalid=true]:ring-0 aria-[invalid=true]:ring-warn focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-0  focus-visible:ring-offset-0 ${disableAmount ? "text-black" : "black"
                  }`}
                placeholder="0.00"
                onValueChange={(values) => {
                  const { floatValue } = values;
                  form.setValue(amount, floatValue || (0.0 as any));
                }}
              />

              <FormField
                control={form.control}
                name={currency}
                render={({ field }) => (
                  <FormItem
                    className={`absolute right-0 top-0 h-full flex pr-4 items-center justify-center`}
                  >
                    <CommandSelect
                      disableInput={disableCurrency}
                      searchPlaceholder={" "}
                      filterOption={(value, search) => {
                        if (value.includes(search)) return 1;
                        return 0;
                      }}
                      notFound="No currency found."
                      options={currencies.map((item) => ({
                        ...item,
                        id: item.id + "",
                        value: item.currency,
                      }))}
                      buttonSelectClass={cn(
                        "max-w-32  w-full rounded-3xl text-black bg-white justify-between focus:outline-none  focus:ring-0  data-[state=open]:ring-0 aria-[invalid=true]:ring-0 aria-[invalid=true]:ring-warn",
                        !field.value ? "text-black" : "text-black"
                        // disableCurrency ? "cursor-not-allowed" : ""
                      )}
                      SelectTrigger={() =>
                        field.value?.length ? (
                          <span
                            className="flex items-center gap-1.5 text-[20px] text-shiga-black"
                            style={{
                              color: disableCurrency ? "black" : "inherit",
                            }}
                          >
                            {currencies?.find(
                              (cur) => cur.currency === field.value
                            ) && (
                                <img
                                  src={field?.value === 'AUD' ? '/flags/aud.svg' :
                                    currencies?.find(
                                      (cur) => cur.currency === field.value
                                    )?.icon
                                  }
                                  alt={field.value}
                                  className="size-5"
                                />
                              )}
                            {field.value}
                            <ChevronDown className="ml-2 size-4 shrink-0 opacity-50 " />
                          </span>
                        ) : (
                          <span
                            className="flex items-center gap-1.5 text-[20px] text-shiga-black"
                            style={{
                              color: disableCurrency ? "black" : "inherit",
                            }}
                          >
                            {currencies?.find(
                              (cur) => cur.currency === "NGN"
                            ) && (
                                <img
                                  src={
                                    currencies?.find(
                                      (cur) => cur.currency === "NGN"
                                    )?.icon
                                  }
                                  alt={field.value}
                                  className="size-5"
                                />
                              )}
                            NGN
                            <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                          </span>
                        )
                      }
                      RenderOption={({ item }) => (
                        <>
                          <span className="flex">
                            <img
                              src={item.icon || noisygradient}
                              alt={item.country}
                              className="size-5 mr-3"
                            />
                            {item.country}
                          </span>
                          {field.value === item.country}
                        </>
                      )}
                      handleSelect={(select) => {
                        form.setValue(currency, select.value as any);
                      }}
                    />
                  </FormItem>
                )}
              />
            </div>
          </FormControl>
          {footer && footer({ currentWallet: currentWallet })}

          <div
            className={`transition-colors duration-150 text-xs flex items-center w-full z-5 bg-gray-100 px-3 pt-2 pb-2.5 rounded-b-2xl  ${hasError
              ? "bg-shiga-red-25 text-shiga-red-50"
              : "bg-shiga-gray-25 text-shiga-gray-300"
              }`}
          >
            {hasError ? <WarningRed /> : <WarningGrey />}
            {hasError ? (
              <FormMessage className=" bg-shiga-red-25 text-shiga-red-50 rounded-b-2xl" />
            ) : (
              <span className="font-medium ml-2">{bottomText}</span>
            )}
          </div>
        </FormItem>
      )}
    />
  );
}

export default InputAmount;
