import { toast } from "react-toastify";
import * as actions from "./actionTypes";

interface DataResponse<T> {
    success: boolean;
    statusCode: number;
    message: string;
    data: T;
}

interface InitialState {
    isLoadingCards: boolean;
    cards: DataResponse<any[]> | null;
    createCardLoading: boolean;
}

const initialState: InitialState = {
    cards: null,
    isLoadingCards: false,
    createCardLoading: false
}


export default function reducer(state = initialState, action: any): InitialState {
    switch (action.type) {
        case actions.GET_CARDS_START:
            return {
                ...state,
                isLoadingCards: true
            }
        case actions.GET_CARDS:
            return {
                ...state,
                cards: action.payload,
                isLoadingCards: false
            }
        case actions.CREATE_CARDS_FAILED:
            return {
                ...state,
                createCardLoading: false
            }
        case actions.CREATE_CARDS_START:
            return {
                ...state,
                createCardLoading: true
            }
        case actions.CREATE_CARDS:
            toast(action?.payload?.message)
            return {
                ...state,
                createCardLoading: false
            }
        default:
            return state;
    }
}