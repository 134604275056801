import { Transaction } from 'types/transaction';
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { RefObject } from 'react';

type Props = {
   transaction: Transaction;
   receiptRef: RefObject<HTMLDivElement>;
}

const TransactionPdfReceipt = ({ transaction, receiptRef }: Props) => {
   const { showValue } = useToolkit();
   const containerClass = 'w-full mx-auto px-12'

   return (
      <div ref={receiptRef} className="w-full lg:w-3/5 mx-auto pt-12">

         <div className={containerClass + ' flex-btw'}>
            <img src="/logo-dark.png" alt="Shiga logo" width={30} />

            <div className="font-ojah text-lg font-bold">
               Transaction Receipt
            </div>
         </div>

         <div className='my-6 h-[0.8px] w-full bg-shiga-gray-50' />

         <div className={containerClass}>

            <div className="transaction-receipt-section">
               <div className="w-full transaction-receipt-header">
                  <p className="mb-0 transaction-receipt-title">
                     Transfer Confirmation
                  </p>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Transfer Date
                  </div>

                  <div className="transaction-receipt-inline-value capitalize">
                     {moment(transaction?.createdAt).format('MMM D, YYYY HH:mm:ss')}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Payout Date
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {moment(transaction?.updatedAt).format('MMM D, YYYY HH:mm:ss')}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Reference Number
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {transaction?.reference}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Session ID
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {showValue(transaction?.bankTransfer?.sessionId)}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper-last'>
                  <div className="transaction-receipt-inline-key">
                     Payment Method
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {transaction?.method}
                  </div>
               </div>

            </div>

            <div className="transaction-receipt-section">
               <div className="w-full transaction-receipt-header">
                  <p className="mb-0 transaction-receipt-title">
                     Transfer Overview
                  </p>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Amount Sent
                  </div>

                  <div className="transaction-receipt-inline-value font-bold">
                     {transaction?.amount?.toLocaleString()} {transaction?.currency}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Recipient receives
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {transaction?.amount - Number(transaction?.fee)} {transaction?.currency}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Transaction Fee {transaction?.currency}
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {transaction?.fee}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper-last'>
                  <div className="transaction-receipt-inline-key">
                     Exchange Rate
                  </div>

                  <div className="transaction-receipt-inline-value">
                     1 {transaction?.currency} = 1 {transaction?.currency}
                  </div>
               </div>

            </div>

            <div className="transaction-receipt-section">
               <div className="w-full transaction-receipt-header">
                  <p className="mb-0 transaction-receipt-title">
                     Receiver's Information
                  </p>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Recipient's Name
                  </div>

                  <div className="transaction-receipt-inline-value capitalize">
                     {moment(transaction?.createdAt).format('MMM D, YYYY HH:mm:ss')}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Country
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {showValue(transaction?.bankTransfer?.country)}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Bank Name
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {showValue(transaction?.bankTransfer?.beneficiaryBankName)}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper'>
                  <div className="transaction-receipt-inline-key">
                     Account Number
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {showValue(transaction?.bankTransfer?.beneficiaryAccountNumber)}
                  </div>
               </div>

               <div className='transaction-receipt-wrapper-last'>
                  <div className="transaction-receipt-inline-key">
                     Note
                  </div>

                  <div className="transaction-receipt-inline-value">
                     {showValue(transaction?.description)}
                  </div>
               </div>
            </div>

            <div className=''>
               <p className="text-shiga-gray-300 text-xs font-inter leading-[1.5]">
                  Shiga Business by Payshiga technologies limited is a financial platform and not a bank. Therefore, banking and debit card services provided on this platform are issued and secured by banking partners. To learn more, visit&nbsp;
                  <span className="text-shiga-purple-200">
                     <a href="https://www.payshiga.com" target="_blank" rel="noopener noreferrer">
                        https://www.payshiga.com
                     </a>
                  </span>
               </p>

               <br />

               <p className="text-shiga-gray-300 text-xs font-inter leading-[1.5]">
                  Need help? <span className='font-medium'>Contact&nbsp;</span>
                  <span className="text-shiga-purple-200">
                     <a href="mailto:business@payshiga.com" target="_blank" rel="noopener noreferrer">
                        business@payshiga.com
                     </a>
                  </span>
               </p>
            </div>

         </div>

      </div>
   )
}

export default TransactionPdfReceipt