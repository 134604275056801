export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_START = "GET_USER_PROFILE_START";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";
export const GET_USER_PROFILE_RESET = "GET_USER_PROFILE_RESET";

export const GET_BUSINESS_PROFILE = "GET_BUSINESS_PROFILE";
export const GET_BUSINESS_PROFILE_START = "GET_BUSINESS_PROFILE_START";
export const GET_BUSINESS_PROFILE_FAILED = "GET_BUSINESS_PROFILE_FAILED";
export const GET_BUSINESS_PROFILE_RESET = "GET_BUSINESS_PROFILE_RESET";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const REQUEST_PASSWORD_CHANGE = "REQUEST_PASSWORD_CHANGE";
export const REQUEST_PASSWORD_CHANGE_START = "REQUEST_PASSWORD_CHANGE_START";
export const REQUEST_PASSWORD_CHANGE_FAILED = "REQUEST_PASSWORD_CHANGE_FAILED";
export const REQUEST_PASSWORD_CHANGE_RESET = "REQUEST_PASSWORD_CHANGE_RESET";

export const INITIALIZE_MFA = "INITIALIZE_MFA";
export const INITIALIZE_MFA_START = "INITIALIZE_MFA_START";
export const INITIALIZE_MFA_FAILED = "INITIALIZE_MFA_FAILED";
export const INITIALIZE_MFA_RESET = "INITIALIZE_MFA_RESET";

export const COMPLETE_MFA = "COMPLETE_MFA";
export const COMPLETE_MFA_START = "COMPLETE_MFA_START";
export const COMPLETE_MFA_FAILED = "COMPLETE_MFA_FAILED";
export const COMPLETE_MFA_RESET = "COMPLETE_MFA_RESET";

export const VERIFY_MFA = "VERIFY_MFA";
export const VERIFY_MFA_START = "VERIFY_MFA_START";
export const VERIFY_MFA_FAILED = "VERIFY_MFA_FAILED";
export const VERIFY_MFA_RESET = "VERIFY_MFA_RESET";

export const DISABLE_MFA = "DISABLE_MFA";
export const DISABLE_MFA_START = "DISABLE_MFA_START";
export const DISABLE_MFA_FAILED = "DISABLE_MFA_FAILED";
export const DISABLE_MFA_RESET = "DISABLE_MFA_RESET";
