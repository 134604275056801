export const CONVERT_GET_EXCHANGE_RATE = "CONVERT_GET_EXCHANGE_RATE"
export const CONVERT_GET_EXCHANGE_RATE_START = "CONVERT_GET_EXCHANGE_RATE_START"
export const CONVERT_GET_EXCHANGE_RATE_FAILED = "CONVERT_GET_EXCHANGE_RATE_FAILED"
export const RESET_CONVERT_DATA = "RESET_CONVERT_DATA"

export const CONVERT_MONEY_API = "CONVERT_MONEY_API"
export const CONVERT_MONEY_API_START = "CONVERT_MONEY_API_START"
export const CONVERT_MONEY_API_FAILED = "CONVERT_MONEY_API_FAILED"

export const UPDATE_CONVERT = "UPDATE_CONVERT"

