import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "./Maybe";


export const PageContent = (props) => {
    const {loading, data, loadingText, emptyText, errorText, classes, padding} = props;
    
    return (
        <>


            <Maybe condition={loading === true}>
                <div className={"h-full flex justify-center " + classes}>
                    <div className={"table-info " + padding}>
                        <FontAwesomeIcon icon="circle-notch" spin />
                        <div className="font-medium">
                            {loadingText || "Loading"}
                        </div>
                    </div>
                </div>
            </Maybe>

            
            <Maybe condition={loading === false && (data === undefined || data === null || data?.status === "failed" || data?.status === "error")}>
                <div className={"h-full flex justify-center " + classes}>
                    <div className={"table-info " + padding}>
                        <FontAwesomeIcon icon="unlink" />
                        <div className="font-medium">
                            {errorText || <>Unable to load data <br /> Please try again later</>}
                        </div>
                    </div>
                </div>
            </Maybe>

            
            <Maybe condition={loading === false && data && (data === "" || data.length === 0 || Object.keys(data).length === 0)}>
                <div className={"h-full flex justify-center " + classes}>
                    <div className={"table-info " + padding}>
                        <FontAwesomeIcon icon="list" />
                        <div className="font-medium">
                            {emptyText || "No data yet"}
                        </div>
                    </div>
                </div>
            </Maybe>


        </>
    )
}


export const PageContentCustom = (props) => {
    const {icon, image, text, classes, padding, iconClass} = props;
    
    return (
        <div className={"h-full text-shiga-gray " + classes}>
            <div className={"h-full table-info flex flex-col justify-center " + padding}>
                <Maybe condition={icon !== undefined}>
                    <FontAwesomeIcon icon={icon} className={iconClass} />
                </Maybe>
                <Maybe condition={image !== undefined}>
                    {image}
                </Maybe>                
                <div className="font-medium text-shiga-gray">
                    {text ?? ""}
                </div>
            </div>
        </div>
    )
}


export default PageContent;