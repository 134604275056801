import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";

import { cn } from "lib/utils";
import { Loader2 } from "lucide-react";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap rounded-2xl font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-black text-white hover:bg-black/90",
        primary: "bg-primary text-white hover:opacity-90",
        warn: "bg-warn text-white hover:bg-warn/90",
        outline:
          "border border-icon-container-gray bg-white text-black hover:bg-icon-container-gray/25",
        secondary: "bg-subtext-light-gray text-black hover:bgsubtext-gray/80",
        ghost: "text-black hover:bg-dashboard-gray",
        link: "text-primary underline-offset-4 hover:underline",
        yellow: "text-white bg-yellow-dark hover:opacity-90",
        form: "text-form-gray aria-[invalid=false]:text-black bg-[#FAFAFB] hover:opacity-90 focus:ring ring-black data-[state=open]:ring aria-[invalid=true]:ring aria-[invalid=true]:ring-warn",
      },
      size: {
        default: "h-12 px-4 py-2 text-lg",
        sm: "h-9 px-3 text-sm",
        lg: "h-12 px-8 text-lg",
        icon: "size-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          isLoading && "pointer-events-none "
        )}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <>
            <span className="absolute inline-flex justify-center items-center gap-2.5">
              <Loader2 className="size-4 animate-spin" />
            </span>
            <span className="invisible">
              <Slottable>{children}</Slottable>
            </span>
          </>
        ) : (
          <Slottable>{children}</Slottable>
        )}
      </Comp>
    );

    // return (
    //   <Comp
    //     className={cn(buttonVariants({ variant, size, className }))}
    //     ref={ref}
    //     {...props}
    //   />
    // );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
