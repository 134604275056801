interface ChevronDownIconProps extends React.BaseHTMLAttributes<SVGElement> {}

const ChevronDownIcon = ({ ...props }: ChevronDownIconProps) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.25049 1.12568L4.99981 4.875L8.74981 1.125"
        stroke="#99999C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDownIcon;
