import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Input } from 'components/common/form';
import { Search, CircleCheck } from "lucide-react";
import Maybe from 'components/common/Maybe';
import { googleApiKey } from "data/config";
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
// import ChevronDownIcon from "assets/images/icons/ChevronDown";
// import { getAddressObject } from 'lib/format.address';

type AddressFieldProps = {
   inputLabel: string;
   isRequired?: boolean;
   addressValue: string;
   setAddressValue: Dispatch<SetStateAction<string>>;
   handleAddressSelection?: (address: any) => void;
}

let googleAddressPayload: GoogleAddressProps = {
   description: '',
   place_id: '',
   reference: '',
   types: [],
}; // payload object for the selected address field

const AddressField = ({ inputLabel, isRequired, addressValue, setAddressValue, handleAddressSelection }: AddressFieldProps) => {
   const [address, setAddress] = useState(addressValue || '(No)(street Name)(State)(Country)');
   const [dropdownShown, setDropdownShown] = useState(false);
   const dropdownRef = useRef<HTMLDivElement>(null);
   const [addressObj, setAddressObj] = useState<Partial<GoogleAddressProps>>({
   });

   const {
      // eslint-disable-next-line
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading
   } = usePlacesAutocompleteService({ apiKey: googleApiKey });
   const toggleDropdown = () => setDropdownShown(!dropdownShown);


   const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setDropdownShown(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, []);

   useEffect(() => {
      getPlacePredictions({ input: addressValue });
      // eslint-disable-next-line 
   }, [addressValue])

   useEffect(() => {
      if (addressObj?.description !== undefined) {
         placesService.getDetails({
            placeId: addressObj?.place_id,
            fields: ["address_components", "adr_address", "formatted_address"],
         }, (result: any) => {
            // const formatted = getAddressObject(result.address_components);
         })
      }
      // eslint-disable-next-line 
   }, [addressObj])

   useEffect(() => {
      if (dropdownShown) {
         setAddressValue('')
      }
      // eslint-disable-next-line
   }, [dropdownShown])


   return (
      <div className='w-full'>
         <div className='relative'>
            <Input
               disabled
               value={address}
               label={inputLabel}
               rightIconClass='pr-2'
               required={isRequired}
            // rightIcon={<ChevronDownIcon />}
            />

            <div onClick={toggleDropdown} className='h-12 cursor-pointer w-full absolute top-6' />

            <Maybe condition={dropdownShown}>
               <div ref={dropdownRef} className="p-3 pb-7 bg-shiga-dark-500 w-full rounded-lg absolute top-20 min-h-[120px] max-h-[180px] z-50 overflow-y-scroll text-white">

                  <div className="flex items-center border-b border-neutral-500 mb-3 sticky top-0 left-0 bg-shiga-dark-500">
                     <Search className="mr-2 size-4 shrink-0 text-white text-opacity-5" />

                     <input
                        placeholder="Search"
                        value={addressValue}
                        onChange={(e: any) => setAddressValue(e.target.value)}
                        className='border-none focus:!outline-none focus:!border-none focus:ring-0 bg-transparent placeholder:text-shiga-gray-300 text-white text-opacity-40 w-full'
                     />
                  </div>

                  <Maybe condition={placePredictions?.length > 0}>
                     {placePredictions?.map((item: any) => (
                        <div onClick={() => {
                           googleAddressPayload = item;
                           setAddressValue(item?.description);
                           setAddress(item?.description);
                           setAddressObj(item);
                           handleAddressSelection && handleAddressSelection(item)
                           toggleDropdown();
                        }}
                           className='hover:bg-gray-50 hover:bg-opacity-20 pl-1.5 cursor-pointer py-px mb-1 flex items-center justify-between w-full' key={item?.place_id}>
                           <span>
                              {item.description}
                           </span>
                           {address === item.description && (
                              <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black ml-2" />
                           )}
                        </div>
                     ))}
                  </Maybe>

                  <Maybe condition={isPlacePredictionsLoading}>
                     <div className="text-center text-gray-400 py-6">Searching addresses...</div>
                  </Maybe>

                  <Maybe condition={addressValue === ''}>
                     <div className="text-center text-gray-400 py-6">Enter an address to see suggestions</div>
                  </Maybe>

                  <Maybe condition={isPlacePredictionsLoading === false && addressValue !== '' && placePredictions?.length === 0}>
                     <div className="text-center text-gray-400 py-6">No addresses found</div>
                  </Maybe>

               </div>
            </Maybe>
         </div>
      </div>
   )
}


export { AddressField, googleAddressPayload }