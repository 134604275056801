import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";

const InlineTransfersHeader = (props: any) => (
   <div className="px-6 lg:px-9 py-6 flex items-center justify-between border-b border-gray-100 bg-white z-20 fixed w-full top-0">
      <div className="pl-3 ml-3 text-xl text-shiga-dark-100 font-ojah font-bold">
         Make a Transfer
      </div>
      <div onClick={() => props.setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
         <IconClose className="m-auto text-white svg-stroke-gray" />
      </div>
   </div>
)

export default InlineTransfersHeader;