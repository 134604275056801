import { ButtonLoader, Input } from "components/common/form";
import TextArea from "components/common/form/TextArea";
import { useNavigate } from "react-router-dom";
import { useSharedStore } from "zustand-store";
import { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as Calender } from "assets/images/icons/Calendar.svg";
import { ReactComponent as Weight } from "assets/images/icons/weight.svg";
import { ReactComponent as Revenue } from "assets/images/icons/revenue.svg";
import ImageCarousel from "./ImageCarousel";

interface FormValues {
  productName: string;
  description: string;
  category: string;
  amount: string;
  quantity: number;
}

const EditProduct = ({ product }: any) => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  const initialFormValues: FormValues = {
    productName: "",
    description: product?.description || "",
    category: product?.category || "",
    amount: product?.price || "",
    quantity: product?.totalItem || 0.0,
  };
  const [formV, setFormV] = useState<FormValues>(initialFormValues);

  const goBack = () => {
    navigate("/ojah/products");
  };
  const close = () => {
    navigate("/ojah/products");
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormV({
      ...formV,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., update backend
  };

  return (
    <div>
      <div className="flex flex-col-reverse lg:flex-row justify-between items-center lg:items-start">
        <div>
          <div className="bg-shiga-black w-[224px] h-[224px] rounded-2xl mb-6">
            <ImageCarousel />
          </div>
          <div>
            <div className="mb-4">
              <p className="text-uppercase text-[#99999C] font-[400]">STATS</p>
            </div>
            <p className="flex items-center text-[13px] text-[#B4B4B4] mb-2">
              <Calender className="mr-3" /> Created{" "}
              <span className="text-[#99999C] font-[500] ml-2">
                {product?.dateCreated}
              </span>
            </p>
            <p className="flex items-center text-[13px] text-[#B4B4B4] mb-2">
              <Weight className="mr-3" /> Weight{" "}
              <span className="text-[#99999C] font-[500] ml-2">5.5kg</span>
            </p>
            <p className="flex items-center text-[13px] text-[#B4B4B4] mb-2">
              <Revenue className="mr-3" /> Revenue{" "}
              <span className="text-[#99999C] font-[500] ml-2">
                0.00 {product?.currency}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-5 lg:0 w-full lg:w-[530px] space-y-4">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between gap-6 ">
              <div className="w-full">
                <Input
                  name="productName"
                  type="text"
                  label="Product Name"
                  placeholder="Add a Name"
                  value={product?.name || formV.productName}
                  disabled={!editMode}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Input
                  name="price"
                  type="text"
                  label="Price"
                  placeholder="price"
                  value={
                    `${product?.price.toString()} ${product?.currency}` ||
                    formV.amount
                  }
                  disabled={!editMode}
                />
              </div>
            </div>

            <div className="mb-[1.75rem]">
              <TextArea
                placeholder="Say a bit more about this product"
                label="Description"
                value={product?.description || formV.description}
                // onChange={handleChange}
              />
            </div>

            <div className="flex justify-between gap-6">
              <div className="w-full">
                <Input
                  name="category"
                  type="text"
                  label="Category"
                  placeholder="Category"
                  value={product?.category || formV.category}
                  disabled={!editMode}
                />
              </div>
              <div className="w-full">
                <Input
                  type="number"
                  label="Quantity in Stock"
                  placeholder="0.00"
                  name="quantity"
                  value={
                    product?.totalItem - product?.itemSold || formV.quantity
                  }
                  disabled={!editMode}
                />
              </div>
            </div>

            {/* <ButtonLoader
                disabled={form.formState.isValid}
                // loading={loadingBuyElectricity}
                type="submit"
                className="btn btn-lg btn-block btn-primary mt-3"
              >
                Confirm & Publish
              </ButtonLoader> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
