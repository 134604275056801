import { useMemo, useState } from "react";

import { DataTable } from "components/data-table/data-table";
import { DataTableHeader } from "components/data-table/data-table-header";
import { useToggle } from "hooks/useToggle";
import {
  PaymentLink,
  defaultPaymentLink,
} from "types/payment/payments.interface";
import { DeletePaymentLinkModal } from "../DeletePaymentLinkModal";
import { PaymentLinkColumns } from "./PaymentColumn";
import { formatDate } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";
import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "pages/payments/paths";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import EmptyTransactions from "pages/transactions/components/EmptyTransactions";
import Maybe from "components/common/Maybe";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import moment from "moment";
import { PaymentLinkActions } from "../PaymentLinkAction";
import { PaymentLinkFilterHandler } from "./PaymentLinkFilterHandler";
import { deletePaymentLink } from "lib/payments";
import { EmptyState } from "components/common/EmptyState";
import { ReactComponent as EmptyPaymentLinkIcon } from "assets/images/icons/payment/empty-payment-links.svg";


export const PaymentLinkTable: React.FC<{
  paymentLinks: any;
  loading: boolean;
}> = ({ paymentLinks: data, loading }) => {
  const navigate = useNavigate();
  const [currentPaymentLink, setCurrentPaymentLink] =
    useState<PaymentLink>(defaultPaymentLink);
  const [showDelete, toggleDelete] = useToggle(false);
  const [filter, setFilter] = useState("");
  // const [loading, setLoading] = useState(false);

  const handleShowDelete = (arg: PaymentLink) => {
    setCurrentPaymentLink(arg);
    toggleDelete();
  };

  const handleClick = (paymentLink: PaymentLink) => {
    sessionStorage.setItem("view-link", paymentLink.id);
    navigate(
      `${PAYMENT_PATHS.PAYMENT_LINK}/${paymentLink.name.replaceAll(" ", "-")}`
    );
  };

  const paymentLink = useMemo(
    () =>
      data?.payload.map((item: any) => ({
        ...item,
        formattedAmount:
          item.amountType === "FIXED"
            ? currencyFormat(item.amount, item.currency)
            : "N/A",
        formattedType: item.type.replace("_", " ").toLowerCase(),
        createdAt: formatDate(item.createdAt),
      })),
    [data]
  );

  const filteredPaymentLink = useMemo(() => {
    if (filter && filter !== "All") {
      return paymentLink.filter(
        (payment: any) => payment.status.toLowerCase() === filter.toLowerCase()
      );
    }
    return paymentLink;
  }, [paymentLink, filter]);

  const handleDelete = (id: any) => {
    deletePaymentLink(id);
  };

  return (
    <>
      <div>
        <PaymentLinkFilterHandler paymentLinks={data} />

        <Table>
          {/* <Maybe
            condition={
              (transactionsLoading === false && !transactions) ||
              transactionsAreValid
            }
          > */}
          <Table.Head>
            <th>Name</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Status</th>
            <th>Date Created</th>
            <th></th>
          </Table.Head>
          {/* </Maybe> */}

          <Table.Body>
            <TableContent
              total={data?.payload.length || 0}
              loading={loading}
              loadingText="Fetching Payment links"
              data={data.payload}
              colspan={6}
              emptyUI={
                <EmptyState
                  heading="No Payment Link"
                  description="You have no payment link yet. Why not create one?"
                  icon={<EmptyPaymentLinkIcon />}
                />
              }
              // customLoader={<Skeleton />}
            />

            {!loading &&
              paymentLink.map((link: any, idx: number) => {
                const isLinkActive = link.status === "ACTIVE";

                return (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={idx * 2}
                    onClick={() => handleClick(link)}
                  >
                    <td>
                      <div className="flex items-center gap-3 cursor-pointer">
                        <img
                          alt={link.name}
                          className="w-8 h-8 rounded-md"
                          src={link.logoUrl || noisygradient}
                        />
                        <p className="text-zinc-800 font-medium !capitalize text-sm">
                          {link.name}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="text-zinc-800 text-sm font-medium cursor-pointer">
                        {link.formattedAmount}
                      </div>
                    </td>
                    <td>
                      <div className="border border-shiga-gray-75 rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium">
                        {link.formattedType}
                      </div>
                    </td>
                    <td>
                      <div>
                        <div
                          className={`border 
                      ${
                        isLinkActive
                          ? "text-shiga-green-100 border-shiga-green-50"
                          : "text-shiga-red-100 border-shiga-red-40"
                      } rounded-md py-1 px-[5px] flexed w-max text-[10px] capitalize font-inter font-medium`}
                        >
                          {link.status.toLowerCase()}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="font-medium text-sm">
                        {moment(link?.createdAt).format(
                          "MMM D, YYYY [at] h:mma"
                        )}
                      </span>
                    </td>
                    <td>
                      <PaymentLinkActions
                        handleDelete={() => handleDelete(link.id)}
                        paymentLink={link}
                      />
                    </td>
                  </tr>
                );
              })}
          </Table.Body>
        </Table>
      </div>
      {/* <div className="mt-6">
        <DataTable
          data={filteredPaymentLink}
          columns={PaymentLinkColumns}
          renderToolbar={(table) => (
            <DataTableHeader
              filterOptions={["All", "Active", "Inactive"]}
              placeholder="Search for payment link..."
              table={table}
              setFilter={setFilter}
            />
          )}
          meta={{
            handleShowDelete,
            handleClick,
          }}
        />
      </div> */}

      <DeletePaymentLinkModal
        paymentLink={currentPaymentLink}
        open={showDelete}
        closeModal={toggleDelete}
      />
    </>
  );
};
