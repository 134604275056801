export function formatDate(dateString: string) {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString); // Parse the ISO 8601 string
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  //@ts-ignore
  return date.toLocaleDateString("en-US", options);
}

export function getTime(dateTimeString: string) {
  if (!dateTimeString) {
    return "";
  }
  const timeRegex = /(\d{2}):(\d{2})/; // Matches hours and minutes in HH:MM format
  const match = dateTimeString.match(timeRegex);
  if (match) {
    return `${match[1]}:${match[2]}`; // "17:56"
  } else {
    return "Invalid time format"; // Handle cases with invalid time formats
  }
}


export const formatDateWTime = (date: string) => {
  const newDate = new Date(date);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[newDate.getMonth()];
  const day = newDate.getDate();
  let hour = newDate.getHours();
  const minute = newDate.getMinutes();
  const ampm = hour >= 12 ? 'pm' : 'am';

  // Convert hour to 12-hour format
  hour = hour % 12;
  hour = hour ? hour : 12;

  return `${month} ${day}, ${newDate.getFullYear()} at ${hour}:${minute < 10 ? '0' + minute : minute}${ampm}`;
}