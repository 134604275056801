import { useEffect, useState } from 'react'
import useTransfersProvider from '../../context/useTransfersProvider'
import TransferFlowWrapper from '../../layout/TransferFlowWrapper'
import Maybe from 'components/common/Maybe'
import BeneficiarySelectionView from '../common/BeneficiarySelectionView'
import TransferAmountView from '../common/TransferAmountView'
import ReviewAndPay from '../common/ReviewAndPay'
import UsdAccountDetails from './UsdAccountDetails'

const UsdTransfersHomepage = () => {
   const { singleTrfStep, accountName } = useTransfersProvider()
   const [header, setheader] = useState({
      title: '',
      subtitle: ''
   });

   useEffect(() => {
      if (singleTrfStep === 1) {
         setheader({
            title: 'Add a recipient',
            subtitle: 'Search or select a recipient for this transaction'
         });
      } else if (singleTrfStep === 2) {
         setheader({
            title: 'Add their USD bank account details (ACH Transfer)',
            subtitle: 'Add the bank account information of the recipient'
         });
      } else if (singleTrfStep === 3) {
         setheader({
            title: 'How much are you sending?',
            subtitle: 'Enter the amount you are sending to the recipient'
         });
      } else if (singleTrfStep === 4) {
         setheader({
            title: `Confirm your transaction to ${accountName}`,
            subtitle: ''
         });
      }
      // eslint-disable-next-line
   }, [singleTrfStep])


   return (
      <TransferFlowWrapper title={header.title} subtitle={header.subtitle}>

         <Maybe condition={singleTrfStep === 1}>
            <BeneficiarySelectionView />
         </Maybe>

         <Maybe condition={singleTrfStep === 2}>
            <UsdAccountDetails />
         </Maybe>

         <Maybe condition={singleTrfStep === 3}>
            <TransferAmountView />
         </Maybe>

         <Maybe condition={singleTrfStep === 4}>
            <ReviewAndPay />
         </Maybe>

      </TransferFlowWrapper>
   )
}

export default UsdTransfersHomepage

// {
//     "accountName": "Ayo Test",
//     "accountNumber": "1234567812",
//     "amount": "1234",
//     "bankName": "Alpha capital",
//     "currency": "USD",
//     "country": "US",
//     "saveBeneficiary": true,
//     "narration": "asdf",
//     "token": "787516",
//     "transferType": "ACH",
//     "meta": {
//         "routingNo": "123412341",
//         "bankAddress": {
//             "description": "London HA9 0WS, UK",
//             "place_id": "ChIJbYd61YERdkgRl-DoXRifT6Y"
//         },
//         "bankState": "England",
//         "postCode": "HA9 0WS",
//         "accountType": "SAVINGS"
//     }
// }