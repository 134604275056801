import { findAllInvoice } from "lib/payments/invoices";
import { PaginationResponse } from "types/data-interface";
import { Invoice, InvoiceItem, defaultInvoice } from "types/payment/invoice";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const initialState = {
  invoice: defaultInvoice as Invoice,
  createdInvoice: [] as Invoice[],
  completed: [] as string[],
  editIndex: 0,
  invoices: [] as Invoice[],
  invoicesData: {} as PaginationResponse<Invoice[]>,
};
interface ActionsState {
  setInvoice: (invoice: Invoice) => void;
  setCreatedInvoice: (invoice: Invoice[]) => void;
  setInvoiceItem: (item: InvoiceItem, index: number) => void;
  setInvoiceItems: (items: InvoiceItem[]) => void;
  setInvoiceItemsFromInvoice: (invoice: Invoice) => void;
  setEditIndex: (index: number) => void;
  setInvoices: (invoices: Invoice[]) => void;
  resetInvoice: () => void;
  addItem: (item: InvoiceItem) => void;
  editAdvanceFields: (
    field: keyof Invoice["advancedCustomer"],
    value: number
  ) => void;
  removeItem: (index: number) => void;
  setCompleted: (slug: string) => void;
  fetchInvoices: (param:any) => Promise<void>;
  updateInvoice: (arg: Invoice, action?: string) => void;
}
export const useInvoiceStore = create(
  persist<typeof initialState & ActionsState>(
    (set, get) => ({
      ...initialState,
      setInvoice: (invoice) => set({ invoice }),
      setInvoiceItem: (item, index) =>
        set((state) => {
          state.invoice.items[index] = item;
          return { invoice: { ...state.invoice } };
        }),
      setInvoiceItems: (items) =>
        set((state) => ({ invoice: { ...state.invoice, items } })),
      setInvoiceItemsFromInvoice: (invoice) => set((state) => ({ invoice })),

      setEditIndex: (index) => set({ editIndex: index }),
      setInvoices: (invoices) => set({ invoices }),
      resetInvoice: () => set({ ...initialState }),
      addItem: (item) =>
        set((state) => {
          const existingIndex = state.invoice.items.findIndex(
            (i) => i.name === item.name
          );

          if (existingIndex !== -1) {
            // Update existing item
            const updatedItems = [...state.invoice.items];
            updatedItems[existingIndex] = item;

            return {
              invoice: {
                ...state.invoice,
                items: updatedItems,
              },
            };
          } else {
            // Add new item
            return {
              invoice: {
                ...state.invoice,
                items: [...state.invoice.items, item],
              },
            };
          }
        }),
      // set((state) => ({
      //   invoice: { ...state.invoice, items: [...state.invoice.items, item] },
      // })),
      editAdvanceFields: (field, value) =>
        set((state) => ({
          invoice: {
            ...state.invoice,
            advancedCustomer: {
              ...state.invoice.advancedCustomer,
              [field]: value,
            },
          },
        })),
      removeItem: (index) =>
        set((state) => {
          state.invoice.items.splice(index, 1);
          return { invoice: { ...state.invoice } };
        }),
      setCompleted: (slug) =>
        set((state) => ({ completed: [...state.completed, slug] })),
      fetchInvoices: async (params?: any) => {
        try {
          const { data } = await findAllInvoice(params);
          const date = new Date();
          set({
            createdInvoice: data.payload.map((item) => ({
              ...item,
              status:
                date.getTime() > new Date(item.endDate).getTime() &&
                item.status !== "PAID"
                  ? "DUE"
                  : item.status,
            })),
          });
          set({
            invoicesData: data,
          });
        } catch (error) {}
      },
      setCreatedInvoice: (invoices) => {
        set({ createdInvoice: invoices });
      },
      updateInvoice: (arg: Invoice, action?: string) => {
        const { createdInvoice } = get();
        const newInvoices = [...createdInvoice];
        const foundInvoiceIDX = createdInvoice.findIndex(
          (item) => item.id === arg.id
        );
        if (foundInvoiceIDX > -1) {
          if (action === "delete") {
            newInvoices.splice(foundInvoiceIDX, 1);
          } else {
            newInvoices.splice(foundInvoiceIDX, 1, arg);
          }
          set({ createdInvoice: newInvoices });
        }
      },
    }),
    {
      name: "invoice-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
