import { useLocation } from "react-router-dom";
import queryString from 'query-string';


const useUrlQueryParams = () => {
   const { search } = useLocation();
   const qs = queryString.parse(search);
   const urlQueryParams = qs;

   return { urlQueryParams };
}


export default useUrlQueryParams;
