import { StepHeading } from "components/common/StepHeading";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { CREATE_TUTION_STEPS } from "constants/payments/create.payment.link.step";
import { ArrowLeft, X } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StudentInfo } from "./student-info";
import { Amount } from "./Amount";
import { ReviewPay } from "./ReviewPay";
import useBackButton from "hooks/useBackButton";
import { PAYMENT_PATHS } from "../paths";
import { defaultPayload, useTuitionStore } from "zustand-store/payments/tution";

export const PayTutionSteps = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || CREATE_TUTION_STEPS[0].slug;
  return (
    <div className="space-y-4 w-full py-10">
      <Nav tab={tab} />
      <Separator />
      {getComp(tab)}
    </div>
  );
};

const Nav = ({ tab }: { tab: string }) => {
  const { goBack } = useBackButton();
  const { completed, setPayload } = useTuitionStore();
  const navigate = useNavigate();

  const onClose = () => {
    setPayload(defaultPayload);
    navigate(`${PAYMENT_PATHS.TUITION}`);
  };

  return (
    <div className="flex  justify-between w-full items-center">
      <Button
        size="icon"
        variant="outline"
        onClick={goBack}
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <ArrowLeft size={18} />
      </Button>

      <StepHeading
        completed={completed}
        path={PAYMENT_PATHS.TUITION_STEPS}
        tab={tab}
        steps={CREATE_TUTION_STEPS}
      />
      <Button
        size="icon"
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
        onClick={() => onClose()}

      >
        <X size={18} />
      </Button>
    </div>
  );
};

const getComp = (tab: string) => {
  switch (tab) {
    case "student_info":
      return <StudentInfo />;
    case "amount":
      return <Amount />;

    case "review_pay":
      return <ReviewPay />;
    default:
      return <StudentInfo />;
  }
};
