export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_START = "GET_CARDS_START";
export const GET_CARDS_FAILED = "GET_CARDS_FAILED";
export const GET_CARDS_RESET = "GET_CARDS_RESET";



export const CREATE_CARDS =  "CREATE_CARDS";
export const CREATE_CARDS_START =  "CREATE_CARDS_START";
export const CREATE_CARDS_FAILED =  "CREATE_CARDS_FAILED";
export const CREATE_CARDS_RESET =  "CREATE_CARDS_RESET";

export const LOCK_CARD = "LOCK_CARD";
export const LOCK_CARD_START = "LOCK_CARD_START";
export const LOCK_CARD_FAILED = "LOCK_CARD_FAILED";
export const LOCK_CARD_RESET = "LOCK_CARD_RESET";

export const UNLOCK_CARD = "UNLOCK_CARD";
export const UNLOCK_CARD_START = "UNLOCK_CARD_START";
export const UNLOCK_CARD_FAILED = "UNLOCK_CARD_FAILED";
export const UNLOCK_CARD_RESET = "UNLOCK_CARD_RESET";
