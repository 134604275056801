import { InputHTMLAttributes } from "react";

interface CheckboxProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "onBlur" | "required" | "disabled" | "className" | "readOnly"
  > {
  type: "checkbox" | "radio";
  label: React.ReactNode;
  checked?: boolean;
  defaultProps?: any;
  containerClass?: string;
  value?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    type,
    label,
    checked,
    value,
    onChange,
    onBlur,
    required,
    readOnly,
    disabled,
    defaultProps,
    containerClass,
    className,
  } = props;

  return (
    <div className="checkbox">
      <label
        className={
          "max-w-max flex space-x-3 cursor-pointer items-center " +
          (containerClass ?? "")
        }
      >
        <input
          type={"checkbox"}
          className={
            "w-5 h-5 border-2 border-[#E7E7E7] " +
            (type === "radio"
              ? "form-radio rounded-full"
              : "form-checkbox rounded-full") +
            " " +
            ((readOnly || disabled) === true && "disabled") +
            ` ${className}`
          }
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          required={required || false}
          readOnly={readOnly || disabled}
          disabled={disabled}
          {...defaultProps}
        />
        <div className="overflow-hidden">{label}</div>
      </label>
    </div>
  );
};

export default Checkbox;
