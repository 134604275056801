import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { useCardStore } from "zustand-store/cards";
import { copyText } from "utils/handleCopy";
import { useMemo } from "react";
import { ButtonLoader } from "components/common/form";

export const CardDetailsModal: React.FC<{
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
    const { selectedCard } = useCardStore()
    const cardDetailsInfo = useMemo(() => [
        {
            title: "Card Name",
            detail: selectedCard.customer || "John Doe",
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Card Number",
            detail: selectedCard.number || "5439 9910 0329 9211",
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Expires",
            detail: selectedCard.expiryMonth ? `${selectedCard.expiryMonth}/${selectedCard.expiryYear}` : "11/29",
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "CCV",
            detail: selectedCard.cvv || 830,
            icon: <IconCopy className="w-6 h-6" />
        },
        {
            title: "Billing Address",
            detail: "333 Fremont Street, San Francisco, California, 94105, United States of America"
        }
    ], [selectedCard])

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="p-8 pb-6">
                <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                    <div className="text-2xl font-ojah font-medium">
                        Card Details
                    </div>
                    <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                        <IconClose className="m-auto svg-stroke-gray" />
                    </div>
                </div>
                <div className="pt-6 space-y-4">
                    {
                        cardDetailsInfo.map((item, idx) => (
                            <div className="flex justify-between items-center" key={idx}>
                                <div className="flex flex-col">
                                    <span className="text-sm text-shiga-gray">{item.title}</span>
                                    <span className="font-medium">{item.detail}</span>
                                </div>
                                <div onClick={copyText(item.detail + "", item.title)}>
                                    {item.icon}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="border-t border-gray-100 p-6 pt-3">
                <ButtonLoader onClick={() => {
                    setIsOpen(false)
                }} className="btn btn-block btn-lg btn-shiga-black">
                    Done
                </ButtonLoader>
            </div>
        </FormDrawer>
    )
}

export default CardDetailsModal;