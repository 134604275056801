import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ButtonLoader, Input } from "components/common/form";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import getPasswordStrength from "utils/getPasswordStrength";
import PasswordStrengthIndicator from "components/common/PasswordStrengthIndicator";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import { ReactComponent as IconLock } from "assets/images/icons/payment/lock.svg";
import { ReactComponent as IconTick } from "assets/images/icons/Check-Circle.svg";
import { ReactComponent as IconPhone } from "assets/images/icons/phone.svg";
import { ReactComponent as IconKey } from "assets/images/icons/Key.svg";
import { ReactComponent as IconMail } from "assets/images/icons/Mail-Send-Envelope.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import * as settingsActions from "store/entities/settings/action";
import Maybe from "components/common/Maybe";
import useToolkit from "hooks/useToolkit";
import WarningPromptModal from "components/common/WarningPromptModal";
import PATHS from "NavigationRoute";
import moment from "moment";


const AcceptInvite = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const { id } = useParams()
    const [searchParams] = useSearchParams();
    const { inviteAcceptLoading, inviteAccept, bvnData, bvnLoading, ninData, ninLoading } = useSelector((s: any) => s.entities.settings);

    const email = searchParams.get('email');
    const role = searchParams.get('role');
    const country = searchParams.get('country');

    const memberIsNigerian = country !== undefined && country === 'NG'

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        bvn: "",
        nin: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        document: "",
        mobileCode: "",
    });

    const { toastInfo, isLocalEnv } = useToolkit();
    const [bvnValidated, setBvnValidated] = useState(false);
    const [ninValidated, setNinValidated] = useState(false);
    const [promptShown, setPromptShown] = useState(false);

    const onFinish = async (e: React.FormEvent) => {
        e.preventDefault();

        if (currentStep === 1) {
            setCurrentStep(2);
        } else if (currentStep === 2) {
            dispatch(settingsActions.memberAcceptInvite(id, form))
        }
    }

    const verifyLoading = bvnLoading || ninLoading;
    const paramsInvalid = !email?.length || !role?.length || !id?.length || !country?.length;

    const stepOneDisabled = paramsInvalid || (memberIsNigerian && !form.bvn?.length)
        || (memberIsNigerian && !form.nin?.length) || (!memberIsNigerian && !form?.dob?.length)
        || form?.phoneNumber?.length < 8;


    const stepTwoDisabled = !getPasswordStrength(form.password).hasCharacterLength
        || !getPasswordStrength(form.password).hasLowerCase
        || !getPasswordStrength(form.password).hasUpperCase
        || !getPasswordStrength(form.password).hasNumber
        || !getPasswordStrength(form.password).hasSpecialCharacter
        || form.confirmPassword !== form.password;


    useEffect(() => {
        if (inviteAccept?.success === true) {
            dispatch(settingsActions.resetMemberAcceptInvite());
            setTimeout(() => {
                navigate(PATHS.AUTH.LOGIN);
            }, 1500);
        }
        // eslint-disable-next-line
    }, [inviteAccept]);


    useEffect(() => {
        if (form.bvn?.length === 11) {
            if (currentStep === 1) {
                toastInfo('Validating BVN')
            }
            dispatch(settingsActions.verifyBvn(form.bvn))
        }
        // eslint-disable-next-line
    }, [form.bvn]);


    useEffect(() => {
        if (form.nin?.length === 11) {
            if (currentStep === 1) {
                toastInfo('Validating NIN')
            }
            dispatch(settingsActions.verifyNin(form.nin))
        }
        // eslint-disable-next-line
    }, [form.nin]);

    useEffect(() => {
        if (bvnData?.data?.status === true) {
            setForm({
                ...form,
                firstName: bvnData?.data?.firstName,
                lastName: bvnData?.data?.lastName,
            })
            setBvnValidated(true);
        }
        // eslint-disable-next-line
    }, [bvnData]);

    useEffect(() => {
        if (ninData?.data?.status === true) {
            setForm({
                ...form,
                dob: ninData?.data?.dateOfBirth,
            })
            setNinValidated(true);
        }
        // eslint-disable-next-line
    }, [ninData]);

    useEffect(() => {
        if (paramsInvalid === true) {
            setPromptShown(true);
        } else if (paramsInvalid === false) {
            setPromptShown(false);
        }
    }, [paramsInvalid])

    // useEffect(() => {
    //     if (uploadedFile?.success === true && uploadedFile?.data?.file?.link !== '') {
    //         setForm({
    //             ...form,
    //             document: uploadedFile?.data?.file?.link,
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [uploadedFile])


    return (
        <>

            <WarningPromptModal
                control={promptShown}
                title="Invalid Invite"
                onClose={() => {
                    setPromptShown(false);
                    navigate(PATHS.AUTH.LOGIN);
                }}
                subtitle="You seem to have visited this page with incomplete details... Please contact your administrator"
            />

            <DynamicHeadTag
                headerText="Join Team"
            />

            <AuthPageTitle
                title="Join Payshiga Technol..."
                description={`${email}, please complete this form to create a Payshiga account and join this team`}
            />

            <Maybe condition={currentStep === 1}>

                <div>
                    <form onSubmit={onFinish}>
                        <div className="mt-10">
                            <Maybe condition={memberIsNigerian}>
                                <Input
                                    disabled={verifyLoading}
                                    min={11}
                                    maxLength={11}
                                    type="number"
                                    label="Bank verification number"
                                    required={true}
                                    placeholder="Enter your BVN"
                                    value={form.bvn || ''}
                                    rightIcon={bvnValidated && form.bvn?.length === 11 && <IconTick className="h-4 w-4" />}
                                    onChange={(e: any) => {
                                        setBvnValidated(false);
                                        setForm({ ...form, firstName: '', lastName: '', })
                                        setForm({ ...form, bvn: e.target.value })
                                    }}
                                />
                            </Maybe>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                                <Input
                                    required={!memberIsNigerian}
                                    disabled={memberIsNigerian}
                                    type="text"
                                    value={form.firstName}
                                    label="First Name"
                                    rightIconClass="pr-1"
                                    placeholder="First Name"
                                    rightIcon={memberIsNigerian ? <IconLock /> : null}
                                    onChange={(e: any) => setForm({ ...form, firstName: e.target.value })}
                                />
                                <Input
                                    required={!memberIsNigerian}
                                    disabled={memberIsNigerian}
                                    value={form.lastName}
                                    type="text"
                                    label="Last Name"
                                    rightIconClass="pr-1"
                                    placeholder="Last Name"
                                    rightIcon={memberIsNigerian ? <IconLock /> : null}
                                    onChange={(e: any) => setForm({ ...form, lastName: e.target.value })}
                                />
                            </div>

                            <Maybe condition={memberIsNigerian}>
                                <Input
                                    disabled={verifyLoading}
                                    type="text"
                                    label="National Identity Number"
                                    placeholder="Enter your NIN"
                                    required={true}
                                    value={form.nin}
                                    onChange={(e: any) => {
                                        setNinValidated(false);
                                        setForm({ ...form, nin: e.target.value })
                                    }}
                                    rightIcon={ninValidated && form.nin?.length === 11 && <IconTick className="h-4 w-4" />}
                                />
                            </Maybe>

                            <Maybe condition={!memberIsNigerian}>
                                <Input
                                    type="date"
                                    label="Date of Birth"
                                    placeholder="DD/MM/YY"
                                    required={!memberIsNigerian}
                                    value={form.dob || ''}
                                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                    onChange={(e: any) => {
                                        setForm({ ...form, dob: e.target.value });
                                    }}
                                />
                            </Maybe>


                            <div className="relative">
                                <Input
                                    type="text"
                                    label="Phone Number"
                                    placeholder="Enter phone number"
                                    required={true}
                                    // inputClass={`${memberIsNigerian && '!pl-14'}`}
                                    value={form.phoneNumber}
                                    onChange={(e: any) => setForm({ ...form, phoneNumber: e.target.value })}
                                    rightIcon={form?.phoneNumber?.length >= 9 && <IconTick className="h-4 w-4" />}
                                />
                            </div>


                            <div className="!my-6" />

                            <ButtonLoader disabled={stepOneDisabled} type="submit" className="btn btn-lg btn-block btn-primary mt-8">
                                Continue
                            </ButtonLoader>

                        </div>
                    </form>
                </div>
            </Maybe>


            <Maybe condition={currentStep === 2}>

                <div>
                    {
                        isLocalEnv &&
                        <button onClick={() => setCurrentStep(1)} type="button">
                            Back
                        </button>
                    }


                    <form onSubmit={onFinish}>
                        <div className="mt-10">

                            <div className="w-full border-b pb-6 mb-6">

                                <div className="flex items-start justify-start min-h-[120px]">
                                    <div className="w-max h-full mr-6">
                                        <IconUserProfile className="h-[124px] w-[124px]" />
                                    </div>


                                    <div className="w-2/4">
                                        <p className="mb-4 font-medium whitespace-nowrap text-shiga-dark-100 text-lg lg:text-2xl font-inter">
                                            {form.firstName} {form.lastName}
                                        </p>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconPhone className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                +234{form.phoneNumber}
                                            </p>
                                        </div>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconMail className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                {email}
                                            </p>
                                        </div>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconKey className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                <span className="text-shiga-gray-100">
                                                    Role:&nbsp;
                                                </span>

                                                <span className="capitalize">
                                                    {role?.toLowerCase()}
                                                </span>
                                            </p>
                                        </div>


                                    </div>
                                </div>

                            </div>

                            <Input
                                type={showPassword1 ? "text" : "password"}
                                autoComplete="false"
                                label="Current Password"
                                placeholder="Password"
                                required={true}
                                value={form.password} onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                                rightIcon={showPassword1 ? <IconEyeOpen /> : <IconEyeClose />}
                                rightIconClicked={() => setShowPassword1(!showPassword1)}
                                rightIconClass="px-3"
                            />

                            <div className="-mt-2 mb-5">
                                <PasswordStrengthIndicator
                                    password={form.password}
                                />
                            </div>

                            <Input
                                type={showPassword2 ? "text" : "password"}
                                autoComplete="false"
                                label="Confirm Password"
                                placeholder="Password"
                                required={true}
                                value={form.confirmPassword} onChange={(e: any) => setForm({ ...form, confirmPassword: e.target.value })}
                                rightIcon={showPassword2 ? <IconEyeOpen /> : <IconEyeClose />}
                                rightIconClicked={() => setShowPassword2(!showPassword2)}
                                rightIconClass="px-3"
                            />

                            <ButtonLoader disabled={stepTwoDisabled} type="submit" loading={inviteAcceptLoading} className="btn btn-lg btn-block btn-primary mt-8">
                                Join this Team
                            </ButtonLoader>

                        </div>
                    </form>
                </div>
            </Maybe>

        </>
    )
}


export default AcceptInvite;