import { combineReducers } from "redux";
import selectionsReducer from "./selections/reducer";
import countriesReducer from "./countries/reducer";
import industriesReducer from "./industries/reducer";
import preferencesReducer from "./preferences/reducer";
import currenciesReducer from "./currencies/reducer";
import banksReducer from "./banks/reducer";
import mediaServiceReducer from "./mediaService/reducer";
import dashboardReducer from "./dashboard/reducer"

export default combineReducers({
    selections: selectionsReducer,
    countries: countriesReducer,
    industries: industriesReducer,
    preferences: preferencesReducer,
    currencies: currenciesReducer,
    banks: banksReducer,
    mediaService: mediaServiceReducer,
    dashboard: dashboardReducer
});