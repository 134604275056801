import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader } from "components/common/form";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import IconMerchantBlackWhite from "assets/images/icons/merchant-black-white.svg";
import IconAgentBlackWhite from "assets/images/icons/agent-black-white.svg";
import IconProducerBlackWhite from "assets/images/icons/exporting-producer-black-white.svg";
import IconMerchantColored from "assets/images/icons/merchant-colored.svg";
import IconAgentColored from "assets/images/icons/agent-colored.svg";
import IconProducerColored from "assets/images/icons/exporting-producer-colored.svg";
import Maybe from "components/common/Maybe";
import * as userActions from "store/auth/user/action";
import ChevronRightIcon from "assets/images/icons/ChevronRight";
import { ReactComponent as IconArrowRight } from "assets/images/icons/arrow-right.svg";

const RegisterAccountType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registerForm } = useSelector((s: any) => s.auth.user);

  const [form, setForm] = useState({
    accountType: "",
  });

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = validate(form);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      dispatch(
        userActions.saveRegisterForm({
          ...registerForm,
          ...form,
        })
      );
      navigate("/register/business-info");
    }
  };

  useEffect(() => {
    if (Object.keys(registerForm).length === 0) {
      navigate("/register");
    } else {
      setForm({
        ...form,
        accountType: registerForm.accountType,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DynamicHeadTag headerText="Register - Account Type" />

      <AuthPageTitle
        title="What kind of account do you want to open?"
        description="You can always add another account later on."
        showBackButton={true}
        backButtonUrl="/register"
      />

      <div>
        <form onSubmit={submitForm}>
          <div className="mt-10">
            <div className="flex flex-col space-y-6">
              <AccountTypeOptions
                imageInactive={IconMerchantBlackWhite}
                imageActive={IconMerchantColored}
                value="MERCHANT"
                title="General Business"
                description="I want to send and receive from businesses across the world."
                form={form}
                onchange={(e: string) => setForm({ ...form, accountType: e })}
              />
              {/* <AccountTypeOptions
                                imageInactive={IconAgentBlackWhite}
                                imageActive={IconAgentColored}
                                value="AGENT"
                                title="Agent Account"
                                description="I want to help clients with global flights, hotels, tuition payments & accommodation."
                                form={form}
                                onchange={(e:string) => setForm({...form, accountType: e})}
                            /> */}
              <AccountTypeOptions
                imageInactive={IconProducerBlackWhite}
                imageActive={IconProducerColored}
                value="ECOMMERCE"
                title="Merchant"
                description="I want to export from Africa to the rest of the world."
                form={form}
                onchange={(e: string) => setForm({ ...form, accountType: e })}
              />
            </div>

            <ButtonLoader
              type="submit"
              loading={false}
              className="btn btn-lg btn-block btn-primary mt-10"
            >
              Continue
            </ButtonLoader>
          </div>
        </form>
      </div>
    </>
  );
};

const AccountTypeOptions = (props: any) => {
  const {
    imageInactive,
    imageActive,
    value,
    title,
    description,
    form,
    onchange,
  } = props;
  return (
    <div
      onClick={() => onchange(value)}
      className={
        "px-3 py-6 flex items-center space-x-6 bg-white border rounded-2xl cursor-pointer " +
        (form.accountType === value
          ? "border-shiga-black ring-1 ring-shiga-black"
          : "border-gray-200")
      }
    >
      <div className="w-16 h-16 flex-shrink-0 flex items-center justify-center overflow-hidden">
        <Maybe condition={form.accountType === value}>
          <img
            src={imageActive}
            alt={title}
            className="w-full h-full object-contain"
          />
        </Maybe>
        <Maybe condition={form.accountType !== value}>
          <img
            src={imageInactive}
            alt={title}
            className="w-full h-full object-contain"
          />
        </Maybe>
      </div>
      <div className="flex-grow">
        <div className="font-medium font-geist">{title}</div>
        <div className="text-shiga-gray text-sm ellipsis-2-lines">
          {description}
        </div>
      </div>
      <ChevronRightIcon className=" w-4 h-4" />
    </div>
  );
};

export default RegisterAccountType;
