import { zodResolver } from "@hookform/resolvers/zod";
import BackBtnIcon from "assets/images/icons/BackBtn";
import CloseBtnIcon from "assets/images/icons/CloseBtn";
import {
  ButtonLoader,
  Input,
  InputAmount,
  InputAmountHeader,
  SelectInput,
} from "components/common/form";
import TextArea from "components/common/form/TextArea";
import { Form } from "components/ui/form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useSharedStore } from "zustand-store";
import { Upload } from "components/common/Upload";
import { ChangeEvent, useEffect, useState } from "react";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import CustomAmountField from "components/custom/CustomAmountField";

const formSchema = z.object({
  productName: z.string({ message: "this field is required" }),
  description: z.string({ message: "this field is required" }),
  category: z.string({ message: "this field is required" }),
  amount: z.number({ message: "this field is required" }),
  weight: z.number({ message: "this field is required" }),
  quantity: z.number({ message: "this field is required" }),
  imageUrl: z.string({ message: "this field is required" }),
});

type FormType = z.infer<typeof formSchema>;

const category = [
  { label: "Fashion", value: "fashion" },
  { label: "Technology", value: "technology" },
  { label: "Media", value: "media" },
];

const CreateProduct = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  // Filter currencies based on wallet data
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );
  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 0) {
      const value = event.target.value;
      setAmount(value);
      form.setValue("amount", Number(value));
    }
  };

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      productName: "",
      description: "",
      category: "",
      amount: 0,
      weight: 0.0,
      quantity: 0.0,
    },
    context: "onChange",
    criteriaMode: "all",
    mode: "onChange",
  });

  const goBack = () => {
    navigate("/ojah/products");
  };
  const close = () => {
    navigate("/ojah/products");
  };
  useEffect(() => {
    if (form.watch("imageUrl")) {
      setPreviewUrl(form.getValues("imageUrl"));
    }
  }, []);

  useEffect(() => {
    if (previewUrl.length) {
      form.setValue("imageUrl", previewUrl);
    }
  }, [previewUrl]);

  const onSubmit = ({
    productName,
    description,
    category,
    amount,
    weight,
    quantity,
  }: FormType) => {
  };
  return (
    <div>
      <header
        className={`pb-7 border-b border-outline-grey flex justify-between items-center `}
      >
        <BackBtnIcon className="cursor-pointer" onClick={goBack} />

        <CloseBtnIcon className="cursor-pointer" onClick={close} />
      </header>
      <div className="flex flex-col-reverse lg:flex-row p-6 justify-between items-center lg:items-start">
        <div className="mt-5 lg:0 w-full lg:w-[412px] space-y-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Input
                name="productName"
                type="text"
                label="Name of Product"
                placeholder="Add a Name"
                required
                // {...form.control}
                // {...form.register("productName", {
                //   required: "Product Name is required",
                // })}
              />
              <div className="mb-[1.75rem]">
                <TextArea
                  placeholder="Say a bit more about this product"
                  label="Add a Description (optional)"
                  form={form as any}
                />
              </div>
              <SelectInput
                // name="category"
                type="text"
                label="Product Category "
                placeholder="Select a Category"
                required={true}
                // {...form}
                {...form.register("category")}
              >
                {category.map((item, index) => (
                  <option value={item.value} key={index * 2}>
                    <div className="flex items-center gap-1.5">
                      {item.label}
                    </div>
                  </option>
                ))}
              </SelectInput>
              <div className="mb-[1.75rem]">
              
                <InputAmount
                  //   disableCurrency
                  currencies={filteredCurrencies || []}
                  form={form as any}
                  minAmount={100}
                  label="Price of Product"
                  // header={InputAmountHeader}
                  header={({ label, showCurrency, currentWallet }) => (
                    <InputAmountHeader
                      currentWallet={currentWallet}
                      showCurrency={true}
                      label={label}
                    />
                  )}
                />
              </div>
              <div className="flex justify-between gap-4">
                <Input
                  type="number"
                  label="Weight (KG)"
                  placeholder="0.00"
                  min="0"
                  max="100"
                  required
                  {...form.control}
                />
                <Input
                  type="number"
                  label="Quantity in Stock"
                  placeholder="0.00"
                  min="0"
                  max="100"
                  required
                  {...form.control}
                />
              </div>

              <ButtonLoader
                disabled={form.formState.isValid}
                // loading={loadingBuyElectricity}
                type="submit"
                className="btn btn-lg btn-block btn-primary mt-3"
              >
                Confirm & Publish
              </ButtonLoader>
            </form>
          </Form>
        </div>
        <div>
          <Upload
            previewUrl={previewUrl || form.getValues("imageUrl")}
            setPreviewUrl={setPreviewUrl}
            file={file}
            setFile={setFile}
          />

          <div className=" mt-8">
            <div className="flex justify-between mb-8">
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
            </div>
            <div className="flex justify-between">
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
              <Upload
                placeholder=" "
                heightAndWidth="w-[80px] h-[80px]"
                previewUrl={previewUrl || form.getValues("imageUrl")}
                setPreviewUrl={setPreviewUrl}
                file={file}
                setFile={setFile}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
