import { monthNames, weekdays } from "utils/dateFormat";

export const CurrentDate = (separator = '-') => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let newDate = new Date();
  let day = newDate.getDay();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const monthDisplay = ((month < 10) ? '0' + month : month);

  return (days[day] + ", " + date + separator + monthDisplay + separator + year);
};

export const getCurrentDate = (separator = ' ') => {
  let newDate = new Date();
  let day = newDate.getDay();
  let date = newDate.getDate();
  let month = newDate.getMonth();
  let year = newDate.getFullYear();

  const dayDisplay = weekdays[day];
  const monthDisplay = monthNames[month];

  return (dayDisplay + ", " + monthDisplay + separator + date + "," + separator + year);
};

export default CurrentDate;

export const getCurrentGreeting = () => {
  // Get the current date and time
  var currentDate = new Date();
  var currentHour = currentDate.getHours();

  // Define different greetings based on the time of the day
  var greeting;
  if (currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }
  return greeting;
}