import { useEffect } from 'react'
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import Maybe from 'components/common/Maybe';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import { ReactComponent as Settltments } from "assets/images/icons/settlements-logo.svg";
import SettlementsDark from "assets/images/icons/settlements-dark.svg"
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import { RootState } from 'store/store';
import { SettlementsFilterHandler } from '../transactions/SettlementUtils';
import PATHS from 'NavigationRoute';
import EmptyUI from 'components/common/EmptyUi';
import { getAllSettlements, selectSettlement } from 'store/transactions/action';
import { currencyFormat } from 'utils/CurrencyFormat';


const SettlementsHomepage = () => {
   const navigate = useNavigate();
   const { copyItem, useState } = useToolkit();
   const dispatch = useAppDispatch();

   const location = useLocation();
   const { urlQueryParams } = useUrlQueryParams();
   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });

   const { allSettlementsData, allSettlementsLoading } = useSelector((state: RootState) => state.transactions);

   const settlementsData = allSettlementsData?.data;
   const isValidSettlements = allSettlementsLoading === false &&
      settlementsData?.data && settlementsData?.data?.length > 0;

   const fetchSettlements = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "currency", "string", "", "currency");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
      params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

      setListParams(params);
      dispatch(getAllSettlements(params));
   }

   useEffect(() => {
      fetchSettlements();
      // eslint-disable-next-line
   }, [location]);

   return (
      <div className='dashboard-page-container'>

         <DynamicHeadTag
            headerText="Settlements"
         />

         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg-flex-nowrap lg:border-b lg:border-shiga-gray-50 pb-10">
            <div className="page-title w-full lg:w-max">
               Settlements
            </div>
         </div>

         <div className="pb-24 pt-6">

            <Maybe condition={!allSettlementsLoading && isValidSettlements}>
               <SettlementsFilterHandler transactions={allSettlementsData} />
            </Maybe>

            <Table>

               <Maybe condition={(allSettlementsLoading === false && !allSettlementsData) || (isValidSettlements)}>
                  <Table.Head>
                     <th>Narration</th>
                     <th>Amount</th>
                     <th>Status</th>
                     <th>Settltment ID</th>
                     <th>Date Initiated</th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     data={settlementsData?.data}
                     loading={allSettlementsLoading}
                     loadingText="Fetching settlements"
                     total={settlementsData?.data?.length || 0}
                     colspan={5}
                     emptyUI={
                        <EmptyUI
                           icon={SettlementsDark}
                           header='No Settlements yet'
                           subheader='No payment has been made to your settlement account at this time'
                        />
                     }
                  />

                  {allSettlementsLoading === false && isValidSettlements && settlementsData?.data?.map((item: any) => {

                     const isSuccessful = item?.status === 'SUCCESS';
                     const isPending = item?.status === 'PENDING';
                     const isFailed = item?.status === 'FAILED';

                     return (
                        <tr
                           key={item.narration}
                           className='cursor-pointer font-inter'
                           onClick={() => {
                              dispatch(selectSettlement(item))
                              navigate(PATHS.SETTLEMENTS.SETTLEMENT_DETAILS_PAGE + item?.settlementId)
                           }}>

                           <td>
                              <div className="flex items-center">
                                 <Settltments />
                                 <div className="ml-5">
                                    <p className="mb-px text-shiga-gray-300 text-xs">Payout sent to</p>
                                    <p className="mb-0 text-shiga-dark-100 font-medium lg:text-plg capitalize">
                                       {item?.accountName?.toLowerCase() || 'N/A'}
                                    </p>
                                 </div>
                              </div>
                           </td>

                           <td>
                              <div className="text-shiga-dark-150 font-medium lg:text-plg">
                                 {currencyFormat(item?.amount, item?.currency)}
                              </div>
                           </td>

                           <td>
                              <div className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] capitalize font-inter font-medium ${isSuccessful ? 'border-shiga-green-50 text-shiga-green-100'
                                 : isPending ? 'text-shiga-orange-100 border-shiga-orange-50'
                                    : isFailed ? 'border-shiga-red-40 text-shiga-red-100'
                                       : ''
                                 }`}>
                                 {isSuccessful ? 'Completed' : item?.status?.toLowerCase()}
                              </div>
                           </td>

                           <td>
                              <div className='flex items-center font-medium'>
                                 {item?.settlementId}
                                 <button className='ml-1.5' onClick={(e: any) => {
                                    e.stopPropagation();
                                    copyItem(item?.settlementId, 'Settlement ID');
                                 }}>
                                    <CopyIcon width={20} />
                                 </button>
                              </div>
                           </td>

                           <td>
                              <div className='font-medium'>
                                 {moment(item?.dateInitiated).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>
      </div>
   )
}

export default SettlementsHomepage