import { AdvancedOptionType } from "types/payment/invoice";

export const ADVANCED: AdvancedOptionType[] = [
    {
      name: "Discount",
      desc: "Deducted from total invoice value",
      value: 0,
      key: "discount",
    },
    {
      name: "Vat (Value added tax)",
      desc: "Deducted from total invoice value",
      value: 0,
      key: "vat",
    },
  ];