export const getToken = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return token;
  }
  const authStr = sessionStorage.getItem("persist:auth");
  if (authStr) {
    const parsedAuth = JSON.parse(authStr);
    const parsedUserPersist = JSON.parse(parsedAuth.userPersist);
    sessionStorage.setItem("token", parsedUserPersist.userInfo.token);
    return parsedUserPersist.userInfo.token;
  }
  return "";
};
