import "assets/css/transfermethod.css";
import { ReactComponent as IconRight } from "assets/images/icons/arrow-right-gray.svg";

import React from 'react';

interface TransferMethodProps {
    onClick: () => void;
    image: string;
    title: string;
    details: string;
    active?: boolean | undefined;
    borderColor?: string | undefined;
}

const TransferMethod: React.FC<TransferMethodProps> = ({
    onClick,
    image,
    title,
    details,
    active,
    borderColor }) => {
    return (
        <div
            className={`active:scale-90 flex items-center justify-between rounded-3xl p-4 border border-shiga-gray-75 cursor-pointer transition-all ${active ? 'active' : ''}`}
            onClick={onClick}
            style={active ? { borderColor: borderColor, outline: `2px solid ${borderColor}` } : {}}>

            <div className='mr-5'>
                <img src={image} alt={title} width={70} />
            </div>

            <div>
                <h3 className='font-geist text-lg mb-1.5'>{title}</h3>
                <div className='font-inter text-shiga-gray-300'>{details}</div>
            </div>

            <div className="ml-3">
                <IconRight />
            </div>
        </div>
    );
};

export default TransferMethod;
