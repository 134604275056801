import { FilterFormatValues } from "components/data-table/interface";
import { Transaction } from "./transaction";

export interface Refund extends FilterFormatValues {
    id: string;
    currency: string;
    amount: string;
    reference: string;
    channel: string;
    type: "BUSINESS"
    status: "PENDING" | "COMPLETED";
    createdAt: string;
    transaction?: Transaction;
    refundedAt: string;
    note?: string;
    initiatedByAccount?: {
        firstName: string;
        lastName: string
    }
    customer: {
        firstName: string;
        email: string;
        lastName: string
    }
}

export const defaultRefund: Refund = {
    id: "",
    currency: "",
    amount: "0",
    reference: "",
    channel: "",
    createdAt: "",
    status: "PENDING",
    type: "BUSINESS",
    refundedAt: "",
    customer: {
        email: "",
        firstName: "",
        lastName: ""
    }
}