
import { ChangeEvent, FC, useState } from "react";
import { NumericFormat } from "react-number-format";

interface AmountInputProps {
    amount: string;
    onAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
    currencies: string[];
    selectedCurrencies: string;
    iconUrl: string;
    disabled: boolean;
    onCurrencySelect: (currency: string) => void;
    amountError?: boolean;
}

const AmountInput: FC<AmountInputProps> = ({
    amount,
    onAmountChange,
    currencies,
    selectedCurrencies,
    iconUrl,
    disabled,
    amountError,
}) => {
    const [isLoading, setIsLoading] = useState(true);

    // Function to handle when the image has loaded
    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className={`bg-white transition-colors duration-150 relative z-10 flex items-center justify-between border rounded-2xl py-5 px-2 ${amountError ? 'border-shiga-red-50' : 'border-shiga-gray-75 '}`}>
            <div>
                <NumericFormat
                    value={amount}
                    onValueChange={(values: { formattedValue: string; value: string }) => {
                        onAmountChange({ target: { value: values.value } } as ChangeEvent<HTMLInputElement>);
                    }}
                    type="text"
                    placeholder="0.00"
                    className="border-none ps-2 pbs-1 p-0 focus-visible:outline-0 focus:border-transparent text-lg lg:text-[28px] font-ojah text-shiga-dark-100 placeholder:text-shiga-gray-100"
                    thousandSeparator={true}
                    decimalScale={2}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    disabled={disabled}
                />
            </div>

            <div className="flex items-center absolute right-2 z-10">
                <img
                    alt="Flag"
                    src={iconUrl}
                    loading="lazy"
                    onLoad={handleImageLoad}
                    className={`h-5 w-5 mr-1 ${isLoading ? 'shimmer' : ''}`}
                />

                <span className="ml-1 mr-px text-shiga-dark-100 font-medium lg:text-[19px]">
                    {selectedCurrencies}
                </span>
            </div>
        </div>
    );
};

export default AmountInput;
