export const GET_TV_SERVICES = "GET_TV_SERVICES";
export const GET_TV_SERVICES_START = "GET_TV_SERVICES_START";
export const GET_TV_SERVICES_FAILED = "GET_TV_SERVICES_FAILED";
export const GET_TV_SERVICES_RESET = "GET_TV_SERVICES_RESET";

export const GET_INTERNET_SERVICES = "GET_INTERNET_SERVICES";
export const GET_INTERNET_SERVICES_START = "GET_INTERNET_SERVICES_START";
export const GET_INTERNET_SERVICES_FAILED = "GET_INTERNET_SERVICES_FAILED";
export const GET_INTERNET_SERVICES_RESET = "GET_INTERNET_SERVICES_RESET";

export const GET_AIRTIME_SERVICES = "GET_AIRTIME_SERVICES";
export const GET_AIRTIME_SERVICES_START = "GET_AIRTIME_SERVICES_START";
export const GET_AIRTIME_SERVICES_FAILED = "GET_AIRTIME_SERVICES_FAILED";
export const GET_AIRTIME_SERVICES_RESET = "GET_AIRTIME_SERVICES_RESET";

export const GET_ELECTRICITY_SERVICES = "GET_ELECTRICITY_SERVICES";
export const GET_ELECTRICITY_SERVICES_START = "GET_ELECTRICITY_SERVICES_START";
export const GET_ELECTRICITY_SERVICES_FAILED = "GET_ELECTRICITY_SERVICES_FAILED";
export const GET_ELECTRICITY_SERVICES_RESET = "GET_ELECTRICITY_SERVICES_RESET";

export const GET_DATA_PLAN = "GET_DATA_PLAN";
export const GET_DATA_PLAN_START = "GET_DATA_PLAN_START";
export const GET_DATA_PLAN_FAILED = "GET_DATA_PLAN_FAILED";
export const GET_DATA_PLAN_RESET = "GET_DATA_PLAN_RESET";

export const CREATE_AIRTIME = "CREATE_AIRTIME";
export const CREATE_AIRTIME_START = "CREATE_AIRTIME_START";
export const CREATE_AIRTIME_FAILED = "CREATE_AIRTIME_FAILED";
export const CREATE_AIRTIME_RESET = "CREATE_AIRTIME_RESET";


export const CREATE_INTERNET_SERVICE = "CREATE_INTERNET_SERVICE";
export const CREATE_INTERNET_SERVICE_START = "CREATE_INTERNET_SERVICE_START";
export const CREATE_INTERNET_SERVICE_FAILED = "CREATE_INTERNET_SERVICE_FAILED";
export const CREATE_INTERNET_SERVICE_RESET = "CREATE_INTERNET_SERVICE_RESET";

export const CREATE_TV_SERVICES = "CREATE_TV_SERVICES";
export const CREATE_TV_SERVICES_START = "CREATE_TV_SERVICES_START";
export const CREATE_TV_SERVICES_FAILED = "CREATE_TV_SERVICES_FAILED";
export const CREATE_TV_SERVICES_RESET = "CREATE_TV_SERVICES_RESET";

export const GET_CABLE_PACKAGES = "GET_CABLE_PACKAGES";
export const GET_CABLE_PACKAGES_START = "GET_CABLE_PACKAGES_START";
export const GET_CABLE_PACKAGES_FAILED = "GET_CABLE_PACKAGES_FAILED";
export const GET_CABLE_PACKAGES_RESET = "GET_CABLE_PACKAGES_RESET";

export const CREATE_ELECTRICITY = "CREATE_ELECTRICITY";
export const CREATE_ELECTRICITY_START = "CREATE_ELECTRICITY_START";
export const CREATE_ELECTRICITY_FAILED = "CREATE_ELECTRICITY_FAILED";
export const CREATE_ELECTRICITY_RESET = "CREATE_ELECTRICITY_RESET";