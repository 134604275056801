import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Textarea } from "components/ui/textarea";
import { UseFormReturn } from "react-hook-form";

export const TextArea: React.FC<{
  form?: UseFormReturn<
    {
      note: string;
    },
    any,
    undefined
  >;
  value?: string;
  label?:string;
  placeholder?: string;
}> = ({ form, value, label, placeholder = "Add a Note (Optional)" }) => {
  return form ? (
    <FormField
      control={form.control}
      name="note"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            <div className="flex w-full justify-between mb-2">
              <span className="text-[14px] text-shiga-gray-100" >{label}</span>
              <p>{`${field.value ? field.value.length : 0}/200`}</p>
            </div>
          </FormLabel>
          <FormControl>
            <Textarea placeholder={placeholder} {...field} />
          </FormControl>

          <FormMessage />
        </FormItem>
      )}
    />
  ) : (
    <div className="mt-4">
      <div className="flex justify-between text-[#B4B4B4]">
        <p>Add a Note</p>
        <p>{`${value?.length || 0}/200`}</p>
      </div>
      <textarea
        disabled
        value={value}
        className="w-full !h-24 form-input resize-none mt-2"
        placeholder="Add a few notes to help you later"
      ></textarea>
    </div>
  );
};

export default TextArea;
