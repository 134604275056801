import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as accountActions from "store/auth/account/action";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { toast } from "react-toastify";
import { useCustomToast } from "utils/context/custom-toast/ToastContext";

type CustomToastProps = {
	message: string;
	onClick?: () => void;
}

const useToolkit = () => {
	const dispatch = useDispatch();
	const { userLogin, userInfo } = useSelector(getAuthPersist);
	const { addCustomToast } = useCustomToast();

	const userProfile = userInfo?.userProfile
	const businessProfile = userInfo?.businessProfile

	const isLocalEnv = process.env.NODE_ENV === 'development';
	// const isOnlineSandboxEnv = true;
	const isOnlineSandboxEnv = process.env.REACT_APP_API_BASE_URL?.includes('sandbox') && !isLocalEnv;
	const isSandboxEnv = window !== undefined && window.location.hostname?.includes('business.sandbox.payshiga.com')
	const isProductionEnv = window !== undefined && window.location.hostname?.includes('business.payshiga.com')

	const refetchProfiles = () => {
		dispatch(accountActions.getUserProfile());
		dispatch(accountActions.getBusinessProfile());
	}

	const mfaEnabled = userInfo?.userProfile?.auth === "Y";

	const formatCurrency = (currency: string, floatValue?: number) => {
		if (!floatValue) {
			return Number(currency).toLocaleString('en-US')
		} else {
			return Number(currency).toLocaleString('en-US', {
				minimumFractionDigits: floatValue,
				maximumFractionDigits: floatValue
			})
		}
	}

	const toastError = (message: string) => toast.error(message);
	const toastInfo = (message: string) => toast.info(message);
	const toastSuccess = (message: string) => toast.success(message);

	const showCustomSuccessToast = ({ message, onClick }: CustomToastProps) => {
		addCustomToast('success', message, onClick);
	}

	const copyItem = (item: any, message: string) => {
		navigator.clipboard.writeText(item);
		toastSuccess(message + ' copied !');
	}

	const showValue = (value: any) => value || 'N/A';

	const getCurrencySymbol = (currency: string): string => {
		const symbols: { [key: string]: string } = {
			EUR: "€",
			USD: "$",
			GBP: "£",
			NGN: "₦",
		};

		return symbols[currency] || '';
	};

	const kybStatus = businessProfile?.verificationStatus

	const kybVerified = businessProfile?.verificationStatus === 'VERIFIED';
	const kybPending = businessProfile?.verificationStatus === 'PENDING';
	const kybNotStarted = businessProfile?.verificationStatus === 'UNVERIFIED';
	const kybRejected = businessProfile?.verificationStatus === 'REJECTED';


	return {
		dispatch,
		useSelector,
		useEffect,
		useState,
		userLogin,
		userInfo,
		userProfile,
		businessProfile,
		refetchProfiles,
		mfaEnabled,
		formatCurrency,
		toastSuccess,
		toastInfo,
		toastError,
		copyItem,
		showValue,
		showCustomSuccessToast,
		isLocalEnv,
		getCurrencySymbol,
		isOnlineSandboxEnv,
		isSandboxEnv,
		isProductionEnv,
		kybStatus,
		kybVerified,
		kybPending,
		kybNotStarted,
		kybRejected,
	};

}


export default useToolkit;