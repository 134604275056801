import { useEffect, useState } from "react";
import Debounce from "lodash.debounce";
import substract from "assets/images/icons/Multiply.png";
import addition from "assets/images/icons/addition.png";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import equal from "assets/images/icons/Equals.png";
import { ButtonLoader, InputAmount } from "components/common/form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConvertMoneyHeader from "components/transfer/CovertMoneyHeader";
import PATHS from "NavigationRoute";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/ui/form";
import { useSharedStore } from "zustand-store";
import { currencyFormat } from "lib/format.amount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wallet } from "types/wallet";
import { getRate } from "./utils";
import { toast } from "react-toastify";
import GetShimmer from "components/layouts/GetShimmer";
import { useAppDispatch } from "store/hooks";
import { resetConvertData, updateConvert } from "store/convert/action";
import { CurrencyType } from "types/misc";
import { zodValidation } from "utils/zodValidate";
import * as currenciesActions from "store/ui/currencies/action";

import { useSelector } from "react-redux";
// import useWalletFilteredCurrencies from "lib/filteredconstant";
// import {CurrencyComponent} from "lib/filteredconstant";

const ConvertMoneyInputHeader = ({
  currentWallet: { balance, currency },
}: {
  currentWallet: Wallet;
}) => (
  <div className="flex mb-4 justify-between">
    <p className="">
      You convert<span className="form-input-required">*</span>
    </p>
    {balance && currency ? (
      <p className="text-shiga-dark">
        <span>
          <FontAwesomeIcon icon={faWallet} />
        </span>
        You have{" "}
        <span className="text-shiga-purple">
          {currencyFormat(balance, currency)}
        </span>{" "}
        available
      </p>
    ) : (
      <></>
    )}
  </div>
);

const formSchema = z
  .object({
    "from-amount": z.number(),
    "to-amount": z.number(),
    "from-currency": z.string(),
    "to-currency": z.string(),
    balance: zodValidation.balance,
  })
  .superRefine((field, ctx) => {
    if (field["from-currency"] === field["to-currency"]) {
      ctx.addIssue({
        code: "invalid_enum_value",
        message: "You cannot convert to the same currency",
        path: ["from-currency"],
        options: [],
        received: "",
      });
    }
  });

type FormType = z.infer<typeof formSchema>;

const ConvertMoney: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { currencies } = useSharedStore();
  const walletString = sessionStorage.getItem("wallet");
  const wallet = JSON.parse(walletString!);

  // Filter currencies based on wallet data
  const currencyCodesInWallet = wallet.map(
    (walletItem: any) => walletItem?.currency
  );
  const filteredCurrencies = currencies.filter((currency) =>
    currencyCodesInWallet.includes(currency.currency)
  );

  const [convertDetail, setConvertDetail] = useState({
    rate: 0,
    receiveAmount: 0,
    sendAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isToAmountUpdated, setIsToAmountUpdated] = useState(false);
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      "from-amount": 0,
      "to-amount": 0,
      "from-currency": searchParams.get("fromCurrency") || "NGN",
      "to-currency": "USD",
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const amount = form.watch("from-amount");
  const currencyFrom = form.watch("from-currency");
  const currencyTo = form.watch("to-currency");

  const handleGetRate = Debounce(() => {
    setLoading(true);
    getRate({
      amount,
      currencyFrom,
      currencyTo,
    })
      .then((data) => {
        setConvertDetail({
          rate: data?.rate,
          receiveAmount: data?.receiveAmount,
          sendAmount: data?.sendAmount,
        });
      })
      .catch((err) => {
        toast.error(`Something went wrong:`, err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 2000);

  useEffect(() => {
    if (currencyFrom.length && currencyTo.length && amount > 1) {
      handleGetRate();
    }
  }, [amount, currencyFrom, currencyTo]);

  useEffect(() => {
    if (convertDetail.receiveAmount) {
      form.setValue("to-amount", convertDetail.receiveAmount);
      setIsToAmountUpdated(true);
    }
  }, [convertDetail]);

  useEffect(() => {
    dispatch(currenciesActions.getCurrencies());
  }, []);
  const onSubmit = (arg: FormType) => {
    if (convertDetail.sendAmount > Number(arg.balance)) {
      return form.setError("from-amount", {
        message: "Amount is more than balance in wallet",
      });
    }
    dispatch(
      updateConvert({
        rate: convertDetail.rate,
        receiveAmount: convertDetail.receiveAmount,
        sendAmount: convertDetail.sendAmount,
        fromCurrency: currencyFrom as CurrencyType,
        toCurrency: currencyTo as CurrencyType,
      })
    );
    navigate(`${PATHS.CONVERT_MONEY_CONFIRM}`);
  };

  return (
    <div className="w-full justify-center items-center font-inter  pt-[80px] pl-6 pr-6">
      <ConvertMoneyHeader
        step={1}
        onClose={() => {
          dispatch(resetConvertData());
          navigate("/dashboard");
        }}
      />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-center">
            <div className="w-full sm:max-w-lg md:max-w-lg mt-16">
              <InputAmount
                form={form as any}
                currencies={filteredCurrencies}
                minAmount={0}
                header={ConvertMoneyInputHeader}
                formKey={
                  {
                    "from-currency": "",
                    "from-amount": 0,
                  } as Partial<FormType>
                }
              />
              <div className="flex gap-2 items-center px-8 relative">
                <span className="border-[#FAFAFA] h-24 w-2 bg-[#FAFAFA]"></span>
                <div className="absolute flex gap-2 left-[20px] items-center">
                  <div className="bg-[#FAFAFA] p-[0.3rem] rounded-full">
                    <img
                      src={addition}
                      alt="subtract icon"
                      className="w-4 h-4"
                    />
                  </div>
                  <GetShimmer loading={loading}>
                    <p className={`text-shiga-gray text-[14px]`}>
                      <span className="text-shiga-black font-medium">0</span>{" "}
                      Conversion fee
                    </p>
                  </GetShimmer>
                </div>
              </div>
              <div className="flex gap-2 items-center px-8 relative">
                <span className="border bg-[#FAFAFA]"></span>
                <div className={`absolute flex gap-2 left-[20px] items-center`}>
                  <div className="bg-[#FAFAFA] p-[0.3rem] rounded-full">
                    <img src={equal} alt="subtract icon" className="w-4 h-4" />
                  </div>
                  <GetShimmer loading={loading}>
                    <p className={`text-shiga-gray text-[14px]`}>
                      <span className="text-shiga-black font-medium">
                        {currencyFrom
                          ? currencyFormat(amount, currencyFrom)
                          : ""}
                      </span>{" "}
                      Amount we’ll convert
                    </p>
                  </GetShimmer>
                </div>
              </div>
              <div className="flex gap-2 items-center px-8 relative">
                <span className="border=[#FAFAFA] w-2 h-24 bg-[#FAFAFA]"></span>
                <div className="absolute flex gap-2 left-[20px] items-center">
                  <div className="bg-[#FAFAFA] p-[0.3rem] rounded-full">
                    <img
                      src={substract}
                      alt="subtract icon"
                      className="w-4 h-4"
                    />
                  </div>
                  {
                    <GetShimmer loading={loading}>
                      <p className={`text-shiga-gray text-[14px]`}>
                        <span className="text-shiga-black font-medium">
                          {convertDetail.rate}
                        </span>{" "}
                        Exchange rate
                      </p>
                    </GetShimmer>
                  }
                </div>
              </div>

              <InputAmount
                form={form as any}
                currencies={filteredCurrencies}
                disableAmount
                minAmount={0}
                formKey={
                  {
                    "to-currency": "",
                    "to-amount": 0,
                  } as Partial<FormType>
                }
              />

              <ButtonLoader
                type="submit"
                className="btn bg-shiga-black text-white btn-lg btn-block mt-11"
                disabled={amount < 1 || !isToAmountUpdated}
              >
                Continue
              </ButtonLoader>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default ConvertMoney;
