import { Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "assets/images/icons/logo-black.svg";
import ImgDashboard from "assets/images/bgs/Dashboard.png";
import URLBanner from "components/common/URLBanner";
import Footer from "components/common/Footer";
import useLoginInfo from 'hooks/useLoginInfo';


const RegisterLayout = () => {
    const { userLogin } = useLoginInfo();
    const navigate = useNavigate()
    return (

        <div className="min-h-screen flex flex-col justify-between">
            <URLBanner />

            <div className="w-full flex-grow md:flex md:justify-between">
                <div className="w-full md:w-[28rem] lg:w-[36rem] bg-[#FAFAFA] pt-10 md:fixed md:z-[1] top-0 left-0">
                    <RegisterLeftlInfo />
                </div>
                <div className="w-full md:ml-[28rem] lg:ml-[36rem] flex flex-col justify-between">
                    <div className="w-full h-full mb-auto px-8 sm:px-10 pt-14 md:pt-28 pb-20 flex items-center justify-center">
                        <div className="w-full max-w-md">
                            <Outlet />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </div>
    );
}


const RegisterLeftlInfo = () => {
    return (
        <div className="md:min-h-screen flex flex-col justify-between">
            <div className="pt-6 md:pt-16 pb-14 md:pb-10 px-8 md:pl-20 md:pr-16">
                <Link to="/" className="w-10 h-10 inline-block flex-shrink-0">
                    <img src={logo} alt="logo" className="w-full h-full object-contain" />
                </Link>

                <div>
                    <div className="mt-10 text-4xl font-ojah font-bold">
                        Empowering your
                        <br />
                        financial horizon.
                    </div>

                    <div className="mt-10 space-y-6">
                        <RegisterLeftlInfoCheckbox
                            text="Set up your business account in 3 simple steps."
                        />
                        <RegisterLeftlInfoCheckbox
                            text="Built for payments, tuition Merchant businesses, tuition agents and food businesses shipping abroad."
                        />
                        <RegisterLeftlInfoCheckbox
                            text="Integrate with developer-friendly APIs or choose low-code or pre-built solutions."
                        />
                    </div>
                </div>
            </div>
            <div className="hidden md:flex flex-grow justify-end items-end">
                <img src={ImgDashboard} alt="Dashboard" className="w-full h-full" />
            </div>
        </div>
    )
}


const RegisterLeftlInfoCheckbox = (props: any) => {
    const { text } = props;
    return (
        <div className="flex justify-start">
            <div className="w-10 flex-shrink-0">
                <FontAwesomeIcon icon="check-circle" className="text-[#99999C] text-xl" />
            </div>
            <div className="text-[#757484] text-sm">
                {text}
            </div>
        </div>
    )
}


export default RegisterLayout;
