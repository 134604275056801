import CenterModal from "components/common/CenterModal";
import React, { SetStateAction, useState } from "react";
import { Transaction } from "types/transaction";
import { ReactComponent as IconOption } from "assets/images/icons/Option.svg";
import CustomNotePad from "components/custom/CustomNotePad";
import useInlineRouter from "components/common/useInlineRouter";
import OrderDetail from "./OrderDetail";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { Menu } from "@headlessui/react";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconMap } from "assets/images/icons/map.svg";
import Maybe from "components/common/Maybe";
import { ReactComponent as ProductImg } from "assets/images/icons/ProductsIcons.svg";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import Dropdown from "utils/dropdown";

type Props = {
  open: boolean;
  closeModal: () => void;
  order: any;
};

const apiTrxNavItems: InlineRouterNavItemProps[] = [
  {
    title: "More Details",
    navValue: "details",
  },
  {
    title: "Shipping Info",
    navValue: "shipping",
  },
];

const OrderDetailModal = (props: Props) => {
  const { order, open, closeModal } = props;
  //   const { InlineRouter, activeTab } = useInlineRouter(
  //     apiTrxNavItems[0]?.navValue
  //   );
  const { OrderDetailRouter, activeTab } = useOrderDetailRouter(
    apiTrxNavItems[0].navValue
  );
  const [noteValue, setNoteValue] = useState("");
  const [optionShown, setOptionShown] = useState(false);

  return (
    <Dialog open={open} onOpenChange={() => closeModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Order Details</DialogTitle>
        </DialogHeader>
        <div>
          <div className="my-8">
            <div className="flex items-center">
              <div className="w-10 h-10 mr-4 bg-gray-200 rounded-2xl">
                {/* <IconGTBank className="w-full h-full" /> */}
                <ProductImg />
              </div>
              <div className="w-full flex justify-between pt-2">
                <div className="">
                  <div className="font-medium ">
                    {order?.productName ?? "-"}
                  </div>
                  <p className="text-[#99999C] ">Purchased</p>
                </div>
                <div className=" flex flex-col justify-end ">
                  <div className="font-medium text-right ">
                    {order?.amount ?? "-"} {order?.currency ?? "-"}
                  </div>
                  <p className="text-[#99999C] ">
                    {order?.dateCreated} at {order?.time}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 flex-btw pb-3 pt-2 relative">
            <div className="pb-3">
              <OrderDetailRouter navItems={apiTrxNavItems} />
            </div>
            <div
              onClick={() => setOptionShown(!optionShown)}
              className={`cursor-pointer ${
                order.status === "Dispatched" ? "cursor-not-allowed" : ""
              } `}
            >
              {/* <IconOption /> */}
              <Dropdown
                dropdownClass="dropdown-right"
                rotate
                disabled={order.status !== "Dispatched"}
              >
                <></>
                <div>
                  <Menu.Item
                    as="div"
                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                    //   onClick={() => handleShowDelete(product)}
                  >
                    <IconMap />
                    Track Order
                  </Menu.Item>
                  <Menu.Item
                    as="div"
                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                    //   onClick={() => handleShowDelete(product)}
                  >
                    <IconClose />
                    Cancel Order
                  </Menu.Item>
                </div>
              </Dropdown>
              {/* {optionShown && (
                
              )} */}
            </div>
          </div>

          <div className="p-3.5 border border-shiga-gray-50 rounded-xl">
            <OrderDetail activeTab={activeTab} order={order} />
          </div>
          <CustomNotePad
            noteValue={noteValue}
            setNoteValue={setNoteValue}
            noteIsRequired={false}
            notePadLeftLabel="Add a Note"
            notePadPlaceholder="Add a few notes to help you later"
          />
          <Separator className="my-8" />

          <div className="flex justify-between">
            <Button
              className={`bg-[#FFEEE8] text-[#FF4E4E] px-12 ${
                order.status === "Dispatched" ? "w-full" : ""
              } `}
            >
              Cancel Order
            </Button>
            {order?.status !== "Dispatched" && (
              <Button className="bg-[#00BA67] px-12">Mark as sent</Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

type NavItem = {
  title: string;
  navValue: string;
};

type OrderDetailRouterProps = {
  navItems: NavItem[];
};
export const useOrderDetailRouter = (defaultTabValue: string) => {
  const [activeTab, setActiveTab] = useState(defaultTabValue);

  const OrderDetailRouter: React.FC<OrderDetailRouterProps> = ({
    navItems,
  }) => (
    <div className="flex items-center space-x-4">
      {navItems.map(({ title, navValue }, index) => {
        const isActive = activeTab === navValue;

        return (
          <div key={index} className="relative">
            <button
              onClick={() => setActiveTab(navValue)}
              className={`${
                isActive ? "text-black font-[500]" : "text-gray-500"
              } font-ojah`}
            >
              {title}
              <Maybe condition={isActive}>
                <div className="absolute left-0 w-full bg-black h-[2px] -bottom-[3px]" />
              </Maybe>
            </button>
          </div>
        );
      })}
    </div>
  );

  return { OrderDetailRouter, activeTab };
};

export default OrderDetailModal;
