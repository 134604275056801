import { currencyFormat } from "lib/format.amount";
import { formatDate } from "lib/format.date";
import { findInvoice } from "lib/payments/invoices";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CurrencyType } from "types/misc";
import { ReactComponent as IconEllipsisHorizontal } from "assets/images/icons/ellipsis-horizontal.svg";
import { Invoice, defaultInvoice } from "types/payment/invoice";
import { Separator } from "components/ui/separator";
import { ArrowLeft } from "lucide-react";
import { Button } from "components/ui/button";
import useBackButton from "hooks/useBackButton";
import { Badge } from "components/ui/badge";
import { removeUnderscore } from "lib/remove-underscore";
import { useAppSelector } from "store/hooks";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { ReviewItem } from "../forms/components/ReviewItem";
type Props = {
  invoice: Invoice;
  invoiceRef: RefObject<HTMLDivElement>;
};
export const InvoiceFile: React.FC<Props> = ({
  invoice,
  invoiceRef,
}: Props) => {
  const {
    userInfo: { businessProfile },
  } = useAppSelector(getAuthPersist);

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  //   useEffect(() => {
  //     fetchInvoices();
  //   }, [fetchInvoices]);

  useEffect(() => {
    if (invoice?.id) {
      findInvoice(invoice?.id)
        .then((res) => {
          const date = new Date();
          setSelectedInvoice({
            ...res.data,
          });
        })
        .catch((error) => {});
    }
  }, [invoice.id]);

  const total = useMemo(() => {
    return (
      selectedInvoice?.items.reduce(
        (acc, item) => acc + item.price * item.qty,
        0
      ) || 0
    );
  }, [selectedInvoice]);

  const additionalCalc = useMemo(
    () => ({
      discount: total * ((selectedInvoice?.discount || 0) / 100),
      vat: total * ((selectedInvoice?.vat || 0) / 100),
    }),
    [selectedInvoice?.discount, selectedInvoice?.vat, total]
  );
  const containerClass = "w-full mx-auto px-12";

  return (
    <div ref={invoiceRef}>
      <div className="w-[650px] mx-auto rounded-2xl border border-neutral-100">
        <div className="h-[230px] w-full rounded-xl flex-col justify-start items-start flex  ">
          <div className="self-stretch px-4 pt-6 pb-5 bg-neutral-50 border-b border-neutral-100 justify-start items-center gap-3 inline-flex">
            {selectedInvoice?.imageUrl.length ? (
              <img
                alt="invoice"
                className="w-10 h-10 rounded"
                src={selectedInvoice?.imageUrl}
              />
            ) : (
              <></>
            )}
            {selectedInvoice?.invoiceNumber ? (
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                    INVOICE NO
                  </div>
                  <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                    {selectedInvoice?.invoiceNumber}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start gap-1 flex">
                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                  INVOICE DATE
                </div>
                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                  {`${formatDate(
                    selectedInvoice?.startDate ?? ""
                  )} - ${formatDate(selectedInvoice?.endDate ?? "")}`}
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch px-4 py-4 rounded-bl-xl rounded-br-xl justify-start items-start gap-6 inline-flex">
            {/* <div className="grow shrink basis-0 flex-col justify-start items-start gap-[13px] inline-flex">
                            <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                                <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter']">
                                    Business Address
                                </div>
                                <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                                </div>
                            </div>
                        </div> */}
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-[13px] inline-flex ">
              <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter']">
                  Business Address
                </div>
                <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                  {businessProfile?.address}
                </div>
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter']">
                  Billing Address
                </div>
                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                  {selectedInvoice?.billingAddress?.address}
                  <br />
                  {selectedInvoice?.billingAddress?.postCode}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[445px] flex-col w-full justify-start items-start flex">
          <div className="self-stretch px-4 py-3 bg-neutral-50 border-b border-neutral-100 justify-start items-start gap-3 inline-flex">
            <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                Items
              </div>
            </div>
            <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                Cost
              </div>
            </div>
            <div className="w-20 flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                QUANTITY
              </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
              <div className="text-right text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                PRICE
              </div>
            </div>
          </div>
          <div className="self-stretch h-[406px] px-4 py-9 rounded-bl-xl rounded-br-xl flex-col justify-start items-start gap-6 flex">
            {selectedInvoice?.items.map((item: any, idx: any) => (
              <ReviewItem
                key={idx}
                item={item}
                invoiceCurrency={selectedInvoice.currency as CurrencyType}
              />
            ))}
            {/* <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" /> */}
            <Separator />

            <div className="self-stretch h-[136px] flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`Subtotal (${selectedInvoice?.items.length})`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(
                      total || 0,
                      selectedInvoice?.currency || "NGN"
                    )}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`Discount (${selectedInvoice?.discount}%)`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(
                      additionalCalc.discount,
                      selectedInvoice?.currency || "NGN"
                    )}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {`VAT (${selectedInvoice?.vat}%)`}
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                    {currencyFormat(
                      additionalCalc.vat,
                      selectedInvoice?.currency || "NGN"
                    )}
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 self-stretch" />
                <div className="grow shrink basis-0 py-0.5 flex-col justify-start items-start gap-2.5 inline-flex" />
              </div>
              <div className="flex justify-end items-end w-full">
                <Separator className=" w-[63%]" />
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                    Product Name
                  </div>
                </div>
                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                    Total
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                  <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                    2
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                    {currencyFormat(
                      total + additionalCalc.vat - additionalCalc.discount,
                      selectedInvoice?.currency || "NGN"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Separator />
            {/* <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" /> */}
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex ">
                  <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                    Note
                  </div>
                  <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                    {selectedInvoice?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
