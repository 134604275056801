import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import WalletIcon from "assets/images/icons/Wallet";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";

import { Input } from "components/ui/input";
import { useNavigate } from "react-router-dom";
import { useSharedStore } from "zustand-store";
import { useGiftCardStore } from "zustand-store/payments/gift-cards";
import { PAYMENT_PATHS } from "../paths";
import InputAmount from "components/common/form/InputAmount";
import TextArea from "components/common/form/TextArea";
import { CurrencyType } from "types/misc";
import { currencyFormat } from "lib/format.amount";
import FormHeader from "components/common/form/FormHeader";
import { Checkbox } from "components/common/form";
import { useAppSelector } from "store/hooks";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { useEffect, useState } from "react";

const AmountHeader: React.FC<{
  balance: number;
  currency: CurrencyType
}> = ({ balance, currency }) => {
  return (
    <FormLabel className="grid grid-cols-2">
      <div className="w-[159px]">
        <span className="text-zinc-400 text-sm font-normal font-['Inter']">
          Amount
        </span>
      </div>
      <div className="flex items-center space-x-1">
        <WalletIcon />
        <div className="flex text-right flex-nowrap whitespace-nowrap">
          <span className="text-neutral-400 text-sm font-normal font-['Inter']">
            You have
          </span>
          <div className="overflow-hidden mx-1">
            &nbsp;
            <span className="text-indigo-500 text-sm font-medium font-['Inter'] w-full inline-flex overflow-ellipsis">
              {(balance && currency) ? currencyFormat(balance, currency) : "0.00"}
            </span>
            &nbsp;
          </div>
          <span className="text-neutral-400 text-sm font-normal font-['Inter']">
            available
          </span>
        </div>
      </div>
    </FormLabel>
  )
}


const FormSchema = z.object({
  amount: z.coerce.number().optional(),
  currency: z.string({ message: "currency is required" }),
  email: z.string().email(),
  note: z.string().optional(),
  use_registered_email: z.boolean(),
});

const GiftCardTab: React.FC<{
  selected: boolean;
  label: string;
  onClick: () => void
}> = ({ selected, label, onClick }) => (
  <div onClick={onClick} className={`cursor-pointer rounded-2xl p-4 border-2 flex items-center justify-center ${selected ? "border-shiga-black" : "border-shiga-dark"}`}>
    <p className={`text-[20px] font-medium ${selected ? "text-shiga-black" : "text-shiga-dark"}`}>
      {label}
    </p>
  </div>
)

export function PaymentInfo() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      currency: "",
      note: "",
      use_registered_email:false
    },
  });
  const navigate = useNavigate();
  const [currentGiftCard, setCurrentGiftCard] = useState(0)
  const { setCompleted, payload, setPayload } = useGiftCardStore();
  const [balance, setBalance] = useState(0);
  const { currencies } = useSharedStore();

  const useRegsiteredMail = form.watch("use_registered_email");
  const currency = form.watch("currency") as CurrencyType;
  const { userInfo: { userProfile } } = useAppSelector(getAuthPersist);

  useEffect(() => {
    form.setValue("amount", payload.amount)
    form.setValue("currency", payload.giftCard.recipientCurrencyCode)
    form.setValue("email", payload.email)
    form.setValue("note", payload.note)
    form.setValue("use_registered_email", payload.use_registered_email)
  }, [payload, form])

  useEffect(() => {
    if (useRegsiteredMail) {
      form.setValue("email", userProfile?.email || "");
    } else {
      form.setValue("email", "");
    }
  }, [form, useRegsiteredMail, userProfile?.email])


  function onSubmit(data: z.infer<typeof FormSchema>) {
    setPayload({
      ...payload,
      ...data,
      note: data.note || "",
      amount: payload.giftCard.denominationType === "FIXED" ? currentGiftCard : (data.amount ||0)
    })
    setCompleted("payment_info");
    navigate(PAYMENT_PATHS.GIFT_CARD_PURCHASE + "?tab=review_send");
  }

  return (
    <div className="w-full flex flex-col items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <div className="mt-12 mb-7 pb-6 border-b border-outline-grey text-[32px]">
            <FormHeader>
              {`${payload.giftCard.brand.brandName} giftcard`}
            </FormHeader>
          </div>
          {
            payload.giftCard.denominationType === "FIXED" ? <div className="space-y-6">
              <AmountHeader {...{ balance, currency }} />
              <div className="grid grid-cols-3 gap-4">
                {payload.giftCard.fixedRecipientDenominations
                  .map((item) => <GiftCardTab selected={currentGiftCard === item}
                    onClick={() => {
                      setCurrentGiftCard(item)
                    }} label={currencyFormat(item,payload.giftCard.recipientCurrencyCode)} />)}
              </div>
            </div> : <></>
          }
          <div className={payload.giftCard.denominationType === "FIXED" ? "hidden" : ""}>
            <InputAmount currencies={currencies} disableCurrency form={form as any}
              minAmount={0} header={({ currentWallet }) => {
                setBalance(currentWallet.balance)
                return (<AmountHeader
                  balance={currentWallet.balance} currency={currentWallet.currency} />)
              }} />
          </div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <div className="flex w-full justify-between">
                    <span>Recipient Email</span>
                    <FormField
                      control={form.control}
                      name="use_registered_email"
                      render={({ field }) => (
                        <Checkbox
                          type="checkbox"
                          label={"Use registered email"}
                          containerClass="!items-start text-shiga-gray text-sm"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </FormLabel>
                <FormControl>
                  <Input disabled={useRegsiteredMail} placeholder="example@mail.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <TextArea form={form as any} />
          <Button className="w-full" type="submit">
            Continue
          </Button>
        </form>
      </Form>
    </div>
  );
}
