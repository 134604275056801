import { useDispatch, useSelector } from "react-redux";
import store from "store/store";
import * as mediaServiceActions from "store/ui/mediaService/action";


const useMediaService = () => {
    const dispatch = useDispatch();
    const { uploadedFileLoading, uploadedFile } = useSelector((s) => s.ui.mediaService);


    const uploadFile = async (file) => {

        let data = new FormData();
        data.append("file", file);
        await dispatch(mediaServiceActions.uploadFile(data))

        const uploadResponse = store.getState().ui.mediaService.uploadedFile;
        return uploadResponse;
    }


    // eslint-disable-next-line no-unused-vars
    const uploadMultipleFiles = async (files) => {
        // loop files[]
        // files[0] files[1] files[2]
    }


    return {
        uploadFile,
        uploadedFileLoading,
        uploadedFile,
    };
}


export default useMediaService;
