import * as actions from "./actionTypes";
import * as config from "data/config";

export function uploadFile(data: FormData) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiFileUpload,
            method: "post",
            data,
            onStart: actions.FILE_UPLOAD_START,
            onSuccess: actions.FILE_UPLOAD,
            onError: actions.FILE_UPLOAD_FAILED,
        },
    }
}
