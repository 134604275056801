import React, { useEffect, useState } from "react";
import { products, ProductType } from "./Products";
import BackBtnIcon from "assets/images/icons/BackBtn";
import { useNavigate } from "react-router-dom";
import ProductOrderTable from "./table/ProductOrderTable";
import { orders } from "./Orders";
// import EmptyOrder from "assets/images/icons/empty-order.svg";
import { ReactComponent as EmptyOrder } from "assets/images/icons/empty-order.svg";
import EditProduct from "./form/EditProduct";
import { Separator } from "components/ui/separator";
import { ReactComponent as IconEllipsisHorizontal } from "assets/images/icons/ellipsis-horizontal.svg";
import { Button } from "components/ui/button";
import { Switch } from "components/ui/switch";

const ViewProduct = () => {
  const navigate = useNavigate();
  const id = sessionStorage.getItem("view-product");
  const [product, setProduct] = useState<ProductType[]>([]);

  useEffect(() => {
    if (id) {
      const filteredProduct = products.data.payload.filter(
        (product) => product.name.toString() === id
      );
      setProduct(filteredProduct);
    }
  }, [id]);

  const filteredProductOrders = orders.data.payload.filter(
    (order) => order.productName === product[0]?.name
  );
  const goBack = () => {
    navigate("/ojah/products");
  };

  return (
    <div>
      <header className="flex justify-between items-center">
        <BackBtnIcon className="cursor-pointer" onClick={goBack} />

        <div className="flex justify-end items-center">
          <div className="mr-8 flex items-center">
            <p className="text-zinc-800 text-[15px] font-medium font-['Inter'] mr-4">
              Status
            </p>
            <Switch
              checkedBg="bg-[#00C46C]"
              checked={product[0]?.status === "Outgoing"}
              // onCheckedChange={() => toggleStatus(index)}
            />
          </div>
          <Button
            variant="ghost"
            className="flex bg-[#f5f5f5] size-8 p-0 data-[state=open]:bg-muted"
          >
            <IconEllipsisHorizontal className="size-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </div>
      </header>
      <Separator className="mt-6 mb-4" />

      <div>
        <EditProduct product={product[0]} />
      </div>

      <Separator className="mt-2 mb-12" />

      {filteredProductOrders.length < 1 ? (
        <div className="empty_state flex flex-col justify-center items-center self-stretch pt-[5.75rem] pb-12 px-0 bg-white">
          <div className="side-bar-icons flex justify-center items-center p-6">
            <EmptyOrder />
          </div>
          <div className="text-container flex flex-col justify-center items-center w-[400px]">
            <div className="text-content flex flex-col justify-center items-center self-stretch">
              <div className="no-accounts-text self-stretch text-[#343433] text-center  text-[1.625rem] leading-normal">
                No Orders yet
              </div>
              <div className="no-accounts-label self-stretch text-[#99999c] text-center font-['Inter'] leading-normal">
                You have not received any orders for this product yet
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ProductOrderTable orders={orders} product={product} />
      )}
    </div>
  );
};

export default ViewProduct;
