export const FormHeader: React.FC<{
    children: React.ReactNode;
    className?: string;
}> = ({ children, className }) => {
    return (
        <div className="mt-12 mb-7 pb-6 border-b border-outline-grey text-[32px]">
            <h1 className={`font-ojah text-[32px] font-bold ${className}`}>
                {children}
            </h1>
        </div>
    )
}

export default FormHeader