import { CurrencyType } from "types/misc";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function getTVServices() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTVService,
            method: "get",
            data: {},
            onStart: actions.GET_TV_SERVICES_START,
            onSuccess: actions.GET_TV_SERVICES,
            onError: actions.GET_TV_SERVICES_FAILED,
        },
    };
}


export function getInternetServices() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetInternetService,
            method: "get",
            data: {},
            onStart: actions.GET_INTERNET_SERVICES_START,
            onSuccess: actions.GET_INTERNET_SERVICES,
            onError: actions.GET_INTERNET_SERVICES_FAILED,
        },
    };
}

export function getAirtimeServices() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAirtime,
            method: "get",
            data: {},
            onStart: actions.GET_AIRTIME_SERVICES_START,
            onSuccess: actions.GET_AIRTIME_SERVICES,
            onError: actions.GET_AIRTIME_SERVICES_FAILED,
        },
    };
}

export function getElectricityServices() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetElectricity,
            method: "get",
            data: {},
            onStart: actions.GET_ELECTRICITY_SERVICES_START,
            onSuccess: actions.GET_ELECTRICITY_SERVICES,
            onError: actions.GET_ELECTRICITY_SERVICES_FAILED,
        },
    };
}


export function getDataPlan(network: string) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetDataPlans}/${network}`,
            method: "get",
            data: {},
            onStart: actions.GET_DATA_PLAN_START,
            onSuccess: actions.GET_DATA_PLAN,
            onError: actions.GET_DATA_PLAN_FAILED,
        },
    };
}

export function getTVPackages(cableProvider: string) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetTVPackages}/${cableProvider}`,
            method: "get",
            data: {},
            onStart: actions.GET_CABLE_PACKAGES_START,
            onSuccess: actions.GET_CABLE_PACKAGES,
            onError: actions.GET_CABLE_PACKAGES_FAILED,
        },
    };
}
