import * as actions from "./actionTypes";
import * as config from "data/config";

export function getIndustries() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetIndustries,
            method: "get",
            data: {},
            onStart: actions.GET_INDUSTRIES_START,
            onSuccess: actions.GET_INDUSTRIES,
            onError: actions.GET_INDUSTRIES_FAILED,
        },
    }
}
