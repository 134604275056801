import React, { Dispatch, MutableRefObject, SetStateAction } from "react";

interface OrdersContextType {
   transferType: string;
   setTransferType: Dispatch<SetStateAction<string>>;

   singleTrfStep: number;
   setSingleTrfStep: Dispatch<SetStateAction<number>>;

   bulkTrfStep: number;
   setBulkTrfStep: Dispatch<SetStateAction<number>>;

   incrementSingleTrfStep: () => void;
   decrementSingleTrfStep: () => void;
   goToSingleTrfStep: (e: number) => void;
   businessVerificationRef: MutableRefObject<any>;
   isSingleTransfer: boolean;
   isBulkTransfer: boolean;

   transferWallet: any;
   trfCurrency: string;
   beneficiaries: any;
   incrementBulkTrfStep: () => void;
   decrementBulkTrfStep: () => void;
   navigateBulkTrfStep: (e: number) => void;

   navigateSingleTrfStep: (step: number) => void;

   ngnForm: any;
   setNgnForm: Dispatch<SetStateAction<any>>;

   usdForm: any;
   setUsdForm: Dispatch<SetStateAction<any>>;

   gbpForm: any;
   setGbpForm: Dispatch<SetStateAction<any>>;

   audForm: any;
   setAudForm: Dispatch<SetStateAction<any>>;

   formattedWalletBalance: string;
   currencyFlag: string;

   transferAmount: string;
   setTransferAmount: Dispatch<SetStateAction<string>>;

   transferFee: string;
   setTransferFee: Dispatch<SetStateAction<string>>;
   trfPin: string;
   setTrfPin: Dispatch<SetStateAction<string>>;

   twoFacPromptOpen: boolean;
   twofacSetupOpen: boolean;
   twoFacInputOpen: boolean;

   toggleTwoFacPrompt: () => void;
   toggleTwoFacSetup: () => void;
   toggleTwoFacInput: () => void;

   accountName: string;
   setupTwoFac: () => void;
   transferNote: string;
   setTransferNote: Dispatch<SetStateAction<string>>;
   beneficiaryQuery: string;
   setBeneficiaryQuery: Dispatch<SetStateAction<string>>;

   summedAmount: string;
   setSummedAmount: Dispatch<SetStateAction<string>>;

   isNgnTransfer: boolean;
   isUsdTransfer: boolean;
   isGbpTransfer: boolean;
   isAudTransfer: boolean;

   saveTrfBeneficiary: boolean;
   setSaveTrfBeneficiary: Dispatch<SetStateAction<boolean>>;

   endSingleTransferSession: () => void;
   setTrfModalOpen: Dispatch<SetStateAction<boolean>>;
   isBulkPreviewPage: boolean;
};

const TransfersContext = React.createContext<OrdersContextType | null>(null)

export default TransfersContext