import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Maybe from "components/common/Maybe";
import {ReactComponent as IconLink } from "assets/images/icons/Link.svg";
import {ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import * as preferencesActions from "store/ui/preferences/action";


const URLBanner = () => {
    const dispatch = useDispatch();
    const { isUrlBannerHidden } = useSelector((s: any) => s.ui.preferences);
    const url = "https://business.payshiga.com";
    
    const hideUrlBanner = () => {
        dispatch(preferencesActions.hideUrlBanner(true));
    }

    return (
        <Maybe condition={isUrlBannerHidden === false}>
            <div className="w-full py-3 text-white bg-shiga-purple relative z-[2]">
                <div className="px-2 sm:px-6 text-sm sm:text-base flex items-center justify-between">
                    <div></div>
                    <div className="flex flex-wrap items-center justify-center relative">
                        <IconLink className="mr-1 relative -top-px" />
                        <span className="mr-1">Confirm if this URL is</span>
                        <Link to={url} className="relative top-px whitespace-nowrap border-b-2 border-white border-opacity-35 hover:border-transparent transition-all hover:px-3 hover:pt-1.5 hover:pb-1 hover:text-shiga-purple hover:bg-white hover:rounded-xl">
                            {url}
                        </Link>
                    </div>
                    <IconClose onClick={hideUrlBanner} className="hover:bg-black hover:bg-opacity-30 rounded-sm cursor-pointer" />
                </div>
            </div>
        </Maybe>
    )
}

export default URLBanner