import React, { ChangeEvent } from 'react'

type NotePadProps = {
   notePadMaxLength?: number;
   notePadLeftLabel: string;
   notePadPlaceholder: string;
   noteIsRequired: boolean;
   notePadHeight?: string;
   noteValue: string;
   setNoteValue: React.Dispatch<React.SetStateAction<string>>
}

const CustomNotePad = ({
   notePadMaxLength = 200,
   notePadLeftLabel,
   notePadPlaceholder,
   noteIsRequired,
   notePadHeight,
   noteValue,
   setNoteValue,

}: NotePadProps) => {

   const updateNotePad = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      if (value?.length <= notePadMaxLength) setNoteValue(value)
   };

   return (
      <div className="w-full">

         <div className="w-full flex mt-8 justify-between text-sm">
            <p className="text-shiga-gray-100">{notePadLeftLabel}
               {noteIsRequired && <span className="form-input-required">*</span>}
            </p>

            <p>{noteValue?.length}/{notePadMaxLength}</p>
         </div>

         <div className="w-full mt-2 text-shiga-gray">
            <textarea
               maxLength={200}
               value={noteValue}
               onChange={updateNotePad}
               placeholder={notePadPlaceholder}
               style={{ height: notePadHeight || '80px', resize: 'none' }}
               className="w-full border-0 py-3 px-5 rounded-2xl  bg-shiga-gray-40 text-shiga-dark-100 placeholder:text-shiga-gray-100 placeholder:font-normal font-medium"
            />
         </div>

      </div>
   )
}

export default CustomNotePad