import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ButtonLoader, Input } from "components/common/form";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import { ReactComponent as IconInfo } from "assets/images/icons/Info.svg";
import * as userActions from "store/auth/user/action";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgotPasswordLoading, forgotPassword } = useSelector(
    (s: any) => s.auth.user
  );

  const [form, setForm] = useState({
    email: "",
  });

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = validate(form);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      dispatch(userActions.forgotPassword(form));
    }
  };

  useEffect(() => {
    if (forgotPassword?.success === true) {
      navigate("/reset-password");
    }
    // eslint-disable-next-line
  }, [forgotPassword]);

  return (
    <>
      <DynamicHeadTag headerText="Forgot Password" />

      <AuthPageTitle
        title="Reset your password"
        description="Enter the email linked to your account. We will send you instructions to reset your password."
        showBackButton={true}
        backButtonUrl="/login"
      />

      <div>
        <form onSubmit={submitForm}>
          <div className="mt-10">
            <Input
              type="email"
              label="Email Address"
              placeholder="Email Address"
              required={true}
              value={form.email}
              onChange={(e: any) => setForm({ ...form, email: e.target.value })}
            />

            <div className="relative -top-3 text-sm flex justify-end">
              <IconInfo />
              <span className="text-shiga-gray">
                Use your registered business email address
              </span>
            </div>

            <ButtonLoader
              type="submit"
              loading={forgotPasswordLoading}
              className="btn btn-lg btn-block btn-primary mt-8"
            >
              Reset Password
            </ButtonLoader>

            <div className="mt-4 flex justify-center text-sm text-shiga-gray">
              <span className="mr-1">New to Shiga Business?</span>
              <Link
                to="/register"
                className="text-shiga-purple font-medium hover-border hover:border-shiga-purple"
              >
                Create an Account
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
