interface WalletIconProps extends React.BaseHTMLAttributes<SVGElement> {}

const WalletIcon = ({ ...props }: WalletIconProps) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.44232 0.193359C1.57388 0.193359 0.869873 0.897368 0.869873 1.76581V9.23493C0.869873 10.1034 1.57388 10.8074 2.44232 10.8074H11.5625C12.431 10.8074 13.135 10.1034 13.135 9.23493V4.43897C13.135 3.57053 12.431 2.86652 11.5625 2.86652H2.95337C2.58428 2.86652 2.28508 2.56732 2.28508 2.19823C2.28508 1.82914 2.58428 1.52994 2.95337 1.52994H10.8942C11.2633 1.52994 11.5625 1.23074 11.5625 0.86165C11.5625 0.492563 11.2633 0.193359 10.8942 0.193359H2.44232ZM10.6191 7.58386C11.1401 7.58386 11.5625 7.16146 11.5625 6.6404C11.5625 6.11933 11.1401 5.69693 10.6191 5.69693C10.098 5.69693 9.67558 6.11933 9.67558 6.6404C9.67558 7.16146 10.098 7.58386 10.6191 7.58386Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default WalletIcon;
