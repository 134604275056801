import { RootState } from 'store/store'
import { ReactComponent as IconInfo } from "assets/images/icons/custom-toast-info.svg";
import useToolkit from 'hooks/useToolkit';
import { hideIdleTimer } from 'store/auth/user/action';
import { Countdown } from 'components/common/Countdown';
import { useEffect, useState } from 'react';
import useLoginInfo from 'hooks/useLoginInfo';

type Props = {}

const IdleCheckerModal = (props: Props) => {
   const { dispatch, useSelector } = useToolkit();
   const { logoutUser } = useLoginInfo();

   const { isIdleModalOpen } = useSelector((state: RootState) => state.auth.user);
   const [timeLeft, setTimeLeft] = useState({ minutes: '' });
   const [timeIsRed, setTimeIsRed] = useState(false);

   const retainSession = () => {
      setTimeIsRed(false);
      dispatch(hideIdleTimer());
   }

   const endSession = () => {
      dispatch(hideIdleTimer());
      logoutUser();

      setTimeout(() => {
         if (isIdleModalOpen === true) {
            dispatch(hideIdleTimer());
         }
      }, 1500);
   }


   useEffect(() => {
      if (timeLeft.minutes !== '' && Number(timeLeft?.minutes) < 5 && isIdleModalOpen) {
         if (timeIsRed === false) {
            setTimeIsRed(true);
         }
      }
      // eslint-disable-next-line
   }, [timeLeft, timeIsRed])

   return isIdleModalOpen === true ? (
      <section
         onClick={() => { }}
         style={{ zIndex: "900" }}
         className='fixed top-0 left-0 right-0 bottom-0 backdrop-blur-[4px] flex items-start justify-center pt-7 min-w-full z-50 bg-[#000000] bg-opacity-20'>

         <div className="h-[130px] md:h-[110px] w-[390px] md:w-[470px] rounded-[18px] animate-customToastIn text-sm bg-shiga-dark-800 text-white font-inter p-4 pt-1">
            <div className='flex items-start'>

               <div className="mr-4 pt-px">
                  <IconInfo width={25} />
               </div>

               <p className="pt-2">
                  Your session has been idle for 5 minutes. To ensure your security, you will be logged out of this dashboard after&nbsp;

                  <span className={`${timeIsRed ? 'text-[#ff001a]' : 'text-[#f29100]'}`}>
                     <Countdown
                        minutes={5}
                        onExpiry={endSession}
                        onTimerUpdate={(time: any) => setTimeLeft(time)}
                     />m
                  </span>

                  &nbsp;of inactivity.
               </p>
            </div>


            <div className="flex items-center justify-between pl-12 mt-5">
               <p className="mb-0">
                  Do you want to continue with this current session?
               </p>

               <div className="flex items-center">
                  <button onClick={retainSession} className='font-medium text-sm bg-shiga-dark-50 px-3.5 py-1.5 rounded-xl mr-3'>
                     Yes
                  </button>

                  <button onClick={endSession} className='font-medium text-sm bg-shiga-dark-50 px-4 py-1.5 rounded-xl'>
                     No
                  </button>
               </div>

            </div>

         </div>
      </section>
   ) : null
}

export default IdleCheckerModal