import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    loadingWallets: false,
    walletList: [],
    initialWallet: {}
};


export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case actions.GET_WALLETS_AND_BALANCE:
            state = {
                ...state,
                loadingWallets: false,
                walletList: action.payload.data,
                initialWallet: action.payload.data[0]
            }
            state = {
                ...state,
                walletList: [
                    ...state.walletList,
                ] as never[]
            }
            return state;
        case actions.GET_WALLETS_AND_BALANCE_START:
            state = {
                ...state,
                loadingWallets: true,
            }
            return state;
        case actions.GET_WALLETS_AND_BALANCE_START:
            errorReducer(action.payload);
            state = {
                ...state,
                loadingWallets: false,
            }
            return state;

        default:
            return state;
    }
}