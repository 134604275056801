import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    industriesLoading: false,
    industries: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_INDUSTRIES:
            state = {
                ...state,
                industriesLoading: false,
                industries: action.payload,
            }
            return state;


        case actions.GET_INDUSTRIES_START:
            state = {
                ...state,
                industriesLoading: true,
            }
            return state;


        case actions.GET_INDUSTRIES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                industriesLoading: false,
                industries: {},
            }
            return state;


        default:
            return state;
    }
}