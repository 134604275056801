import { CurrencyType } from "./misc";

export interface DashboardWallet { "balance": number; "currency": CurrencyType; "status": string, id: string }
export interface Wallet {
    balance: number;
    status: 'UNLOCKED' | "LOCKED";
    accountId: string | null;
    businessId: string;
    createdAt: string;
    currency: CurrencyType;
    id: string;
    updatedAt: string;
}

export const defaultWallet: Wallet = {
    accountId: "",
    balance: 0,
    businessId: "",
    createdAt: "",
    currency: "",
    id: "",
    status: "LOCKED",
    updatedAt: ""
}