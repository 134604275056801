import PATHS from 'NavigationRoute';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TransHeaderItemProps {
    selected: boolean;
    title: string;
    image: string;
    image2: any;
    step?: number;
}

const TransHeaderItem: React.FC<TransHeaderItemProps> = ({ selected, title, image, image2, step }) => {
    const navigate = useNavigate()


    const selectedStep = {
        color: "#343433",
        fontSize: "13px",
        lineHeight: "20px",
        fontWeight: 500,
    };

    const notSelectedStep = {
        color: "#B4B4B4",
        fontSize: "13px",
        lineHeight: "20px",
        fontWeight: 500,
    };

    const h3Style = selected ? selectedStep : notSelectedStep;
    const imgSrc = selected ? image : image2;
    const stepValid = step && step > 1;

    const onHeaderClick = (title: string) => {
        if (step === 2) {
            if (title === 'Recipient') {
                navigate(PATHS.TRANSFER_BENEFICIARY)
            }
        } else if (step === 3) {
            if (title === 'Recipient') {
                navigate(PATHS.TRANSFER_BENEFICIARY)
            } else if (title === 'Amount to Send') {
                navigate(PATHS.TRANSFER_AMOUNT)
            }
        }
    }

    return (
        <div onClick={() => onHeaderClick(title)} className={`flex space-x-2 items-center ${stepValid ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
            <img src={imgSrc} alt={title} className='transfer_header_image' width={22} />
            <h3 style={h3Style} className="transfer_header_title whitespace-nowrap">
                {title}
            </h3>
        </div>
    );
};

export default TransHeaderItem;
