import { useNavigate } from "react-router-dom";

const useBackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
    /*
        if (prevPageUrl && isSiteDomain(prevPageUrl)){
            navigate(-1);
        }
        else{
            navigate("/");
        }
        */
  };

  return { goBack };
};

export default useBackButton;
