import React, { useEffect, useState } from "react";
import Maybe from "components/common/Maybe";
import { SelectInput, Input } from "components/common/form";
import { useSelector, useDispatch } from "react-redux";
import { transferNameEnquiry } from "store/transfer/action";
import * as banksActions from "store/ui/banks/action";
import { LoaderCircleIcon } from "lucide-react";
import useToolkit from "hooks/useToolkit";
import { useAppSelector } from "store/hooks";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { toast } from "react-toastify";

interface CurrencyRequiredFieldsProps {
  form: any;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  isOpen: boolean;
}

export const CurrencyRequiredFields = ({
  form,
  setForm,
  isOpen,
}: CurrencyRequiredFieldsProps) => {
  const dispatch = useDispatch();
  const { banksLoading, banks } = useSelector((s: any) => s.ui.banks);
  const { accountName, loadingName } = useSelector((s: any) => s.transfer);
  const {
    userInfo: { businessProfile },
  } = useAppSelector(getAuthPersist);

  // const { businessProfile, userProfile, refetchProfiles } = useToolkit();
  // console.log("businessProfile", businessProfile);
  // console.log("userProfile", userProfile);
  // console.log("refetchProfiles", refetchProfiles());

  const getBanks = () => {
    if (
      !banksLoading &&
      (Object.keys(banks).length === 0 || banks?.success === false)
    ) {
      dispatch(banksActions.getBanks());
    }
  };

  useEffect(() => {
    getBanks();
    // refetchProfiles();
  }, []);

  useEffect(() => {
    if (form.accountNumber && form.bankCode) {
      if (form.accountNumber.length === 10) {
        dispatch(transferNameEnquiry(form.accountNumber, form.bankCode));
      } else {
        setForm((prevForm: any) => ({ ...prevForm, accountName: "" }));
      }
    }
  }, [form.accountNumber, form.bankCode, dispatch]);

  // useEffect(() => {
  //   if (form.accountNumber) {
  //     setForm((prevForm: any) => ({ ...prevForm, accountName }));
  //   }
  // }, [form.accountNumber]);

  useEffect(() => {
    if (form.accountNumber.length === 10) {
      setForm((prevForm: any) => ({ ...prevForm, accountName }));
      // if (
      //   form.accountName.toLowerCase() !== businessProfile?.name.toLowerCase()
      // ) {
      //   toast.error("Account name does not match business name");
      // }
    } else {
      setForm((prevForm: any) => ({ ...prevForm, accountName: "" }));
    }
  }, [accountName, form.accountNumber, setForm]);

  //  useEffect(() => {
  //   if (isOpen && accountName) {
  //     setForm((prevForm) => ({ ...prevForm, accountName }));
  //   }
  // }, [accountName, isOpen]);

  return (
    <div>
      {form.currency === "NGN" && (
        <div>
          <Maybe condition={banksLoading === true}>
            <Input
              type="text"
              label="Bank Name"
              value="Loading banks..."
              required={true}
              disabled={true}
            />
          </Maybe>
          <Maybe condition={banksLoading === false}>
            <SelectInput
              label="Bank Name"
              required={true}
              value={form.bankCode}
              onChange={(e: { target: { value: string } }) => {
                const selectedBank = banks?.data.find(
                  (bank: { bankCode: string }) =>
                    bank.bankCode === e.target.value
                );
                setForm({
                  ...form,
                  bankCode: selectedBank?.bankCode || "",
                  bankName: selectedBank?.name || "",
                });
              }}
            >
              <option value="" disabled>
                Select a bank
              </option>
              {banks?.data &&
                banks?.data?.map((bank: any) => (
                  <option value={bank?.bankCode} key={bank?.bankCode}>
                    {bank?.name}
                  </option>
                ))}
            </SelectInput>
          </Maybe>

          <div className="mb-[26px] relative">
            <div className="flex w-full justify-between items-center">
              <span className="text-shiga-gray-100">
                Account Number <span className="form-input-required">*</span>
              </span>
              {loadingName ? (
                <LoaderCircleIcon className="absolute top-0 right-0 animate-spin" />
              ) : (
                <p className="text-sm font-medium text-shiga-black uppercase">
                  {form.accountName || ""}
                </p>
              )}
            </div>
            <Input
              type="number"
              // label="Account Number"
              placeholder="01234567890"
              value={form.accountNumber}
              maxLength={10}
              onChange={(e: any) =>
                setForm({ ...form, accountNumber: e.target.value })
              }
              required={true}
            />
          </div>
          {/* <Input
            type="name"
            label="Account Name"
            placeholder="John Doe"
            value={form.accountName || (loadingName ? "Loading..." : "")}
            readOnly
            required={true}
          /> */}
        </div>
      )}
    </div>
  );
};
