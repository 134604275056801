export const currencyFormat = (amount: number, currency: string, options?: Intl.NumberFormatOptions) => new Intl.NumberFormat("en", {
    style: "currency",
    currency,
    ...options
}).format(amount)

export const formatAmount = (amount: number) => {
    return new Intl.NumberFormat().format(amount);
}

export const removeCommasFromNumber = (formattedNumber: string) => {
    return formattedNumber.replace(/,/g, '');
}