import Joi from "joi-browser";


const schemas = {
    // generics
    genericStringRequired: Joi.string().required().error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "is a required field";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    
    
    // specifics
    firstName: Joi.string().regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/).required().label("First Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "First name is a required field";
                    break;
                case "string.regex.base":
                    err.message = `First name is invalid`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    lastName: Joi.string().regex(/^([A-Za-z]+)([-]{0,1})([A-Za-z]+)$/).required().label("Last Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Last name is a required field";
                    break;
                case "string.regex.base":
                    err.message = `Last name is invalid`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    fullName: Joi.string().min(2).required().label("Full Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                    err.message = "Fullname is a required field";
                    break;
                case "string.min":
                    err.message = "Fullname must have minimum 2 characters";
                    break;
                case "string.regex.base":
                    err.message = `Full name is invalid`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    jobTitle: Joi.string().required().label("Job Title").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Job title is a required field";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    email: Joi.string().email().required().label("Email Address").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Email address is a required field";
                    break;
                case "string.email":
                    err.message = "Email address is invalid";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    phoneNumber: Joi.string()
    // .regex(/^\+[1-9]{1}[0-9]{3,14}$/)
    .required()
    .label("Phone Number")
    .error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the phone number";
                    break;
                case "string.pattern.base":
                    err.message = "Please enter a valid phone number with country code (e.g., +1234567890)";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),

    password: Joi.string().required().label("Password").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter your password";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.allowOnly":
                    err.message = "Your passwords does not match";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    password_confirmation: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.allowOnly":
                    err.message = "Your password does not match";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    otp: Joi.string().regex(/^[0-9]{6}$/).required().label("OTP code").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = `Please enter the OTP code sent to your email address`;
                    break;
                case "string.min":
                case "string.max":
                case "string.regex.base":
                    err.message = `Please enter a valid OTP code sent to your email address`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    token: Joi.string().regex(/^[0-9]{6}$/).required().label("Token").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = `Please enter the OTP code sent to your email address`;
                    break;
                case "string.min":
                case "string.max":
                case "string.regex.base":
                    err.message = `Please enter a valid OTP code sent to your email address`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    accountType: Joi.string().required().label("Account Type").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select an account type";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid account type`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    location: Joi.string().required().label("Location").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a location";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid location`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    locationCode: Joi.string().required().label("Location").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a location code";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    countryCode: Joi.string().required().label("Country").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a location code";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    businessName: Joi.string().required().label("Business Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a business name";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid business name`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    industry: Joi.string().required().label("Industry").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select an industry";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid industry`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    companySize: Joi.string().required().label("Company Size").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a company size";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid company size`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    annualVolume: Joi.string().required().label("Annual Volume").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select an annual volume";
                    break;
                case "string.regex.base":
                    err.message = `Please select an valid annual volume`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    identityDocument: Joi.alternatives(Joi.string(), Joi.object()).required().label("Identity Document").error(errors => {
        errors.forEach(err => {
            err.context.reason?.forEach(err2 => {
                switch (err2.type) {
                    case "any.empty":
                    case "any.required":
                        err2.message = "Identity document is required for verification";
                        break;
                    case "object.base":
                        err2.message = "Identity document is required for verification";
                        break;
                    default:
                        break;
                }
            })
        });
        return errors;
    }),
    cacCertificateDocument: Joi.alternatives(Joi.string(), Joi.object()).required().label("CAC Certificate Document").error(errors => {
        errors.forEach(err => {
            err.context.reason?.forEach(err2 => {
                switch (err2.type) {
                    case "any.empty":
                    case "any.required":
                        err2.message = "CAC Certificate document is required for verification";
                        break;
                    case "object.base":
                        err2.message = "CAC Certificate document is required for verification";
                        break;
                    default:
                        break;
                }
            })
        });
        return errors;
    }),
    memorandumDocument: Joi.alternatives(Joi.string(), Joi.object()).required().label("Memorandum of incoperation").error(errors => {
        errors.forEach(err => {
            err.context.reason?.forEach(err2 => {
                switch (err2.type) {
                    case "any.empty":
                    case "any.required":
                        err2.message = "Memorandum of incoperation document is required for verification";
                        break;
                    case "object.base":
                        err2.message = "Memorandum of incoperation document is required for verification";
                        break;
                    default:
                        break;
                }
            })
        });
        return errors;
    }),
    scumlDocument: Joi.alternatives(Joi.string(), Joi.object()).required().label("SCUML Document").error(errors => {
        errors.forEach(err => {
            err.context.reason?.forEach(err2 => {
                switch (err2.type) {
                    case "any.empty":
                    case "any.required":
                        err2.message = "SCUML document is required for verification";
                        break;
                    case "object.base":
                        err2.message = "SCUML document is required for verification";
                        break;
                    default:
                        break;
                }
            })
        });
        return errors;
    }),
    utilityBillDocument: Joi.alternatives(Joi.string(), Joi.object()).required().label("Utility Bill").error(errors => {
        errors.forEach(err => {
            err.context.reason?.forEach(err2 => {
                switch (err2.type) {
                    case "any.empty":
                    case "any.required":
                        err2.message = "Utility bill document is required for verification";
                        break;
                    case "object.base":
                        err2.message = "Utility bill document is required for verification";
                        break;
                    default:
                        break;
                }
            })
        });
        return errors;
    }),
    country: Joi.string().required().label("Country").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a country";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid country`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    dateOfBirth: Joi.string().required().label("Date of Birth").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = `Please enter the date of birth`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    currency: Joi.string().required().label("Currency").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a currency";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid currency`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    bankCode: Joi.string().required().label("Bank Code").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a bank code";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid bank code`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    bankName: Joi.string().required().label("Bank Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a bank name";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid bank name`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    accountNumber: Joi.string().required().label("Account Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter an account number";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid account number`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    accountName: Joi.string().required().label("Account Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter an account name";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid account name`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    isPrimary: Joi.boolean().required().label("Is Primary").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "boolean.empty":
                case "boolean.required":
                    err.message = "Please select if account is primary or not";
                    break;
                case "boolean.base":
                    err.message = "Please select if account is primary or not";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    // eslint-disable-next-line
    webhookUrl: Joi.string().regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).label("Webhook URL").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a webhook URL";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid webhook URL`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    // eslint-disable-next-line
    webhookCallback: Joi.string().regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).label("Webhook Callback").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a webhook callback URL";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid webhook callback URL`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    // eslint-disable-next-line
    website: Joi.string().regex(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).label("Website").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a website";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid website`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    tin: Joi.string().regex(/^[0-9]+[0-9\\-]*$/).required().label("TIN Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                    err.message = "Please enter the TIN number";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid TIN number`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    role: Joi.string().required().label("Role").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a role";
                    break;
                case "string.regex.base":
                    err.message = `Please select a valid role`;
                        break;
                default:
                    break;
            }
        });
        return errors;
    }),
    address: Joi.string().required().label("Address").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the address";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    description: Joi.string().min(3).required().label("Description").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a description";
                    break;
                case "string.min":
                    err.message = `Please select a valid description`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    meansOfIdentification: Joi.string().required().label("Means of Identitification").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a means of identitification";
                    break;
                case "string.regex.base":
                    err.message = `Please enter a valid means of identitification`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    bvn: Joi.string().regex(/^[0-9]{11}$/).label("BVN").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "BVN is a required field";
                    break;
                case "string.min":
                case "string.max":
                case "string.regex.base":
                    err.message = `BVN must contain 11 digits`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    nin: Joi.string().regex(/^[0-9]{11}$/).label("NIN").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "NIN is a required field";
                    break;
                case "string.min":
                case "string.max":
                case "string.regex.base":
                    err.message = `NIN must contain 11 digits`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
}


const validate = (data) => {
    if (!data) {
        return { 0: "Please provide the form data to validate" };
    }

    const dataKeys = Object.keys(data);
    let schema = {};
    let missingSchemas = [];

    for (const key of dataKeys) {
        if (schemas[key]) {
            schema[key] = schemas[key];
        } else {
            missingSchemas.push(key);
        }
    }

    if (missingSchemas.length > 0) {
        const missingKeys = missingSchemas.join(', ');
        return { missing: `Validation for the following keys has not been configured: ${missingKeys}` };
    }

    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(data, schema, options);
    const error = result.error;
    if (!error) return null;

    const errors = {};
    for (const item of result.error.details) {
        errors[item.path[0]] = item.message;
    }
    return errors;
};

export default validate;
