import CenterModal from 'components/common/CenterModal'
import { Input } from 'components/common/form';
import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import { Transaction } from 'types/transaction';
import { useSharedStore } from 'zustand-store';
import { ReactComponent as IconDown } from "assets/images/icons/arrow-down-green.svg";
import TextAreaNote from 'components/common/TextAreaNote';
import CustomAmountField from 'components/custom/CustomAmountField';
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import { createRefund } from 'store/refund/action';
import { useNavigate } from 'react-router-dom';
import PATHS from 'NavigationRoute';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   transaction: Transaction | undefined;
}

const defaultForm = {
   customerName: '',
   email: '',
   transactionType: '',
   transactionReference: '',
   amount: '',
   note: '',
}

const PaymentRefundModal = ({
   isOpen,
   setIsOpen,
   transaction,
}: Props) => {
   const navigate = useNavigate();
   const { currencies } = useSharedStore();
   const { dispatch, useSelector, toastError, toastSuccess } = useToolkit();
   const [form, setForm] = useState(defaultForm);
   const [noteRef, setNoteRef] = useState('');
   const actual = currencies?.find((curr: any) => curr?.currency === transaction?.currency);
   const inputExceedsTrxAmount = transaction?.amount !== undefined && (Number(form?.amount) > transaction?.amount);
   const { createRefundData: refundData, createRefundLoading } = useSelector((state: RootState) => state.refunds)

   const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.value.length > 0) {
         const value = event.target.value;

         setForm(({ ...form, amount: value }))
      }
   };

   const submitRefundRequest = () => {
      const payload = {
         reference: transaction?.reference,
         amount: Number(form.amount),
         note: noteRef
      }
      dispatch(createRefund(payload))
   }

   useEffect(() => {
      if (refundData?.message?.length && isOpen === true) {
         if (refundData?.status === true) {
            toastSuccess(refundData?.message);
            setIsOpen(false);
            setTimeout(() => {
               navigate(PATHS.REFUNDS.REFUNDS_HOMEPAGE)
            }, 1500);
         } else if (refundData?.status === false) {
            toastError(refundData?.message);
            setTimeout(() => {
               setIsOpen(false)
            }, 1500);
         }
      }
      // eslint-disable-next-line
   }, [refundData])

   useEffect(() => {
      setForm(defaultForm)
   }, [])

   const buttonDisabled = !form.customerName?.length || !form.email?.length ||
      !form.transactionType?.length || (!form.amount?.length || inputExceedsTrxAmount) || !noteRef?.length;

   const fullName = `${transaction?.paymentCustomer?.firstName || 'N/A'} ${transaction?.paymentCustomer?.lastName || ''}`;

   return (
      <CenterModal
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         headerTitle='Refund Payment'
         hasButton
         buttonText='Process Refund'
         isButtonLoading={createRefundLoading}
         onButtonClick={submitRefundRequest}
         buttonDisabled={buttonDisabled}>
         <div className='w-full'>

            <div className="flex-btw flex-wrap">
               <Input
                  disabled
                  type="text"
                  label="Customer's Name"
                  placeholder="Enter name"
                  name='customerName'
                  value={fullName}
                  containerClass='w-full lg:w-[47.5%]'
               />

               <Input
                  disabled
                  type="email"
                  label="Email Address"
                  placeholder="Enter email"
                  name='email'
                  value={transaction?.paymentCustomer?.email}
                  containerClass='w-full lg:w-[47.5%]'
               />

               <Input
                  disabled
                  type="text"
                  label="Transaction type"
                  placeholder="Enter type"
                  name='transactionType'
                  value={transaction?.type}
                  containerClass='w-full lg:w-[47.5%]'
               />

               <Input
                  type="text"
                  label="Transaction reference"
                  placeholder="Enter reference"
                  name='transactionReference'
                  value={transaction?.reference}
                  disabled
                  containerClass='w-full lg:w-[47.5%]'
               />

               <CustomAmountField
                  leftLabel='Amount to refund'
                  isFieldRequired
                  setInputAmount={updateAmount}
                  inputError={inputExceedsTrxAmount}
                  errorText={inputExceedsTrxAmount ?
                     'Refund amount cannot exceed transaction amount' :
                     'Refunds are processed within 3 to 15 business working days'
                  }
                  flagIconUrl={actual?.icon || ""}
                  inputAmount={form.amount}
                  transactionCurrency={transaction?.currency || ''}
                  rightLabel={
                     <button onClick={() => {
                        setForm({ ...form, amount: transaction?.amount?.toString() || '0' })
                     }}>
                        <p className="text-shiga-dark flex items-center font-inter">
                           <span className="mr-1">
                              <IconDown />
                           </span>

                           Transaction amount:&nbsp;
                           <span className="text-shiga-purple font-medium">
                              {`${Number(transaction?.amount).toLocaleString()} ${transaction?.currency ?? ''}`}
                           </span>
                        </p>
                     </button>
                  }
               />

               <TextAreaNote
                  note={noteRef}
                  setNote={setNoteRef}
                  label='Add a note'
                  placeholder='Add a reason for logging this refund'
               />

            </div>

         </div>
      </CenterModal>
   )
}

export default PaymentRefundModal