import * as actions from "./actionTypes";

const initialState = {
    isUrlBannerHidden: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.UPDATE_URL_BANNER:
            state = {
                ...state,
                isUrlBannerHidden: action.payload,
            }
            return state;


        default:
            return state;
    }
}