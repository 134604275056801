import { Link, Outlet } from "react-router-dom";
import logo from "assets/images/icons/logo-black.svg";
import URLBanner from "components/common/URLBanner";
import Footer from "components/common/Footer";
import { ReactComponent as IconGlobe } from "assets/images/icons/Support.svg";


const AuthLayout = () => {
    return ( 
        <div className="min-h-screen flex flex-col justify-between">
            <div>
                <URLBanner />
                <Header />
            </div>

            <div className="w-full px-6 py-20 flex justify-center">
                <div className="w-full max-w-sm">
                    <Outlet />
                </div>
            </div>

            <Footer />
        </div>
    );
}


const Header = () => {
    return (
        <div className="w-full px-6 h-16 md:h-20 mx-auto flex items-center justify-between border-b border-gray-100">
            <Link to="/" className="h-6 flex-shrink-0">
                <img src={logo} alt="logo" className="w-full h-full object-contain" />
            </Link>
            <div className="flex justify-end items-center">
                <span>Need help?</span>
                <IconGlobe className="mx-1" />
                <Link to="mailto:support@payshiga.com" className="font-medium relative top-px border-b-2 border-transparent hover:border-black">Contact Support</Link>
            </div>
        </div>
    )
}
 

export default AuthLayout;