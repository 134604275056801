export const companySize = [
    "1 - 5",
    "6 - 10",
    "11 - 50",
    "51 - 100",
    "101 - above",
];

export const annualVolume = [
    "$1 - $1,000",
    "$1,001 - $10,000",
    "$10,001 - $100,000",
    "$100,001 - $1,000,000",
    "$1,000,001 - above",
];

export const meansOfIdentication:any = {
    "NIN": "NIN - National Identification Number",
    "BVN": "BVN - Bank Verification Number",
    "PASSPORT": "International Passport",
    "DRIVER_LICENSE": "Driver’s License",
    "PERMANENT_VOTERS_CARD": "Permanent Voter’s Card",
};

export const countryIndex:any = {
    "UK": "United Kingdom",
    "NG": "Nigeria",
};

export const memberRoles:any = {
    "OWNER": "Business Owner",
    "DEVELOPER": "Developer",
    "SUPPORT": "Support",
    "ADMIN": "Administrator",
    "OPERATIONS": "Operations",
    "USER": "User",
};

export const networkProviders: { [x: string]: string } = {
    "MTN": "MTN Nigeria",
    "GLO": "Globacom",
    "AIRTEL": "Airtel Nigeria",
    "9MOBILE": "9 Mobile",
}

export const billCurrencies = ["NGN"];

export const dataPlans = ["100 GB for 30days"];

export const cableProviders = ["DSTV", "GOTV"];

export const cableTvPlans = ["COMPACT PLUS", "EXPLORER"];

export const utilityServiceProviders = ["EKEDC", "IKEDC"];

export const utilityPlans = ["Prepaid", "Postpaid"];

export const permissionsData = [
    {
      label: "Owner",
      /**
       * @type string[]
       */
      permissions: [],
      active: true,
    },
    {
      label: "Admin",
      /**
       * @type string[]
       */
      permissions: [],
      active: false,
    },
    {
      label: "Developer",
      /**
       * @type string[]
       */
      permissions: [],
      active: false,
    },
    {
      label: "Analyst",
      /**
       * @type string[]
       */
      permissions: [],
      active: false,
    },
    {
      label: "Operations",
      /**
       * @type string[]
       */
      permissions: [],
      active: false,
    },
    {
      label: "Support",
      /**
       * @type string[]
       */
      permissions: [],
      active: false,
    },
  ];
  
  export const permissionsDetailsData = [
    {
      permission: "Transactions",
      options: [
        {
          label: "Can view transactions",
          value: "CAN_VIEW_TRANSACTIONS",
        },
        {
          label: "Export transactions",
          value: "EXPORT_TRANSACTIONS",
        },
      ],
    },
    {
      permission: "Balance",
      options: [
        {
          label: "Can view balances",
          value: "CAN_VIEW_BALANCES",
        },
        {
          label: "Can fund balances",
          value: "CAN_FUND_BALANCES",
        },
        {
          label: "Can view settlements",
          value: "CAN_VIEW_SETTLEMENTS",
        },
      ],
    },
    {
      permission: "Transfer",
      options: [
        {
          label: "Can view transfers",
          value: "CAN_VIEW_TRANSFERS",
        },
        {
          label: "Can create transfers",
          value: "CAN_CREATE_TRANSFERS",
        },
      ],
    },
    {
      permission: "Cards",
      options: [
        {
          label: "Can view cards",
          value: "CAN_VIEW_CARDS",
        },
        {
          label: "Can create cards",
          value: "CAN_CREATE_CARDS",
        },
        {
          label: "Can fund cards",
          value: "CAN_FUND_CARDS",
        },
        {
          label: "Can freeze and unfreeze cards",
          value: "CAN_FREEZE_AND_UNFREEZE_CARDS",
        },
        {
          label: "Can delete cards",
          value: "CAN_DELETE_CARDS",
        },
      ],
    },
    {
      permission: "Bill payments",
      options: [
        {
          label: "Can view bill payments",
          value: "CAN_VIEW_BILL_PAYMENTS",
        },
        {
          label: "Can initiate bill payments",
          value: "CAN_INITIATE_BILL_PAYMENTS",
        },
      ],
    },
    {
      permission: "Payment",
      options: [
        {
          label: "Can view payment links",
          value: "CAN_VIEW_PAYMENT_LINKS",
        },
        {
          label: "Can create payment links",
          value: "CAN_CREATE_PAYMENT_LINKS",
        },
        {
          label: "Can edit payment links",
          value: "CAN_EDIT_PAYMENT_LINKS",
        },
      ],
    },
    {
      permission: "Store",
      options: [
        {
          label: "Can create products",
          value: "CAN_CREATE_PRODUCTS",
        },
        {
          label: "Can view products",
          value: "CAN_VIEW_PRODUCTS",
        },
        {
          label: "Can manage products",
          value: "CAN_MANAGE_PRODUCTS",
        },
      ],
    },
    {
      permission: "Invoices",
      options: [
        {
          label: "Can view invoices",
          value: "CAN_VIEW_INVOICES",
        },
        {
          label: "Can create invoices",
          value: "CAN_CREATE_INVOICES",
        },
        {
          label: "Can edit invoices",
          value: "CAN_EDIT_INVOICES",
        },
      ],
    },
    {
      permission: "Settings",
      options: [
        {
          label: "Can manage preferences",
          value: "CAN_MANAGE_PREFRENECES",
        },
        {
          label: "Can manage fees and earnings",
          value: "CAN_MANAGE_FEES_AND_EARNINGS",
        },
        {
          label: "Can manage payment methods",
          value: "CAN_MANAGE_PAYMENTS_METHODS",
        },
        {
          label: "Can manage settlement accounts",
          value: "CAN_MANAGE_SETTLEMENT_ACCOUNTS",
        },
        {
          label: "Can manage notification settings",
          value: "CAN_MANAGE_NOTIFICATION_SETTINGS",
        },
        {
          label: "Can view and generate API keys",
          value: "CAN_VIEW_AND_GENERATE_API_KEYS",
        },
        {
          label: "Can view and update webhooks",
          value: "CAN_VIEW_AND_UPDATE_WEBHOOKS",
        },
      ],
    },
  ];
