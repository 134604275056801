import { Steps } from "types/payment/steps";
import user from "assets/images/icons/user_circle.png";
import { ReactComponent as Note } from "assets/images/icons/note.svg";
import dollar from "assets/images/icons/dollar-2.svg";
import paperPlane from "assets/images/icons/paper-plane.svg";

const CREATE_PAYMENT_LINK_STEP: Steps[] = [
  {
    name: "Payment type",
    icon: <Note className="w-6 h-6" />,
    slug: "payment_type",
  },
  {
    name: "Personalize link",
    icon: <img src={user} alt="user" />,
    slug: "personalize_link",
  },
];

export const CREATE_TUTION_STEPS: Steps[] = [
  {
    name: "Student Info",
    icon: <img src={user} alt="user" />,
    slug: "student_info",
  },
  {
    name: "Amount to Send",
    icon: <img src={dollar} alt="dollar" />,
    slug: "amount",
  },
  {
    name: "Review & Pay",
    icon: <img src={paperPlane} alt="review and pay" />,
    slug: "review_pay",
  },
];

export default CREATE_PAYMENT_LINK_STEP;
