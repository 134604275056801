import { StepHeading } from "components/common/StepHeading";
import { Separator } from "components/ui/separator";
import { PURCHASE_GIFTCARD_STEPS } from "constants/payments/purchase.giftcard.steps";
import { useSearchParams } from "react-router-dom";
import { useGiftCardStore } from "zustand-store/payments/gift-cards";
import { PAYMENT_PATHS } from "../paths";
import { PaymentInfo } from "./payment.iinfo";
import { ReviewAndSend } from "./review.send";

export const PurhaseGiftCard = () => {
  const [searchParams] = useSearchParams();
  const { completed } = useGiftCardStore();
  const tab = searchParams.get("tab") || PURCHASE_GIFTCARD_STEPS[0].slug;
  return (
    <div className="p-10 w-full space-y-4">
      <div className="flex items-center justify-center w-full">
        <StepHeading
          completed={completed}
          tab={tab}
          steps={PURCHASE_GIFTCARD_STEPS}
          path={PAYMENT_PATHS.GIFT_CARD_PURCHASE}
        />
      </div>

      <Separator />
      {getComp(tab)}
    </div>
  );
};

const getComp = (tab: string) => {
  switch (tab) {
    case "payment_info":
      return <PaymentInfo />;
    default:
      return <ReviewAndSend />;
  }
};
