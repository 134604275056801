import { useRef, useState, useEffect } from "react";

export const GetShimmer: React.FC<{
    children: React.ReactNode;
    loading: boolean
}> = ({ children, loading }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const handleSetDimension = () => {
        setDimensions({
            height: ref.current?.clientHeight || 0,
            width: ref.current?.clientWidth || 0
        })
    }
    useEffect(() => {
        handleSetDimension()
        return () => {
            window.removeEventListener("resize", handleSetDimension)
        }
    }, [])
    window.addEventListener("resize", handleSetDimension);

    return (
        <div className="relative" ref={ref}>
            {
                loading ?
                    <div style={{
                        height: `${dimensions.height}px`,
                        width: `${dimensions.width}px`
                    }} className={`absolute z-10 rounded-md top-0 left-0 shimmer`} /> : <></>
            }
            {children}
        </div>
    )
}

export default GetShimmer;