import React, { useEffect, useState } from "react";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { useDispatch, useSelector } from "react-redux";
import * as settingsActions from "store/entities/settings/action";

// Define types for the permissions data
interface Permission {
  id: string;
  permissionName: string;
  description: string;
  allowed: boolean;
}

interface Role {
  roleName: string;
  permissions: Permission[];
}

interface PermissionModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const PermissionsModal: React.FC<PermissionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [activeRoleIndex, setActiveRoleIndex] = useState<number | null>(0);
  const [roles, setRoles] = useState<Role[]>([]);
  const { businessRolesPermission } = useSelector(
    (s: any) => s.entities.settings
  );

  useEffect(() => {
    if (!businessRolesPermission) return;
    dispatch(settingsActions.getBusinessRolesPermission());
  }, [dispatch]);

  useEffect(() => {
    if (businessRolesPermission) {
      setRoles(businessRolesPermission?.data);
    }
  }, [businessRolesPermission]);

  const switchActivePermission = (index: number) => {
    setActiveRoleIndex(index);
  };

  const handleCheckboxChange = async (permissionId: string) => {
    if (activeRoleIndex === null) return;

    const updatedRoles = roles?.map((role, roleIndex) => {
      if (roleIndex !== activeRoleIndex) return role;

      const updatedPermissions = role.permissions.map((permItem: any) => {
        if (permItem.id !== permissionId) return permItem;
        return {
          ...permItem,
          allowed: !permItem.allowed,
        };
      });

      return {
        ...role,
        permissions: updatedPermissions,
      };
    });

    setRoles(updatedRoles);

    await dispatch(settingsActions.updateBusinessRolePermissions(permissionId));
  };
  // const togglePermissionValue = (permissionValue: string, checked: boolean) => {
  //   if (activeRoleIndex === null) return;

  //   const updatedRoles = roles.map((role, roleIndex) => {
  //     if (roleIndex !== activeRoleIndex) return role;

  //     dispatch(settingsActions.updateBusinessRolePermissions(id ,{
  //       allowed:checked
  //     }))
  //     // updateBusinessRolePermissions
  //     const updatedPermissions = role.permissions.map((permItem: Permission) => {
  //       if (permItem.permissionName !== permissionValue) return permItem;
  //       return {
  //         ...permItem,
  //         allowed: checked,
  //       };
  //     });

  //     return {
  //       ...role,
  //       permissions: updatedPermissions,
  //     };
  //   });

  //   setRoles(updatedRoles);
  // };

  const groupPermissionsByBaseName = (permissions: Permission[]) => {
    return permissions.reduce((acc: any, perm: Permission) => {
      const baseNameMatch = perm.permissionName.match(/^(.*?)_PERMISSION/);
      if (baseNameMatch) {
        const baseName = baseNameMatch[1].replace("_", " ");
        if (!acc[baseName]) {
          acc[baseName] = [];
        }
        acc[baseName].push(perm);
      }
      return acc;
    }, {});
  };

  return (
    <div>
      {/* Modal content */}
      <div className="bg-white rounded-2xl shadow-xl transform transition-all  max-h-[95vh]">
        {/* content container */}
        <div className="h-full flex flex-col px-6 pb-8">
          {/* Modal header */}
          <div className="bg-[#ffffff] border-b border-[#F5F5F5] pl-6 pr-5 py-6 flex items-center justify-between">
            <h2 className="font-[400] font-ojah text-semiblack text-[24px] font-medium font-shiga">
              Manage Roles
            </h2>
            <div
              onClick={onClose}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>

          {/* Modal body */}
          <div className="">
            <div className="flex-1 pt-8 pb-4 mb-4 text-text-semiblack flex flex-col justify-between border-b border-[#F5F5F5]">
              <div className="flex overflow-hidden">
                <div className="border-r border-faint overflow-auto">
                  <ul className="grid gap-1 font-shiga text-[16px]">
                    {roles.map((roleItem, index) => (
                      <li key={index}>
                        <button
                          onClick={() => switchActivePermission(index)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              switchActivePermission(index);
                            }
                          }}
                          className={`pr-10 py-2.5 pl-2 text-[16px] font-[500] cursor-pointer hover:bg-deep-blue/5 w-full ${
                            index === activeRoleIndex
                              ? "bg-[#FAFAFF] text-text-semiblack border-r-4 border-shiga-purple-2"
                              : "bg-transparent text-shiga-gray"
                          }`}
                          role="menuitem"
                        >
                          {roleItem.roleName.charAt(0).toUpperCase() +
                            roleItem.roleName.slice(1).toLowerCase()}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-1 space-y-6 overflow-y-scroll custom-scrollbar pr-2 h-[300px] w-auto pl-4 pb-4">
                  {activeRoleIndex !== null &&
                    roles[activeRoleIndex] &&
                    (() => {
                      const groupedPermissions = groupPermissionsByBaseName(
                        roles[activeRoleIndex].permissions
                      );

                      return (
                        <div className="space-y-4 mb-8">
                          {Object.keys(groupedPermissions).map((baseName) => (
                            <div key={baseName}>
                              <h3
                                className="text-shiga-gray font-ojah font-[400] capitalize mb-4 text-[20px]"
                                style={{
                                  letterSpacing: "0.5px !important",
                                  lineHeight: 1.25,
                                }}
                              >
                                {baseName.charAt(0).toUpperCase() +
                                  baseName.slice(1).toLowerCase()}{" "}
                                Permission
                              </h3>
                              <div className="space-y-3">
                                {groupedPermissions[baseName].map(
                                  (permItem: Permission) => (
                                    <div
                                      key={permItem.permissionName}
                                      className="text-[14px] text-[#343433] flex items-center gap-3"
                                    >
                                      <input
                                        className="w-6 h-6 border rounded-md border-[#E2E3E5]"
                                        type="checkbox"
                                        checked={permItem.allowed}
                                        // onChange={(e) => togglePermissionValue(permItem.permissionName, e.target.checked)}
                                        onChange={() =>
                                          handleCheckboxChange(permItem?.id)
                                        }
                                      />
                                      {permItem.description}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })()}
                </div>
              </div>
            </div>
          </div>
          {/* Assign button */}
          <button
            className="my-0 bg-shiga-black text-white px-6 py-3 w-full rounded-[16px]"
            onClick={onClose}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default PermissionsModal;
