import { BillPayment } from "./billPayment";
import { Business } from "./business";
import { Deposit } from "./deposit";
import { MethodType, STATUS } from "./misc";
import { Tuition } from "./tuition";

export interface Transaction {
    id: string;
    amount: number;
    formattedAmount?: string;
    status: STATUS;
    fee: string;
    description: string;
    type: "CREDIT" | "DEBIT" | string;
    channel: MethodType;
    // If Medium === API, add it next to method
    // If Medium === Dashboard, show initiated by
    medium: string;
    method: MethodType;
    formattedMethod?: string;
    currency: string;
    createdAt: string;
    updatedAt: string;
    business?: Business;
    businessId: string;
    reference?: string;
    depositId: string;
    spendingId: string;
    billPaymentId: string;
    refundId: string;
    topupId: string;
    tuitionId: string;
    walletId: string;
    cardId: string;
    swapId: string;
    paymentLinkId: string;
    topup?: string;
    spending?: string;
    refund?: string;
    deposit?: Deposit;
    name?: string;
    references?: string[];
    billPayment?: BillPayment;
    wallet: {};
    card: {};
    swap: string;
    balance: string;
    bankTransfer?: {
        beneficiaryAccountName: string;
        beneficiaryAccountNumber: string;
        beneficiaryBankName: string;
        debitAccountName: string
        debitAccountNumber: string
        debitBankName: null;
        sessionId: string;
        country?: string;
        narration?: string;
    }
    tuition?: Tuition;
    paymentLink?: string;
    paymentCustomer?: {
        firstName: string;
        lastName: string;
        email: string;
    };
    initiatedByAccount?: {
        firstName: string;
        lastName: string
    }
    note?: string;
    cardTransaction?: {
        id: string;
        last4: string;
        ip: string;
        accountName: string;
        countryCode: string;
        bank: string;
        brand: string;
        transactionId: string;
        businessId: string;
        createdAt: string;
        updatedAt: string;
    }
    recipientEmail?: string;
}

export const defaultTransaction: Transaction = {
    amount: 0,
    balance: "",
    billPaymentId: "",
    businessId: "",
    card: {},
    cardId: "",
    channel: "",
    createdAt: "",
    currency: "NGN",
    depositId: "",
    description: "",
    fee: "",
    id: "",
    medium: "",
    method: "",
    paymentLinkId: "",
    refundId: "",
    spendingId: "",
    status: "PENDING",
    swap: "",
    swapId: "",
    topupId: "",
    tuitionId: "",
    type: "",
    updatedAt: "",
    wallet: "",
    walletId: ""
}

export const getMethodLabel: Record<MethodType, string> & { null: string } & Record<Transaction['type'], string> = {
    "BANK_TRANSFER": "Bank Transfer",
    "BILL_PAYMENT": "Bills Payment",
    "TUITION": "Tuition",
    "CARD": "Card",
    "DEPOSIT": "Deposit",
    "REFUND": "Refund",
    "SPENDINGS": "Spendings",
    "SWAP": "Swap",
    "TOPUP": "Topup",
    "GIFT_CARD": "Gift Card",
    "WALLET": "Wallet",
    "DIRECT_DEBIT": "Direct Debit",
    "CREDIT": "CREDIT",
    "DEBIT": "DEBIT",
    "": "----",
    null: "----"
}